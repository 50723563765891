import { Form, Input } from "antd";
import React, { Component } from "react";
import styles from "../Auth.module.scss";
import PropTypes, { arrayOf } from "prop-types";

export class FormItemInput extends Component {
  render() {
    const { addonBefore, getFieldDecorator, name, label, rules, placeholder } =
      this.props;
    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, {
          rules,
        })(
          <Input
            addonBefore={addonBefore || <></>}
            className={styles.inputItem}
            placeholder={placeholder}
          />
        )}
      </Form.Item>
    );
  }
}

FormItemInput.defaultProps = {
  addonBefore: null,
  getFieldDecorator: null,
  name: null,
  rule: null,
  placeholder: "",
};
FormItemInput.propTypes = {
  addonBefore: PropTypes.element,
  getFieldDecorator: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.element,
  rule: arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      message: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string
};

export default FormItemInput;
