import React from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import { googleMapApiKey } from "../../config/main";
import Markers from "./markers";
import * as actions from "../../store/actions";
import _ from "lodash";

class MapCustom extends React.Component<{
  center: {},
  zoom: number,
  onRef?: void,
}> {
  constructor(props) {
    super(props);
    this.state = {
      center: props.center || {
        lat: 10.7724535,
        lng: 106.6652484,
      },
      zoom: props.zoom || 15,
      maps: null,
      map: null,
    };
  }

  componentDidMount(): void {
    if (this.props.onRef) this.props.onRef(this);
  }

  componentWillUnmount(): void {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    if (
      !_.isEmpty(
        _.differenceWith(this.props.dataSource, prevProps.dataSource, _.isEqual)
      )
    ) {
      if (this.state.maps) {
        let bounds = new this.state.maps.LatLngBounds();
        this.props.dataSource.forEach((item) => {
          let latLng = new this.state.maps.LatLng(item.lat, item.lng);
          bounds.extend(latLng);
        });
        this.state.map.fitBounds(bounds);
      }
    }
  }

  createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT,
        style: maps.ZoomControlStyle.SMALL,
      },
      fullscreenControl: false,
    };
  };

  workingOnMap = (map, maps) => {
    if (maps) {
      this.setState(
        {
          maps,
          map,
        },
        this.fitBoundsMap
      );
    }
  };

  fitBoundsMap = () => {
    const { maps, map } = this.state;
    let bounds = new maps.LatLngBounds();
    this.props.dataSource.forEach((item) => {
      let latLng = new maps.LatLng(item.lat, item.lng);
      bounds.extend(latLng);
    });
    map.fitBounds(bounds);
  };

  onChildClick = (item, zoom = null) => {
    if (this.state.maps) {
      item.show = !item.show;
      this.props.showMarker(item);
      this.setState({
        center: {
          lat: item.lat,
          lng: item.lng,
        },
        zoom: zoom,
      });
    }
  };

  _onMapClick = () => {
    this.props.closeMarker(this.props.dataSource);
  };

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <GoogleMapReact
        ref={this.props.ref}
        options={this.createMapOptions}
        bootstrapURLKeys={{ key: googleMapApiKey }}
        center={this.state.center}
        zoom={this.state.zoom}
        onClick={this._onMapClick}
        onGoogleApiLoaded={({ map, maps }) => this.workingOnMap(map, maps)}
      >
        {this.props.dataSource.map((item, i) => (
          <Markers
            lat={item.lat}
            lng={item.lng}
            key={i}
            marker={item}
            show={item.show}
            onChildClick={this.onChildClick}
          />
        ))}
      </GoogleMapReact>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSource: state.searchData.data || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showMarker: (item) => dispatch(actions.searchDataUpdate(item)),
    closeMarker: (data) => dispatch(actions.closeAllMarker(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapCustom);
