/*global google*/
import React from "react";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Checkbox,
  Select,
  Icon,
  Spin,
  Radio,
  Upload,
  Modal,
  Typography, 
} from "antd";
import styles from "../real_estate_form/realEstateForm.module.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PostRealEstatePageApi from "../../api/postRealEstatePage";

import DatNen from "../15-types-real-estate/datNen";
import CanHo from "../15-types-real-estate/canHo";
import NhaPho from "../15-types-real-estate/nhaPho";
import BietThu from "../15-types-real-estate/bietThu";
import DuAn from "../15-types-real-estate/duAn";
import ShopHouseCanHo from "../15-types-real-estate/shophouseCanHo";
import ShopHouseNhaPho from "../15-types-real-estate/shophouseNhaPho";
import NhaXuong from "../15-types-real-estate/nhaXuong";
import Officetel from "../15-types-real-estate/officetel";
import NhaHang from "../15-types-real-estate/nhaHang";
import KhachSan from "../15-types-real-estate/khachSan";
import Cafe from "../15-types-real-estate/cafe";
import Codotel from "../15-types-real-estate/codotel";
import HomeStay from "../15-types-real-estate/homesStay";
import Building from "../15-types-real-estate/building";
import { uploadUrl, ResourceUrl } from "../../config/main";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "../../global.scss";
import slug from "slug"; 
import { redirectToPostRealEstate } from "../../store/actions/redirectToPostRealEstate";

const { Title } = Typography;
const { Option, OptGroup } = Select;
const OptionAutoComplete = AutoComplete.Option;

const typeRealEstates = [
  {
    type: "Đất nền",
    value: 1,
    component: <DatNen />,
  },
  {
    type: "Căn hộ",
    value: 2,
    component: <CanHo />,
  },
  {
    type: "Nhà phố",
    value: 3,
    component: <NhaPho />,
  },
  {
    type: "Biệt thự",
    value: 4,
    component: <BietThu />,
  },
  {
    type: "Dự án",
    value: 5,
    component: <DuAn />,
  },
  {
    type: "Shophouse căn hộ",
    value: 6,
    component: <ShopHouseCanHo />,
  },
  {
    type: "Shophouse nhà phố",
    value: 7,
    component: <ShopHouseNhaPho />,
  },
  {
    type: "Nhà xưởng",
    value: 8,
    component: <NhaXuong />,
  },
  {
    type: "Officetel",
    value: 9,
    component: <Officetel />,
  },
  {
    type: "Nhà hàng",
    value: 10,
    component: <NhaHang />,
  },
  {
    type: "Khách sạn",
    value: 11,
    component: <KhachSan />,
  },
  {
    type: "Cafe",
    value: 12,
    component: <Cafe />,
  },
  {
    type: "Codotel",
    value: 13,
    component: <Codotel />,
  },
  {
    type: "Homesstay",
    value: 14,
    component: <HomeStay />,
  },
  {
    type: "Building",
    value: 15,
    component: <Building />,
  },
];

const positions = [
  {
    mainPosition: `Mặt tiền`,
    detailPosition: [
      {
        name: "1MT",
        value: 1,
      },
      {
        name: "2MT",
        value: 2,
      },
      {
        name: "3MT",
        value: 3,
      },
      {
        name: "4MT",
        value: 4,
      },
    ],
  },
  {
    mainPosition: `Hẻm`,
    detailPosition: [
      {
        name: "Cấp 1",
        value: 5,
      },
      {
        name: "Cấp 2",
        value: 6,
      },
      {
        name: "Cấp 3",
        value: 7,
      },
      {
        name: "Cấp 4",
        value: 8,
      },
      {
        name: "> 4",
        value: 9,
      },
    ],
  },
];

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function getBase64FromListWall(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class RealEstateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeRealEstate: 1,
      body: "",
      confirmDirty: false,
      cities: [],
      districts: [],
      directions: [],
      streets: [],
      wards: [],
      list_project: [],

      real_estate: null,
      loading: false,
      imageUrl: null,

      isLoadingForm: true,

      previewVisible: false,
      fileList: [],

      file_list_insert: [],
      file_list_delete: [],

      currency: "",
      isSubmit: false,
      content: "", 
      phoneConfirm: "",
      listInternalOptions: [],
      listFurnitures: [],
      showSelectInternalOptions: false,
      showSelectFurnitures: false,

      selectedFurnitures: [],
      selectedInternalOptions: [],
    };
  }

  convertCategory = (name) => {
    if (name === "Đất nền") return 1;
    if (name === "Căn hộ") return 2;
    if (name === "Nhà phố") return 3;
    if (name === "Biệt thự") return 4;
    if (name === "Dự án") return 5;
    if (name === "Shophouse căn hộ") return 6;
    if (name === "Shophouse nhà phố") return 7;
    if (name === "Nhà xưởng") return 8;
    if (name === "Officetel") return 9;
    if (name === "Nhà hàng") return 10;
    if (name === "Khách sạn") return 11;
    if (name === "Cafe") return 12;
    if (name === "Codotel") return 13;
    if (name === "Homesstay") return 14;
    if (name === "Building") return 15;
  };

  componentDidMount = async () => {
    this.CKEditor = require("@ckeditor/ckeditor5-react");
    this.ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
    if (this.props.match.params.slug) {
      await PostRealEstatePageApi.getDetailRealEstate(
        this.props.match.params.slug
      ).then(async (res) => {
        if (res.data.status === 1) {
          let temp = res.data.project.real_estate_galleries;
          if (temp) {
            temp.forEach((item) => {
              item.url = `${ResourceUrl}${item.url}`;
            });
          } else {
            temp = [];
          }
          this.setState({
            real_estate: res.data.project,
            typeRealEstate: this.convertCategory(res.data.project.category),
            imageUrl: `${ResourceUrl}${res.data.project.cover_image}`,
            fileList: temp,
          });
          await PostRealEstatePageApi.autoCompleteProject(
            res.data.project.owner.title
          ).then((result) => {
            if (result.data.status === 1) {
              this.setState({
                list_project: result.data.list_project,
              });
            }
          });

          await PostRealEstatePageApi.getDistrictByCity(
            res.data.project.province_code
          ).then((respond) => {
            if (respond.data.status === 1) {
              this.setState({
                districts: respond.data.districts,
                isLoadingForm: false,
              });
            } else {
              alert(respond.data.message);
            }
          });

          await PostRealEstatePageApi.getWardsByDistrict(
            res.data.project.district_id
          ).then((result) => {
            if (result.data.status === 1) {
              this.setState({
                wards: result.data.wards,
              });
            }
          });

          await PostRealEstatePageApi.autoCompleteStreet(
            res.data.project.street
          ).then((re) => {
            if (re.data.status === 1)
              this.setState({
                streets: re.data.streets,
                body: res.data.project.body,
              });
          });

          await PostRealEstatePageApi.getAllInternalOptions().then((re) => {
            if (re.data.status === 1) {
              this.setState({
                listInternalOptions: re.data.internals,
              });
            } else {
              alert(re.data.message);
            }
          });

          await PostRealEstatePageApi.getAllFurniture().then((re) => {
            if (re.data.status === 1) {
              this.setState(
                {
                  listFurnitures: re.data.furnitures,
                  isLoadingForm: false,
                },
                () => {
                  let resultFurnitures = [],
                    resultInternals = [];
                  if (res.data.project.furnitures) {
                    let temp1 = [...res.data.project.furnitures];
                    temp1.forEach((item) => {
                      resultFurnitures.push(item.id);
                    });
                  }
                  if (res.data.project.internals) {
                    let temp2 = [...res.data.project.internals];
                    temp2.forEach((item) => {
                      resultInternals.push(item.id);
                    });
                  }
                  this.props.form.setFieldsValue({
                    internals: resultInternals,
                    furnitures: resultFurnitures,
                  });
                  this.setState({
                    selectedFurnitures: resultFurnitures,
                    selectedInternalOptions: resultInternals,
                  });
                }
              );
            } else {
              alert(re.data.message);
            }
          });
        }
      });
    }

    await PostRealEstatePageApi.getAllDirections().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          directions: res.data.directions,
        });
      } else {
        alert(res.data.message);
      }
    });

    if (!this.props.match.params.slug) {
      await PostRealEstatePageApi.getAllProject().then((res) => {
        if (res.data.status === 1) {
          this.setState({
            list_project: res.data.list_project,
          });
        } else {
          alert(res.data.message);
        }
      });

      if (this.props.domain.province_code) {
        this.setState(
          {
            province: this.props.domain.province_code,
          },
          () => {
            PostRealEstatePageApi.getDistrictByCity(
              this.props.domain.province_code
            ).then((res) => {
              if (res.data.status === 1) {
                this.setState({
                  districts: res.data.districts,
                });
                PostRealEstatePageApi.getWardsByDistrict(
                  res.data.districts[0].id
                ).then((result) => {
                  if (result.data.status === 1) {
                    this.setState({
                      wards: result.data.wards,
                    });
                  }
                });
                PostRealEstatePageApi.getStreetsByDistrict(
                  res.data.districts[0].id
                ).then((result) => {
                  if (result.data.status === 1) {
                    this.setState(
                      {
                        streets: result.data.streets,
                        isLoadingForm: false,
                      },
                      () => {
                        this.props.form.setFieldsValue({
                          street: result.data.streets[0].id.toString(),
                        });
                      }
                    );
                  }
                });
                PostRealEstatePageApi.getAllInternalOptions().then((re) => {
                  if (re.data.status === 1) {
                    this.setState({
                      listInternalOptions: re.data.internals,
                    });
                  } else {
                    alert(re.data.message);
                  }
                });

                PostRealEstatePageApi.getAllFurniture().then((re) => {
                  if (re.data.status === 1) {
                    this.setState({
                      listFurnitures: re.data.furnitures,
                    });
                  } else {
                    alert(re.data.message);
                  }
                });
              } else {
                alert(res.data.message);
              }
            });
          }
        );
      } else {
        this.setState(
          {
            province: "SG",
          },
          () => {
            PostRealEstatePageApi.getDistrictByCity("SG").then((res) => {
              if (res.data.status === 1) {
                this.setState({
                  districts: res.data.districts,
                });
                PostRealEstatePageApi.getWardsByDistrict(
                  res.data.districts[0].id
                ).then((result) => {
                  if (result.data.status === 1) {
                    this.setState({
                      wards: result.data.wards,
                    });
                  }
                });
                PostRealEstatePageApi.getStreetsByDistrict(
                  res.data.districts[0].id
                ).then((result) => {
                  if (result.data.status === 1) {
                    this.setState(
                      {
                        streets: result.data.streets,
                        isLoadingForm: false,
                      },
                      () => {
                        this.props.form.setFieldsValue({
                          street: result.data.streets[0].id.toString(),
                        });
                      }
                    );
                  }
                });
                PostRealEstatePageApi.getAllInternalOptions().then((re) => {
                  if (re.data.status === 1) {
                    this.setState({
                      listInternalOptions: re.data.internals,
                    });
                  } else {
                    alert(re.data.message);
                  }
                });

                PostRealEstatePageApi.getAllFurniture().then((re) => {
                  if (re.data.status === 1) {
                    this.setState({
                      listFurnitures: re.data.furnitures,
                    });
                  } else {
                    alert(re.data.message);
                  }
                });
              } else {
                alert(res.data.message);
              }
            });
          }
        );
      }
    }

    await PostRealEstatePageApi.getAllCities().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          cities: res.data.cities,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  selectCity = (e) => {
    this.setState(
      {
        province: e,
      },
      () => {
        PostRealEstatePageApi.getDistrictByCity(e).then((res) => {
          if (res.data.status === 1) {
            this.setState(
              {
                districts: res.data.districts,
              },
              () => {
                this.props.form.setFieldsValue({
                  district_id: res.data.districts[0].id,
                });
                this.selectDistrict(res.data.districts[0].id);
              }
            );
          } else {
            alert(res.data.message);
          }
        });
      }
    );
  };

  selectDistrict = (e) => {
    this.setState(
      {
        district: e,
      },
      () => {
        PostRealEstatePageApi.getWardsByDistrict(e).then((res) => {
          if (res.data.status === 1) {
            this.setState(
              {
                wards: res.data.wards,
              },
              () => {
                this.props.form.setFieldsValue({
                  ward_id: res.data.wards[0].id,
                });
              }
            );
          } else {
            alert(res.data.message);
          }
        });
        PostRealEstatePageApi.getStreetsByDistrict(e).then((res) => {
          if (res.data.status === 1) {
            this.setState(
              {
                streets: res.data.streets,
              },
              () => {
                this.props.form.setFieldsValue({
                  street: res.data.streets[0].id.toString(),
                });
              }
            );
          } else {
            alert(res.data.message);
          }
        });
      }
    );
  };

  handleChangeTypeRealEstate = (e) => {
    this.setState({
      typeRealEstate: e,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isSubmit: true,
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        var detectAddressLat, detectAddressLng;

        let nameProvince, nameDistrict, nameWard;
        this.state.cities.forEach((item) => {
          if (item.code === values.province_code) nameProvince = item.name;
        });
        this.state.districts.forEach((item) => {
          if (item.id === values.district_id) nameDistrict = item.name;
        });
        this.state.wards.forEach((item) => {
          if (item.id === values.ward_id) nameWard = item._name;
        });

        var geocoder = new google.maps.Geocoder();
        await geocoder.geocode(
          {
            address: `${values.address_number} ${values.street} ${nameWard} ${nameDistrict} ${nameProvince}`,
          },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              detectAddressLat = results[0].geometry.location.lat();
              detectAddressLng = results[0].geometry.location.lng();
              values = {
                ...values,
                slug: slug(values.title),
                body: this.state.body,
                images: this.state.fileList,
                lat: detectAddressLat,
                lng: detectAddressLng,
              };

              if (!this.props.match.params.slug) {
                PostRealEstatePageApi.addNew(values).then((res) => {
                  if (res.data.status === 1) {
                    this.props.form.resetFields();
                    Swal.fire({
                      icon: "success",
                      title: "Đăng tin thành công",
                      showConfirmButton: true,
                    });
                    this.setState({
                      isSubmit: false,
                    });
                    this.props.history.push("/user/real-estates");
                  } else {
                    alert(res.data.message);
                  }
                });
              } else {
                values = {
                  ...values,
                  slug: slug(values.title),
                  id: this.state.real_estate.id,
                  images_delete: this.state.file_list_delete,
                  images_insert: this.state.file_list_insert,
                };
                PostRealEstatePageApi.change(values).then((res) => {
                  if (res.data.status === 1) {
                    Swal.fire({
                      icon: "success",
                      title: "Sửa tin thành công",
                      showConfirmButton: true,
                    });
                    this.setState({
                      isSubmit: false,
                    });
                    this.props.history.push("/user/real-estates");
                  } else {
                    alert(res.data.message);
                  }
                });
              }
            } else {
              detectAddressLat = null;
              detectAddressLng = null;
              values = {
                ...values,
                slug: slug(values.title),
                body: this.state.body,
                images: this.state.fileList,
                lat: detectAddressLat,
                lng: detectAddressLng,
              };

              if (!this.props.match.params.slug) {
                PostRealEstatePageApi.addNew(values).then((res) => {
                  if (res.data.status === 1) {
                    this.props.form.resetFields();
                    Swal.fire({
                      icon: "success",
                      title: "Đăng tin thành công",
                      showConfirmButton: true,
                    });
                    this.setState({
                      isSubmit: false,
                    });
                    this.props.history.push("/user/real-estates");
                  } else {
                    alert(res.data.message);
                  }
                });
              } else {
                values = {
                  ...values,
                  slug: slug(values.title),
                  id: this.state.real_estate.id,
                  images_delete: this.state.file_list_delete,
                  images_insert: this.state.file_list_insert,
                }; 
                PostRealEstatePageApi.change(values).then((res) => {
                  if (res.data.status === 1) {
                    Swal.fire({
                      icon: "success",
                      title: "Sửa tin thành công",
                      showConfirmButton: true,
                    });
                    this.setState({
                      isSubmit: false,
                    });
                    this.props.history.push("/user/real-estates");
                  } else {
                    alert(res.data.message);
                  }
                });
              }
            }
          }
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Đăng tin thất bại",
          text: "Vui lòng nhập các thông tin cần thiết",
          showConfirmButton: true,
        });
        this.setState({
          isSubmit: false,
        });
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64FromListWall(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleChangeWall = ({ file, fileList }) => {
    if (file.status === "done" && file.response) {
      let temp = [...this.state.file_list_insert];
      temp.push(file);
      this.setState({
        file_list_insert: temp,
      });
    }
    this.setState({ fileList });
  };

  handleRemove = (file) => {
    let temp = [...this.state.file_list_delete];
    temp.push(file);
    this.setState({
      file_list_delete: temp,
    });
  };

  onChange = (value) => {
    this.setState({ value });
  };

  handleChangeAutoCompleteProject = (title) => {
    if (title) {
      PostRealEstatePageApi.autoCompleteProject(title).then((res) => {
        if (res.data.status === 1)
          this.setState({ list_project: res.data.list_project });
      });
    } else {
      PostRealEstatePageApi.getAllProject().then((res) => {
        if (res.data.status === 1) {
          this.setState({
            list_project: res.data.list_project,
          });
        } else {
          alert(res.data.message);
        }
      });
    }
  };

  handSelectStreet = (value, option) => {
    this.props.form.setFieldsValue({
      street: option.props.children,
    });
  };

  handleChangeAutoCompleteStreet = (name) => {
    if (name) {
      PostRealEstatePageApi.autoCompleteStreet(name).then((res) => {
        if (res.data.status === 1) this.setState({ streets: res.data.streets });
      });
    }
  };

  validateMinPrice = (rule, value, callback) => {
    if (value && value < 100000) {
      callback("Giá tối thiểu là 100 000 đồng!");
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { real_estate } = this.state;

    const { previewVisible, previewImage, fileList } = this.state;
    const uploadWallButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    
    return this.state.isLoadingForm ? (
      <Spin
        style={{
          left: `50%`,
          top: `50%`,
          position: "absolute",
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
      />
    ) : (
      <div className={styles.realEstateFormWrapper}>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col xl={12}>
              <div className={styles.sectionInfo}>
                <Title level={4}>Thông tin cơ bản</Title>
                <Row>
                  <Form.Item label="Thuộc dự án" className={styles.rowInput}>
                    {getFieldDecorator("project_id", {
                      initialValue: !real_estate
                        ? null
                        : real_estate.project_id
                        ? real_estate.project_id.toString()
                        : null,
                    })(
                      <AutoComplete
                        backfill={true}
                        allowClear={true}
                        onSearch={this.handleChangeAutoCompleteProject}
                      >
                        {!!this.state.list_project.length &&
                          this.state.list_project.map((item, key) => (
                            <OptionAutoComplete
                              key={key}
                              value={item.id.toString()}
                            >
                              {item.title}
                            </OptionAutoComplete>
                          ))}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="Loại BĐS" className={styles.rowInput}>
                    {getFieldDecorator("category_id", {
                      initialValue: !real_estate
                        ? this.state.typeRealEstate
                        : this.convertCategory(real_estate.category),
                      rules: [
                        {
                          required: true,
                          message: "Vui lòng chọn loại BĐS!",
                        },
                      ],
                    })(
                      <Select
                        onSelect={this.handleChangeTypeRealEstate.bind(this)}
                      >
                        {typeRealEstates.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.type}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="Tiêu đề tin đăng"
                    className={styles.rowInput}
                  >
                    {getFieldDecorator("title", {
                      initialValue: !real_estate ? "" : real_estate.title,
                      rules: [
                        {
                          required: true,
                          message: "Vui lòng nhập tên BĐS!",
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Row>
                <Row>
                  {/* <input type="hidden" id="trix" />
                  <trix-editor className="trix-content" input="trix" /> */}
                  <div
                    className="ant-col ant-form-item-label"
                    style={{ marginBottom: `5px` }}
                  >
                    <label
                      for="title"
                      className="ant-form-item"
                      title="Tiêu đề tin đăng"
                    >
                      Nội dung tin đăng
                    </label>
                  </div>
                  {this.CKEditor ? (
                    <this.CKEditor
                      editor={this.ClassicEditor}
                      data={this.state.body ? this.state.body : ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                          body: data,
                        });
                      }}
                      config={{
                        ckfinder: {
                          uploadUrl: uploadUrl,
                        },
                      }}
                    />
                  ) : null}
                </Row>
                {/* <Row>
                  <Form.Item label="Ảnh bìa">
                    {getFieldDecorator("cover_image")(
                      <Upload
                        name="logo"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={uploadUrl}
                        onChange={this.handleChange}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Row> */}
                <Row>
                  <Form.Item label="Hình ảnh">
                    {getFieldDecorator("images")(
                      <React.Fragment>
                        <Upload
                          name="logo"
                          action={uploadUrl}
                          listType="picture-card"
                          fileList={fileList}
                          multiple
                          onPreview={this.handlePreview}
                          onChange={this.handleChangeWall}
                          onRemove={this.handleRemove}
                        >
                          {fileList.length >= 8 ? null : uploadWallButton}
                        </Upload>
                        <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={this.handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </React.Fragment>
                    )}
                  </Form.Item>
                </Row>
              </div>
              {/* <Divider /> */}
              <div
                style={{
                  display: `block`,
                  clear: `both`,
                  width: `100%`,
                  minWidth: `100%`,
                  height: `1px`,
                  margin: `12px 0`,
                }}
              ></div>
              <div className={styles.sectionInfo}>
                <Title level={4}>Địa chỉ</Title>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Tỉnh thành" className={styles.rowInput}>
                      {getFieldDecorator("province_code", {
                        initialValue: !real_estate
                          ? this.state.province
                          : real_estate.province_code,
                        rules: [
                          {
                            required: true,
                            message: "Vui lòng chọn tỉnh thành!",
                          },
                        ],
                      })(
                        <Select onSelect={this.selectCity.bind(this)}>
                          {!!this.state.cities.length &&
                            this.state.cities.map((city, index) => (
                              <Option key={index} value={city.code}>
                                {city.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Quận/ huyện" className={styles.rowInput}>
                      {getFieldDecorator("district_id", {
                        initialValue: !real_estate
                          ? !!this.state.districts.length &&
                            this.state.districts[0].id
                          : real_estate.district_id,
                        rules: [
                          {
                            required: true,
                            message: "Vui lòng chọn quận/ huyện!",
                          },
                        ],
                      })(
                        <Select onSelect={this.selectDistrict.bind(this)}>
                          {!!this.state.districts.length &&
                            this.state.districts.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Phường" className={styles.rowInput}>
                      {getFieldDecorator("ward_id", {
                        initialValue: !real_estate
                          ? !!this.state.wards.length && this.state.wards[0].id
                          : real_estate.ward_id,
                        rules: [
                          {
                            required: true,
                            message: "Vui lòng chọn phường!",
                          },
                        ],
                      })(
                        <Select>
                          {!!this.state.wards.length &&
                            this.state.wards.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item._name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Đường" className={styles.rowInput}>
                      {getFieldDecorator("street", {
                        initialValue: !real_estate
                          ? null
                          : real_estate.street.toString(),
                        rules: [
                          {
                            required: true,
                            message: "Vui lòng nhập tên đường!",
                          },
                        ],
                      })(
                        <AutoComplete
                          backfill={true}
                          allowClear={true}
                          onSelect={this.handSelectStreet}
                          onSearch={this.handleChangeAutoCompleteStreet}
                        >
                          {!!this.state.streets.length &&
                            this.state.streets.map((item, key) => (
                              <OptionAutoComplete
                                key={key}
                                value={item.id.toString()}
                              >
                                {item.name}
                              </OptionAutoComplete>
                            ))}
                        </AutoComplete>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Số nhà" className={styles.rowInput}>
                      {getFieldDecorator("address_number", {
                        initialValue: !real_estate
                          ? ""
                          : real_estate.address_number,
                        rules: [
                          {
                            required: true,
                            message: "Vui lòng nhập số nhà!",
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item label="Vị trí" className={styles.rowInput}>
                    {getFieldDecorator("position", {
                      initialValue: !real_estate ? 1 : real_estate.position,
                    })(
                      <Select>
                        {positions.map((item, index) => (
                          <OptGroup key={index} label={item.mainPosition}>
                            {item.detailPosition.map((child, key) => (
                              <Option key={key} value={child.value}>
                                {child.name}
                              </Option>
                            ))}
                          </OptGroup>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label="Hướng" className={styles.rowInput}>
                    {getFieldDecorator("direction_code", {
                      initialValue: !real_estate
                        ? !!this.state.directions.length &&
                          this.state.directions[0].code
                        : real_estate.direction,

                      rules: [
                        { required: true, message: "Vui lòng chọn hướng!" },
                      ],
                    })(
                      <Select>
                        {!!this.state.directions.length &&
                          this.state.directions.map((item, index) => (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Row>
              </div>
            </Col>
            <Col xl={12}>
              <div className={styles.sectionInfo}>
                <Title level={4}>Thông tin chi tiết</Title>
                {typeRealEstates.map((item, index) => {
                  if (index + 1 === this.state.typeRealEstate) {
                    return React.cloneElement(item.component, {
                      form: this.props.form,
                      key: index,
                      real_estate: this.state.real_estate,
                    });
                  }
                  return null;
                })}
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Giá (đ)" className={styles.rowInput}>
                      {getFieldDecorator("price", {
                        initialValue: !real_estate ? "" : real_estate.price,
                        rules: [
                          {
                            required: true,
                            message: "Vui lòng nhập giá BĐS!",
                          },
                          {
                            validator: this.validateMinPrice,
                          },
                        ],
                      })(
                        <InputNumber
                          className={styles.customInpNum}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  display: `block`,
                  clear: `both`,
                  width: `100%`,
                  minWidth: `100%`,
                  height: `1px`,
                  margin: `12px 0`,
                }}
              ></div>
              <div className={styles.sectionInfo}>
                <Title level={4}>Tiện ích nội khu</Title>
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    this.setState({ showSelectInternalOptions: true });
                  }}
                >
                  Chọn tiện ích nội khu
                </Button>
                <div>
                  {this.props.form.getFieldValue("internals") &&
                    !!this.props.form.getFieldValue("internals").length && (
                      <div style={{ margin: `5px 0`, fontWeight: "bold" }}>
                        Danh sách tiện ích nội khu
                      </div>
                    )}

                  <div>
                    {this.props.form.getFieldValue("internals") &&
                      !!this.props.form.getFieldValue("internals").length &&
                      this.props.form
                        .getFieldValue("internals")
                        .map((item, index) => {
                          var position =
                            this.state.listInternalOptions.findIndex(
                              (element) => {
                                return element.id === item;
                              }
                            );
                          if (
                            index ===
                            this.props.form.getFieldValue("internals").length -
                              1
                          ) {
                            return (
                              <span
                                key={index}
                                className={styles.selectedInternals}
                              >
                                {this.state.listInternalOptions[position].name}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                className={styles.selectedInternals}
                              >
                                {this.state.listInternalOptions[position].name},{" "}
                              </span>
                            );
                          }
                        })}
                  </div>
                </div>
                <Modal
                  closable={null}
                  maskClosable={false}
                  title="Chọn tiện ích nội khu"
                  visible={this.state.showSelectInternalOptions}
                  onOk={() => {
                    this.setState({ showSelectInternalOptions: false });
                  }}
                  onCancel={() => {
                    this.props.form.resetFields("internals");
                    this.setState({ showSelectInternalOptions: false });
                  }}
                  cancelText={"Hủy"}
                  okText={"Chọn"}
                >
                  <div style={{ overflowY: `scroll`, height: `250px` }}>
                    <Form.Item name="checkbox-group">
                      {getFieldDecorator("internals", {
                        initialValue: this.state.selectedInternalOptions,
                      })(
                        <Checkbox.Group>
                          <Row>
                            {!!this.state.listInternalOptions &&
                              this.state.listInternalOptions.map(
                                (item, key) => (
                                  <Col key={key} xs={12}>
                                    <Checkbox name={item.name} value={item.id}>
                                      {item.name}
                                    </Checkbox>
                                  </Col>
                                )
                              )}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </div>
                </Modal>
              </div>
              <div
                style={{
                  display: `block`,
                  clear: `both`,
                  width: `100%`,
                  minWidth: `100%`,
                  height: `1px`,
                  margin: `12px 0`,
                }}
              ></div>
              <div className={styles.sectionInfo}>
                <Title level={4}>Nội thất</Title>
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    this.setState({ showSelectFurnitures: true });
                  }}
                >
                  Chọn nội thất
                </Button>
                <div>
                  {this.props.form.getFieldValue("furnitures") &&
                    !!this.props.form.getFieldValue("furnitures").length && (
                      <div style={{ margin: `5px 0`, fontWeight: "bold" }}>
                        Danh sách nội thất
                      </div>
                    )}

                  <div>
                    {this.props.form.getFieldValue("furnitures") &&
                      !!this.props.form.getFieldValue("furnitures").length &&
                      this.props.form
                        .getFieldValue("furnitures")
                        .map((item, index) => {
                          var position = this.state.listFurnitures.findIndex(
                            (element) => {
                              return element.id === item;
                            }
                          );
                          if (
                            index ===
                            this.props.form.getFieldValue("furnitures").length -
                              1
                          ) {
                            return (
                              <span
                                key={index}
                                className={styles.selectedInternals}
                              >
                                {this.state.listFurnitures[position].name}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                className={styles.selectedInternals}
                              >
                                {this.state.listFurnitures[position].name},{" "}
                              </span>
                            );
                          }
                        })}
                  </div>
                </div>
                <Modal
                  closable={null}
                  maskClosable={false}
                  title="Chọn nội thất"
                  visible={this.state.showSelectFurnitures}
                  onOk={() => {
                    this.setState({ showSelectFurnitures: false });
                  }}
                  onCancel={() => {
                    this.props.form.resetFields("furnitures");
                    this.setState({ showSelectFurnitures: false });
                  }}
                  cancelText={"Hủy"}
                  okText={"Chọn"}
                >
                  <div style={{ overflowY: `scroll`, height: `250px` }}>
                    <Form.Item>
                      {getFieldDecorator("furnitures", {
                        initialValue: this.state.selectedFurnitures,
                      })(
                        <Checkbox.Group>
                          <Row>
                            {!!this.state.listFurnitures &&
                              this.state.listFurnitures.map((item, key) => (
                                <Col key={key} xs={12}>
                                  <Checkbox name={item.name} value={item.id}>
                                    {item.name}
                                  </Checkbox>
                                </Col>
                              ))}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </Form.Item>
                  </div>
                </Modal>
              </div>
              <div
                style={{
                  display: `block`,
                  clear: `both`,
                  width: `100%`,
                  minWidth: `100%`,
                  height: `1px`,
                  margin: `12px 0`,
                }}
              ></div>
              <div className={styles.sectionInfo}>
                <Title level={4}>Tình trạng BĐS</Title>
                <Row gutter={16}>
                  <Col xs={24} lg={12} span={12}>
                    <Form.Item label="Sổ pháp lý" className={styles.rowInput}>
                      {getFieldDecorator("house_certificate", {
                        initialValue: !real_estate
                          ? 1
                          : real_estate.house_certificate,
                      })(
                        <Radio.Group>
                          <Radio value={1}>Có</Radio>
                          <Radio value={0}>Không</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12} span={12}>
                    <Form.Item
                      label="Tình trạng bán"
                      className={styles.rowInput}
                    >
                      {getFieldDecorator("sell_status", {
                        initialValue: !real_estate
                          ? 1
                          : real_estate.sell_status,
                      })(
                        <Radio.Group>
                          <Radio value={1}>Bình thường</Radio>
                          <Radio value={0}>Bán gấp</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={24} span={12}>
                    <Form.Item
                      label="Loại hình giao dịch"
                      className={styles.rowInput}
                    >
                      {getFieldDecorator("status", {
                        initialValue: real_estate
                          ? real_estate.status === "Bán"
                            ? 1
                            : real_estate.status === "Cho thuê"
                            ? 2
                            : real_estate.status === "Thuế chấp"
                            ? 3
                            : 0
                          : 1,
                      })(
                        <Radio.Group>
                          <Radio value={1}>Bán</Radio>
                          <Radio value={2}>Cho thuê</Radio>
                          <Radio value={3}>Thuế chấp</Radio>
                          {/* <Radio value={0}>Đã bán</Radio> */}
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Form.Item>
              <Col span={12} offset={6} style={{ textAlign: "center" }}>
                <Button
                  loading={this.state.isSubmit ? true : false}
                  type="primary"
                  htmlType="submit"
                  style={{
                    fontSize: `18px`,
                  }}
                >
                  {this.props.match.params.slug ? `Cập nhật` : `Đăng tin`}
                </Button>
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domain: state.domainInfo.domain,
    profile: state.profile.infor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Form.create()(RealEstateForm)));
