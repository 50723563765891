import React, {CSSProperties} from "react";
import {Card, Icon, Typography} from "antd";
import styles from "./customCard.module.scss";
import {ResourceUrl} from "../../config/main";
import MoneyFormat from "../moneyFormat";
import {Link} from "react-router-dom";
import classNames from "classnames"

const { Text } = Typography;

class CustomCard extends React.Component<{sourceContent: Object, style?: CSSProperties}>{
    render() {
        const {sourceContent, style, className} = this.props;
        return (
            <Card
                hoverable
                cover={
                    <aside style={{
                        backgroundImage: "url("+ResourceUrl+sourceContent.cover_image+")"
                    }} className={styles.cartImage+ " customImage "}/>
                }
                size="small"
                className={classNames(styles.cartBlock, className)}
                style={style}
            >
                <Card.Meta title={
                    <React.Fragment>
                        <Link style={{
                            width: "100%",
                            display: "block",
                            fontSize: "18px"
                        }} to={'/bat-dong-san/'+sourceContent.slug}>{sourceContent.title}</Link>
                        <Text className={styles.cartTitle}>
                            <MoneyFormat style={{
                                color: "#bf0000"
                            }}>{sourceContent.price}</MoneyFormat>
                            {" / "}
                            <span style={{
                                color: "#cc9036"
                            }}>{parseFloat(sourceContent.acreage, 2)}m<sup>2</sup></span>
                        </Text>
                    </React.Fragment>
                } description={
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <Text
                            style={{margin: 0}}
                        >
                            <b><Icon style={{
                                marginRight: "5px"
                            }} type="environment" /></b>{(sourceContent.address ? sourceContent.address+ ", " : "") + (sourceContent.ward ? sourceContent.ward+", " : "") +(sourceContent.district ? sourceContent.district+", " : "") + (sourceContent.city ? sourceContent.city+" " : "")}
                        </Text>
                    </div>
                }/>
            </Card>
        );
    }
}

export default CustomCard;