import { Button, Form, Input, Modal } from "antd";
import moment from "moment";
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';

class ConfirmChangeSellRealEstateStatus extends PureComponent {
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmitChangeStatus(
          values,
          this.props.id
        );
      }
    });
  };
  componentDidMount() {
    this.props.form.setFieldsValue({
      date_of_sale: moment(new Date()).format("DD/MM/YYYY"),
    });
  }
  render() {
    const { isVisible, onClose, isLoading, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title="Xác nhận tình trạng mua bán - cho thuê"
        visible={isVisible}
        maskClosable={false}
        onCancel={onClose}
        footer={[
          <Button
            loading={isLoading}
            key="submit"
            type="primary"
            onClick={this.handleSubmit.bind(this)}
          >
            Xác nhận
          </Button>,
          <Button onClick={onClose} key="back">
            Đóng
          </Button>,
        ]}
      >
        <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
          <div
            style={{
              background: "#F7F2C9",
              padding: "15px",
              color: "#35aa47",
              fontSize: "18px",
            }}
          >
            Chúc mừng bạn vừa giao dịch thành công. Vui lòng xác nhận để cập
            nhật trạng thái của tin đăng và được vinh danh trên hệ thông
          </div>
          <Form.Item label="Tên khách hàng">
            {getFieldDecorator("name", {
              rules: [
                { required: true, message: "Vui lòng nhập tên khách hàng!" },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Số điện thoại khách hàng">
            {getFieldDecorator("phone", {
              rules: [
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại khách hàng!",
                },
                {
                  validator: this.validatePhoneNumber,
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Ngày cập nhật">
            {getFieldDecorator("date_of_sale", {})(<Input disabled />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
ConfirmChangeSellRealEstateStatus.defaultProps = {
  isVisible: false,
  onClose: () => {},
  isLoading: false,
  form: null,
}

ConfirmChangeSellRealEstateStatus.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  form: PropTypes.object,
};

export default Form.create()(ConfirmChangeSellRealEstateStatus);
