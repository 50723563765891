import { Button, Col, Divider, Icon, Popover, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  isAttributeArea,
  isAttributeNumberBedRoom,
  isAttributeNumberToilet,
} from '../../../utils/apartmentUtils';
import { APARTMENT_STATUS_SALEABLE_VALUE } from '../constants';
import styles from '../OnlinePrices.module.scss';
import { getApartmentStatus, getNumberBedRoom, getWallArea } from '../utils';

const AppartmentCell = (props) => {
  const {
    Status,
    TotalPriceVAT,
    onMouseEnter,
    onMouseLeave,
    className,
    onClickCart,
    ApartmentAttributes,
    houseCode,
    onBookAparment,
    ApartmentID,
    onRemoveSelectedAparment,
  } = props;
  const selectedApartment = useSelector(
    (state) => state.cartProject.selectedApartment
  );
  const apartment = getApartmentStatus(Status);
  const formatMillion = (amount) => {
    const newAmount = (amount / 1000000000).toFixed(2) + ' tỷ';
    return newAmount;
  };
  const numberBedRoom = getNumberBedRoom(ApartmentAttributes || []);
  const wallArea = getWallArea(ApartmentAttributes || []);
  const isVisible = () => {
    if (!ApartmentID) return false;
    if (!selectedApartment?.ApartmentID) return false;
    return selectedApartment?.ApartmentID === ApartmentID;
  };
  return (
    <td
      className={`${styles.apartmentCellContent} ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Popover
        overlayClassName={styles.apartmentPopoverWrapper}
        content={
          <div>
            {ApartmentAttributes?.length > 0
              ? ApartmentAttributes.map((attribute, index) => (
                  <p key={index} className={styles.apartmentAttributesRow}>
                    {attribute.label}:
                    <span>
                      {attribute.value}
                      {isAttributeNumberBedRoom(attribute) && (
                        <span className={styles.attributeSuffix}>PN</span>
                      )}
                      {isAttributeNumberToilet(attribute) && (
                        <span className={styles.attributeSuffix}>WC</span>
                      )}

                      {isAttributeArea(attribute) && (
                        <span className={styles.attributeSuffix}>
                          m<sup>2</sup>
                        </span>
                      )}
                    </span>
                  </p>
                ))
              : null}
            <Row type="flex" gutter={16}>
              <Col span={12}>
                <Button block onClick={onRemoveSelectedAparment}>
                  Đóng
                </Button>
              </Col>
              <Col span={12} className={styles.apartmentCode}>
                <Button block type="primary" onClick={() => onBookAparment()}>
                  Đặt cọc
                </Button>
              </Col>
            </Row>
          </div>
        }
        title={
          <Row type="flex" className={styles.apartmentPopoverTitle}>
            <Col span={12}>
              <p>Mã căn</p> <p>{houseCode}</p>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col span="6" className={styles.apartmentPopoverPrice}>
                  <Divider type="vertical" className={styles.divider} />
                </Col>
                <Col span="18" className={styles.apartmentPopoverPrice}>
                  <p>Giá niêm yết</p> <p>{formatMillion(TotalPriceVAT)}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        trigger="click"
        visible={isVisible()}
        onVisibleChange={onClickCart}
      >
        {apartment?.value === APARTMENT_STATUS_SALEABLE_VALUE ? (
          <div className={styles.cartIconWrapper}>
            <Icon
              type={apartment?.icon}
              className={styles.apartmentIcon}
              style={{ color: apartment?.color }}
            />
            <p
              style={{ color: apartment.color }}
              className={styles.apartmentPrice}
            >
              {formatMillion(TotalPriceVAT)}
            </p>
            <p className={styles.attributes}>
              {wallArea}m<sup>2</sup> - {numberBedRoom}PN
            </p>
          </div>
        ) : (
          <>
            <Icon
              type={apartment?.icon}
              className={styles.apartmentIcon}
              style={{ color: apartment?.color }}
            />
            <p className={styles.attributes}>
              {wallArea}m<sup>2</sup> - {numberBedRoom}PN
            </p>
          </>
        )}
      </Popover>
    </td>
  );
};
AppartmentCell.defaultProps = {
  onClickCart: () => {},
  onBookAparment: () => {},
  onRemoveSelectedAparment: () => {},
};
AppartmentCell.prototype = {
  onClickCart: PropTypes.func,
  onBookAparment: PropTypes.func,
  onRemoveSelectedAparment: PropTypes.func,
};
export default AppartmentCell;
