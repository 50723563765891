import { Empty, Pagination } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const ContactTable = ({
  contactList,
  changePageIndex,
  pageIndex,
  totalItems,
}) => {
  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Tên</Th>
            <Th>Số điện thoại</Th>
            <Th>Message</Th>
            <Th>Ngày gửi</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {contactList?.length > 0 ? (
            contactList.map((item, key) => (
              <Tr key={key}>
                <Td>{item.name}</Td>
                <Td>{item.phone}</Td>
                <Td>{item.body}</Td>
                <Td>
                  {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="6">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={1}
        pageSize={10}
        current={pageIndex}
        total={totalItems}
        onChange={(page, pageSize) => {
          changePageIndex(page);
        }}
      />
    </>
  );
};
ContactTable.defaultProps = {
  contactList: [],
  changePageIndex: () => {},
  pageIndex: 0,
  totalItems: 0,
};

ContactTable.propTypes = {
  contactList: PropTypes.array,
  changePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  totalItems: PropTypes.number,
};

export default memo(ContactTable);
