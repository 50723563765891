import React, { Component } from 'react';
import { Col,  Radio, Row } from 'antd';
import { Form } from 'antd';
import InputRange from './inputRange/inputRange';
import SelectRange from './inputRange/selectRange';
import ChartRate from './Chart/chartRate';
import InformationLoan from './informationLoan/InformationLoan';
import Helpers from '../../../../functions/Helpers';

const optionLoan = [
   { label: 'Thanh toán theo dư nợ giảm dần', value: 0, text: 'Thanh toán tháng đầu' },
   { label: 'Thanh toán đều hàng tháng', value: 1,  text: 'Thanh toán hàng tháng'},
];

const optionBankDefaultValue = {
  0: 7.6
};


class interestRate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: 0, // giá trị bất động sản
            loanMoney: 0, // số tiền vay
            loanTime: 0, // thời gian vay
            rate: optionBankDefaultValue[0], // lãi suất
            percent: 0, // phần trăm vay
            optionLoan: optionLoan[0].value,
            textLoan: optionLoan[0].text,
            statisticMoney : {},
            updated: false
        }
    }
    handleCalculateLoad() {
      this.setState({
        price: Math.round(this.props.price),
        loanMoney: Math.round(this.props.price * 50/100),
        loanTime: 15 * 12,
        rate: optionBankDefaultValue[0],
        percent: 50,
        updated: true
      }, () => this.loanCalculate());
    }
    componentDidMount() {
      this.handleCalculateLoad();
    }

    componentDidUpdate() {
        if(!this.state.updated) {
          this.handleCalculateLoad();
        }
    }

    componentWillReceiveProps(nextProps) {
      if(this.props.price !== nextProps.price) {
        this.setState({
            price: Math.round(nextProps.price),
            loanMoney: Math.round(nextProps.price * 50/100),
        }, () => this.loanCalculate());
      }
    }

    onChangePrice = (value) => {
      let loanMoney = this.formatMillionNumber(value)  * this.state.percent/100,
          price = this.formatMillionNumber(value);
      this.setState({
        price: Math.round(price),
        loanMoney: Math.round(loanMoney)
      }, () => this.loanCalculate());
    };

    onChangeLoanTime = (value) => {
      this.setState({
          loanTime: value * 12
      }, () => this.loanCalculate());
    };

    onChangePercent = (value) => {
      let loanMoney = value/100 * this.state.price;
      this.setState({
         percent: parseFloat(value),
         loanMoney: Math.round(loanMoney)
      }, () => this.loanCalculate());
    };

    onChangeRate = (value) => {
       this.setState({
          rate: value
       }, () => this.loanCalculate());
    };

    onChangeOption = (event) => {
       let value = event.target.value;
       this.setState({
          optionLoan: value,
          textLoan: optionLoan[value].text
       }, () => this.loanCalculate());
    };

    loanCalculate = () => {
       let  loanMoney = this.state.loanMoney,
            loanTime = this.state.loanTime,
            statisticMoney = {} ,
            originalMoney = parseInt(loanMoney)/loanTime;// tính gốc phải trả cho từng tháng

      if(this.state.optionLoan === 0) {
         statisticMoney = this.optionLoan1(loanMoney, loanTime, originalMoney);
      } else if(this.state.optionLoan === 1) {
         statisticMoney = this.optionLoan2(loanMoney, loanTime, originalMoney);
      }

      this.setState({
          statisticMoney: statisticMoney
      })

    };

    optionLoan1 = (loanMoney, loanTime, originalMoney) => {
       let statisticMoney = {},
           tmpLoanMoney = loanMoney,
           sumOriginalAndRate = 0,
           rateEachMonth = 0,
           sumRate = 0;

       for(let i = 0; i < loanTime; i++) {
            rateEachMonth = parseInt(tmpLoanMoney) /12 * this.state.rate / 100; // tính lãi từng tháng
            tmpLoanMoney = tmpLoanMoney - originalMoney;  // gốc còn lại theo từng tháng
            sumOriginalAndRate = originalMoney + rateEachMonth; // lãi vs tiền gốc từng tháng
            sumRate += rateEachMonth; // lải tổng cộng

          statisticMoney[i] = {
            'tmpLoanMoney': Helpers.convertNumber(Math.round(tmpLoanMoney)),
            'originalMoney': Helpers.convertNumber(Math.round(originalMoney)),
            'rateEachMonth': Helpers.convertNumber(Math.round(rateEachMonth)),
            'sumOriginalAndRate': Helpers.convertNumber(Math.round(sumOriginalAndRate)),
          };
       }
       statisticMoney['sumRate'] = Math.round(sumRate);
       return statisticMoney;
    };

    optionLoan2 = (loanMoney, loanTime, originalMoney) => {
         let statisticMoney = {},
             tmpLoanMoney = loanMoney,
             rateEachMonth = parseInt(loanMoney) / 12 * this.state.rate / 100, // tính lãi
             sumOriginalAndRate = originalMoney + rateEachMonth,	// tính gốc + lãi
             sumRate = 0;

         for(let i = 0; i < loanTime; i++) {
             tmpLoanMoney = tmpLoanMoney - originalMoney; // gốc còn lại
             sumRate += rateEachMonth;
             statisticMoney[i] = {
               'tmpLoanMoney': Helpers.convertNumber(Math.round(tmpLoanMoney)),
               'originalMoney': Helpers.convertNumber(Math.round(originalMoney)),
               'rateEachMonth': Helpers.convertNumber(Math.round(rateEachMonth)),
               'sumOriginalAndRate': Helpers.convertNumber(Math.round(sumOriginalAndRate)),
             };
         }

         statisticMoney['sumRate'] = Math.round(sumRate);

         return statisticMoney;
    };

    formatMillionTo2Decimal = (number) => {
        if(this.props.price > 1000000000)
          return parseFloat(Math.round(number/1000000000 * 100) /100, 2);
        else
          return parseFloat(Math.round(number/1000000 * 100) /100, 2);

    };

    formatMillionNumber = (number) => {
      if(this.props.price > 1000000000)
        return parseFloat(number * 1000000000, 2);
      else
        return parseFloat(number * 1000000, 2);
    };

    render () {
        return (
            <div className="interest-rate">
                <h3 className="sub-heading">Tính Lãi xuất vay</h3>
                <Form layout='horizontal'>
                  <Row>
                    <Col   xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 } className="calculate-content">
                      <div className="input-range">
                          <InputRange label="Giá trị nhà đất"
                                      value={ this.formatMillionTo2Decimal(this.state.price) }
                                      max={ this.formatMillionTo2Decimal(this.state.price) * 10 }
                                      onChange={ this.onChangePrice }
                                      price={ true }
                                      addonAfter={ this.props.price > 1000000000 ? "Tỷ" : "Triệu"}/>
                          <InputRange label="Tỷ lệ vay (%)"
                                      value={ this.state.percent }
                                      disabled={ false }
                                      max={ 100 }
                                      onChange={ this.onChangePercent }
                                      addonAfter={"%"}/>
                          <InputRange label="Thời hạn vay (năm)"
                                      value={ this.state.loanTime/12 }
                                      max={ 20 }
                                      onChange={ this.onChangeLoanTime }
                                      addonAfter="Năm"/>
                          <SelectRange data={ this.props.banks }
                                       value={ this.state.rate }
                                       optionDefault={ optionBankDefaultValue }
                                       onChange={ this.onChangeRate } />
                          <Radio.Group className="radio-group"
                                       options={ optionLoan }
                                       onChange={ this.onChangeOption }
                                       value={ this.state.optionLoan } />

                      </div>
                    </Col>
                    <Col  xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 } className="estimate-content">
                        <div className="chart" >
                            <Col xs={ 24 } sm={ 24 } md={ 16 } lg={ 16 } xl={ 16 }>
                                <ChartRate price={ this.state.price - this.state.loanMoney }
                                           loanMoney={ this.state.loanMoney }
                                           sumRate={ this.state.statisticMoney.sumRate }
                                           totalPaid={ (this.state.price - this.state.loanMoney) + this.state.loanMoney + this.state.statisticMoney.sumRate}
                                           formatMillionTo2Decimal={ this.formatMillionTo2Decimal }
                                           convertNumber={ Helpers.convertNumber }
                                           priceProps= { this.props.price }/>
                            </Col>
                            <Col xs={ 24 } sm={ 24 } md={ 8 } lg={ 8 } xl={ 8 }>
                                <InformationLoan price={ this.state.price }
                                                 loanMoney={ this.state.loanMoney }
                                                 sumRate={ this.state.statisticMoney.sumRate }
                                                 convertNumber={ Helpers.convertNumber }/>
                            </Col>
                        </div>
                        <Row className="first-month-rate">
                            <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 }>
                                <strong className="label">
                                    { this.state.textLoan }
                                </strong>
                            </Col>
                            <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 }>
                                <strong className="price blue price-paid">
                                   {
                                      Object.keys(this.state.statisticMoney).length &&
                                      typeof this.state.statisticMoney[0] !== 'undefined'?
                                      Helpers.convertNumber(this.state.statisticMoney[0]['sumOriginalAndRate']) :
                                      '...'
                                   }
                                </strong>
                            </Col>
                        </Row>
                    </Col>
                  </Row>
                </Form>
            </div>
        )
    }
}
export default interestRate;
