import Axios from "../axiosApi";

const InvestorPageApi = {
    listInvestor: () => {
        return Axios.get('/investor');
    },
    getDetail(slug) {
        return Axios.get('/investor/detail/'+slug).then(data=> data.data);
    }
};

export default InvestorPageApi;
