import { Button, Form, Input } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IdentityImage from "../../../../component/BookingModule/IdentityImage";
import styles from "../../Cart.module.scss";

const { TextArea } = Input;
class TransactionForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(err);
      if (!err) {
        this.props.onSubmitTransaction(values);
      }
    });
  };
  handleRedirectToTransaction = () => {
    this.props.history.push("/user/booking-list");
  };
  validateMinPrice = (rule, value, callback) => {
    if (value && value < 50000000) {
      callback("Giá tối thiểu là 50.000.000 đồng!");
    } else {
      callback();
    }
  };
  render() {
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit.bind(this)}>
        <Form.Item label="Số tài khoản" className={styles.formItem}>
          {getFieldDecorator("bankNumber", {
            rules: [
              {
                required: true,
                message: "Thông tin này không thể bỏ trống",
              },
            ],
          })(<Input placeholder="Số tài khoản" className={styles.inputItem} />)}
        </Form.Item>
        <Form.Item label="Tên tài khoản" className={styles.formItem}>
          {getFieldDecorator("bankAccountName", {
            rules: [
              {
                required: true,
                message: "Thông tin này không thể bỏ trống",
              },
            ],
          })(
            <Input placeholder="Tên tài khoản" className={styles.inputItem} />
          )}
        </Form.Item>
        <Form.Item label="Số tiền giao dịch" className={styles.formItem}>
          {getFieldDecorator("amount", {
            rules: [
              {
                required: true,
                message: "Thông tin này không thể bỏ trống",
              },
              {
                validator: this.validateMinPrice,
              },
            ],
          })(
            <Input
              type="number"
              placeholder="Số tiền giao dịch"
              className={styles.inputItem}
            />
          )}
        </Form.Item>
        <Form.Item label="Ghi chú" className={styles.formItem}>
          {getFieldDecorator("note")(
            <TextArea rows={4} placeholder="Ghi chú" />
          )}
        </Form.Item>
        <Form.Item label="Uỷ nhiệm chi" className={styles.formItem}>
          {getFieldDecorator("imagePath", {
            rules: [
              {
                required: true,
                message: "Thông tin này không thể bỏ trống",
              }
            ],
          })(<IdentityImage setFieldsValue={setFieldsValue} />)}
        </Form.Item>
        <div className={styles.buttonGroupWrapper}>
          <Button type="default" onClick={this.handleRedirectToTransaction}>
            Huỷ
          </Button>
          <Button
            className={styles.confirmationButton}
            type="primary"
            htmlType="submit"
            loading={this.props.isLoading}
          >
            Xác nhận
          </Button>
        </div>
      </Form>
    );
  }
}
TransactionForm.defaultProps = {
  isLoading: false,
};
TransactionForm.propTypes = {
  isLoading: PropTypes.bool,
};

export default Form.create()(withRouter(TransactionForm));
