import * as actionTypes from './actionTypes';
import Axios from '../../axiosApi';
import RealStateApi from '../../api/realEstatePage'
import HomeApi from "../../api/homePage";

export const innitRealEstate = (slug, count, isLook) => {
    return dispatch => {
        /*Axios.interceptors.request.use((config) => {
            config.headers['CountView'] = count;
        });*/
        Axios.get('/realState/' + slug, {
            headers: {
                CountView: count,
                IsLook: isLook
            }
        }).then(response => {
            if(Object.keys(response.data.project).length > 0) {
                dispatch(setRealState(response.data.project));
                dispatch(getRealEstateVip());
                dispatch(innitRealEstateSamePrice(response.data.project));
                dispatch(innitRealEstateSameStreet(response.data.project));
                dispatch(innitRealEstateSameAcreage(response.data.project));
            }
        })
    };
};

export const innitRealEstateSamePrice = (project) => {
    return dispatch => {
        RealStateApi.realEstateSamePrice(project).then(response => {
            if(response.data.list.records && response.data.list.records.length > 0) {
                dispatch(setRealEStateSamePrice(response.data.list))
            }
        });
    }
};

export const setRealEStateSamePrice = (realStates) => {
    return {
        type: actionTypes.GET_REAL_ESTATE_SAME_PRICE,
        realStates: realStates
    }
}

export const innitRealEstateSameStreet = (project) => {
    return dispatch => {
        RealStateApi.realEstateSameStreet(project).then(response => {
            if(response.data.list.records && response.data.list.records.length > 0) {
                dispatch(setRealEStateSameStreet(response.data.list))
            }
        });
    }
}

export const setRealEStateSameStreet = (realStates) => {
    return {
        type: actionTypes.GET_REAL_ESTATE_SAME_STREET,
        realStates: realStates
    }
}


export const innitRealEstateSameAcreage = (project) => {
    return dispatch => {
        RealStateApi.realEstateSameAcreage(project).then(response => {
            if(response.data.list.records && response.data.list.records.length > 0) {
                dispatch(setRealEStateSameAcreage(response.data.list))
            }
        });
    }
}

export const setRealEStateSameAcreage = (realStates) => {
    return {
        type: actionTypes.GET_REAL_ESTATE_SAME_ACREAGE,
        realStates: realStates
    }
}

export const setRealState = data => (
    {
        type: actionTypes.LOAD_REAL_ESTATE,
        realEstate: data
    }
);

export const getRealEstateVip = () => {
 return dispatch => {
     HomeApi.getRealEstatePriority().then(response => {
         dispatch({
             type: actionTypes.GET_REAL_ESTATE_VIP,
             realStates: response.data.list
         })
     })
 }
}
