import {
  BOOKING_STATUSES,
  BOOKING_STATUS_PURCHASE_TIME_OUT,
  BOOKING_STATUS_SUCCESSFUL_DES_VALUE,
  BOOKING_STATUS_WAITING_FOR_DEPOSIT_VALUE,
} from "../constants/booking";
import {
  BUSINESS_TYPES,
  BUYER_TYPES,
  IDENTIFY_TYPES,
} from "../page/Cart/constants";

export const getIdentifyType = (value) => {
  if (!value) return null;
  return IDENTIFY_TYPES.find((item) => item.value === value);
};
export const getBookingStatus = (value) => {
  return BOOKING_STATUSES.find((item) => item.value === value);
};
export const getBusinessType = (value) => {
  return BUSINESS_TYPES.find((item) => item.value === value);
};
export const getBuyerType = (value) => {
  return BUYER_TYPES.find((item) => item.value === value);
};
export const isWaitingForDeposite = (status) =>
  status === BOOKING_STATUS_WAITING_FOR_DEPOSIT_VALUE;

export const isPurchaseTimeOut = (status) =>
  status === BOOKING_STATUS_PURCHASE_TIME_OUT;

export const shouldDisplayPurchaseConfirm = (item) => {
  return (
    item?.buy_able === 1 && item.status === BOOKING_STATUS_SUCCESSFUL_DES_VALUE
  );
};
