import React from "react";
import PropTypes from "prop-types";
import MoneyFormat from "../moneyFormat";
import cx from 'classnames';
import Image from "../image";
import {ResourceUrl} from "../../config/main";
import {Link} from "react-router-dom";

const InfoWindow = (props) => {
    const {source} = props;
    return (
        <div className="searchlisting">
            <div className="triangle-up"/>
            <section className="exclusive">
                <article className="featured">
                    <Link target="_blank" to={'/bat-dong-san/'+source.slug}>
                        <div className="cover">
                            <Image src={ResourceUrl+source.cover_image} alt=""/>
                        </div>
                    </Link>
                    <header>
                        <Link target="_blank" to={'/bat-dong-san/'+source.slug} href="">
                            <h2>{source.title}</h2>
                        </Link>
                        <div className="address">{source.district+', '+source.city}</div>
                        <div>
                            <div className="price-marker"><MoneyFormat>{source.price}</MoneyFormat></div>
                        </div>
                    </header>
                </article>
            </section>
        </div>
    );
};

class Markers extends React.Component{
    state = {
        hoverClass: null
    };

    _onMouseEnterMarker = (e) => {
        this.setState({
            hoverClass: 'active'
        });
    };

    _onMouseLeaveMarker = (e) => {
        this.setState({
            hoverClass: null
        });
    };

    _onChildClick = (e) => {
        this.props.onChildClick(this.props.marker, 18);
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        return <React.Fragment>
            <div
                className={cx("listings-map-box", this.state.hoverClass, this.props.show ? 'active_click': null)}
                onMouseEnter={this._onMouseEnterMarker}
                onMouseLeave={this._onMouseLeaveMarker}
                onClick={this._onChildClick}
            >
                <div className="listings-map"><MoneyFormat>{this.props.marker.price}</MoneyFormat></div>
                <div className="triangle-down"/>
            </div>
            {this.props.show && <InfoWindow source={this.props.marker} />}
        </React.Fragment>;
    }
}

Markers.defaultProps = {
    show: false
};

Markers.propTypes = {
    marker: PropTypes.any,
    show: PropTypes.bool.isRequired,
    onChildClick: PropTypes.func
};

export default Markers;
