import { Avatar, Button, Icon } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import loginSignUpApi from "../../../api/loginSignUp";
import { ResourceUrl } from "../../../config/main";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import avatarBase from "../../../images/avatar-base.jpeg";
import DefaultAvatar from "../../../images/generic-avatar.png";
import login from "../../../images/icon/login.svg";
import logoutIcon from "../../../images/icon/logout.svg";
import profileAdd from "../../../images/icon/profile-add.svg";
import profileIcon from "../../../images/icon/profile.svg";
import User from "../../../models/User";
import { logout } from "../../../store/actions/profile";
import { redirectToPostRealEstate } from "../../../store/actions/redirectToPostRealEstate";
import styles from "./UserPopover.module.scss";

const UserPopover = () => {
  const [isOpenPopover, setIsPopover] = useState(false);
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();

  const handleTogglePopover = () => {
    setIsPopover((prev) => !prev);
  };
  useOnClickOutside(ref, () => isOpenPopover && setIsPopover(false));
  const profile = useSelector((state) => state.profile.infor);
  const isLogin = Boolean(profile);
  const handleRedirectTo = (path) => history.push(path);
  const handleLogout = (event) => {
    event.preventDefault();
    loginSignUpApi.logout().then((res) => {
      if (res.data.status) {
        dispatch(logout());
        dispatch(redirectToPostRealEstate(false));
        User.logout();
      }
    });
    setIsPopover(false);
  };

  return (
    <>
      {isLogin ? (
        <nav className={styles.userInfoMobile}>
          <div className={styles.userInfo}>
            <Avatar
              src={
                profile.avatart ? ResourceUrl + profile.avatar : DefaultAvatar
              }
            />
            <div className={styles.fullNameWrapper}>
              <h3 className={styles.fullName}>{profile.full_name}</h3>
            </div>
          </div>
          <ul>
            <li className={styles.userMenuItem}>
              <Link to="/user/my-profile">
                <img src={profileIcon} alt="thong-tin-cua-toi" />
                Thông tin của tôi
              </Link>
            </li>
            <li className={styles.userMenuItem}>
              <a href="/" onClick={(e) => handleLogout(e)}>
                <img src={logoutIcon} alt="dang-xuat" />
                Đăng xuất
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <nav className={styles.userInfoMobile}>
          <ul>
            <li
              className={styles.userMenuItem}
              onClick={() => handleRedirectTo("/sign-in")}
            >
              <img src={login} alt="dang-nhap" />
              Đăng nhập
            </li>
            <li
              className={styles.userMenuItem}
              onClick={() => handleRedirectTo("/sign-up")}
            >
              <img src={profileAdd} alt="dang-ky" />
              Đăng ký
            </li>
          </ul>
        </nav>
      )}
      <div ref={ref} className={styles.userPopoverWrapper}>
        <Button className={styles.userButton} onClick={handleTogglePopover}>
          <Icon type="menu" />
          <img src={avatarBase} alt="avatar" />
        </Button>
        {isOpenPopover && (
          <>
            {isLogin ? (
              <nav className={styles.userMenuItems}>
                <div className={styles.userInfo}>
                  <Avatar
                    src={
                      profile.avatart
                        ? ResourceUrl + profile.avatar
                        : DefaultAvatar
                    }
                  />
                  <div className={styles.fullNameWrapper}>
                    <h3 className={styles.fullName}>{profile.full_name}</h3>
                  </div>
                </div>
                <ul>
                  <li className={styles.userMenuItem}>
                    <Link to="/user/my-profile">
                      <img src={profileIcon} alt="thong-tin-cua-toi" />
                      Thông tin của tôi
                    </Link>
                  </li>
                  <li className={styles.userMenuItem}>
                    <a href="/" onClick={handleLogout}>
                      <img src={logoutIcon} alt="dang-xuat" />
                      Đăng xuất
                    </a>
                  </li>
                </ul>
              </nav>
            ) : (
              <nav className={styles.userMenuItems}>
                <ul>
                  <li
                    className={styles.userMenuItem}
                    onClick={() => handleRedirectTo("/sign-in")}
                  >
                    <Link>
                      <img src={login} alt="dang-nhap" />
                      Đăng nhập
                    </Link>
                  </li>
                  <li
                    className={styles.userMenuItem}
                    onClick={() => handleRedirectTo("/sign-up")}
                  >
                    <Link>
                      <img src={profileAdd} alt="dang-ky" />
                      Đăng ký
                    </Link>
                  </li>
                </ul>
              </nav>
            )}
          </>
        )}
      </div>
    </>
  );
};

UserPopover.propTypes = {};

export default UserPopover;
