import React from "react";
import styles from "../widget.module.scss";
import "./widgetInterested.scss";
import {Typography, List, Skeleton, Card} from "antd";
import {ResourceUrl} from "../../../../config/main";
import CardSlider from "../../../../component/card_slider/cardSlider";
import HomePageApi from "../../../../api/homePage";

const { Title, Text } = Typography;

class WidgetInterested extends React.Component{
    state = {
        paused: [],
        reverse: [],
        locations: []
    };

    componentDidMount(): void {
        HomePageApi.locations().then(res => {
            this.setState({
                locations: res.data.locations
            });
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        return(
            this.state.locations.length ?
            <div className={styles.frontPageSection}>
                <div className="section-wrap">
                    <Title level={2} className={styles.widgetTitle} style={{
                        textAlign: 'center',
                        paddingTop: '30px',
                        paddingBottom: '15px',
                        textTransform: 'uppercase',
                        flexDirection: 'column'
                    }}>
                        Hôm nay cả nước có gì mới
                        <Text style={{
                            textTransform: 'none'
                        }} type="secondary" className={styles.widgetSubtitle}>
                            Danh sách nhà/đất có giá hời nhất tại thời điểm này trên cả nước
                        </Text>
                    </Title>
                    <div className={styles.categoriesWrap}>
                        {this.loadInterested(this.state.locations)}
                    </div>
                </div>
            </div> : <React.Fragment/>
        );
    }
    loadInterested(data) {
        return (
            <CardSlider className="card-location">
                {data.length > 0 ? data.map((dt, index) => {
                    return (
                        <a rel="noopener noreferrer" target="_blank" href={dt.domain} key={index}>
                            <div className={styles.categoryCover} style={{
                                backgroundImage: "url("+ResourceUrl+dt.cover_image+")"
                            }}>
                                <div className={styles.categoryText}>
                                    <div className={styles.categoryTitle}>
                                        {dt.name}
                                    </div>
                                    <List
                                        size="small"
                                        style={{
                                            background: "transparent",
                                            color: "white"
                                        }}
                                    >
                                        <List.Item>
                                            Cần bán: {dt.sell_number}
                                        </List.Item>
                                        <List.Item>
                                            Cần cho thuê: {dt.rent_number}
                                        </List.Item>
                                    </List>
                                </div>
                            </div>
                        </a>
                    );
                }) : <Card className={styles.categoryCover} bodyStyle={{
                    padding: 0
                }}>
                    <Skeleton className={styles.categoryText} paragraph={{
                        rows: 3
                    }}/>
                </Card>}
            </CardSlider>
        );
    }
}

export default WidgetInterested;
