/* eslint-disable no-unused-expressions */
import PropTypes from "prop-types";
import React from "react";
// COMP
import AppartmentCell from "./AppartmentCell";

import styles from "../OnlinePrices.module.scss";

const AppartmentTable = ({
  roomNumbersLength,
  apartmentPivots,
  apartmentHeaders,
  onMouseApparment,
  onMouseLeaveApparment,
  onClickCart,
  onBookAparment,
  onRemoveSelectedAparment
}) => {
  return (
    <div className={styles.apartmentTableWrapper}>
      <table
        className={styles.appartmentTable}
        style={{
          width:
            roomNumbersLength > 0
              ? `calc(${roomNumbersLength + 1} * 99px)`
              : "100%",
        }}
      >
        {apartmentPivots.length === 0 ? (
          <tbody>
            <tr>
              <td>Không có dữ liệu</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr className={styles.tableFixed}>
              <table
                style={{ width: `calc(${roomNumbersLength + 1} * 99px)` }}
                className={styles.tableFixed}
              >
                <tbody>
                  {apartmentHeaders.map(
                    ({ tooltipTitle, iconName, collections, id }) => (
                      <tr style={{ height: 35 }} key={id}>
                        <td
                          className={`${styles.apartmentCellComon} ${styles.headerFirstColumn}`}
                        >
                          <div className="tooltip-wrapper">
                            <span>Căn số</span>
                          </div>
                        </td>
                        {collections?.map(
                          ({ property, className, roomNumber }) => {
                            return (
                              <td
                                key={roomNumber}
                                className={`${styles.apartmentCellComon} ${className}`}
                              >
                                {property}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </tr>
            {apartmentPivots.map(
              ({ floorNumber, apartments, saleableCounter }) => {
                return (
                  <tr key={floorNumber}>
                    <td
                      className={`${styles.apartmentCellContent} ${styles.firstColumn}`}
                    >
                      <div className={styles.floorInfo}>
                        <span>Tầng {floorNumber}</span>
                        <span>Còn {saleableCounter || 0} căn</span>
                      </div>
                    </td>
                    {apartments?.map((item) => {
                      return (
                        <AppartmentCell
                          key={`${floorNumber}${item.roomNumber}`}
                          {...item}
                          onMouseEnter={() => onMouseApparment(item)}
                          onMouseLeave={() => onMouseLeaveApparment(item)}
                          onClickCart={() => onClickCart(item)}
                          houseCode={item.HouseCode}
                          onBookAparment={onBookAparment}
                          onRemoveSelectedAparment={onRemoveSelectedAparment}
                        />
                      );
                    })}
                  </tr>
                );
              }
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

AppartmentTable.defaultProps = {
  roomNumbersLength: 0,
  apartmentPivots: [],
  apartmentHeaders: [],
  onMouseApparment: () => {},
  onMouseLeaveApparment: () => {},
  onClickCart: () => {},
  onBookAparment: () => {},
  onRemoveSelectedAparment: () => {},
};
AppartmentTable.prototype = {
  roomNumbersLength: PropTypes.number,
  apartmentPivots: PropTypes.arrayOf(
    PropTypes.shape({
      floorNumber: PropTypes.number,
      apartments: PropTypes.arrayOf(
        PropTypes.shape({
          roomNumber: PropTypes.number,
        })
      ),
      saleableCounter: PropTypes.number,
    })
  ),
  apartmentHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      tooltipTitle: PropTypes.string,
      iconName: PropTypes.string,
      collections: PropTypes.arrayOf(
        PropTypes.shape({
          property: PropTypes.string,
          className: PropTypes.string,
          roomNumber: PropTypes.number,
        })
      ),
      id: PropTypes.number,
    })
  ),
  onMouseApparment: PropTypes.func,
  onMouseLeaveApparment: PropTypes.func,
  onClickCart: PropTypes.func,
  onBookAparment: PropTypes.func,
  onRemoveSelectedAparment: PropTypes.func,
};

export default AppartmentTable;
