/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, Modal, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import PostRealEstatePageApi from "../../../../api/postRealEstatePage";
import { useFormModal } from "../../../../hooks";
//Comp
import {
  ConfirmChangeSellRealEstateStatus,
  RealEstateTable,
} from "../components";

const { confirm } = Modal;

const SellRealEstate = () => {
  const [realEstateList, setRealEstateList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRealEstateId, setSelectedRealEstateId] = useState();
  const { isVisible, show, close } = useFormModal();
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);

  const fetchData = (pageIndexParam) => {
    setLoading(true);
    PostRealEstatePageApi.mySellRealEstatesList(10, pageIndexParam)
      .then((res) => {
        if (res.data.status) {
          setRealEstateList(res.data.real_estates);
          setTotalItems(res.data.total);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(pageIndex);
  }, [pageIndex]);

  const changePageIndex = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  const handleClickHideRealEstate = useCallback((realEstateId) => {
    setSelectedRealEstateId(realEstateId);
    show();
  }, []);

  const handleSubmmitChangeStatus = useCallback((formData, id) => {
    const paramsSubmit = {
      ...formData,
      id: id,
      real_estate_id: id,
      date_of_sale: new Date(),
    };
    setIsLoadingChangeStatus(true);
    PostRealEstatePageApi.changeStatus(paramsSubmit).then((res) => {
      if (res.data.status) {
        fetchData(pageIndex);
        setIsLoadingChangeStatus(false);
        close();
        setSelectedRealEstateId();
      }
    });
  }, []);

  const handleDeleteRealEstate = useCallback((id) => {
    confirm({
      title: "Bạn có chắc muốn xóa tin bất động sản này?",
      okText: "Có",
      okType: "danger",
      cancelText: "Không",
      onOk: () => {
        PostRealEstatePageApi.remove(id).then((res) => {
          if (res.data.status) {
            fetchData(pageIndex);
            Swal.fire({
              icon: "success",
              title: "Xóa thành công",
              showConfirmButton: true,
            });
          }
        });
      },
      onCancel() {},
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Spin
          style={{
            width: `100%`,
            margin: `auto`,
          }}
          indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
        />
      ) : (
        <RealEstateTable
          realEstateList={realEstateList}
          changePageIndex={changePageIndex}
          pageIndex={pageIndex}
          isLoading={isLoading}
          onClickHideSellRealEstate={handleClickHideRealEstate}
          onDeleteSellRealEstate={handleDeleteRealEstate}
          isSell
          totalItems={totalItems}
        />
      )}

      <ConfirmChangeSellRealEstateStatus
        isVisible={isVisible}
        onClose={close}
        onSubmitChangeStatus={handleSubmmitChangeStatus}
        isLoading={isLoadingChangeStatus}
        id={selectedRealEstateId}
      />
    </>
  );
};

export default SellRealEstate;
