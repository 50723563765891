import React from "react";
import { Card, Descriptions, Icon, Typography, Carousel, Empty } from "antd";
import "./cardSlider.scss";
import { Settings } from "react-slick";
import { ResourceUrl } from "../../config/main";
import { merge } from "less/lib/less/utils";
import Helpers from "../../functions/Helpers";
import moment from "moment";
import { EnvironmentOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <div {...props}>
    <Icon type="left" />
  </div>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <div {...props}>
    <Icon type="right" />
  </div>
);

class CardSlider extends React.Component<{
  dataSource?: Array,
  settings?: Settings,
  className?: string,
  slideItem?: number,
}> {
  state = {
    settings: {
      infinite: false,
      speed: 500,
      slidesToShow:
        this.props.slideItem !== undefined ? this.props.slideItem : 6,
      slidesToScroll:
        this.props.slideItem !== undefined ? this.props.slideItem : 6,
      arrows: true,
      dots: false,
      nextArrow: <SlickArrowRight />,
      prevArrow: <SlickArrowLeft />,
      responsive: [
        {
          breakpoint: 1441,
          settings: {
            slidesToShow:
              this.props.slideItem !== undefined ? this.props.slideItem : 4,
            slidesToScroll:
              this.props.slideItem !== undefined ? this.props.slideItem : 4,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow:
              this.props.slideItem !== undefined ? this.props.slideItem : 3,
            slidesToScroll:
              this.props.slideItem !== undefined ? this.props.slideItem : 3,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  };
  componentDidMount(): void {
    let data = [];
    if (this.props.settings && this.props.settings.responsive) {
      data = this.state.settings.responsive.map((itm) => {
        return merge(
          itm,
          this.props.settings.responsive.find(
            (item) => itm.breakpoint === item.breakpoint
          )
        );
      });
    } else {
      data = this.state.settings.responsive;
    }
    this.setState({
      settings: {
        ...this.state.settings,
        ...this.props.settings,
        responsive: data,
      },
    });
  }

  defaultStyle = () =>
    this.props.dataSource.map((source, index) => (
      <a
        href={"/bat-dong-san/" + source.slug}
        style={{
          margin: "0 5px",
        }}
        key={index}
      >
        <Card
          hoverable
          cover={
            <aside
              style={{
                backgroundImage:
                  "url('" + ResourceUrl + source.cover_image + "')",
              }}
              className="cartImage"
            />
          }
          className="cardBlock"
          actions={[
            <Descriptions
              column="3"
              style={{
                textAlign: "left",
                padding: "0 12px",
              }}
              className="cartInfo"
              size={"small"}
            >
              <Descriptions.Item>
                {source.bedroom_quantity} PN
              </Descriptions.Item>
              <Descriptions.Item>
                {source.bathroom_quantity} WC
              </Descriptions.Item>
              <Descriptions.Item>{source.floor} TẤM</Descriptions.Item>
            </Descriptions>,
          ]}
          size="small"
        >
          <Card.Meta
            title={
              <Text className="cartTitle">
                {Helpers.moneyFormatVn(source.price)}
                <span style={{ float: "right" }}>
                  {parseFloat(source.acreage, 2)} m<sup>2</sup>
                </span>
              </Text>
            }
            description={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text className="cartDes">
                  <p>{source.title}</p>
                </Text>
                <Text
                  style={{
                    margin: 0,
                  }}
                >
                  <Text>
                    <EnvironmentOutlined
                      className="icon"
                      style={{
                        marginRight: "5px",
                      }}
                    />
                    {Helpers.getString(source.address) +
                      Helpers.getString(source.district) +
                      Helpers.getString(source.city, true)}
                  </Text>
                </Text>
                <Text
                  style={{
                    margin: 0,
                  }}
                >
                  <Icon
                    type="clock-circle"
                    className="icon"
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  {Helpers.getLinkBroker(source)}<span>đăng{" "}</span>
                  {moment(source.created_at).fromNow()}
                </Text>
              </div>
            }
          />
        </Card>
      </a>
    ));

  renderView(view) {
    return (
      <Carousel
        className={"cardList " + this.props.className}
        {...this.state.settings}
      >
        {view}
      </Carousel>
    );
  }

  render() {
    return this.props.children ? (
      this.renderView(this.props.children)
    ) : this.props.dataSource.length > 0 ? (
      this.renderView(this.defaultStyle())
    ) : (
      <Empty imageStyle={{ height: 410 }} description={false} />
    );
  }
}

export default CardSlider;
