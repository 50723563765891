import { Affix, Button, Drawer, Layout } from "antd";
import cx from "classnames";
import _ from "lodash";
import React from "react";
import { isMobile } from "react-device-detect";
import { Link, withRouter } from "react-router-dom";
import { ResourceUrl } from "../../config/main";
import styles from "./header.module.scss";
import MenuTop from "./menuTop/menuTop";

const { Header } = Layout;

class SiteHeader extends React.Component {
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    this.onClose = this.onClose.bind(this);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showSearch = () => {
    let tus = !this.state.searchShow;
    let classSearch;
    if (tus) {
      classSearch = cx(styles.searchBar, styles.show);
    } else {
      classSearch = styles.searchBar;
    }
    this.setState({
      searchShow: tus,
      classSearch,
    });
  };

  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      type: location ? location.pathname : null,
      visible: false,
      searchShow: false,
      classSearch: styles.searchBar,
    };
  }

  render() {
    return (
      <Affix
        style={{
          zIndex: 1000,
        }}
      >
        <Header className={styles.siteHeader} id="size-height">
          <div className="section-wrap">
            <div
              className={styles.blockHead}
              style={{
                alignItems: "center",
              }}
            >
              <div className={styles.siteBranding}>
                <div className={styles.mobileMenu}>
                  <Button
                    icon="menu"
                    size="large"
                    type="link"
                    onClick={this.showDrawer}
                  />
                  <Drawer
                    bodyStyle={{ display: "flex", flexDirection: "column" }}
                    title="Menu"
                    style={{
                      zIndex: 1004,
                      padding: 0,
                    }}
                    placement="left"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    className={styles.drawerVisible}
                  >
                    <MenuTop mode="vertical" onClose={this.onClose} />
                  </Drawer>
                </div>
                {this.state.searchShow &&
                !_.isEmpty(this.props.province) ? null : (
                  <Link to="/">
                    <img
                      width={isMobile ? 180 : 200}
                      src={ResourceUrl + this.props.province?.logo}
                      alt="logo"
                    />
                  </Link>
                )}
                {/* <React.Fragment>
                  <SearchBar
                    className={this.state.classSearch}
                    style={{ float: "left", marginLeft: "10px", flexGrow: 1 }}
                  />
                  <div className={styles.searchMobile}>
                    <Button
                      icon="search"
                      size="large"
                      type="link"
                      onClick={this.showSearch}
                    />
                  </div>
                </React.Fragment> */}
              </div>
              <MenuTop />
            </div>
          </div>
        </Header>
      </Affix>
    );
  }
}

export default withRouter(SiteHeader);
