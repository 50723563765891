import { useQuery } from '@tanstack/react-query';
import { Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getHotRealEstates,
  getProjects,
  GET_HOT_REAL_ESTATES_URL,
  GET_PROJECTS_URL,
} from '../../../api/project';
import Banner from '../../../component/banner/banner';
import HeadingContent from '../../../component/banner/HeadingContent';
import HeadingBox from '../../../component/HeadingBox';
import ProjectList from '../../../component/project/ProjectList';
import ProjectWidget from '../../../component/project/ProjectWidget';
import Helpers from '../../../functions/Helpers';
import {
  PROJECT_ORDER_TYPES,
  PROJECT_ORDER_TYPE_NEW_PRODUCT,
} from './contants';
import './project.scss';

const { Option } = Select;

const Projects = () => {
  const history = useHistory();

  const orderBy = Helpers.urlParam('order_by');
  const page = Helpers.urlParam('page');
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderBy);
  const { data: dataRealEstate, isLoading: isLoadingRealEstate } = useQuery(
    [GET_HOT_REAL_ESTATES_URL],
    () => getHotRealEstates()
  );
  const { data, isFetching } = useQuery(
    [GET_PROJECTS_URL, { orderBy: selectedOrderBy, page: page }],
    () => getProjects({ orderBy: selectedOrderBy, page: page })
  );
  const responseProjects = data?.data;
  const projects = responseProjects?.projects;
  const handleChangeSelected = (value) => {
    history.push({
      search: '?order_by=' + value,
    });
  };
  useEffect(() => {
    console.log(orderBy);
    setSelectedOrderBy(orderBy);
  }, [orderBy]);
  return (
    <div className="project">
      <Banner
        headingContent={
          <HeadingContent
            heading="DỰ ÁN"
            subheading="Các dự án về bất động sản"
          />
        }
      />
      <div className="section-wrap">
        <div className="project-content">
          <Row gutter={{ xs: 0, lg: 16 }}>
            <Col xs={24} lg={16} className="project-content-left">
              <div className="project-content-left-header">
                <h3 className="project-content-left-header-title">
                  Dự án toàn quốc
                </h3>
                <Select
                  defaultValue={PROJECT_ORDER_TYPE_NEW_PRODUCT}
                  style={{ width: 120 }}
                  onChange={handleChangeSelected}
                >
                  {PROJECT_ORDER_TYPES.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <ProjectList
                isLoading={isFetching}
                data={projects}
                pageSize={responseProjects?.page_size || 0}
                currentPage={responseProjects?.page || 1}
                total={responseProjects?.total || 0}
              />
            </Col>
            <Col xs={24} lg={8}>
              <ProjectWidget
                header={
                  <HeadingBox
                    title="Dự án nóng sốt"
                    backgroundColor="#BF0000"
                  />
                }
                data={dataRealEstate?.data?.list?.records}
                isLoading={isLoadingRealEstate}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Projects;
