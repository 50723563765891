import React from 'react';
import ReactDOM from 'react-dom';
import "./fonts/Nunito_Sans/NunitoSans-Regular.ttf";
import "./theme.less";
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom"
import { Provider } from 'react-redux';
import configureStore from "./configure-store";
import {HelmetProvider} from "react-helmet-async";

const store = configureStore();
ReactDOM.hydrate((
    <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </HelmetProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
