import { Icon, Modal, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import PostRealEstatePageApi from "../../../../api/postRealEstatePage";
//Comp
import { RealEstateTable } from "../components";

const { confirm } = Modal;

const SoldRealEstate = () => {
  const [realEstateList, setRealEstateList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = (pageIndexParam) => {
    setLoading(true);
    PostRealEstatePageApi.mySoldRealEstatesList(10, pageIndexParam)
      .then((res) => {
        if (res.data.status) {
          setRealEstateList(res.data.real_estates);
          setTotalItems(res.data.total);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(pageIndex);
  }, [pageIndex]);
  const changePageIndex = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  const handleDeleteRealEstate = (id) => {
    confirm({
      title: "Bạn có chắc muốn xóa tin bất động sản này?",
      okText: "Có",
      okType: "danger",
      cancelText: "Không",
      onOk: () => {
        PostRealEstatePageApi.remove(id).then((res) => {
          if (res.data.status) {
            fetchData(pageIndex);
            Swal.fire({
              icon: "success",
              title: "Xóa thành công",
              showConfirmButton: true,
            });
          }
        });
      },
      onCancel() {},
    });
  };
  return (
    <>
      {isLoading ? (
        <Spin
          style={{
            width: `100%`,
            margin: `auto`,
          }}
          indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
        />
      ) : (
        <RealEstateTable
          realEstateList={realEstateList}
          changePageIndex={changePageIndex}
          pageIndex={pageIndex}
          onDeleteSellRealEstate={handleDeleteRealEstate}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default SoldRealEstate;
