import { EnvironmentOutlined } from '@ant-design/icons';
import { Col, Icon, Layout, List, Row, Typography } from 'antd';
import React from 'react';
import Helpers from '../../../functions/Helpers';
import Broker from './broker/broker';
import ImageRealEstate from './image/imageRealEstate';
import InterestRate from './interestRate/interestRate';
import PriceEstimate from './PriceEstimate';
import styles from './realEstateContent.module.scss';
import RealEstateDesc from './RealEstateDesc';
import Specs from './Specs';
import Utility from './utility/utility';

const { Content } = Layout;
const { Title } = Typography;

const realStateContent = (props) => {
  let realEstate = props.realEstate,
    province = props.province;
  if (Object.keys(realEstate).length === 0) return null;
  return (
    <Content className="project_content">
      <div className="header_title">
        <span className="project_name">{realEstate.title}</span>
      </div>
      <span className="create-time">
        <Icon type="clock-circle" className="i_clock_circle" />
        Ngày đăng {Helpers.formatDate(realEstate.created_at)}
        <EnvironmentOutlined className="i_location" />
        {Helpers.getString(realEstate.address) +
          Helpers.getString(realEstate.district) +
          Helpers.getString(realEstate.city, true)}
      </span>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="gallery-container"
        >
          {typeof realEstate.real_estate_galleries !== 'undefined' &&
          realEstate.real_estate_galleries !== null ? (
            <ImageRealEstate
              avatar={realEstate.cover_image}
              galleries={realEstate.real_estate_galleries}
            />
          ) : null}
        </Col>
        <Col span={24}>
          <Broker broker={realEstate.broker} realEstate={realEstate} />
        </Col>

        <Col
          span={24}
          className={`${styles.statContainer} mb-24 border-bottom`}
        >
          <Row type="flex">
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <p>Mức giá</p>
              <Title className={styles.statTitlePrice} level={4}>
                {Helpers.moneyFormatVn(realEstate.price)}
              </Title>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <p>Diện tích</p>
              <Title className={styles.statTitle} level={4}>
                {parseFloat(realEstate.acreage, 2)} m<sup>2</sup>
              </Title>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <p>Trung bình</p>
              <Title className={styles.statTitle} level={4}>
                {Helpers.moneyFormatVn(realEstate.price / realEstate.acreage)}{' '}
                /m<sup>2</sup>
              </Title>
            </Col>
          </Row>
        </Col>
        {!Helpers.isBlank(realEstate.body) ? (
          <Col span={24} className="mb-24 border-bottom">
            <RealEstateDesc realEstate={realEstate} />
          </Col>
        ) : (
          ''
        )}
        {realEstate.details && realEstate.details?.length > 0 && (
          <Col span={24} className="border-bottom">
            <h3 className="sub-heading">Đặc điểm bất động sản</h3>
            <Specs specs={realEstate.details} />
          </Col>
        )}
      </Row>
      {realEstate.furnitures && !!realEstate.furnitures.length && (
        <Row className="mt-24 mb-24 border-bottom">
          <List
            className={styles.renderList}
            header={<h3 className="sub-heading">Nội thất</h3>}
            grid={{
              gutter: 16,
              column: 3,
              xs: 2,
              sm: 2,
              md: 2,
            }}
            dataSource={realEstate.furnitures}
            renderItem={(item) => (
              <List.Item>
                <li>{item.name}</li>
              </List.Item>
            )}
          />
        </Row>
      )}

      {realEstate.internals && !!realEstate.internals.length && (
        <Row className="mt-24 mb-24 border-bottom">
          <List
            className={styles.renderList}
            header={<h3 className="sub-heading">Tiện ích nội khu</h3>}
            grid={{
              gutter: 16,
              column: 3,
              xs: 2,
              sm: 2,
              md: 2,
            }}
            dataSource={realEstate.internals}
            renderItem={(item) => (
              <List.Item>
                <li>{item.name}</li>
              </List.Item>
            )}
          />
        </Row>
      )}

      {realEstate.lat === 0 && realEstate.lng === 0 ? null : (
        <Row className="mt-24 mb-24 pb-24 border-bottom">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Utility realEstate={realEstate} province={province} />
          </Col>
        </Row>
      )}

      <Row className="border-bottom pb-24 mb-24">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <InterestRate price={realEstate.price} banks={realEstate.banks} />
        </Col>
      </Row>
      <Row className="mt-24 mb-24">
        <Col>
          <PriceEstimate realEstate={realEstate} />
        </Col>
      </Row>
    </Content>
  );
};

export default realStateContent;
