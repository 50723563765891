import {
  Form, Modal,
  Tabs
} from "antd";
import React from "react";
import { connect } from "react-redux";
import LoginForm from "./loginForm";
import SignUpForm from "./signupForm";

const { TabPane } = Tabs;

const mapStateToProps = state => {
  return {
    profile: state.profile.infor
  };
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmDirty: false,
      alertLoginFailed: false,

      googleLoginUrl: null,
      isSubmit: false,
      activeForm: false,
      user: {},
    };
  }

  show = () => {
    this.setState({
      visible: true,
    });
  };

  handleAfterRegister = (user) => {
    if (user) {
      this.setState({
        visible: false,
        user,
      });
    }
  };

  closeActiveForm = () => {
    this.setState({
      activeForm: false,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profile) {
      this.setState({
        visible: false,
      });
    }
  };

  handleAfterLogin = () => {
    this.handleAfterRegister();  
  }


  render() {
    return (
      <Modal
        closable={null}
        visible={this.state.visible}
        footer={null}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={400}
        style={{
          zIndex: 1005,
        }}
      >
         <Tabs tabBarStyle={{ textAlign: "center" }} defaultActiveKey="1">
            <TabPane tab="Đăng nhập" key="1">
              <LoginForm afterSubmit={this.handleAfterLogin} />
            </TabPane>
            <TabPane tab="Đăng ký" key="2">
              <SignUpForm afterSubmit={this.handleAfterRegister} />
            </TabPane>
          </Tabs>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, null)(Form.create()(Login));
