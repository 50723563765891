export const getBasePath = (path) => {
  if (path.startsWith("/price-by-month")) {
    return "/price-by-month";
  }
  if (path.startsWith("/blog")) {
    return "/blog";
  }
  if (path.startsWith("/du-an-bat-dong-san")) {
    return "/du-an-bat-dong-san";
  }
  if (path.startsWith("/event")) {
    return "/event";
  }
  return null;
};
