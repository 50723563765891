import * as types from '../actions/actionTypes';
import SearchModel from "../../models/Search";

const initialState = new SearchModel();

const reducer = (state = initialState, action) => {
    if (action.type === types.SEARCH) {
        return action.search;
    } else {
        return state
    }
};

export default reducer;