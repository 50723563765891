import { useState, useCallback } from "react";

const useFormModal = ({ visibleDefault = false } = {}) => {
  const [isVisible, setIsVisible] = useState(visibleDefault);

  const show = useCallback((data) => {
    setIsVisible(true);
  }, []);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);
  const toggle = useCallback(() => {
    setIsVisible(prevState => !prevState);
  }, []);

  return { isVisible, show, close, toggle };
};

export default useFormModal;
