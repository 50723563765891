import Axios from "../axiosApi";
import * as realEstateConst from "../constants/realEstate";

export const GET_ONLINE_PRICE_URL = "/price-board/details";
export const getOnlinePrice = async ({ projectSapId, building }) => {
  const result = await Axios.post(GET_ONLINE_PRICE_URL, {
    Token: "",
    ProjectSapId: projectSapId,
    BuildingSAPId: building?.SAPID,
  });
  return result;
};
export const CREATE_BOOKING_URL = "/apartment/book";
export const createBooking = async (params) => {
  const result = await Axios.post(CREATE_BOOKING_URL, params);
  return result;
};

export const CREATE_TRANSACTION_URL = "/transaction/create";
export const createTransaction = async (body) => {
  const result = await Axios.post(CREATE_TRANSACTION_URL, body);
  return result;
};

export const GET_BOOKING_LIST_URL = "/apartment/book";
export const getBookingList = async ({ pageSize = 10, pageIndex = 1 }) => {
  try {
    const params = {
      limitPage: pageSize,
      page: pageIndex,
    };
    const result = await Axios.get(GET_BOOKING_LIST_URL, { params: params });
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const GET_BOOKED_BY_ID_URL = (id) => `apartment/book/${id}`;
export const getBookedById = async (id) => {
  const result = await Axios.get(GET_BOOKED_BY_ID_URL(id), {});
  return result;
};

export const GET_PROJECT_CARTS_URL = "/listsProjectCart";
export const getProjectCarts = async () => {
  const bookingList = Axios.post(GET_PROJECT_CARTS_URL, {
    status_cart: realEstateConst.PROJECT_CART_STATUS_RECEIVE_BOOKING_VALUE,
    page_size: 5,
  });
  const sellingList = Axios.post(GET_PROJECT_CARTS_URL, {
    status_cart: realEstateConst.PROJECT_CART_STATUS_SELLING_VALUE,
    page_size: 5,
  });
  const soldList = Axios.post(GET_PROJECT_CARTS_URL, {
    status_cart: realEstateConst.PROJECT_CART_STATUS_SOLD_VALUE,
    page_size: 5,
  });
  const result = await Promise.all([bookingList, sellingList, soldList]);
  return result;
};

export const GET_EXPIRED_TIME_BY_BOOK_ID_URL = (id) =>
  `expired-time/book/${id}`;
export const getExpiredTimeByBookId = async (id) => {
  const result = await Axios.get(GET_EXPIRED_TIME_BY_BOOK_ID_URL(id), {});
  return result;
};

export const GET_PAYMENT_ACCOUNT_URL = `getPaymentAccount`;
export const getPaymentAccount = async () => {
  const result = await Axios.get(GET_PAYMENT_ACCOUNT_URL, {});
  return result;
};

const CONFIRM_PERCHASE = (bookingId) => `/apartment/buy/${bookingId}`;
export const confirmPerchase = async ({ bookingId }) => {
  const result = await Axios.post(CONFIRM_PERCHASE(bookingId));
  return result;
};

export const GET_APARMENT_DETAIL = (apartmentId) => `/apartment/${apartmentId}`;
export const getApartmentDetail = async (apartmentId) => {
  const result = await Axios.get(GET_APARMENT_DETAIL(apartmentId));
  return result;
};

const REQUEST_REFUND = (bookingId) => `/booking/refund/${bookingId}`;
export const requestRefund = async ({ bookingId }) => {
  const result = await Axios.post(REQUEST_REFUND(bookingId));
  return result;
};