import React from "react";
import styles from "./hotKeyword.module.scss";
import Services from "../../api/services";
import {Descriptions} from "antd";
import _ from "lodash";
import {Link} from "react-router-dom";

class HotKeyword extends React.Component{
    state = {
        data: []
    };

    componentDidMount(): void {
        Services.hotKeyword().then(r => {
            this.setState({
                data: r
            })
        });
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="section-wrap">
                {_.isEmpty(this.state.data) ? null : (
                    <Descriptions className={styles.keywordHot} colon={false} column={{
                        xxl: 6,
                        xl: 6,
                        lg: 5,
                        md: 3,
                        sm: 2,
                        xs: 2
                    }} title="Từ khoá phổ biến nhất">
                        {this.state.data.map((o, i) => (
                            <Descriptions.Item key={i}>
                                <Link to={"/"+o.slug+'.html'}>{o.value}</Link>
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                )}
            </div>
        );
    }
}

export default HotKeyword;