import * as actionTypes from './actionTypes';

export const setSelectedProjectId = (projectId) => {
  return {
    type: actionTypes.SET_SELECTED_PROJECT_ID,
    selectedProjectId: projectId,
  };
};
export const setSelectedAparment = (apartment) => {
  return {
    type: actionTypes.SET_SELECTED_APARTMENT,
    selectedApartment: apartment,
  };
};
