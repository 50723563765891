export const BOOKING_STATUS_WAITING_FOR_DEPOSIT_VALUE = 0; //vừa mới book chưa up uỷ nhiệm chi
export const BOOKING_STATUS_PENDING_VALUE = 1; // đã up uỷ nhiệm chi chờ xác nhận
export const BOOKING_STATUS_SUCCESSFUL_DES_VALUE = 2; // đã được admin xác nhận uỷ nhiệm chi
export const BOOKING_STATUS_FAILED_DES_VALUE = 3; // uỷ nhiệm chi sai thông tin admin xác nhận thất bại
export const BOOKING_STATUS_CANCELED_VALUE = 4; // bị huỷ do quá thời gian chuyển tiền đặt cọc hoặc admin huỷ
export const BOOKING_STATUS_PURCHASE_TIME_OUT = 5; // quá thời gian xác nhận mua
export const BOOKING_STATUS_BOUGHT_VALUE = 6; // Khách khách đã xác nhận mua
export const BOOKING_STATUS_WAITING_FOR_REFUND_VALUE = 7; // Khách yêu cầu hoàn tiền
export const BOOKING_STATUS_REFUNDED_VALUE = 8; // Đã hoàn tiền cho khách

export const BOOKING_STATUSES = [
  {
    label: "Đang chờ thanh toán cọc",
    value: BOOKING_STATUS_WAITING_FOR_DEPOSIT_VALUE,
    color: "#2db7f5",
  },
  {
    label: "Đang chờ xác nhận cọc",
    value: BOOKING_STATUS_PENDING_VALUE,
    color: "#E2A329",
  },
  {
    label: "Cọc thành công",
    value: BOOKING_STATUS_SUCCESSFUL_DES_VALUE,
    color: "#21B21E",
  },
  {
    label: "Cọc thất bại",
    value: BOOKING_STATUS_FAILED_DES_VALUE,
    color: "#BF0000",
  },
  {
    label: "Đã huỷ",
    value: BOOKING_STATUS_CANCELED_VALUE,
    color: "#bf0000",
  },
  {
    label: "Quá thời gian xác nhận mua",
    value: BOOKING_STATUS_PURCHASE_TIME_OUT,
    color: "#666666",
  },
  {
    label: "Đã xác nhận mua",
    value: BOOKING_STATUS_BOUGHT_VALUE,
    color: "#21B21E",
  },
  {
    label: "Chờ hoàn tiền",
    value: BOOKING_STATUS_WAITING_FOR_REFUND_VALUE,
    color: "#f50",
  },
  {
    label: "Đã hoàn tiền",
    value: BOOKING_STATUS_REFUNDED_VALUE,
    color: "#f50",
  },
];
