import * as types from '../actions/actionTypes';

const initialState = {};

const reducer = (state = initialState, action) => {
    if (action.type === types.SEARCH_FILTER_OPTIONS) {
        return {
            ...state,
            ...action.filter
        };
    } else {
        return state;
    }
};

export default reducer;