import React, { useEffect, useState } from "react";
import ListVertical from "../../component/listVertical/listVertical";
import {
  Row,
  Col,
  Breadcrumb,
  Icon,
  Button,
  Skeleton,
  Modal,
} from "antd";
import { connect } from "react-redux";

import RealEstateItem from "./realEstateItem";
import Packages from "./packages";
import PackageSetting from "./packageSetting";
import Payments from "./payment";

import RealEstateApi from "../../api/realEstatePage";
import Helpers from "../../functions/Helpers";
import "./index.scss";
import { Redirect } from "react-router-dom";
import Api from "../../api/profileApi";

const Package = {
  Vip1: 1,
  Vip2: 2,
  Vip3: 3,
};

function SubmitButton(props) {
  const [priceTotal, setPriceTotal] = useState(0);
  useEffect(() => {
    setPriceTotal(props.packageObject.price * props.days);
  }, [props.days, props.packageObject]);

  return (
    <Button className="btn-submit-payment" onClick={props.submitPayment}>
      Phí {Helpers.formatNumber(priceTotal)} đ - Thanh toán ngay
    </Button>
  );
}

function ReactEstatePush(props) {
  const slug = props.match.params.slug;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [realEstate, setRealEstate] = useState(null);
  const [packages, setPackages] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [packageSelected, setPackageSelected] = useState(Package.Vip1);
  const [label, setLabel] = useState("");
  const [days, setDays] = useState(3);
  const [packageObject, setPackageObject] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [orderFreeExist, setOrderFreeExist] = useState(false);

  useEffect(() => {
    RealEstateApi.getRealEstatePush(slug).then(async (res) => {
      if (!res.data.error) {
        setRealEstate(res.data.real_estate);
        setPackages(res.data.packages);
        setLabelOptions(res.data.packages[0].label);
        setLabel(res.data.packages[0].label[0].value);
        setPayments(res.data.payments);
        setPackageObject(
          res.data.packages.find(
            (packageItem) => packageItem.id === packageSelected
          )
        );
        setOrderFreeExist(res.data.orderFreeExist);
      } else setError(true);
      setLoading(false);
    });
  }, [packageSelected, slug]);

  function submitPayment() {
    const data = {
      package_id: packageSelected,
      label: packageSelected === Package.Vip1 ? "Hot" : label,
      broker_id: props.profile.id,
      real_estate_id: realEstate.id,
      payment_id: paymentSelected,
      price: packageObject.price * days,
      days: days,
    };
    setSubmitLoading(true);
    if (data.payment_id === null) {
      Modal.error({
        title: "Lỗi",
        content: "Bạn vui lòng chọn phương thức thanh toán",
      });
      setSubmitLoading(false);
      return false;
    }
    Api.submitPayment(data).then((res) => {
      const data = res.data;
      if (data.payment_type === 1 && data.statusCode === 1) {
        window.location = data.url;
      } else if (data.payment_type === 0) {
        setSubmitSuccess(true)
      } else {
        Modal.error({
          title: "Máy chủ đang bị lỗi",
          content: "Bạn vui lòng quay lại sau, Cám ơn",
        });
      }
      setSubmitLoading(false);
    });
  }

  function classPackageRealEstate() {
    if (packageSelected === Package.Vip1) return "vip-1";
    if (packageSelected === Package.Vip2) return "vip-2";
    if (packageSelected === Package.Vip3) return "vip-3";
  }

  if (error) return <Redirect to="/" />;

  if (submitSuccess) return <Redirect to="/payment-success" />;

  return (
    <div className="frontPageSection real-estate-push">
      <div className="section-wrap-private">
        <Row gutter={32}>
          <Col span={24} style={{ marginBottom: "1rem" }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type="home" />
                <span>Trang chủ</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>Thông tin tài khoản</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="hide-on-mobile" xs={24} sm={24} md={7} lg={6} xl={4}>
            <ListVertical activeLink={"Nhà đất của tôi"} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={17}
            lg={18}
            xl={20}
            className="section-content"
          >
            <span className="text-title">Đẩy tin ưu tiên</span>
            <RealEstateItem
              realEstate={realEstate}
              loading={loading}
              className="top"
            />
            <Row>
              <span className="text-service">Chọn gói dịch vụ</span>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={14}
                xl={14}
                className="package-section"
              >
                {loading ? (
                  <Skeleton paragraph={{ rows: 1 }} title={false} />
                ) : (
                  <>
                    <Packages
                      packages={packages}
                      setLabelOptions={setLabelOptions}
                      setPackageSelected={setPackageSelected}
                      setPackageObject={setPackageObject}
                      packageSelected={packageSelected}
                    />
                    <PackageSetting
                      labelOptions={labelOptions}
                      setLabel={setLabel}
                      label={label}
                      setDays={setDays}
                      days={days}
                      Package={Package}
                      packageSelected={packageSelected}
                      paymentSelected={paymentSelected}
                    />
                    <Payments
                    orderFreeExist={orderFreeExist}
                      payments={payments}
                      paymentSelected={paymentSelected}
                      setDays={setDays}
                      setPaymentSelected={setPaymentSelected}
                    />
                    <SubmitButton
                      packageObject={packageObject}
                      days={days}
                      submitPayment={submitPayment}
                      loading={submitLoading}
                    />
                  </>
                )}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={10}
                xl={10}
                className="real-estate-package"
              >
                <span className="text-title">Xem demo gói tin</span>
                <RealEstateItem
                  className={classPackageRealEstate()}
                  realEstate={realEstate}
                  packageSelected={packageSelected}
                  label={label}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ReactEstatePush);
