import { Button, Col, Icon, Row, Tabs, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
//Comp
import { PageContent, PageSidebar, Wrapper } from "../../components";
import { MENU_REAL_ESTATE_ID } from "../../constants";

import SellRealEstate from "./SellRealEstate";
import SoldRealEstate from "./SoldRealEstate";
import VIPRealEstate from "./VIPRealEstate";

const { Title } = Typography;
const { TabPane } = Tabs;

const RealEstates = () => {
  const history = useHistory();
  const handleRedirectToCreateRealEstate = () => {
    history.push("/user/real-estate/create");
  };
  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_REAL_ESTATE_ID} />
        <PageContent>
          <Row type="flex" justify="space-between">
            <Col>
              <Title className="text-black" level={3}>
                Nhà đất của tôi
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{
                  fontSize: "16px",
                }}
                onClick={handleRedirectToCreateRealEstate}
              >
                <Icon type="form" style={{ fontSize: 18 }} />
                Đăng tin
              </Button>
            </Col>
          </Row>
          <div className={`table-history`}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Tin thường" key="1">
                <SellRealEstate />
              </TabPane>
              <TabPane tab="Đã bán" key="2">
                <SoldRealEstate />
              </TabPane>
              <TabPane tab="Tin VIP" key="3">
                <VIPRealEstate />
              </TabPane>
            </Tabs>
          </div>
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default RealEstates;
