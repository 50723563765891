export const BUYER_TYPE_PERSONAL_VALUE = 1;
export const BUYER_TYPE_ORGANIZATION_VALUE = 2;
export const BUYER_TYPES = [
  {
    value: BUYER_TYPE_PERSONAL_VALUE,
    label: "Cá nhân",
  },
  {
    value: BUYER_TYPE_ORGANIZATION_VALUE,
    label: "Tổ chức",
  },
];
export const IDENTIFY_TYPES = [
  {
    value: 1,
    lable: "CCCD/CMTND",
  },
  {
    value: 2,
    lable: "CMT quân đội",
  },
  {
    value: 3,
    lable: "Hộ chiếu",
  },
];
export const BUSINESS_TYPES = [
  {
    label: "Công ty Cổ Phần",
    value: 4,
  },
  {
    label: "Công ty TNHH",
    value: 5,
  },
  {
    label: "Công ty hợp danh",
    value: 6,
  },
  {
    label: "Doanh nghiệp tư nhân",
    value: 7,
  },
  {
    label: "Doanh nghiệp nhà nước",
    value: 8,
  },
];
export const TITLES = [
  {
    value: 'mr',
    lable: "Ông ",
  },
  {
    value: 'mrs',
    lable: "Bà",
  },
];
