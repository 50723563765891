import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Button,
  Empty,
  Form,
  Modal,
  Input,
  Spin,
  Icon,
  Pagination,
} from "antd";
import "./index.scss";
import PostRealEstatePageApi from "../../api/postRealEstatePage";
import { withRouter } from "react-router-dom";
import { ResourceUrl } from "../../config/main.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Helpers from "../../functions/Helpers";
import { Link } from "react-router-dom";
import moment from "moment";

const { confirm } = Modal;

class MySellList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listMySellRealEstates: [],
      totalMySellRealEstates: 0,
      currentMySellRealEstatesPage: 1,
      visible: false,
      id: "",
      isLoadingChangeStatus: false,
    };
  }

  componentDidMount = () => {
    this.fetchDataRealEstate();
  };

  fetchDataRealEstate() {
    PostRealEstatePageApi.mySellRealEstatesList(20, 1).then((res) => {
      if (res.data.status) {
        this.setState(
          {
            listMySellRealEstates: res.data.real_estates,
            totalMySellRealEstates: res.data.total,
            isLoading: false,
          },
          () => {
            this.props.form.setFieldsValue({
              date_of_sale: moment(new Date()).format("DD/MM/YYYY"),
            });
          }
        );
      }
    });
  }

  handleEdit = (slug) => {
    this.props.history.push(`/me/real-estate/edit/${slug}`);
  };

  handleView = (slug) => {
    this.props.history.push(`/bat-dong-san/${slug}`);
  };

  handleDelete = (id) => {
    confirm({
      title: "Bạn có chắc muốn xóa tin bất động sản này?",
      okText: "Có",
      okType: "danger",
      cancelText: "Không",
      onOk: () => {
        PostRealEstatePageApi.remove(id).then((res) => {
          if (res.data.status) {
            const index = this.state.listMySellRealEstates.findIndex((item) => {
              return item.id === id;
            });
            let temp = [...this.state.listMySellRealEstates];
            if (index > -1) {
              temp.splice(index, 1);
            }
            this.setState({
              listMySellRealEstates: temp,
            });
            Swal.fire({
              icon: "success",
              title: "Xóa thành công",
              showConfirmButton: true,
            });
          }
        });
      },
      onCancel() {},
    });
  };

  showModal = (id) => {
    this.setState({
      visible: true,
      id: id,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoadingChangeStatus: true });
        values = {
          ...values,
          id: this.state.id,
          real_estate_id: this.state.id,
          date_of_sale: new Date(),
        };
        PostRealEstatePageApi.changeStatus(values).then((res) => {
          if (res.data.status) {
            const index = this.state.listMySellRealEstates.findIndex((item) => {
              return item.id === this.state.id;
            });
            let temp = [...this.state.listMySellRealEstates];
            if (index > -1) {
              temp.splice(index, 1);
            }
            this.setState(
              {
                isLoadingChangeStatus: false,
                listMySellRealEstates: temp,
                visible: false,
              },
              () => {
                Swal.fire({
                  icon: "success",
                  title: "Chuyển trạng thái thành công",
                  showConfirmButton: true,
                });
              }
            );
          }
        });
      }
    });
  };

  validatePhoneNumber = (rule, value, callback) => {
    var phoneno = /^\d{10}$/;
    if (value && !value.match(phoneno)) {
      callback("Số điện thoại là số có 10 chữ số!");
    } else {
      callback();
    }
  };

  render() {
    const { isLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    return isLoading ? (
      <Spin
        style={{
          width: `100%`,
          margin: `auto`,
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
      />
    ) : (
      <>
        <Modal
          title="Xác nhận tình trạng mua bán - cho thuê"
          visible={this.state.visible}
          maskClosable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button
              loading={this.state.isLoadingChangeStatus}
              key="submit"
              onClick={this.handleSubmit}
              type="primary"
            >
              Xác nhận
            </Button>,
            <Button onClick={this.handleCancel} key="back">
              Đóng
            </Button>,
          ]}
        >
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div
              style={{
                background: "#F7F2C9",
                padding: "15px",
                color: "#35aa47",
                fontSize: "18px",
              }}
            >
              Chúc mừng bạn vừa giao dịch thành công. Vui lòng xác nhận để cập
              nhật trạng thái của tin đăng và được vinh danh trên hệ thông
            </div>
            <Form.Item label="Tên khách hàng">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Vui lòng nhập tên khách hàng!" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Số điện thoại khách hàng">
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại khách hàng!",
                  },
                  {
                    validator: this.validatePhoneNumber,
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Ngày cập nhật">
              {getFieldDecorator("date_of_sale", {})(<Input disabled />)}
            </Form.Item>
          </Form>
        </Modal>
        <Table>
          <Thead className="ant-table-thead">
            <Tr>
              <Th>Ảnh bìa</Th>
              <Th>Tiêu đề</Th>
              <Th>Diện tích</Th>
              <Th>Giá</Th>
              <Th>Ngày đăng</Th>
              <Th>&nbsp;&nbsp;&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody className="ant-table-tbody">
            {this.state.listMySellRealEstates &&
            !!this.state.listMySellRealEstates.length ? (
              this.state.listMySellRealEstates.map((item, key) => (
                <Tr key={key}>
                  <Td>
                    <div
                      className="img"
                      style={{
                        backgroundImage: `${
                          item.real_estate_galleries
                            ? `url(${ResourceUrl}${item.real_estate_galleries})`
                            : `url(${ResourceUrl}${item.cover_image})`
                        }`,
                      }}
                    ></div>
                  </Td>
                  <Td className="width-title">
                    <Link to={`/bat-dong-san/${item.slug}`}>
                      <div className="hide-on-mobile">{item.title}</div>
                      <div
                        className="hide-on-desktop"
                        style={{ marginTop: "10px" }}
                      >
                        {item.title}
                      </div>
                      <div style={{ color: `#BF0000`, fontSize: `12px` }}>
                        {`${item.address_number} ${item.street}`},{" "}
                        {`${item.district_name}, ${item.province_name}`}
                      </div>
                      <div
                        className="hide-on-mobile"
                        style={{ color: `#62D273`, fontSize: `12px` }}
                      >
                        {item.view_number === 0 ? "0" : item.view_number} lượt
                        xem
                      </div>
                      <div
                        className="hide-on-desktop"
                        style={{ fontSize: `12px` }}
                      >
                        {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                        {"  "}
                        <span style={{ color: `#62D273` }}>
                          {item.view_number === 0 ? "0" : item.view_number} lượt
                          xem
                        </span>
                      </div>
                      <div
                        className="hide-on-desktop"
                        style={{
                          marginTop: "10px",
                          color: `#ff4d4f`,
                          fontSize: `20px`,
                        }}
                      >
                        {item.price > 1000000000
                          ? `${Helpers.convertPrice(item.price, "Tỷ")} tỷ`
                          : `${Helpers.convertPrice(
                              item.price,
                              "Triệu"
                            )} triệu`}{" "}
                        / {`${parseInt(item.acreage)}`} m<sup>2</sup>
                      </div>
                    </Link>
                    <div className="action-td-re">
                      <Link to={"/me/real-estate-push/" + item.slug}>
                        <Button className="btn-dt">Đẩy tin</Button>
                      </Link>
                      <Button
                        onClick={this.showModal.bind(this, item.id)}
                        className="btn-hide"
                      >
                        Ẩn tin đã bán
                      </Button>
                    </div>
                  </Td>
                  <Td className="hide-on-mobile">
                    {`${parseInt(item.acreage)}`} m<sup>2</sup>
                  </Td>
                  <Td className="hide-on-mobile">
                    {item.price > 1000000000
                      ? `${Helpers.convertPrice(item.price, "Tỷ")} tỷ`
                      : `${Helpers.convertPrice(item.price, "Triệu")} triệu`}
                  </Td>
                  <Td className="hide-on-mobile">
                    {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                  </Td>
                  <Td className="td-action">
                    {/* <Button
                      onClick={this.showModal.bind(this, item.id)}
                      type="primary"
                      icon="dollar"
                    /> */}
                    <Link to={`/bat-dong-san/${item.slug}`}>
                      <Button className="btn-user" type="primary" icon="eye" />
                    </Link>
                    <Link to={`/me/real-estate/edit/${item.slug}`}>
                      <Button className="btn-edit" type="primary" icon="edit" />
                    </Link>
                    <Button
                      type="primary"
                      icon="delete"
                      className="btn-delete"
                      onClick={this.handleDelete.bind(this, item.id)}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6">
                  <Empty />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Pagination
          style={{ float: "right", marginTop: "1rem" }}
          defaultPageSize={20}
          pageSize={20}
          current={this.state.currentMySellRealEstatesPage}
          total={this.state.totalMySellRealEstates}
          onChange={(page, pageSize) => {
            this.setState({
              isLoading: true,
              currentMySellRealEstatesPage: page,
            });
            PostRealEstatePageApi.mySellRealEstatesList(20, page).then(
              (res) => {
                if (res.data.status) {
                  this.setState({
                    listMySellRealEstates: res.data.real_estates,
                    totalMySellRealEstates: res.data.total,
                    isLoading: false,
                  });
                }
              }
            );
          }}
        />
      </>
    );
  }
}

export default withRouter(Form.create()(MySellList));
