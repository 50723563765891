import * as actionTypes from "../actions/actionTypes";
const initialState = {
  infor: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PROFILE:
      return {
        ...state,
        infor: {
          ...action.infor
        }
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        infor: null
      };
    default:
      return state;
  }
};
export default reducer;
