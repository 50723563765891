import React, { PureComponent } from 'react';
import { Typography, Col, Pagination } from 'antd';
import ItemArticle from './item/ItemArticle';
import ItemRealEstate from './item/ItemRealEstate';
import './dataList.scss'
import { MainDomain, http } from '../../config/main';
const { Title } = Typography;

const numEachPage = 9;
class DataList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      minPage: 0,
      maxPage: 9,
    };
  }

  handleChangePage = value => {
    this.setState({
      minPage: (value - 1) * numEachPage,
      maxPage: value * numEachPage
    });
  };

  getArticleItem(articles) {
      articles =  articles.slice(this.state.minPage, this.state.maxPage);
      return articles.map((item, index) => (
           <ItemArticle key={index}
                       link={ this.props.isSubDomain ?
                              '/' + item.slug+".html"  :
                              item.user_sub_domain  ?
                              http + item.user_sub_domain + "." + MainDomain + "/" +item.slug+".html" :
                                '/broker/'+ item.user_name +'/' + item.slug+".html" }
                       cover_image={ item.cover_image }
                       title={ item.title }
                       created_at={ item.created_at }
                       user_name={ item.user_name } />
      ));
  }

  getRealEstates(real_estates) {

      if(this.props.pagination)
        real_estates =  real_estates.slice(this.state.minPage, this.state.maxPage);

      return real_estates.map((item, index) => (
           <ItemRealEstate key={index}
                           item={ item } />
      ));
  }

  getPagination(data) {
    if(data.length > numEachPage) {
      return  <div className="pagination-container">
                 <Pagination
                     defaultCurrent={1}
                     defaultPageSize={ numEachPage } //default size of page
                     onChange={this.handleChangePage}
                     total={ data.length }
                   />
               </div>
    }

    return null;
  }

  render () {
    const props = this.props;
    if(typeof props.articles !== 'undefined' &&
        props.articles.length > 0)
        return (
            <Col xs={ props.xs } sm={ props.sm } md={ props.md } lg={ props.lg } xl={ props.xl }
                 className="data-list-container"
                 style={ props.style }>
              <div className="data-list">
                  <Title className={"title " +  props.classTitle} level={4}> {  props.title } </Title>
                   {this.getArticleItem( props.articles)}
              </div>
              { props.pagination ?  this.getPagination(props.articles) : null }
            </Col>
        );

    if(typeof props.real_estates !== 'undefined' &&
        props.real_estates.length > 0)
        return (
            <Col xs={ props.xs } sm={ props.sm } md={ props.md } lg={ props.lg } xl={ props.xl }
                 className="data-list-container"
                 style={ props.style }>
              <div className="data-list">
                  <Title className={"title " +  props.classTitle} level={4}>{  props.title }</Title>
                   {this.getRealEstates( props.real_estates)}
              </div>
              { props.pagination ? this.getPagination(props.real_estates) : null}
            </Col>
        );

     return null;
  }
}

export default DataList;
