import React, { useState } from "react";
import { Row, Col, Descriptions, Icon, Tag, Button, Skeleton } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import moment from "moment";
import zaloImage from "../../../../../images/zalo.png";
import messengerImage from "../../../../../images/messenger.png";

import Helpers from "../../../../../functions/Helpers";
import { ImageMediumWithWatermark } from "../../../../../config/main";
import Image from "../../../../../component/image";

const Package = {
  Vip1: 1,
  Vip2: 2,
  Vip3: 3,
};

const RealEstateItem = (props) => {
  const item = props.realEstate;
  const broker = item ? item.broker : null;
  const [numberCut, setNumberCut] = useState(false);
  const loading = props.loading;

  if (loading)
    return (
      <Row className="data-item real-estates loading">
        <Col xs={6} sm={10} lg={6} xl={6} className="image-loading">
          <Skeleton paragraph={{ rows: 1 }} title={false} />
        </Col>
        <Col xs={18} sm={14} md={18} lg={18} xl={18}>
          <Skeleton title={false} paragraph={{ rows: 4 }} />
        </Col>
      </Row>
    );

  if (!item) return null;

  return (
    <Row
      className={"data-item real-estates " + props.className}
      key={props.index}
    >
      <Col xs={6} sm={10} md={6} lg={6} xl={6} className="image real-estates">
        <div>
          <Image src={ImageMediumWithWatermark + item.cover_image} />
          {item.status ? (
            <Tag color="#BF8100" className="card-tag-left">
              {item.status}
            </Tag>
          ) : (
            ""
          )}
        </div>
      </Col>
      <Col xs={18} sm={14} md={18} lg={18} xl={18} className="description">
        {props.packageSelected === Package.Vip1 && (
          <div className="label">
            <span className="text">Hot</span>
          </div>
        )}

        <Descriptions.Item>
          <span className="title-item">
            <div>{item.title}</div>
          </span>
          <br />
          <div className="price-acreage">
            <span className="price">{Helpers.moneyFormatVn(item.price)} /</span>
            <span className="acreage">
              {" "}
              {item.acreage} m<sup>2</sup>
            </span>
          </div>
          {(props.packageSelected === Package.Vip2 ||
            props.packageSelected === Package.Vip3) && (
            <div className="label">
              <span className="text">{props.label}</span>
            </div>
          )}
          <span className="location">
            <EnvironmentOutlined className="icon" />
            {Helpers.getString(item.address) +
              Helpers.getString(item.district) +
              Helpers.getString(item.city, true)}
          </span>

          <span className="date">
            <Icon type="clock-circle" className="icon" />
            {Helpers.getLinkBroker(item)}<span>đăng{" "}</span>
            {moment(item.created_at).fromNow()}
          </span>
        </Descriptions.Item>
      </Col>
      <Col xl={24}>
        {props.packageSelected === Package.Vip3 && broker && (
          <div className="contact">
            <Button type="primary" onClick={() => setNumberCut(!numberCut)}>
              {!numberCut ? (
                String(broker.phone).slice(0, -3) + "***"
              ) : (
                <a
                  href={"tel:" + broker.phone}
                  target={broker.sub_domain !== "" ? "blank" : "self"}
                >
                  {broker.phone}
                </a>
              )}
              <br />
              <i>
                <small>Gọi ngay</small>
              </i>
            </Button>
            <Button type="danger">
              Nhắn tin
              <br />
              <i>
                <small>Cho người bán</small>
              </i>
            </Button>
            {broker.zalo ? (
              <a href={"http://zalo.me/" + broker.zalo} target="blank">
                <img
                  src={zaloImage}
                  className="zalo-img"
                  alt="zalo_sms"
                  width="40"
                />
              </a>
            ) : null}
            {broker.facebook ? (
              <a href={"http://m.me/" + broker.facebook} target="blank">
                <img
                  src={messengerImage}
                  className="messenger-img"
                  alt="facebook_messgener"
                  width="40"
                />
              </a>
            ) : null}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default RealEstateItem;
