import Axios from "../axiosApi";

const dashboardApi = {
  mySellRealEstates: () => {
    return Axios.get("/realEstate/mySell");
  },
  mySoldRealEstates: () => {
    return Axios.get("/realEstate/mySold");
  },
  myRentRealEstates: () => {
    return Axios.get("/realEstate/myRent");
  },
  myCountArticles: () => {
    return Axios.get("/article/myCount");
  },
  myVip: (limit = 20, page = 1) => {
    return Axios.get('/realEstate/myVip?limit='+limit+'&page='+page);
  }
};

export default dashboardApi;
