import React, { useState } from "react";
import { List, Card, Tag, Typography, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

import Helpers from "../../../../../functions/Helpers";
import { ImageMediumWithWatermark } from "../../../../../config/main";
import MoneyFormat from "../../../../../component/moneyFormat";
import zaloImage from "../../../../../images/zalo.png";
import messengerImage from "../../../../../images/messenger.png";

const { Text } = Typography;
const RealEstateItem = (props) => {
  const styles = props.styles;
  const item = props.item;
  const [numberCut, setNumberCut] = useState(false);

  return (
    <List.Item
      onMouseEnter={() => (props.onHover ? props.onHover(item) : null)}
      onMouseLeave={() => (props.onHover ? props.onHover(item) : null)}
      className={styles.listPageItem + " " + props.size}
      key={item.id}
    >
      <Card
        bordered={false}
        className={item.package_id && "vip-" + item.package_id}
      >
        {item.package_id && item.package_id === 1 && (
          <div className="label">
            <span className="text">Hot</span>
          </div>
        )}
        <div className={styles.listPageImage + " " + props.size}>
          <Link
            to={"/bat-dong-san/" + item.slug}
            className="imageResize"
            style={{
              backgroundImage:
                "url('" + ImageMediumWithWatermark + item.cover_image + "')",
            }}
          >
            {item.status ? (
              <Tag color="#BF8100" className="card-tag-left">
                {item.status}
              </Tag>
            ) : (
              ""
            )}
          </Link>
        </div>
        <div className={styles.listPageContent + " " + props.size}>
          <React.Fragment>
            <Link
              style={{
                width: "100%",
                display: "block",
                fontSize: 16,
              }}
              to={"/bat-dong-san/" + item.slug}
            >
              {item.title}
            </Link>
            <Text className={"cartTitle " + props.size}>
              <MoneyFormat
                style={{
                  color: "#bf0000",
                }}
              >
                {item.price}
              </MoneyFormat>
              <span
                style={{
                  color: "#cc9036",
                  marginLeft: "40px",
                }}
              >
                {parseFloat(item.acreage, 2)}m<sup>2</sup>
              </span>

              {((item.package_id && item.package_id === 2) ||
                (item.package_id && item.package_id === 3)) && (
                <div className="label">
                  <span className="text">{item.text}</span>
                </div>
              )}
            </Text>
          </React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 12,
            }}
          >
            <Text
              style={{
                margin: 0,
              }}
            >
              <b>
                <Icon
                  style={{
                    marginRight: "5px",
                  }}
                  type="environment"
                />
              </b>
              {(item.address ? item.address + ", " : "") +
                (item.ward ? item.ward + ", " : "") +
                (item.district ? item.district + ", " : "") +
                (item.city ? item.city + " " : "")}
            </Text>
            <Text
              style={{
                margin: 0,
              }}
            >
              <b>
                <Icon
                  style={{
                    marginRight: "5px",
                  }}
                  type="clock-circle"
                />
              </b>
              {Helpers.getLinkBroker(item)}<span>đăng{" "}</span>
              {moment(item.created_at).fromNow()}
            </Text>
            {item.package_id === 3 && item.broker && (
              <div className="contact">
                <Button type="primary" onClick={() => setNumberCut(!numberCut)}>
                  {!numberCut ? (
                    String(item.broker.phone).slice(0, -3) + "***"
                  ) : (
                    <a
                      href={"tel:" + item.broker.phone}
                      target={item.broker.sub_domain !== "" ? "blank" : "self"}
                    >
                      {item.broker.phone}
                    </a>
                  )}
                  <br />
                  <i>
                    <small>Gọi ngay</small>
                  </i>
                </Button>
                <Button type="danger">
                  Nhắn tin
                  <br />
                  <i>
                    <small>Cho người bán</small>
                  </i>
                </Button>
                {item.broker.zalo ? (
                  <a href={"http://zalo.me/" + item.broker.zalo} target="blank">
                    <img
                      src={zaloImage}
                      className="zalo-img"
                      alt="zalo_sms"
                      width="40"
                    />
                  </a>
                ) : null}
                {item.broker.facebook ? (
                  <a
                    href={"http://m.me/" + item.broker.facebook}
                    target="blank"
                  >
                    <img
                      src={messengerImage}
                      className="messenger-img"
                      alt="facebook_messgener"
                      width="40"
                    />
                  </a>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </Card>
    </List.Item>
  );
};

export default RealEstateItem;
