export const PROJECT_ORDER_TYPE_NEW_PRODUCT = "new_product";
export const PROJECT_ORDER_TYPES = [
  {
    value: PROJECT_ORDER_TYPE_NEW_PRODUCT,
    label: "Mới nhất",
  },
  {
    value: "new_updated",
    label: "Mới cập nhật",
  },
  {
    value: "price_high",
    label: "Giá cao nhất",
  },
  {
    value: "price_low",
    label: "Giá thấp nhất",
  },
];
