import { Col, Row } from 'antd';
import { LocalStorage } from 'node-localstorage';
import React from 'react';
import { connect } from 'react-redux';
import HomePageApi from '../../api/homePage';
import Banner from '../../component/banner/banner';
import HeadingBox from '../../component/HeadingBox';
import MoneyFormat from '../../component/moneyFormat';
import * as params from '../../config/params';
import { Limit, Page, Type } from '../../config/params';
import Helpers from '../../functions/Helpers';
import { Session } from '../../functions/session';
import * as actions from '../../store/actions';
import EventList from './event/event';
import './home.scss';
import Investor from './investor/investor.js';
import WidgetInterested from './widgets_area/widget_interested/widgetInterested';
import WidgetPage from './widgets_area/widget_page/widgetPage';
import WidgetProject from './widgets_area/widget_project/widgetProject';
import banner2 from '../../images/home/banner2.png';
import banner3 from '../../images/home/banner3.jpeg';
import banner4 from '../../images/home/banner4.jpeg';
import Image from '../../component/image';
import { HeadingContentCommon } from '../../component/banner/HeadingContentCommon';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handlerListChange = this.handlerListChange.bind(this);
    this.state = {
      latest: [],
      isLoadingLatest: false,
      good_price: [],
      hot: [],
      lower_price: [],
      lower_acreage: [],
      list_priority: [],
    };
  }

  componentDidMount() {
    this.props.filterData(this.props.search);
    const page = Helpers.urlParam('pageLt');
    this.setState({
      isLoadingLatest: true,
    });
    HomePageApi.allDataHome(page !== null && parseInt(page) ? page : 1)
      .then((response) => {
        let listPriority = Helpers.randomArray(response[5].data.list);
        let hot = response[2].data.list;
        let latest = response[0].data.list;
        let latestAddItem = 0;
        if (listPriority.length > 0) {
          for (var i = 0; i < listPriority.length; i++) {
            if (latestAddItem < 3) {
              if (i === 0)
                latest.records = Helpers.insertArray(
                  latest.records,
                  0,
                  listPriority[i]
                );
              else if (i === 1)
                latest.records = Helpers.insertArray(
                  latest.records,
                  latest.records.length / 2,
                  listPriority[i]
                );
              else latest.records.push(listPriority[i]);
              latestAddItem++;
            } else {
              hot.records.push(listPriority[i]);
            }
          }
        }

        this.setState({
          latest: latest,
          good_price: response[1].data.list,
          hot: hot,
          lower_price: response[3].data.list,
          lower_acreage: response[4].data.list,
          isLoadingLatest: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoadingLatest: false,
        });
      });
  }

  componentDidUpdate() {
    let realEstateSeen =
      localStorage.getItem('realEstateSeen') !== null
        ? JSON.parse(localStorage.getItem('realEstateSeen'))
        : null;
    if (realEstateSeen !== null) {
      if (
        Helpers.getTimestamp(realEstateSeen.dateSeen) <
        Helpers.getTimestamp(Helpers.getDateNow())
      ) {
        localStorage.removeItem('realEstateSeen');
      }
    }
  }

  handlerListChange(page, type, limit, ting) {
    let query = {};
    query[type] = 1;
    if (type === params.Latest || type === params.GoodPrice) {
      query[Limit] = limit;
      query[Page] = page;
    } else {
      query[Limit] = 4;
    }
    if (ting) query[Type] = ting;
    this.setState({
      isLoadingLatest: true,
    });
    HomePageApi.getList({ params: query })
      .then(({ data, ...response }) => {
        if (data.status) {
          switch (type) {
            case params.Latest:
              this.setState({
                latest: data.list,
              });
              break;
            case params.Hot:
              this.setState({
                hot: data.list,
              });
              break;
            case params.LowerPrice:
              this.setState({
                lower_price: data.list,
              });
              break;
            case params.LowerAcreage:
              this.setState({
                lower_acreage: data.list,
              });
              break;
            case params.GoodPrice:
              this.setState({
                good_price: data.list,
              });
              break;
            default:
              break;
          }
          this.setState({
            isLoadingLatest: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoadingLatest: false,
        });
      });
  }

  render() {
    let ls =
      typeof localStorage === 'undefined'
        ? new LocalStorage('./scratch')
        : localStorage;
    const realEstateSeen =
      ls.getItem('realEstateSeen') !== null
        ? JSON.parse(ls.getItem('realEstateSeen'))
        : null;
    let recommend = Session.getJson('recommendPriceAcreage', []);
    let recommendPrice =
      recommend.reduce(
        (totalPrice, rcP) => totalPrice + parseFloat(rcP.price),
        0
      ) / recommend.length;
    let recommendAcreage =
      recommend.reduce(
        (totalAcreage, rcA) => totalAcreage + parseFloat(rcA.acreage),
        0
      ) / recommend.length;
    recommendAcreage = Math.round(recommendAcreage * 100) / 100;
    return (
      <div className="siteContent">
        <Banner headingContent={<HeadingContentCommon />} {...this.props} />
        <EventList
          title={
            <div className="heading-container">
              <h1>Sự kiện nổi bật</h1>
              <p>Những sự kiện nổi bật đang diễn ra</p>
            </div>
          }
          {...this.props}
        />
        {this.props.isMainDomain ? <WidgetInterested /> : null}
        <WidgetProject slideItem={4} />
        <div
          className="section-wrap"
          style={{
            marginTop: 20,
          }}
        >
          <Row gutter={16}>
            <Col span={16} lg={16} md={24} sm={24} xs={24}>
              <WidgetPage
                dataSource={
                  this.state.latest.records ? this.state.latest.records : []
                }
                total={
                  this.state.latest.pagination
                    ? this.state.latest.pagination.total
                    : 10
                }
                page={
                  this.state.latest.pagination
                    ? this.state.latest.pagination.page
                    : 1
                }
                listType={params.Latest}
                filterChange={this.handlerListChange}
                seeMore={true}
                title="Bất động sản mới nhất"
                loading={this.state.isLoadingLatest}
              />
              {/* <WidgetPage
                        dataSource={this.state.good_price.records}
                        total={this.state.good_price.total}
                        page={this.state.good_price.page}
                        listType={params.GoodPrice}
                        filterChange={this.handlerListChange}
                        seeMore={true}
                        title={<Typography.Text>
                            Bất động sản{" "}
                            <span style={{ color: "#BF0000", fontWeight: "normal!important" }}>
                                giá hời
                            </span>
                        </Typography.Text>}
                    /> */}
            </Col>
            <Col span={8} lg={8} md={24} sm={24} xs={24}>
              {realEstateSeen !== null ? (
                <WidgetPage
                  size="small"
                  title={
                    <HeadingBox title="Bạn vừa xem" backgroundColor="#BF0000" />
                  }
                  dataSource={realEstateSeen.realEstate}
                />
              ) : null}
              <WidgetPage
                dataSource={this.state.hot.records}
                listType={params.Hot}
                size="small"
                title={
                  <HeadingBox
                    title="Bất động sản nóng sốt"
                    backgroundColor="#BF0000"
                  />
                }
              />
              <Image className="img-full sidebar-banner" src={banner2} />
              <Image className="img-full sidebar-banner" src={banner3} />
              <WidgetPage
                listType={params.LowerPrice}
                size="small"
                title={
                  <HeadingBox
                    title={
                      <>
                        Bất động sản dưới
                        <MoneyFormat>
                          {recommendPrice || 2000000000}
                        </MoneyFormat>
                      </>
                    }
                    backgroundColor="#E2A329"
                  />
                }
                dataSource={this.state.lower_price.records}
              />
              <WidgetPage
                listType={params.LowerAcreage}
                size="small"
                title={
                  <HeadingBox
                    title={
                      <>
                        Bất động sản dưới{' '}
                        {parseFloat(recommendAcreage || 50, 2)}m<sup>2</sup>
                      </>
                    }
                    backgroundColor="#E2A329"
                  />
                }
                dataSource={this.state.lower_acreage.records}
              />
              <Image className="img-full" src={banner4} />
            </Col>
          </Row>
        </div>
        {/*<Transaction />*/}
        <Investor />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterData: (searchModel) => dispatch(actions.searchFilter(searchModel)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
