import React, { PureComponent } from "react";
import "./articleDetail.scss";

import ArticleDetail from "../../component/articleDetail/articleDetail";
import { Skeleton } from 'antd';
import BrokerApi from "../../api/brokerPage";


class ArticleDetailPage extends PureComponent {
    state = {
      article: {}
    }

    componentDidMount()  {
        this.initial();
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props !== prevProps) {
          this.initial();
      }
    }

    initial() {
        const slug = this.props.slug;
        BrokerApi.getArticleDetail(slug).then(res => {
            if(res.status === 1)
              this.setState({
                  article: res.article
              });
        });
    }

    render () {
      if(Object.keys(this.state.article).length)
        return (
            <ArticleDetail article={this.state.article} />
        );

      return <div className="section-wrap aritcle-detail-container">
            <Skeleton size='large'  paragraph={{ rows: 15 }} />
      </div>
    }
}

export default ArticleDetailPage;
