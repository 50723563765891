import React from "react";
import "./search.scss";
import Filter from "../../component/search/filter";
import {Col, Empty, Pagination, Row, Spin} from "antd";
import CardLists from "../../component/card_lists/cardLists";
import {googleMapApiKey} from '../../config/main.js';
import {connect} from "react-redux";
import * as actions from "../../store/actions/index";
import PropTypes from "prop-types";
import {BrowserView, isBrowser, isMobile, MobileView} from "react-device-detect";
import FilterMobile from "../../component/search/filterMobile";
import MapCustom from "../../component/search/map";
import _ from "lodash";
import WidgetPage from "../home/widgets_area/widget_page/widgetPage";
import queryString from "query-string";
import {Page} from "../../config/params";

class Search extends React.Component{
    state = {
        map: {
            view: 'map',
            size: {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 12,
                xxl: 8
            },
            colList: {
                lg: 9,
                xl: 12,
                md: 10,
            },
            colMap: {
                style: {
                    display: "block",
                    height: '100%',
                    position: 'relative'
                },
                lg: 15,
                xl: 12,
                md: 14,
            },
        },
        list: {
            colList: 24,
            colMap: {
                style: {
                    display: "none"
                },
                span: 0
            },
            view: 'list',
            size: {
                xs: 24,
                sm: 24,
                md: 12,
                lg: 8,
                xl: 6,
                xxl: 4
            }
        },
        grid: {

        },
        typeView: 'gridView',
        defaultView: null,
        coords: null,
        viewStyles: {},
        slug: null,
        show: {},
        center: {
            lat: 10.7724535,
            lng: 106.6652484
        },
        zoom: 15
    };
    homesFaceMap;
    scrollRef = null;
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        this.changeView = this.changeView.bind(this);
        this.state.defaultView = this.state.list;
        this.props.run(this.props.match.params.slug);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const slug = this.props.match.params.slug;
        const oldSlug = prevProps.match.params.slug;
        if (slug !== oldSlug) {
            this.props.run(this.props.match.params.slug);
        }
    }

    componentDidMount(): void {
        document.title = "TÌM MUA, CẦN THUÊ";
        if (this.state.defaultView.view === 'map') {
            this.setState({
                viewStyles: {
                    height: this.heightMap()
                }
            });
        }
    }

    heightMap = () => {
        return isMobile ? {} : window.innerHeight - document.getElementById('size-height').clientHeight
            - document.getElementById('filBar').clientHeight;
    };

    changeView = (view: string) => {
        if (view === 'map') {
            this.setState({
                defaultView: this.state.map,
                viewStyles: {
                    height: this.heightMap()
                }
            });
        }else {
            this.setState({
                defaultView: this.state.list,
                coords: null,
                viewStyles: {}
            });
        }
    };

    changeTypeView = (view: string) => {
        // typeView: view
        this.setState((prevState: Object) => {
            prevState.typeView = view;
            if (view === "listView") {
                prevState.defaultView = this.state.map;
                prevState.viewStyles = {
                    height: this.heightMap()
                }
            }
            return prevState;
        });
    };

    _onChildClick = (item, zoom = null) => {
        if (isBrowser && this.state.defaultView.view === 'map')
            this.homesFaceMap.onChildClick(item, zoom);
    };

    onChangePage = (val) => {
        const params = this.props.search.fetchAllQuery();
        params[Page] = val;
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: queryString.stringify(params)
        });
        this.props.run(this.props.match.params.slug);
        this.scrollToMyRef();
    };

    scrollToMyRef = () => this.scrollRef.current.scrollIntoView({ behavior: 'smooth'});

    render() {
        return (
            <div className="searchPage" ref={this.scrollRef}>
                <BrowserView>
                    <Filter onChangeTypeView={this.changeTypeView} typeView={this.state.typeView} view={this.state.defaultView.view} onClick={this.changeView} emptyData={_.isEmpty(this.props.dataSource)}/>
                </BrowserView>
                <div style={this.state.viewStyles}>
                    <Row style={{
                        height: "100%",
                        overflow: "auto"
                    }}>
                        <Col lg={this.state.defaultView.colList.lg}
                             md={this.state.defaultView.colList.md}
                             xl={this.state.defaultView.colList.xl} style={{
                            height: "100%",
                            overflow: "auto"
                        }}>
                            {_.isEmpty(this.props.dataSource) ? <Empty/> : (
                                <React.Fragment>
                                    {this.state.typeView && this.state.typeView === 'gridView'
                                        ? <CardLists onHover={this._onChildClick} grid={ this.state.defaultView.size } dataSource={this.props.dataSource}/>
                                        : <WidgetPage onHover={this._onChildClick} loading={false} dataSource={this.props.dataSource}/>}
                                    <Pagination
                                        style={{
                                            textAlign: "center",
                                            margin: "10px 0"
                                        }}
                                        defaultCurrent={this.props.pagination.page || 1}
                                        current={this.props.pagination.page}
                                        total={this.props.pagination.total}
                                        defaultPageSize={this.props.pagination.limit}
                                        onChange={this.onChangePage}
                                    />
                                </React.Fragment>
                            )}
                        </Col>
                        {isBrowser && this.state.defaultView.view === 'map' && !_.isEmpty(this.props.dataSource) ? (
                            <Col id="map-with" lg={this.state.defaultView.colMap.lg}
                                 md={this.state.defaultView.colMap.md}
                                 xl={this.state.defaultView.colMap.xl} style={this.state.defaultView.colMap.style}>
                                {this.props.dataSource.length > 0 && googleMapApiKey ? (
                                    <React.Fragment>
                                        <MapCustom onRef={o => this.homesFaceMap = o} center={this.state.center} zoom={this.state.zoom} />
                                    </React.Fragment>
                                ) : <div style={{
                                    height: "100%",
                                    width: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "rgba(0, 0, 0, 0.05)"
                                }}>
                                    <Spin tip="Loading..." />
                                </div>}
                            </Col>
                        ) : null}
                    </Row>
                    <MobileView>
                        <FilterMobile  />
                    </MobileView>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        search: state.search,
        dataSource: state.searchData.data || [],
        pagination: state.searchData.pagination || {}
    }
};

const mapDispatchToProps = dispatch => {
    return {
        run: (slug) => dispatch(actions.searchDataQuery(slug))
    };
};

Search.propTypes = {
    run: PropTypes.func.isRequired,
    dataSource: PropTypes.array.isRequired,
    search: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
