import Axios from "../axiosApi";

interface BlogOptions {
    current_page: number,
    data: Array,
    first_page_url: string,
    from: number,
    last_page: number,
    last_page_url: string,
    next_page_url: string,
    path: string,
    per_page: number,
    prev_page_url: number,
    to: number,
    total: number
}

interface CatalogOptions {
    id: number,
    name: string,
    code: string,
    created_at: string,
    updated_at: string
}

class BlogModel{
    static blogLists(page, slug) {
        return Axios.get('/blog', {
            params: {
                page,
                slug
            }
        }).then(res => {
            return res.status && new BlogModel(res.data);
        });
    }

    static blogDetail(slug) {
        return Axios.get('/blog/'+slug).then(res => {
            return res.status && res.data;
        });
    }

    detailAttributes: {
        province_code: string,
        id: number,
        slug: string,
        title: string,
        description: string,
        cover_image: string,
        keyword: string,
        body: string,
        created_at: string,
        updated_at: string,
        creator_id: number,
        updater_id: number
    };

    attributes: BlogOptions;

    catalogAttributes: [CatalogOptions];

    constructor(allData) {
        this.attributes = allData.blog;
        this.catalogAttributes = allData.catalog;
        return this;
    }
}

export default BlogModel;