import Axios from "../axiosApi";

interface EventOptions {
    current_page: number,
    data: Array,
    first_page_url: string,
    from: number,
    last_page: number,
    last_page_url: string,
    next_page_url: string,
    path: string,
    per_page: number,
    prev_page_url: number,
    to: number,
    total: number
}

interface EventCategoryOptions {
    id: number,
    name: string,
    code: string,
    created_at: string,
    updated_at: string
}

class EventModel{
    static EventLists(page, slug) {
        return Axios.get('/event', {
            params: {
                page,
                slug
            }
        }).then(res => {
            return res.status && new EventModel(res.data);
        });
    }

    static EventDetail(slug, count = false) {
        return Axios.get('/event/'+slug, {
            headers: {
                CountView: count,
            }
        }).then(res => {
            return res.status && res.data;
        });
    }

    static EventTopView(slug, page) {
        return Axios.get('/listEventTopView', {
            params: {
                page,
                slug
            }
        }).then(res => {
            return res.status && new EventModel(res.data);
        });
    }

    detailAttributes: {
        province_code: string,
        id: number,
        slug: string,
        title: string,
        description: string,
        cover_image: string,
        keyword: string,
        body: string,
        created_at: string,
        updated_at: string,
        creator_id: number,
        updater_id: number
    };

    attributes: EventOptions;

    categories: [EventCategoryOptions];

    constructor(allData) {
        this.attributes = allData.event;
        this.categories = allData.eventCategory;
        return this;
    }
}

export default EventModel;