import * as types from '../actions/actionTypes';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEARCH_DATA:
            return {
                data: action.data,
                keyword: action.keyword,
                pagination: action.pagination
            };
        case types.SEARCH_DATA_UPDATE:
            return {
                data: state.data.map(el => {
                    if (el.id === action.item.id) {
                        return action.item;
                    } else {
                        el.show = false;
                        return el;
                    }
                }),
                keyword: state.keyword,
                pagination: state.pagination
            };
        case types.CLOSE_ALL_MARKER:
            return {
                data: action.data,
                keyword: state.keyword,
                pagination: state.pagination
            };
        default:
            return state;
    }
};

export default reducer;