import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Row,
  Typography,
} from 'antd';
import PropTypes, { string } from 'prop-types';
import React, { PureComponent } from 'react';
import { FormItemInput, FormItemInputPassword } from '../../components';
//
import styles from '../../Auth.module.scss';

const { Text, Title } = Typography;
export class SignUpForm extends PureComponent {
  validatePhoneNumber = (rule, value, callback) => {
    var phoneno = /^\d{10}$/;
    if (value && !value.match(phoneno)) {
      callback('Số điện thoại là số có 10 chữ số!');
    } else {
      callback();
    }
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Mật khẩu xác nhận không khớp với mật khẩu');
    } else {
      callback();
    }
  };
  render() {
    const { form, isLoading, onSubmit, formErrorMessage, onRedirectToSignIn } =
      this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={styles.signInFormContainer}>
        <Row>
          <Col>
            <Title
              level={3}
              style={{ color: 'black', fontWeight: 700, textAlign: 'center' }}
            >
              Đăng Ký Tài Khoản
            </Title>
          </Col>
        </Row>
        <br />
        {formErrorMessage && (
          <Alert message={formErrorMessage} type="error" showIcon />
        )}
        <Form onSubmit={() => {}}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItemInput
                addonBefore={<Icon type="user" />}
                getFieldDecorator={getFieldDecorator}
                name="last_name"
                label={<span style={{ fontWeight: 700 }}>Họ</span>}
                rules={[{ required: true, message: 'Vui lòng nhập họ' }]}
                placeholder="Họ"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItemInput
                addonBefore={<Icon type="user" />}
                getFieldDecorator={getFieldDecorator}
                name="first_name"
                label={<span style={{ fontWeight: 700 }}>Tên</span>}
                rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                placeholder="Tên"
              />
            </Col>
          </Row>
          <FormItemInput
            addonBefore={<Icon type="user" />}
            getFieldDecorator={getFieldDecorator}
            name="phone"
            label={<span style={{ fontWeight: 700 }}>Số điện thoại</span>}
            rules={[
              { required: true, message: 'Vui lòng nhập số điện thoại!' },
              {
                validator: this.validatePhoneNumber,
              },
            ]}
            placeholder="Nhập số điện thoại của bạn"
          />
          <FormItemInputPassword
            getFieldDecorator={getFieldDecorator}
            name="password"
            label={<span style={{ fontWeight: 700 }}>Mật khẩu</span>}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
            placeholder="Nhập mật khẩu"
          />
          <FormItemInputPassword
            getFieldDecorator={getFieldDecorator}
            name="c_password"
            label={<span style={{ fontWeight: 700 }}>Mật khẩu</span>}
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu xác nhận!' },
              {
                validator: this.compareToFirstPassword,
              },
            ]}
            placeholder="Nhập mật khẩu xác nhận"
          />
          <Form.Item>
            <Row>
              <Col span={12}>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Checkbox>Nhớ mật khẩu</Checkbox>)}
              </Col>
              <Col span={12} style={{ textAlign: 'end' }}>
                <a href="/">Quên mật khẩu</a>
              </Col>
            </Row>
            <br />
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                  onClick={onSubmit.bind(this)}
                >
                  Đăng ký
                </Button>
              </Col>
            </Row>

            <Row className={styles.rowCreateAccount}>
              <Text>Bạn đã có tài khoản?</Text>
              <Text onClick={onRedirectToSignIn}>Đăng nhập</Text>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

SignUpForm.defaultProps = {
  form: null,
  onSubmit: () => {},
  onRedirectToSignIn: () => {},
  isLoading: false,
  formErrorMessage: string,
};
SignUpForm.propTypes = {
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  onRedirectToSignIn: PropTypes.func,
  isLoading: PropTypes.bool,
  formErrorMessage: string,
};

export default SignUpForm;
