
export const MENU_DASHBOARD_ID = 'MENU-DASHBOARD--ID';
export const MENU_HOME_ID = 'MENU-HOME--ID';
export const MENU_BOOKING_LIST_ID = 'MENU-BOOKING-LIST--ID';
export const MENU_REAL_ESTATE_ID = 'MENU-REAL-ESTATE--ID';
export const MENU_MY_PROFILE_ID = 'MENU-MY-PROFILE--ID';
export const MENU_CUSTOMERS_ID = 'MENU-CUSTOMERS--ID';
export const MENU_ARTICLES_ID = 'MENU-ARTICLES--ID';
export const MENU_CONTACTS_ID = 'MENU-CONTACTS--ID';
export const MENU_LIST = [
  {
    id: MENU_DASHBOARD_ID,
    name: "Dashboard",
    link: "/user/dashboard",
    icon: "dashboard",
  },
  {
    id: MENU_HOME_ID,
    name: "Về trang chủ",
    link: "/",
    icon: "global",
  },
  {
    id: MENU_BOOKING_LIST_ID,
    name: "Danh sách booking",
    link: "/user/booking-list",
    icon: "unordered-list",
  },
  {
    id: MENU_REAL_ESTATE_ID,
    name: "Nhà đất của tôi",
    link: "/user/real-estates",
    icon: "unordered-list",
  },
  {
    id: MENU_MY_PROFILE_ID,
    name: "Thông tin của tôi",
    link: "/user/my-profile",
    icon: "user",
  },
  {
    id: MENU_CUSTOMERS_ID,
    name: "Khách hàng của tôi",
    link: "/user/customers",
    icon: "team",
  },
  {
    id: MENU_ARTICLES_ID,
    name: "Bài đánh giá của tôi",
    link: "/user/articles",
    icon: "unordered-list",
  },
  {
    id: MENU_CONTACTS_ID,
    name: "Danh sách liên hệ",
    link: "/user/contacts",
    icon: "unordered-list",
  },
];
