const User = {
    getToken(){
        return localStorage.getItem('token');;
    },
    saveToken(token) {
        return localStorage.setItem('token', token)
    },
    getUserId(){
        return localStorage.getItem('userId');
    },
    logout() {
        return localStorage.removeItem('token');
    }
};

export default User;