import React from "react";
import CustomerTable from "../../component/customerTable";
import { Breadcrumb, Icon, Row, Col } from "antd";
import ListVertical from "../../component/listVertical/listVertical";

class MyCustomer extends React.Component {
  render() {
    return (
      <div className="frontPageSection">
        <div className="section-wrap-private">
          <Row gutter={32}>
            <Col span={24} style={{ marginBottom: "1rem" }}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Icon type="home" />
                  <span>Trang chủ</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>Khách hàng của tôi</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="hide-on-mobile" xs={24} sm={24} md={7} lg={4}>
              <ListVertical activeLink={`Khách hàng của tôi`} />
            </Col>
            <Col xs={24} sm={24} md={17} lg={20} className="section-table">
              <CustomerTable />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default MyCustomer;
