import { Col, Layout, Row, Skeleton } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Banner from '../../component/banner/banner';
import { HeadingContentCommon } from '../../component/banner/HeadingContentCommon';
import Breadcrumb from '../../component/breadcrumb/breadcrumb';
import HeadingBox from '../../component/HeadingBox';
import Image from '../../component/image';
import { ResourceUrl } from '../../config/main';
import Helpers from '../../functions/Helpers';
import { Session } from '../../functions/session';
import banner from '../../images/realEstate/banner.jpg';
import banner2 from '../../images/realEstate/banner2.png';
import * as actions from '../../store/actions/index';
import WidgetPage from '../home/widgets_area/widget_page/widgetPage';
import './realEstate.scss';
import RealStateContent from './realEstateContent';
const { Content } = Layout;

class RealEstate extends React.Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    let slug = this.props.match.params.name;
    this.loadRealEstate(slug);
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    let slug = this.props.match.params.name,
      nextSlug = nextProps.match.params.name;
    this.setState({
      loaded: false,
    });
    if (nextSlug !== slug) {
      this.loadRealEstate(nextSlug);
    }
  }

  loadRealEstate(slug) {
    let realEstateSeen = Session.getJson('realEstateSeen');
    let firstLook = Session.getJson('firstLook');
    let count = true;
    if (realEstateSeen) {
      let seen = realEstateSeen.realEstate.find((value, index) => {
        if (value.slug === slug) return value;
        return null;
      });
      if (seen) count = false;
    }
    this.props.innitRealEstate(slug, count, firstLook);
    this.setState({
      loaded: true,
    });
  }

  componentDidUpdate() {
    let realEstateSeen =
      localStorage.getItem('realEstateSeen') !== null
        ? JSON.parse(localStorage.getItem('realEstateSeen'))
        : null;
    if (Object.keys(this.props.realEstate).length !== 0) {
      if (!Session.has('firstLook')) {
        Session.putJson('firstLook', true);
      }
      this.saveRecommendPriceAcreage();
      if (realEstateSeen !== null) {
        if (
          Helpers.getTimestamp(realEstateSeen.dateSeen) <
          Helpers.getTimestamp(Helpers.getDateNow())
        ) {
          localStorage.removeItem('realEstateSeen');
          this.saveNewRealEstateSeen();
        } else if (
          Helpers.getTimestamp(realEstateSeen.dateSeen) ===
          Helpers.getTimestamp(Helpers.getDateNow())
        ) {
          let uniqueRealEstate = [];
          uniqueRealEstate.push(this.props.realEstate);
          realEstateSeen.realEstate.map((x) =>
            uniqueRealEstate.filter((a) => a.slug === x.slug).length > 0
              ? null
              : uniqueRealEstate.push(x)
          );
          realEstateSeen.realEstate = uniqueRealEstate;
          localStorage.setItem(
            'realEstateSeen',
            JSON.stringify(realEstateSeen)
          );
        }
      } else this.saveNewRealEstateSeen();
    }
  }

  saveNewRealEstateSeen = () => {
    let realEstateSeen = {},
      realEstate = [];
    realEstateSeen.dateSeen = Helpers.getDateNow();
    realEstate.push(this.props.realEstate);
    realEstateSeen.realEstate = realEstate;
    localStorage.setItem('realEstateSeen', JSON.stringify(realEstateSeen));
  };

  saveRecommendPriceAcreage = () => {
    let recommend = Session.getJson('recommendPriceAcreage');
    if (recommend) {
      let check = recommend.some(
        (item) => item.id === this.props.realEstate.id
      );
      if (!check) {
        recommend.push({
          price: this.props.realEstate.price,
          acreage: this.props.realEstate.acreage,
          id: this.props.realEstate.id,
          dateSeen: Helpers.getDateNow(),
        });
      }
    } else {
      recommend = [
        {
          price: this.props.realEstate.price,
          acreage: this.props.realEstate.acreage,
          id: this.props.realEstate.id,
          dateSeen: Helpers.getDateNow(),
        },
      ];
    }
    Session.putJson('recommendPriceAcreage', recommend);
  };

  getRealEstateSeen = () => {
    let realEstateSession =
        localStorage.getItem('realEstateSeen') !== null
          ? JSON.parse(localStorage.getItem('realEstateSeen'))
          : null,
      real_estate_return = [];
    if (realEstateSession !== null) {
      realEstateSession.realEstate.forEach((item, i) => {
        if (item.slug !== this.props.realEstate.slug)
          real_estate_return.push(item);
      });
    }
    return real_estate_return;
  };

  render() {
    return (
      <div className="real-estate-container">
        {this.props.realEstate !== undefined ? (
          <Helmet>
            <title>{'Bất động sản - ' + this.props.realEstate.title}</title>
            <meta property="og:title" content={this.props.realEstate.title} />
            <meta
              property="og:image"
              content={ResourceUrl + this.props.realEstate.cover_image}
            />
            <meta
              property="og:description"
              content={Helpers.stripHtml(this.props.realEstate.body)}
            />
            <meta
              name="description"
              content={Helpers.stripHtml(this.props.realEstate.body)}
            />
          </Helmet>
        ) : null}
        <Banner headingContent={<HeadingContentCommon />} {...this.props} />
        <Breadcrumb
          title={this.props.realEstate.title}
          subitem={
            typeof this.props.realEstate.owner !== 'undefined' &&
            typeof this.props.realEstate.owner.title !== 'undefined' ? (
              <Link to={'/du-an/' + this.props.realEstate.owner.slug}>
                {' '}
                {this.props.realEstate.owner.title}{' '}
              </Link>
            ) : null
          }
        />
        <Content className="section-wrap real-estate">
          <Row>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              {Object.keys(this.props.realEstate).length > 0 &&
              !this.state.loaded ? (
                <div className="content-real-estate">
                  <RealStateContent
                    realEstate={this.props.realEstate}
                    province={this.props.province}
                  />
                </div>
              ) : (
                <div className="project_content">
                  <Skeleton
                    style={{ padding: '0px 20px' }}
                    paragraph={{ rows: 80 }}
                  />
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <WidgetPage
                size="small"
                title={
                  <HeadingBox
                    title="Bất động sản vừa xem"
                    backgroundColor="#BF0000"
                  />
                }
                dataSource={this.getRealEstateSeen()}
              />
              <WidgetPage
                size="small"
                title={
                  <HeadingBox
                    title="Bất động sản cùng giá"
                    backgroundColor="#E2A329"
                  />
                }
                dataSource={this.props.reSamePrice.records}
              />
              <Image
                style={{ marginBottom: '24px' }}
                className="img-full"
                src={banner}
              />
              {/* <List
                header={
                  <HeadingBox
                    title="Môi giới cùng khu vực"
                    backgroundColor="#0C4880"
                  />
                }
                dataSource={[]}
                renderItem={(item) => <></>}
              /> */}
              <WidgetPage
                size="small"
                title={
                  <HeadingBox
                    title="Bất động sản cùng diện tích"
                    backgroundColor="#E2A329"
                  />
                }
                dataSource={this.props.reSameAcreage.records}
              />
              <WidgetPage
                size="small"
                title={
                  <HeadingBox
                    title="Bất động sản cùng khu vực"
                    backgroundColor="#E2A329"
                  />
                }
                dataSource={this.getRealEstateSeen()}
              />
              <WidgetPage
                size="small"
                title={
                  <HeadingBox
                    title="Bất động sản cùng quận/huyện"
                    backgroundColor="#E2A329"
                  />
                }
                dataSource={this.props.reSameStreet.records}
              />
              <Image className="img-full" src={banner2} />
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const realEstateReducer = state.realEstate;
  return {
    realEstate: realEstateReducer.realEstate,
    reSameAcreage: realEstateReducer.reSameAcreage,
    reSamePrice: realEstateReducer.reSamePrice,
    reSameStreet: realEstateReducer.reSameStreet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    innitRealEstate: (slug, count, isLook) =>
      dispatch(actions.innitRealEstate(slug, count, isLook)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealEstate);
