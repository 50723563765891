import { useQuery } from "@tanstack/react-query";
import { getApartmentDetail, GET_APARMENT_DETAIL } from "../api/onlinePrice";
import { getAparment } from "../utils";

export const useFetchApartmentDetail = (tmpAparmentId) => {
  const apartment = getAparment();
  const aparmentId = tmpAparmentId || apartment?.ApartmentID;
  const { data } = useQuery(
    [GET_APARMENT_DETAIL(aparmentId), { aparmentId }],
    () => getApartmentDetail(aparmentId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!aparmentId
    }
  );
  return data?.data?.Apartment;
};
