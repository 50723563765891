import { Avatar, Button, Col } from "antd";
import React, { Component } from "react";
import Image from "../../../../component/image";
import { http, MainDomain, ResourceUrl } from "../../../../config/main";
import DefaultAvatar from "../../../../images/generic-avatar.png";
import phone from "../../../../images/icon/phone.svg";
import sms from "../../../../images/icon/sms.svg";
import styles from "./broker.module.scss";
import { Link } from "react-router-dom";
import MessageModal from '../../../../component/message/index'

class Broker extends Component {
  state = {
    number_cut: false,
    visible: false,
  };
  getLink() {
    const broker = this.props.broker;
    return broker.sub_domain !== ""
      ? http + broker.sub_domain + "." + MainDomain
      : "/broker/" + broker.user_slug;
  }

  render() {
    const broker = this.props.broker;
    if(!broker) return null;

    const {
      category_id,
      ward_id,
      district_id,
      province_code,
      street,
      status_number,
    } = this.props.realEstate;

    return (
      <div className="broker">
        <MessageModal
          dataSubmit={{
            category_id,
            ward_id,
            district_id,
            province_code,
            street,
            statusEstate: status_number,
          }}
          slug={this.props.realEstate.slug}
          close={() => this.setState({ visible: false })}
          visible={this.state.visible}
        />
        <Col span={24} className="mt-24 mb-24">
          <div className={styles.brokerContainer}>
            <Avatar
              size="large"
              src={broker.avatar ? ResourceUrl + broker.avatar : DefaultAvatar}
            />
            <div className={styles.brokerInfo}>
              <Link to={"/broker/" + broker.user_slug}>
                <h3 className={styles.brokerHeading}>
                  {broker.last_name} {broker.first_name}
                </h3> 
              </Link>
              <p>Môi giới tự do</p>
              <Button
                className={styles.phoneNumberBtn}
                onClick={() => {
                  this.setState({ number_cut: true });
                }}
              >
                <Image src={phone} /> &nbsp;
                {!this.state.number_cut ? (
                  String(broker.phone).slice(0, -3) + "***"
                ) : (
                  <a
                    href={"tel:" + broker.phone}
                    target={broker.sub_domain !== "" ? "blank" : "self"}
                  >
                    {broker.phone}
                  </a>
                )}
                {!this.state.number_cut && `. Gọi ngay`}
              </Button>
              <Button
                className={styles.inboxBuyerBtn}
                onClick={() => this.setState({ visible: true })}
              >
                <Image src={sms} /> &nbsp; Nhắn tin người bán
              </Button>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

export default Broker;
