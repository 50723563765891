import { Row, Typography } from "antd";
import React from "react";
import "sweetalert2/src/sweetalert2.scss";
import FormArticle from "../../../component/formArticle/formArticle";
import { PageContent, PageSidebar, Wrapper } from "../components";
import { MENU_ARTICLES_ID } from "../constants";

const { Title } = Typography;

const CreateArticles = () => {
  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_ARTICLES_ID} />
        <PageContent>
          <Title style={{ color: "black" }} level={3}>
            Cập nhật bài đánh giá
          </Title>
          <FormArticle />
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default CreateArticles;
