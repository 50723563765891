import PropTypes from "prop-types";
import React from "react";

import "./maker-detail.scss";

import Image from "../../image";
import Helpers from "../../../functions/Helpers";
import { ResourceUrl } from "../../../config/main";

const MarkerDetail = (props) => {
  const { makerDetail, handleHideMakerDetail } = props;

  const { price, title, cover_image } = makerDetail;

  return (
    <div className="marker__detail">
      <button className="marker__detail-close" onClick={handleHideMakerDetail}>
        x
      </button>
      <div className="marker__detail-image">
        <Image src={ResourceUrl + cover_image} />
      </div>
      <div className="marker__detail-content">
        <div className="marker__detail-top">
          <a target="blank" href={"/bat-dong-san/" + makerDetail.slug}>
            <strong className="marker__detail-title">{title}</strong>
          </a>
          <span className="marker__detail-address">
            {`${makerDetail.street} ${makerDetail.ward} ${makerDetail.district}, ${makerDetail.city}`}
          </span>
        </div>
        <span className="marker__detail-price">
          {Helpers.moneyFormatVn(price)}
        </span>
      </div>
    </div>
  );
};

MarkerDetail.propTypes = {
  makerDetail: PropTypes.object.isRequired,
  handleHideMakerDetail: PropTypes.func,
};

MarkerDetail.defaultProps = {
  handleHideMakerDetail: () => null,
};

export default React.memo(MarkerDetail);
