export const LS_APARMENTS = "apartment_";

export const saveApartment = (apartment) => {
  if(!apartment) return;
  localStorage.setItem(LS_APARMENTS, JSON.stringify(apartment));
};
export const getAparment = () => {
  return JSON.parse(localStorage.getItem(LS_APARMENTS));
};
export const removeAparment = () => {
  localStorage.removeItem(LS_APARMENTS);
}
