import { Col, List, Row } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ResourceUrl } from "../../../config/main";
import Image from "../../image";
import styles from "./ProjectList.module.scss";

const ProjectList = (props) => {
  const history = useHistory();
  return (
    <List
      className="custom-pagination"
      loading={props.isLoading}
      dataSource={props.data}
      pagination={{
        size: "small",
        onChange: (page) => {
          history.push({
            search: "?page=" + page,
          });
        },
        pageSize: props.pageSize,
        total: props.total,
        defaultCurrent: 1,
        current: props.currentPage,
        hideOnSinglePage: true,
      }}
      renderItem={(item) => (
        <List.Item>
          <Row type="flex" className={styles.projectItem}>
            <Col
              xs={24}
              sm={24}
              md={5}
              lg={5}
              xl={5}
              className={styles.itemLeft}
            >
              <Image className="img-full h-full" src={ResourceUrl + item.cover_image} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={19}
              lg={19}
              xl={19}
              className={styles.itemRight}
            >
              <Link
                className={styles.title}
                to={"/du-an-bat-dong-san/" + item.slug}
              >
                {item.title}
              </Link>
              <p>{item.location}</p>
              <p className={styles.acreage}>
                {item.acreage} ha
              </p>
              <p>{item.description}</p>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default ProjectList;
