import * as actionTypes from './actionTypes';

export const saveProfile = (infor) => {
    return {
        type: actionTypes.SAVE_PROFILE,
        infor: infor
    }
};

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
};