import axios from 'axios';
import {ApiUrl} from "./config/main";
import User from "./models/User";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const axiosApi = axios.create({
    baseURL: ApiUrl,
    cancelToken: source.token
});

axiosApi.interceptors.request.use(
    (config) => {
        let token = User.getToken();

        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`
        }

        return config
    },

    (error) => {
        return Promise.reject(error)
    }
);

export default axiosApi;
