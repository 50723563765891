import React from "react";
import { List, Typography } from "antd";
import { Link } from "react-router-dom";
import HeadingBox from "../HeadingBox";

const EventCategory = (props) => {
  return (
    <List
      className="list-category"
      header={<HeadingBox title="Danh mục" backgroundColor="#BF0000" />}
      dataSource={props.category}
      renderItem={(item) => (
        <List.Item>
          <Typography.Text className="catalog-style">
            {item.code !== props.selected ? (
              <Link to={"/event/category/" + item.code}>
                {item.name} ({item.event_count})
              </Link>
            ) : (
              <Link to="#" disabled>
                {item.name} ({item.event_count})
              </Link>
            )}
          </Typography.Text>
        </List.Item>
      )}
    />
  );
};

export default EventCategory;
