import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';

import Search from './Search';
import Map from '../../component/Map';
import ProvincesApi from '../../api/provincesApi';
import CategoryApi from '../../api/categoryApi';
import RealEstatePricingApi from '../../api/realEstatePricingApi';

import './price-by-month.scss';
import Banner from '../../component/banner/banner';
import { HeadingContentCommon } from '../../component/banner/HeadingContentCommon';

const DEFAULT_LAT = 10.762622;
const DEFAULT_LNG = 106.660172;

const PriceByMonth = () => {
  const [provinces, setProvinces] = useState([]);
  const [categories, setCategory] = useState([]);
  const [search, setSearch] = useState('');
  const [listPricing, setListPricing] = useState([]);
  const [provinceCodeSearch, setProvinceCodeSearch] = useState([]);
  const [categoriesSearch, setCategoriesSearch] = useState([]);

  const searchOnMap = useCallback(async (provinces, categories) => {
    const result = await RealEstatePricingApi.getAll(
      provinces.filter((provinceCode) => provinceCode !== null).join(','),
      categories.filter((category) => category !== null).join(',')
    );
    if (result.status === 200) {
      setListPricing(
        result.data.data.map((price) => {
          return {
            lat: price.lat,
            lng: price.lng,
            price: price.amount,
          };
        })
      );
    }
  }, []);

  const getAllData = useCallback(async () => {
    try {
      const categoryResponse = await CategoryApi.getAll();
      const { categories } = categoryResponse.data;
      const provincesResponse = await ProvincesApi.getAll();
      const { provinces } = provincesResponse.data;
      setProvinces(
        provinces.map((province) => {
          return { id: province.code, name: province.name };
        })
      );
      setCategory(categories);
      setProvinceCodeSearch(
        provinces.map(
          (province) =>
            (province.code === 'SG' || province.code === 'HN') && province.code
        )
      );
      setCategoriesSearch(
        categories.map(
          (category) =>
            (category.id === 1 ||
              category.id === 2 ||
              category.id === 3 ||
              category.id === 4 ||
              category.id === 5) &&
            category.id
        )
      );
      searchOnMap(
        provinces.map((province) => province.code),
        categories.map((category) => category.id)
      );
    } catch (e) {
      setCategory([]);
    }
  }, [searchOnMap]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  const handleOnChangeSearch = (search) => {
    setSearch(search);
    return delayedSearchProvince(search, categoriesSearch);
  };

  const delayedSearchProvince = useCallback(
    debounce(async (search, categories) => {
      const result = await ProvincesApi.getAll(search);
      if (result.status === 200) {
        setProvinces(
          result.data.provinces.map((province) => {
            return {
              id: province.code,
              name: province.name,
            };
          })
        );
        setProvinceCodeSearch(
          result.data.provinces.map((province) => province.code)
        );
        searchOnMap(
          result.data.provinces.map((province) => province.code),
          categories
        );
      } else {
        return false;
      }
    }, 500),
    []
  );

  const handleOnChangePricing = (name, value) => {
    let provinceCodes = [...provinceCodeSearch];
    let categories = [...categoriesSearch];

    if (name === 'province') {
      provinceCodes = [...provinceCodeSearch].includes(value)
        ? provinceCodeSearch.filter((search) => search !== value)
        : provinceCodeSearch.concat([value]);

      setProvinceCodeSearch(provinceCodes);
    } else if (name === 'category') {
      categories = [...categoriesSearch].includes(value)
        ? categoriesSearch.filter((search) => search !== value)
        : categoriesSearch.concat([value]);

      setCategoriesSearch(categories);
    }

    searchOnMap(provinceCodes, categories);
  };

  return (
    <>
      <Banner headingContent={<HeadingContentCommon />} />
      <div className="frontPageSection">
        <div className="section-wrap-private price-by-month">
          <h1>
            Giá nhà đất tháng {moment().format('M')} năm{' '}
            {moment().format('YYYY')}
          </h1>
          <Row>
            <Col xs={24} sm={24} md={7} lg={6} xl={5}>
              <Search
                listItem={provinces}
                search={search}
                onSearch={handleOnChangeSearch}
                searchPricing={provinceCodeSearch}
                onSearchPricing={handleOnChangePricing}
                searchName="province"
              />
              <Search
                title="Loại bất đổng sản"
                listItem={categories}
                searchPricing={categoriesSearch}
                onSearchPricing={handleOnChangePricing}
                searchName="category"
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={17}
              lg={18}
              xl={19}
              className="price-by-month__map"
            >
              <Map
                data={listPricing}
                shouldShowDetail={false}
                lat={DEFAULT_LAT}
                lng={DEFAULT_LNG}
                showSup={true}
                zoom={12}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PriceByMonth;
