import { NUMBER_BED_ROOM_CODE, NUMBER_TOILET_CODE, WALL_AREA_CODE, WATER_AREA_CODE } from "../constants/apartment";

export const isAttributeNumberBedRoom = (attribute) => {
  if (!attribute) return false;
  return attribute.code === NUMBER_BED_ROOM_CODE;
};
export const isAttributeNumberToilet = (attribute) => {
  if (!attribute) return false;
  return attribute.code === NUMBER_TOILET_CODE;
};

export const isAttributeArea = (attribute) => {
  return (
    attribute.code === WALL_AREA_CODE || attribute.code === WATER_AREA_CODE
  );
};
