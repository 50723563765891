import { Col, List, Row, Skeleton, Tabs, Typography } from "antd";
import React, { Component } from "react";
import { ResourceUrl } from "../../../../config/main";
import styles from '../realEstateContent.module.scss';
import Map from "./map/map.js";
const { TabPane } = Tabs;
const { Title } = Typography;

class Utility extends Component {
  constructor(props) {
    super();
    this.state = {
      listMarker: {},
      zoom: 15,
      inforUnity: {
        show: false,
        content: "",
      },
      updated: false,
    };
    this.real_estate_option = props.realEstate.options;
  }

  componentDidUpdate() {
    if (!this.state.updated) {
      this.setState({
        updated: true,
      });

      this.initialMap(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.realEstate.options !== nextProps.realEstate.options) {
      this.initialMap(nextProps);
    }
  }

  initialMap = (props) => {
    let real_estate_option =
      Object.keys(props.realEstate.options).length > 0
        ? props.realEstate.options
        : [];
    if (Object.keys(real_estate_option).length > 0) {
      const id = parseInt(Object.keys(real_estate_option)[0], 0);
      this.real_estate_option = props.realEstate.options;
      this.itemFilterClick(id);
    }
  };

  renderFilterMap = () => {
    let realEstate = this.props.realEstate,
      real_estate_option = [];

    if (Object.keys(realEstate.options).length > 0) {
      for (let i in realEstate.options) {
        const option = (
          <TabPane
            tab={this.renderOption(realEstate.options[i])}
            key={i}
            className={"tab_content "}
          >
            {realEstate.options[i].items !== null &&
            realEstate.options[i].items.length > 0 ? (
              <div
                className={
                  "ul " +
                  (realEstate.options[i].items.length > 10
                    ? " ant-list-items-srroll "
                    : " ")
                }
              >
                {this.renderItem(
                  realEstate.options[i].items,
                  ResourceUrl + realEstate.options[i].logo
                )}
              </div>
            ) : null}
          </TabPane>
        );
        real_estate_option.push(option);
      }
    }

    return (
      <div className="filter-map">
        <div
          className="custom-tab card_container"
          style={{
            paddingTop: 15,
          }}
        >
          {real_estate_option.length > 0 ? (
            <Tabs type="card" onTabClick={(key) => this.itemFilterClick(key)}>
              {real_estate_option}
            </Tabs>
          ) : null}
        </div>
      </div>
    );
  };

  renderOption = (option) => {
    return (
      <div className="custom_tab">
        <img alt={option.logo} src={ResourceUrl + option.logo} />
        {option.name}
      </div>
    );
  };

  itemFilterClick(key) {
    let itemsNew = this.real_estate_option[key].items,
      logoOption = ResourceUrl + this.real_estate_option[key].logo;

    itemsNew = itemsNew.slice(0, 10);

    itemsNew.forEach(function (element) {
      element.logo = logoOption;
    });

    this.setState({
      listMarker: itemsNew,
      zoom: 15,
    });

    if (this.state.inforUnity.show)
      this.setState({
        inforUnity: {
          show: false,
        },
      });
  }

  showInforItemMap(item, logo) {
    this.setState({
      inforUnity: {
        show: true,
        content: item.name,
        address: item.address,
        lat: item.lat,
        lng: item.lng,
        logo: logo,
      },
      zoom: 17,
    });
  }

  showHideInfor = () => {
    this.setState({
      inforUnity: {
        ...this.state.inforUnity,
        show: !this.state.inforUnity.show,
      },
    });
  };

  renderItem = (items, logo) => {
    items = items.slice(0, 10);
    return (
      <List
        dataSource={items}
        bordered
        renderItem={(item, key) => {
          return (
            <List.Item
              className="list-hover"
              onClick={() => this.showInforItemMap(item, logo)}
            >
              <Skeleton avatar loading={items.length === 0} title={false}>
                {/* <List.Item.Meta
                  avatar={<p>{key + 1}</p>}
                  title={item.name}
                  description={item.address}
                /> */}
                <div className={styles.optionUtilItem}>
                  <p className={styles.order}>{key + 1}</p>
                  <div className={styles.content}>
                    <Title level={4}>{item.name}</Title>
                    <p>{item.address}</p>
                  </div>
                </div>
              </Skeleton>
            </List.Item>
          );
        }}
      />
    );
  };

  render() {
    let realEstate = this.props.realEstate;
    return (
      <div className="utility">
        <h3 className="sub-heading">Tiện ích xung quanh</h3>
        {typeof realEstate.lat !== "undefined" &&
        typeof realEstate.lng !== "undefined" ? (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="map-container"
            >
              <Map
                lat={realEstate.lat}
                lng={realEstate.lng}
                zoom={this.state.zoom}
                listMarker={this.state.listMarker}
                inforUnity={this.state.inforUnity}
                showHideInfor={this.showHideInfor}
                province={this.props.province}
              />
              {this.renderFilterMap()}
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default Utility;
