import icon1 from "../../../images/house.svg";
import icon2 from "../../../images/tag.svg";
import icon3 from "../../../images/cart.svg";
import icon4 from "../../../images/wallet.svg";
import styles from "../Dashboard/Dashboard.module.scss";
export const SUMMARY_SALE_ID = 1;
export const SUMMARY_RENT_ID = 2;
export const SUMMARY_SOLD_ID = 3;
export const SUMMARY_REVIEW_ID = 4;
export const SUMMARY_LIST = [
  {
    id: SUMMARY_SALE_ID,
    title: "ĐANG BÁN",
    className: styles.propertiesForSale,
    count: 0,
    icon: icon1,
    to: "/user/real-estates",
  },
  {
    id: SUMMARY_RENT_ID,
    title: "CHO THUÊ",
    className: styles.propertiesForRent,
    count: 0,
    icon: icon2,
    to: "/user/real-estates",
  },
  {
    id: SUMMARY_SOLD_ID,
    title: "ĐÃ BÁN",
    className: styles.allProperties,
    count: 0,
    icon: icon3,
    to: "/user/real-estates",
  },
  {
    id: SUMMARY_REVIEW_ID,
    title: "BÀI ĐÁNH GIÁ",
    to: "/user/articles",
    className: styles.totalEarnings,
    count: 0,
    icon: icon4,
  },
];
