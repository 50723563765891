import React from "react";
import RealEstateForm from "../../component/real_estate_form/realEstateForm";
import { Row, Col, Breadcrumb, Icon } from "antd";
import "./postRealEstate.scss";
import ListVertical from "../../component/listVertical/listVertical";

class PostRealEstate extends React.Component {
  render() {
    return (
      <div className="frontPageSection">
        <div className="section-wrap-private">
          <Row gutter={32}>
            <Col span={24} style={{ marginBottom: "1rem" }}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Icon type="home" />
                  <span>Trang chủ</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>
                    {this.props.match.params.slug ? `Sửa tin` : `Đăng tin mới`}
                  </span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col
              className="hide-on-mobile"
              xs={24}
              sm={24}
              md={7}
              lg={6}
              xl={4}
            >
              <ListVertical activeLink={`Đăng tin mới`} />
            </Col>
            <Col xs={24} sm={24} md={17} lg={18} xl={20}>
              <RealEstateForm />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PostRealEstate;
