import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Empty, Form, Spin, Icon, Pagination } from "antd";
import "./index.scss";
import CustomerApi from "../../api/customer";
import { withRouter } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import { Link } from "react-router-dom";
import moment from "moment";

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listMyCustomers: [],
      totalMyCustomers: 0,
      currentMyCustomerPage: 1,
    };
  }

  componentDidMount = () => {
    CustomerApi.myList(20, 1).then((res) => {
      if (res.data.status) {
        this.setState({
          listMyCustomers: res.data.buyers,
          totalMyCustomers: res.data.total,
          isLoading: false,
        });
      }
    });
  };

  render() {
    const { isLoading } = this.state;
    return isLoading ? (
      <Spin
        style={{
          width: `100%`,
          margin: `auto`,
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
      />
    ) : (
      <>
        <Table>
          <Thead className="ant-table-thead">
            <Tr>
              <Th>Tên</Th>
              <Th>Số điện thoại</Th>
              <Th>Bất động sản đã mua</Th>
              <Th>Ngày mua</Th>
            </Tr>
          </Thead>
          <Tbody className="ant-table-tbody">
            {this.state.listMyCustomers &&
            !!this.state.listMyCustomers.length ? (
              this.state.listMyCustomers.map((item, key) => (
                <Tr key={key}>
                  <Td>{item.buyers_name}</Td>
                  <Td>{item.buyers_phone}</Td>
                  <Td>
                    <Link to={`/bat-dong-san/${item.real_estate_slug}`}>
                      <div>{item.real_estate_title}</div>
                      <div style={{ color: `#BF0000`, fontSize: `12px` }}>
                        {`${item.real_estate_address_number} ${item.real_estate_street}`}
                        , {`${item.district_name}, ${item.province_name}`}
                      </div>
                      <div style={{ color: `#62D273`, fontSize: `12px` }}>
                        {item.real_estate_view_number === 0
                          ? "0"
                          : item.real_estate_view_number}{" "}
                        lượt xem
                      </div>
                    </Link>
                  </Td>
                  <Td>
                    {moment(item.buyers_created_at).format("DD/MM/YYYY h:m:s")}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="4">
                  <Empty />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Pagination
          style={{ float: "right", marginTop: "1rem" }}
          defaultPageSize={20}
          pageSize={20}
          current={this.state.currentMyCustomerPage}
          total={this.state.totalMyCustomers}
          onChange={(page, pageSize) => {
            this.setState({
              isLoading: true,
              currentMyCustomerPage: page,
            });
            CustomerApi.myList(20, page).then((res) => {
              if (res.data.status) {
                this.setState({
                  listMyCustomers: res.data.buyers,
                  totalMyCustomers: res.data.total,
                  isLoading: false,
                });
              }
            });
          }}
        />
      </>
    );
  }
}

export default withRouter(Form.create()(CustomerList));
