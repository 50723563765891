import SearchModel from "../../models/Search";
import * as types from "./actionTypes";

export const Search = (search: SearchModel) => {
    return {
        type: types.SEARCH,
        search
    };
};

export const searchDataUpdate = (item) => {
    return dispatch => {
        dispatch({
            type: types.SEARCH_DATA_UPDATE,
            item: item
        });
    };
};

export const closeAllMarker = (data) => {
    return dispatch => {
        const dt = data.map(i => {
            i.show = false;
            return i;
        });
        dispatch({
            type: types.CLOSE_ALL_MARKER,
            data: dt
        });
    };
};

export const searchDataQuery = (slug = null) => {
    return dispatch => {
        SearchModel.filter(slug).then(response => {
            let data = [];
            if (response.data.status) {
                data = response.data.results.records.map((i) => {
                    i.show = false;
                    return i;
                });
            }
            dispatch({
                type: types.SEARCH_DATA,
                data: data,
                keyword: response.data.keyword,
                pagination: response.data.results.pagination
            });
        }).catch(error => {

        });
    };
};