import { Icon, Row, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "sweetalert2/src/sweetalert2.scss";
import CustomerApi from "../../../api/customer";
import { PageContent, PageSidebar, Wrapper } from "../components";
import { MENU_CUSTOMERS_ID } from "../constants";
import CustomerTable from "./components/CustomerTable";

const { Title } = Typography;

const Customers = () => {
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = (pageIndexParam) => {
    setLoading(true);
    CustomerApi.myList(10, pageIndexParam)
      .then((res) => {
        if (res.data.status) {
          setCustomerList(res.data.buyers);
          setTotalItems(res.data.total);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(pageIndex);
  }, [pageIndex]);
  const changePageIndex = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_CUSTOMERS_ID} />
        <PageContent>
          <Title className="text-black" level={3}>
            Khách hàng của tôi
          </Title>
          {isLoading ? (
            <Spin
              style={{
                width: `100%`,
                margin: `auto`,
              }}
              indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
            />
          ) : (
            <div className="table-history">
              <CustomerTable
                customerList={customerList}
                changePageIndex={changePageIndex}
                pageIndex={pageIndex}
                isLoading={isLoading}
                totalItems={totalItems}
              />
            </div>
          )}
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default Customers;
