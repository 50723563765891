import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import InvestorPageApi from "../../../api/investorPage";
import Image from "../../../component/image";
import { ResourceUrl } from "../../../config/main";
import styles from "./investor.module.scss";
const { Title, Text } = Typography;

class investor extends React.Component {
  state = {
    investor: [],
  };

  componentDidMount() {
    InvestorPageApi.listInvestor()
      .then((res) => {
        this.setState({
          investor: res.data.investor,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className={`section-wrap ${styles.investorWrapper}`}>
        <div className={styles.headingWrapper}>
          <Title level={3}>Chủ đầu tư nổi bật</Title>
          <Text className="span-ivestor" type="secondary">
            Thông tin cơ bản và danh mục dự án của các chủ đầu tư bất động sản
            uy tín hiện nay
          </Text>
        </div>
        <div className={styles.investorList}>
          {this.state.investor.map((item) => (
            <Link
              to={"/chu-dau-tu/" + item.alias}
              key={item.id}
              className={styles.investorItem}
            >
              <Image className="img-full" src={ResourceUrl + item.logo} />
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default investor;
