import { Row, Typography } from "antd";
import React from "react";
import { default as RealEstateForm } from "../../../../component/real_estate_form/realEstateForm";
import { PageContent, PageSidebar, Wrapper } from "../../components";
import { MENU_REAL_ESTATE_ID } from "../../constants";

const { Title } = Typography;
const CreateRealEstate = () => {
  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_REAL_ESTATE_ID} />
        <PageContent>
          <Title className="text-black" level={3}>
            Đăng tin mới
          </Title>
          <RealEstateForm />
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default CreateRealEstate;
