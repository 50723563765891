import React from "react";

const CountDown = (props) => {
  const { days, hours, minutes, seconds } = props;

  const isDisable = days > 0 && hours > 0 && minutes > 0 && seconds > 0;
  const Item = ({ number, string }) => {
    return (
      <div className="countdown__item">
        {number < 10 && number > 0 && "0"}
        {number}
        <span>{string}</span>
      </div>
    );
  };

  return (
    <>
      {isDisable && (
        <div className="countdown__wrapper">
          <Item number={days} string={"ngày"} />
          <Item number={hours} string={"giờ"} />
          <Item number={minutes} string={"phút"} />
          <Item number={seconds} string={"giây"} />
        </div>
      )}
    </>
  );
};

export default CountDown;
