import moment from "moment";

require('moment/locale/vi');

moment.updateLocale('vi', {
    relativeTime: {
        m: '1 phút',
        h : '1 giờ',
        d : '1 ngày',
        M : '1 tháng',
        y : '1 năm',
    }
});