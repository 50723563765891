import { Button, Empty, Pagination } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import moment from "moment";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ResourceUrl } from "../../../../config/main";
import Helpers from "../../../../functions/Helpers";

const SellRealEstateTable = ({
  realEstateList,
  changePageIndex,
  pageIndex,
  onClickHideSellRealEstate,
  onDeleteSellRealEstate,
  isSell,
  totalItems,
}) => {
  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Ảnh bìa</Th>
            <Th>Tiêu đề</Th>
            <Th>Diện tích</Th>
            <Th>Giá</Th>
            <Th>Ngày đăng</Th>
            <Th>&nbsp;&nbsp;&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {realEstateList?.length > 0 ? (
            realEstateList.map((item, key) => (
              <Tr key={key}>
                <Td>
                  <div
                    className="img"
                    style={{
                      backgroundImage: `${
                        item.real_estate_galleries
                          ? `url(${ResourceUrl}${item.real_estate_galleries})`
                          : `url(${ResourceUrl}${item.cover_image})`
                      }`,
                    }}
                  ></div>
                </Td>
                <Td className="width-title">
                  <Link to={`/bat-dong-san/${item.slug}`}>
                    <div className="hide-on-mobile">{item.title}</div>
                    <div
                      className="hide-on-desktop"
                      style={{ marginTop: "10px" }}
                    >
                      {item.title}
                    </div>
                    <div style={{ color: `#BF0000`, fontSize: `12px` }}>
                      {`${item.address_number} ${item.street}`},{" "}
                      {`${item.district_name}, ${item.province_name}`}
                    </div>
                    <div
                      className="hide-on-mobile"
                      style={{ color: `#62D273`, fontSize: `12px` }}
                    >
                      {item.view_number === 0 ? "0" : item.view_number} lượt xem
                    </div>
                    <div
                      className="hide-on-desktop"
                      style={{ fontSize: `12px` }}
                    >
                      {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                      {"  "}
                      <span style={{ color: `#62D273` }}>
                        {item.view_number === 0 ? "0" : item.view_number} lượt
                        xem
                      </span>
                    </div>
                    <div
                      className="hide-on-desktop"
                      style={{
                        marginTop: "10px",
                        color: `#ff4d4f`,
                        fontSize: `20px`,
                      }}
                    >
                      {item.price > 1000000000
                        ? `${Helpers.convertPrice(item.price, "Tỷ")} tỷ`
                        : `${Helpers.convertPrice(
                            item.price,
                            "Triệu"
                          )} triệu`}{" "}
                      / {`${parseInt(item.acreage)}`} m<sup>2</sup>
                    </div>
                  </Link>
                  {isSell && (
                    <div className="action-td-re">
                      <Link to={`/user/real-estates/${item.slug}/push`}>
                        <Button className="btn-dt">Đẩy tin</Button>
                      </Link>
                      <Button
                        // onClick={this.showModal.bind(this, item.id)}
                        onClick={() => onClickHideSellRealEstate(item.id)}
                        className="btn-hide"
                      >
                        Ẩn tin đã bán
                      </Button>
                    </div>
                  )}
                </Td>
                <Td className="hide-on-mobile">
                  {`${parseInt(item.acreage)}`} m<sup>2</sup>
                </Td>
                <Td className="hide-on-mobile">
                  {item.price > 1000000000
                    ? `${Helpers.convertPrice(item.price, "Tỷ")} tỷ`
                    : `${Helpers.convertPrice(item.price, "Triệu")} triệu`}
                </Td>
                <Td className="hide-on-mobile">
                  {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                </Td>
                <Td className="td-action">
                  <Link to={`/bat-dong-san/${item.slug}`}>
                    <Button className="btn-user" type="primary" icon="eye" />
                  </Link>
                  <Link to={`/user/real-estates/${item.slug}/edit`}>
                    <Button className="btn-edit" type="primary" icon="edit" />
                  </Link>
                  <Button
                    type="primary"
                    icon="delete"
                    className="btn-delete"
                    onClick={() => onDeleteSellRealEstate(item.id)}
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="6">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={1}
        pageSize={10}
        current={pageIndex}
        total={totalItems}
        onChange={(page, pageSize) => {
          changePageIndex(page);
        }}
      />
    </>
  );
};
SellRealEstateTable.defaultProps = {
  realEstateList: [],
  changePageIndex: () => {},
  pageIndex: 0,
  onClickHideSellRealEstate: () => {},
  onDeleteSellRealEstate: () => {},
  isSell: false,
  totalItems: 0,
};

SellRealEstateTable.propTypes = {
  realEstateList: PropTypes.array,
  changePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  onClickHideSellRealEstate: PropTypes.func,
  onDeleteSellRealEstate: PropTypes.func,
  isSell: PropTypes.bool,
  totalItems: PropTypes.number,
};

export default memo(SellRealEstateTable);
