import React, { Component } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import {ImageMediumWithWatermark} from "../../../../config/main";
import Image from '../../../../component/image.js';

class imageRealEstate extends Component {
    getGalleries = () => {
        let galleries = [];
        if( this.props.galleries !== null && this.props.galleries.length > 0) {
            let galleriesObject = this.props.galleries;
            for(let gallery in galleriesObject) {
                const image = {
                    original : ImageMediumWithWatermark + "" + galleriesObject[parseInt(gallery, 0)].url,
                    thumbnail : ImageMediumWithWatermark + "" + galleriesObject[parseInt(gallery, 0)].url,
                    renderItem: () => {
                        return (
                            <Image src={ImageMediumWithWatermark + "" + galleriesObject[parseInt(gallery, 0)].url } />
                        )
                    },
                    renderThumbInner: () => {
                        return (
                            <Image src={ImageMediumWithWatermark + "" + galleriesObject[parseInt(gallery, 0)].url } />
                        )
                    }
                };
                galleries.push(image)
            }
        }
        return galleries
    };

    render() {
        return (
            <div className="project_image" >
                <ImageGallery items={this.getGalleries()}
                              showPlayButton={false}
                              autoPlay={ false } />
            </div>
        );
    }

}
export default imageRealEstate;
