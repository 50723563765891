import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Empty, Pagination } from "antd";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
import Api from "../../../api/dashboardPage";
import { ResourceUrl } from "../../../config/main.js";
import Helpers from "../../../functions/Helpers";

const Item = ({ item }) => (
  <Tr key={item.id}>
    <Td>
      <div
        className="img"
        style={{
          backgroundImage: `${
            item.real_estate_galleries
              ? `url(${ResourceUrl}${item.real_estate_galleries})`
              : `url(${ResourceUrl}${item.cover_image})`
          }`,
        }}
      ></div>
    </Td>
    <Td>
      <Link to={`/bat-dong-san/${item.slug}`}>
        <div className="hide-on-mobile">{item.title}</div>
        <div className="hide-on-desktop" style={{ marginTop: "10px" }}>
          {item.title}
        </div>
        <div style={{ color: `#BF0000`, fontSize: `12px` }}>
          {`${item.address_number} ${item.street}`},{" "}
          {`${item.district_name}, ${item.province_name}`}
        </div>
        <div
          className="hide-on-mobile"
          style={{ color: `#62D273`, fontSize: `12px` }}
        >
          {item.view_number === 0 ? "0" : item.view_number} lượt xem
        </div>
        <div className="hide-on-desktop" style={{ fontSize: `12px` }}>
          {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
          {"  "}
          <span style={{ color: `#62D273` }}>
            {item.view_number === 0 ? "0" : item.view_number} lượt xem
          </span>
        </div>
        <div
          className="hide-on-desktop"
          style={{
            marginTop: "10px",
            color: `#ff4d4f`,
            fontSize: `20px`,
          }}
        >
          {Helpers.moneyFormatVn(item.price)}/ {parseInt(item.acreage)} m
          <sup>2</sup>
        </div>
      </Link>
    </Td>
    <Td className="hide-on-mobile">{item.packages_name}</Td>
    <Td className="hide-on-mobile">{item.days}</Td>
    <Td className="hide-on-mobile">{item.payments_name}</Td>
    <Td className="hide-on-mobile">{Helpers.formatNumber(item.price) + "đ"}</Td>
  </Tr>
);

function MyVip() {
  const [realEstate, setRealEstate] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    Api.myVip().then((res) => {
      setRealEstate(res.data.real_estates);
      setTotal(res.data.total);
    });
  }, []);

  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Ảnh bìa</Th>
            <Th width="40%">Tiêu đề</Th>
            <Th>Loai tin</Th>
            <Th>Số ngày</Th>
            <Th>Hình thức</Th>
            <Th>Thanh toán</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {realEstate.length > 0 ? (
            realEstate.map((item, key) => <Item item={item} />)
          ) : (
            <Tr>
              <Td colSpan="6">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={20}
        pageSize={20}
        current={currentPage}
        total={total}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          Api.myVip(20, page).then((res) => {
            if (res.data.status) {
              setRealEstate(res.data.real_estates);
              setTotal(res.data.total);
            }
          });
        }}
      />
    </>
  );
}

export default MyVip;
