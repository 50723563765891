import { useMutation } from "@tanstack/react-query";
import { Col, Modal, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import { createBooking } from "../../../api/onlinePrice";
import { ApartmentInfo } from "../../../component";
import Breadcumb from "../../../component/breadcrumb/breadcrumb";
import { useFetchApartmentDetail } from "../../../hooks/useFetchApartmentDetail";
import { setSelectedProjectId } from "../../../store/actions";
import { getAparment, removeAparment } from "../../../utils";
import styles from "../Cart.module.scss";
import { BuyerForm } from "../components";

const CreateBuyer = () => {
  const apartment = getAparment();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleRedirectToTransaction = (orderId) =>
    history.push(`/cart/transaction/order/${orderId}`);
  const createBookingMutation = useMutation(createBooking, {
    onSuccess: (res) => {
      removeAparment();
      dispatch(setSelectedProjectId(null));
      handleRedirectToTransaction(res?.data?.Data?.id);
    },
    onError: () => {
      Modal.error({
        title: "Máy chủ đang bị lỗi",
        content: "Bạn vui lòng quay lại sau, Cám ơn",
      });
    },
  });
  if (!apartment) {
    history.push("/project-carts");
  }

  const fetchedApartment = useFetchApartmentDetail();

  return (
    <div className={styles.wrapper}>
      <Breadcumb subitem="Thông tin bên mua" />
      <div className={styles.cartContainer}>
        <div className="section-wrap">
          <h4 className={styles.cartTitle} level={4}>
            Thông tin đặt hàng
          </h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <BuyerForm createBookingMutation={createBookingMutation} />
            </Col>
            <Col span={24} xs={24} sm={24} md={24} lg={8} xl={8}>
              {fetchedApartment && (
                <ApartmentInfo
                  houseName={fetchedApartment.HouseName}
                  floorNumber={fetchedApartment.FloorNumber}
                  apartmentAttributes={fetchedApartment?.ApartmentAttributes}
                  totalPriceNonVAT={fetchedApartment?.TotalPriceNonVAT}
                  buildingName={fetchedApartment?.buildingName}
                  totalPriceVAT={fetchedApartment?.TotalPriceVAT}
                  percent={fetchedApartment.percent}
                  percentAmount={fetchedApartment.percentAmount}
                  shouldDisplayPriceDetail
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CreateBuyer;
