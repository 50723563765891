import React from "react";
import { Radio } from "antd";

import Image from "../../../../../component/image";
import MomoImg from "../../../../../images/momo.jpg";

const PaymentItem = ({ value, text, description, image }) => {
  return (
    <Radio className="radio-custom radio-payment" value={value}>
      <div className="description">
        <div className="logo-payment">
          <Image src={image} />
        </div>
        <span className="name-payment">{text}</span>
      </div>
    </Radio>
  );
};

function Payments(props) {
  const onChangePayment = (e) => {
    props.setPaymentSelected(e.target.value);
    if (e.target.value === "0") {
      props.setDays(3);
    }
  };
  const { orderFreeExist } = props;
  return (
    <div className="payments">
      <span className="title">Phương thức thanh toán</span>
      <div className="payment-container">
        <Radio.Group
          value={props.paymentSelected}
          onChange={(e) => onChangePayment(e)}
        >
          {!orderFreeExist && (
            <Radio className="radio-custom radio-payment" value="0">
              <div className="description">
                <span className="name-payment">Đẩy tin miễn phí</span>
              </div>
            </Radio>
          )}

          {props.payments &&
            props.payments.map((p) => (
              <PaymentItem
                key={p.id}
                value={p.id}
                text={p.name}
                image={MomoImg}
              />
            ))}
        </Radio.Group>
      </div>
    </div>
  );
}

export default Payments;
