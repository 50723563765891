const ApiUrl = 'http://api.nhadatcom.vn/';
const ApiCDNUrl = 'http://api.nhadatcom.vn/';
const ResourceUrl = 'http://api.nhadatcom.vn/resources/';
const ImagesUrl = ApiCDNUrl+'images/';
const ResourcePath = 'resources/';
const FacebookId = '608882763167260';
const GoogleId = '667203847553-n32nr0f0kfhj2chohrri94kuh3lt9gao.apps.googleusercontent.com';
const googleMapApiKey = 'AIzaSyCKiYVU2qSsvqAkYKb3p5EBZ9Q-8xdd-TU';
const uploadUrl = 'http//api.nhadatcom.vn/upload';
const LoadImageUrl = ApiCDNUrl+'services/resizes';
const ImageMedium = LoadImageUrl+'/medium/'+ResourcePath;
const ImageThump = LoadImageUrl+'/thumbnail/'+ResourcePath;
const ImageLarger = LoadImageUrl+'/larger/'+ResourcePath;
const SpecialImage = (size) => {
    return LoadImageUrl+'/'+size+'/'+ResourcePath;
};

const LoadImageUrlWithWatermark = ApiCDNUrl+'services/resizesWithWatermark';
const ImageMediumWithWatermark =  LoadImageUrlWithWatermark+'/medium/'+ResourcePath;

let http = 'http://';
if (typeof window !== 'undefined') {
    http =  window.location.protocol+'//';
}
const MainDomain = 'nhadatcom.vn';
const DomainLink = http + MainDomain;
const reCaptchaKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

export {ApiUrl,FacebookId,GoogleId,googleMapApiKey,
    ResourceUrl,uploadUrl, LoadImageUrl, ResourcePath,
    ImageLarger, ImageMedium, ImageThump, SpecialImage,
    ImageMediumWithWatermark, MainDomain,  DomainLink, http,
    ImagesUrl,reCaptchaKey
};
