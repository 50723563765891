import React from "react";
import { Breadcrumb, Col, Icon, Row, Tabs } from "antd";
import ListVertical from "../../component/listVertical/listVertical";
import ContactTable from "./table";

const { TabPane } = Tabs;

const Contact = () => {
  return (
    <div className="frontPageSection">
      <div className="section-wrap-private">
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type="home" />
                <span>Trang chủ</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>Liên hệ</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="hide-on-mobile" xs={24} sm={24} md={7} lg={4}>
            <ListVertical activeLink={`Liên hệ`} />
          </Col>
          <Col xs={24} sm={24} md={17} lg={20} className="section-table">
            <div className="table-history">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Liên hệ" key="1">
                  <ContactTable />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
