import { Typography } from "antd";
import React from "react";
import styles from "../BookingDetail.module.scss";

const { Title } = Typography;
const OrderInfo = ({ bookingStatusLable, bookedAt }) => {
  return (
    <>
      <Title level={4} className={styles.subHeading}>Thông tin đơn hàng</Title>
      <div className={styles.orderInfoWrapper}>
        <p className={styles.orderInfoRow}>
          <span className={styles.orderInfoLabel}>Trạng thái:</span>
          <span className={styles.orderInfoValue}>{bookingStatusLable}</span>
        </p>
        <p className={styles.orderInfoRow}>
          <span className={styles.orderInfoLabel}>Ngày đặt hàng:</span>
          <span className={styles.orderInfoValue}>{bookedAt}</span>
        </p>
      </div>
    </>
  );
};

OrderInfo.propTypes = {};

export default OrderInfo;
