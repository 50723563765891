import * as actionTypes from '../actions/actionTypes';
const initialState = false;

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.REDIRECT_TO_POST_REAL_ESTATE: 
            return action.flag;
        default: return state;
    }
};

export default reducer;
