import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button, Empty, Modal, Spin, Icon, Pagination } from "antd";
import "./tableHistoryArticle.scss";
import CreateUpdateArticleApi from "../../api/createUpdateArticlePage";
import { ResourceUrl } from "../../config/main.js";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Link } from "react-router-dom";
import moment from "moment";

const { confirm } = Modal;

class TableHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listMyArticles: [],
      isLoading: true,
      totalArticles: 0,
      currentArticlePage: 1,
    };
  }

  componentDidMount = () => {
    CreateUpdateArticleApi.getMyArticles(20, 1).then((res) => {
      if (res.data.status) {
        this.setState({
          listMyArticles: res.data.articles,
          totalArticles: res.data.total,
          isLoading: false,
        });
      }
    });
  };

  handleEdit = (slug) => {
    this.props.history.push(`/me/article/edit/${slug}`);
  };

  handleDelete = (id) => {
    confirm({
      title: "Bạn có chắc muốn xóa tin tức này?",
      okText: "Có",
      okType: "danger",
      cancelText: "Không",
      onOk: () => {
        CreateUpdateArticleApi.remove(id).then((res) => {
          if (res.data.status) {
            const index = this.state.listMyArticles.findIndex((item) => {
              return item.id === id;
            });
            let temp = [...this.state.listMyArticles];
            if (index > -1) {
              temp.splice(index, 1);
            }
            this.setState({
              listMyArticles: temp,
            });
            Swal.fire({
              icon: "success",
              title: "Xóa thành công",
              showConfirmButton: true,
            });
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { isMyPost } = this.props;
    const { isLoading } = this.state;
    return isLoading ? (
      <Spin
        style={{
          width: `100%`,
          margin: `auto`,
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
      />
    ) : (
      <>
        <Table>
          <Thead className="ant-table-thead">
            <Tr>
              <Th>Hình ảnh</Th>
              <Th>Tiêu đề</Th>
              <Th width="20%">Ngày đăng</Th>
              {isMyPost && <Th>&nbsp;&nbsp;&nbsp;</Th>}
            </Tr>
          </Thead>
          <Tbody className="ant-table-tbody">
            {this.state.listMyArticles && !!this.state.listMyArticles.length ? (
              this.state.listMyArticles.map((item, key) => (
                <Tr key={key}>
                  <Td>
                    <div
                      style={{
                        backgroundImage: `url(${ResourceUrl}${item.cover_image})`,
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: ` 50%`,
                        backgroundSize: `cover`,
                        height: "50px",
                        width: "50px",
                      }}
                    ></div>
                  </Td>
                  <Td className="width-title">{item.title}</Td>
                  <Td>{moment(item.created_at).format('DD/MM/YYYY h:m:s')}</Td>
                  {isMyPost && (
                    <Td className="td-action">
                      <Link to={`/me/article/edit/${item.slug}`}>
                        <Button
                          className="btn-edit"
                          type="primary"
                          icon="edit"
                        />
                      </Link>
                      <Button
                        type="primary"
                        icon="delete"
                        className="btn-delete"
                        onClick={this.handleDelete.bind(this, item.id)}
                      />
                    </Td>
                  )}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="7">
                  <Empty />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Pagination
          style={{ float: "right", marginTop: "1rem" }}
          defaultPageSize={20}
          pageSize={20}
          current={this.state.currentArticlePage}
          total={this.state.totalArticles}
          onChange={(page, pageSize) => {
            this.setState({
              isLoading: true,
              currentArticlePage: page,
            });
            CreateUpdateArticleApi.getMyArticles(20, page).then((res) => {
              if (res.data.status) {
                this.setState({
                  listMyArticles: res.data.articles,
                  totalArticles: res.data.total,
                  isLoading: false,
                });
              }
            });
          }}
        />
      </>
    );
  }
}

export default withRouter(TableHistory);
