import React from 'react';
import { Col, Empty, Row } from 'antd';
import EventModel from '../../models/Event';
import queryString from 'query-string';
import EventList from '../../component/event/EventList';
import EventCategory from '../../component/event/EventCategory';
import './event.scss';
import banner1 from '../../images/event/banner1.jpeg';
import banner2 from '../../images/event/banner2.jpeg';
import voucher from '../../images/realEstate/banner2.png';
import Image from '../../component/image';
import Banner from '../../component/banner/banner';
import HeadingContent from '../../component/banner/HeadingContent';

class Event extends React.Component {
  state = {
    data: [],
    total: null,
    current_page: null,
    pageSize: null,
    category: [],
    isLoading: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.loadData();
    }
  }
  loadData() {
    let params = queryString.parse(this.props.location.search);
    const { slug } = this.props.match.params;
    this.loadEventList(params.page || null, slug || null);
  }

  loadEventList(page, slug) {
    this.setState({
      isLoading: true,
    });
    EventModel.EventLists(page, slug)
      .then((res) => {
        const data: EventModel = res;
        this.setState({
          data: data.attributes.data,
          total: data.attributes.total,
          current_page: data.attributes.current_page,
          pageSize: data.attributes.per_page,
          category: data.categories,
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  }

  render() {
    return (
      <>
        <Banner
          headingContent={
            <HeadingContent
              heading="Sự kiện"
              subheading="Những sự kiện nổi bật diễn ra trong vài ngày tới"
            />
          }
        />
        <div className="site-event">
          <div className="section-wrap">
            <div className="event-content">
              <Row gutter={{ xs: 0, lg: 16 }}>
                <Col xs={24} lg={16}>
                  <div className="left-title">
                    {this.state?.data?.length > 0 ? (
                      <EventList {...this.state} />
                    ) : (
                      <Empty />
                    )}
                  </div>
                </Col>
                <Col xs={24} lg={8}>
                  <EventCategory category={this.state.category} />
                  <Image className="img-full" src={banner1} />
                  <Image className="img-full mt-24 mb-24" src={banner2} />
                  <Image className="img-full" src={voucher} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Event;
