import React from "react";
import { Button, Col, Form, Input, Row, Icon, Upload, Spin } from "antd";
import styles from "./formArticle.module.scss";
import CreateUpdateArticleApi from "../../api/createUpdateArticlePage";
import { uploadUrl } from "../../config/main";
import { withRouter } from "react-router-dom";
import { ResourceUrl } from "../../config/main";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class FormArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      content: "",
      image_cover: "",

      loading: false,
      imageUrl: null,
      isLoadingForm: true,
      article: null,
      isSubmit: false
    };
  }

  componentDidMount = () => {
    this.CKEditor = require("@ckeditor/ckeditor5-react");
    this.ClassicEditor = require("@ckeditor/ckeditor5-build-classic");
    if (this.props.match.params.slug) {
      CreateUpdateArticleApi.getDetail(this.props.match.params.slug).then(
        res => {
          if (res.data.status) {
            this.setState({
              content: res.data.article.body,
            });
            this.props.form.setFieldsValue({
              title: res.data.article.title,
            });
            this.setState({
              imageUrl: `${ResourceUrl}${res.data.article.cover_image}`,
              isLoadingForm: false,
              article: res.data.article,
            });
          }
        }
      );
    }
    this.setState({
      isLoadingForm: false
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  normFile = e => {
    if (Array.isArray(e)) {
      console.log(e);
      return e;
    }
    return e && e.fileList;
  };
  handleRedirectToArticles = () => {
    this.props.history.push("/user/articles");
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      isSubmit: true
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values = {
          ...values,
          body: this.state.content
        };
        if (!this.props.match.params.slug) {
          CreateUpdateArticleApi.addNew(values)
            .then(res => {
              if (res.data.status) {
                Swal.fire({
                  icon: "success",
                  title: "Đăng tin thành công",
                  showConfirmButton: true
                });
                this.setState({
                  isSubmit: false
                });
                this.handleRedirectToArticles();
              }
            })
            .catch(error => {});
        } else {
          values = {
            ...values,
            id: this.state.article.id
          };
          CreateUpdateArticleApi.change(values)
            .then(res => {
              if (res.data.status) {
                Swal.fire({
                  icon: "success",
                  title: "Sửa tin thành công",
                  showConfirmButton: true
                });
                this.setState({
                  isSubmit: false
                });
                this.handleRedirectToArticles();
              }
            })
            .catch(error => {});
        }
      }
    });
  };

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    return this.state.isLoadingForm ? (
      <Spin
        style={{
          left: `50%`,
          top: `50%`,
          position: "absolute",
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
      />
    ) : (
      <div className={styles.sectionInfo}>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Form.Item label="Ảnh bìa">
              {getFieldDecorator("cover_image")(
                <Upload
                  name="logo"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={uploadUrl}
                  fileList={[]}
                  onChange={this.handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Tên bài đánh giá" className={styles.rowInput}>
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "Vui lòng nhập tên bài đánh giá!" }],
              })(<Input />)}
            </Form.Item>
          </Row>
          <Row style={{ margin: "1rem 0" }}>
            {/* <input type="hidden" id="trix" />
            <trix-editor className="trix-content" input="trix" /> */}
            {this.CKEditor ? <this.CKEditor
                editor={this.ClassicEditor}
                data={this.state.content ? this.state.content : ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({
                    content: data,
                  });
                }}
                config={{
                  ckfinder: {
                    uploadUrl: uploadUrl,
                  },
                }}
            /> : null}
          </Row>
          <Row>
            <Form.Item>
              <Col span={12} offset={6} style={{ textAlign: "center" }}>
                <Button
                  loading={this.state.isSubmit ? true : false}
                  type="primary"
                  htmlType="submit"
                >
                  {this.props.match.params.slug
                    ? "Cập nhật bài đánh giá"
                    : "Đăng bài đánh giá"}
                </Button>
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(Form.create()(FormArticle));
