import React from "react";
import { Tabs } from "antd";
import "./history.scss";
import MySoldList from "../my-sold-list";
import MySellList from "../my-sell-list";
import MyReVip from "./myReVip";

const { TabPane } = Tabs;

class History extends React.Component {
  render() {
    return (
      <div className="table-history">
        <Tabs
          defaultActiveKey="1"
          // tabBarExtraContent={
          //   <Link to="/create-real-estate">
          //     <Button icon="plus" type="primary">
          //       Tạo mới
          //     </Button>
          //   </Link>
          // }
        >
          <TabPane tab="Tin thường" key="1">
            <MySellList isMyPost={true} />
          </TabPane>
          <TabPane tab="Đã bán" key="2">
            <MySoldList isMyPost={true} />
          </TabPane>
          <TabPane tab="Tin vip" key="3">
            <MyReVip />
          </TabPane>
          {/* <TabPane tab="Tin trong khu vực" key="3">
            <MySameAreaList isMyPost={false} />
          </TabPane> */}
        </Tabs>
      </div>
    );
  }
}

export default History;
