import SearchModel from "../../models/Search";
import * as types from "./actionTypes";

export const searchFilter = () => {
    return dispatch => {
        SearchModel.setFilterOption().then(response => {
            dispatch({
                type: types.SEARCH_FILTER_OPTIONS,
                filter: response.data.status === 1 ? response.data.filter_option : {}
            });
        }).catch(error => {

        });
    };
};