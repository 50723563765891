import React from 'react';
import styles from './menuBot.module.scss';
import { Affix, Icon, Drawer, List, Avatar } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '../login/login';
import MenuLogin from '../../models/MenuLogin';
import { logout } from '../../store/actions/profile';
import loginSignUpApi from '../../api/loginSignUp';
import User from '../../models/User';
import Helpers from '../../functions/Helpers';
import { ResourceUrl } from '../../config/main';
import DefaultAvatar from '../../images/generic-avatar.png';
import { redirectToPostRealEstate } from '../../store/actions/redirectToPostRealEstate';
import item from '../../images/item-bot.svg';

const NavLink = withRouter(({ to, children, onClick, ...props }) => {
  const classActive = props.location.pathname === to ? 'active' : null;
  return (
    <Link onClick={onClick} className={classActive} to={to}>
      {children}
    </Link>
  );
});

class MenuBot extends React.Component {
  login;
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.login = React.createRef();
  }

  showLoginForm = (e) => {
    if (e) e.preventDefault();
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.login.current.show();
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  renderDrawer = (e) => {
    e.preventDefault();
    this.setState({
      visible: !this.state.visible,
    });
  };

  renderLastMenuItem = () => {
    let lastItem;
    if (this.props.profile) {
      lastItem = (
        <li className={styles.menuItem}>
          <Link to="/user/my-profile">
            <Icon type="user" />
            <span>Tài khoản</span>
          </Link>
        </li>
      );
    } else {
      lastItem = (
        <li className={styles.menuItem}>
          <Link to="/sign-in">
            <Icon type="login" />
            <span>Đăng nhập</span>
          </Link>
        </li>
      );
    }
    return lastItem;
  };

  handleLogout = () => {
    loginSignUpApi.logout().then((res) => {
      if (res.data.status) {
        this.props.logout();
        User.logout();
        this.setState({
          visible: false,
        });
      }
    });
  };

  renderMenuWhenLogin = () => {
    const menuLogin = MenuLogin.slice();
    menuLogin.push({
      name: (
        <React.Fragment>
          <Icon
            type="logout"
            style={{
              marginRight: 10,
            }}
          />{' '}
          Đăng xuất
        </React.Fragment>
      ),
      onClick: () => this.handleLogout(),
    });
    let listItem = menuLogin.map((item, index) => {
      let link;
      if (item.to) {
        link = (
          <Link
            style={{
              width: '100%',
            }}
            to={item.to}
            onClick={() => this.setState({ visible: false })}
          >
            <List.Item.Meta title={item.name} />
          </Link>
        );
      } else {
        link = (
          <Link
            style={{
              width: '100%',
            }}
            to="#"
            onClick={item.onClick}
          >
            <List.Item.Meta title={item.name} />
          </Link>
        );
      }
      return (
        <List.Item
          key={index}
          style={{
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          {link}
        </List.Item>
      );
    });
    if (this.props.profile) {
      listItem.unshift(
        <List.Item
          key="user"
          style={{
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Link
            style={{
              width: '100%',
            }}
            to={'/broker/' + this.props.profile.slug}
            onClick={() => this.setState({ visible: false })}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={
                    this.props.profile
                      ? this.props.profile.avatar
                        ? ResourceUrl + this.props.profile.avatar
                        : DefaultAvatar
                      : DefaultAvatar
                  }
                />
              }
              title={Helpers.capitalizeFirstLetter(
                this.props.profile.full_name
              )}
            />
          </Link>
        </List.Item>
      );
    }
    return <List itemLayout="horizontal">{listItem}</List>;
  };

  render() {
    return (
      <Affix offsetBottom={0}>
        <Drawer
          className={styles.menuMore}
          title="Tài Khoản"
          placement="bottom"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          {this.renderMenuWhenLogin()}
        </Drawer>
        <Login wrappedComponentRef={this.login} />
        <ul className={styles.menuBottom}>
          <li className={styles.menuItem}>
            <NavLink to="/">
              <Icon type="home" />
              <span>Trang chủ</span>
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink to="/du-an-bat-dong-san">
              <Icon type="project" />
              <span>Dự án</span>
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <div className={styles.wallperCircle}>
              <img src={item} alt="" />
            </div>
            <div className={styles.circle} />
            <NavLink
              onClick={(e) => {
                e.preventDefault();
                if (!this.props.profile) {
                  this.props.redirectToPostRealEstate(true);
                  this.showLoginForm();
                } else {
                  this.props.history.push('/create-real-estate');
                }
              }}
              to="#"
            >
              <Icon type="form" />
              <span>Đăng tin</span>
            </NavLink>
          </li>
          <li className={styles.menuItem}>
            <NavLink to="/price-by-month">
              <Icon type="dollar" />
              <span>Giá nhà đất</span>
            </NavLink>
          </li>
          {this.renderLastMenuItem()}
        </ul>
      </Affix>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuBot));
