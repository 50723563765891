import { Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import {
  SelectCityField,
  SelectDistrictField,
  SelectWardField,
} from "../../../component";

const AddressForm = ({
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  isDisabled,
}) => {
  const handleSelectCity = (cityCode) => {
    setFieldsValue({ province_code: cityCode });
  };
  const handleSelectDistrict = (districtCode) => {
    setFieldsValue({ district_id: districtCode });
  };
  const handleSelectWard = (wardCode) => {
    setFieldsValue({ ward_id: wardCode });
  };
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Tỉnh/thành phố">
            {getFieldDecorator("province_code", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <SelectCityField
                isDisabled={isDisabled}
                onSelect={handleSelectCity}
                valueOfSelected={getFieldValue("province_code")}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Quận/huyện">
            {getFieldDecorator("district_id", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <SelectDistrictField
                cityCode={getFieldValue("province_code")}
                onSelect={handleSelectDistrict}
                valueOfSelected={getFieldValue("district_id")}
                isDisabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Phường/xã">
            {getFieldDecorator("ward_id", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <SelectWardField
                districtCode={getFieldValue("district_id")}
                onSelect={handleSelectWard}
                valueOfSelected={getFieldValue("ward_id")}
                isDisabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Địa chỉ">
            {getFieldDecorator("address", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(<Input placeholder="Địa chỉ" disabled={isDisabled} />)}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
AddressForm.defaultProps = {
  getFieldDecorator: () => {},
  getFieldValue: () => {},
  setFieldsValue: () => {},
  isDisabled: false,
};

AddressForm.propTypes = {
  getFieldDecorator: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFieldsValue: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default AddressForm;
