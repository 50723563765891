import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PostRealEstatePageApi from "../../../../api/postRealEstatePage";
import { uploadUrl } from "../../../../config/main";
import { getBase64FromList } from "../../../../utils";
import styles from "../MyProfile.module.scss";
import FormRow from "./FormRow";

const { TextArea } = Input;
const { Option } = Select;

const EditUserInfoForm = ({
  getFieldDecorator,
  onSubmit,
  citizeIdenFiles,
  certificationFiles,
  onChangeCitizenIdenFile,
  onChangeCertificationFile,
  onRemoveCitizenIdenFile,
  onRemoveCertificationFile,
  isLoading,
}) => {
  const [brokerCategories, setBrokerCategories] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handleCancel = () => setPreviewVisible(false);

  const handlePreviewImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64FromList(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  useEffect(() => {
    PostRealEstatePageApi.getAllBrokerCategory().then((res) => {
      if (res.data.status === 1) {
        setBrokerCategories(res.data.broker_categories);
      } else {
        alert(res.data.message);
      }
    });
  }, []);
  return (
    <div className={styles.formWrapper}>
      <Form onSubmit={onSubmit.bind(this)}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Ảnh chứng mình nhân dân"}>
              {getFieldDecorator("cmnd")(
                <React.Fragment>
                  <Upload
                    name="logo"
                    action={uploadUrl}
                    listType="picture-card"
                    fileList={citizeIdenFiles}
                    onPreview={handlePreviewImage}
                    onChange={onChangeCitizenIdenFile}
                    onRemove={onRemoveCitizenIdenFile}
                  >
                    {citizeIdenFiles.length >= 8 ? null : (
                      <>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Upload</div>
                      </>
                    )}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </React.Fragment>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Chứng chỉ môi giới"}>
              {getFieldDecorator("chung_chi")(
                <React.Fragment>
                  <Upload
                    name="logo"
                    action={uploadUrl}
                    listType="picture-card"
                    fileList={certificationFiles}
                    onPreview={handlePreviewImage}
                    onChange={onChangeCertificationFile}
                    onRemove={onRemoveCertificationFile}
                  >
                    {certificationFiles.length >= 8 ? null : (
                      <>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Upload</div>
                      </>
                    )}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </React.Fragment>
              )}
            </Form.Item>
          </Col>
        </Row>
        <FormRow
          getFieldDecorator={getFieldDecorator}
          itemList={[
            {
              fieldName: "last_name",
              rules: [{ required: true, message: "Vui lòng nhập họ!" }],
              placeholder: "Họ",
              lablel: "Họ",
            },
            {
              fieldName: "first_name",
              rules: [{ required: true, message: "Vui lòng nhập tên!" }],
              placeholder: "Tên",
              lablel: "Tên",
            },
          ]}
        />
        <FormRow
          getFieldDecorator={getFieldDecorator}
          itemList={[
            {
              fieldName: "phone",
              rules: [
                { required: true, message: "Vui lòng nhập sô điện thoại!" },
              ],
              placeholder: "Số điện thoại",
              lablel: "Số điện thoại",
              disabled: true,
            },
            {
              fieldName: "email",
              rules: [{ required: true, message: "Vui lòng nhập email!" }],
              placeholder: "Email",
              lablel: "Email",
            },
          ]}
        />
        <FormRow
          getFieldDecorator={getFieldDecorator}
          itemList={[
            {
              fieldName: "zalo",
              rules: [],
              placeholder: "Zalo",
              lablel: "Zalo",
            },
            {
              fieldName: "facebook",
              rules: [],
              placeholder: "Link facebook",
              lablel: "Link facebook",
            },
          ]}
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="My domain">
              {getFieldDecorator("slug", {
                rules: [],
              })(
                <Input placeholder="My domain" className={styles.inputItem} />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Tôi là">
              {getFieldDecorator(
                "broker_category_id",
                {}
              )(
                <Select
                  placeholder="Chọn một loại"
                  className={styles.selectCategory}
                  size="large"
                >
                  {brokerCategories?.length > 0 &&
                    brokerCategories?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item label="Thông tin về tôi">
              {getFieldDecorator("about_us", {
                rules: [],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Cập nhật
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditUserInfoForm.defaultProps = {
  getFieldDecorator: null,
  onSubmit: () => {},
  onChangeCitizenIdenFile: () => {},
  citizeIdenFiles: [],
  certificationFiles: [],
  onChangeCertificationFile: () => {},
  onRemoveCitizenIdenFile: () => {},
  onRemoveCertificationFile: () => {},
  isLoading: false,
};
EditUserInfoForm.propTypes = {
  getFieldDecorator: PropTypes.func,
  onSubmit: PropTypes.func,
  citizeIdenFiles: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
    })
  ),
  certificationFiles: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
    })
  ),
  onChangeCitizenIdenFile: PropTypes.func,
  onChangeCertificationFile: PropTypes.func,
  onRemoveCitizenIdenFile: PropTypes.func,
  onRemoveCertificationFile: PropTypes.func,
};

export default EditUserInfoForm;
