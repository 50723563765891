import PropTypes from "prop-types";
import React from "react";

import "./maker.scss";

import Helpers from "../../../functions/Helpers";

const Marker = (props) => {
  const { realState, handleShowMakerDetail, showSup } = props;
  const { lat, lng, price } = realState;

  return (
    <div
      className="map-estimate__item"
      lat={lat}
      lng={lng}
      onClick={() => handleShowMakerDetail(realState)}
    >
      <span>
        {Helpers.moneyFormatVn(price)}
        {showSup && (
          <>
            /m<sup>2</sup>
          </>
        )}
      </span>
    </div>
  );
};

Marker.propTypes = {
  realState: PropTypes.object.isRequired,
  handleShowMakerDetail: PropTypes.func,
  showSup: PropTypes.bool,
};

Marker.defaultProps = {
  handleShowMakerDetail: () => null,
  showSup: false,
};

export default Marker;
