import React  from 'react';
import { Row, Col } from 'antd';

const InformationLoan = (props) => {
          return (
            <div className="infor-loan">
                <Row className="first-row">
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } className="price-content">
                         <span className="label">
                           Cần trả trước
                         </span>
                         <strong className="blue price">{ props.convertNumber(props.price - props.loanMoney) }</strong>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } className="price-content">
                        <span className="label">
                          Gốc cần trả
                        </span>
                        <strong className="black price">{ props.convertNumber(props.loanMoney) }</strong>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } className="price-content">
                        <span className="label">
                          Lãi cần trả
                        </span>
                        <strong className="red price">{ !isNaN(props.sumRate) ? props.convertNumber(props.sumRate) : 0 }</strong>
                    </Col>
                </Row>
            </div>
          );
}

export default InformationLoan;
