import React from 'react';
import styles from './HeadingContent.module.scss';

export default function HeadingContent({ heading, subheading }) {
  return (
    <>
      <h1 className={styles.heading} level={1}>
        {heading}
      </h1>
      {subheading && <p className={styles.subheading}>{subheading}</p>}
    </>
  );
}
