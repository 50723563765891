import React from "react";
import {Row, Col, Card, Typography, Icon} from "antd";
import styles from "./card.module.scss";
import "./global.scss";
import {Link, withRouter} from "react-router-dom";
import {ImageMediumWithWatermark} from "../../config/main";
import MoneyFormat from "../moneyFormat";
import moment from "moment";

const {Text} = Typography;

class CardLists extends React.Component{
    render() {
        return(
            <Row className={styles.cardList} type="flex">
                {this.buildCard(this.props)}
            </Row>
        );
    }

    buildCard(data) {
        return data.dataSource.map((source, index) => (
            <Col
                key={index}
                xs={data.grid.xs}
                sm={data.grid.sm}
                md={data.grid.md}
                lg={data.grid.lg}
                xl={data.grid.xl}
                xxl={data.grid.xxl}
                style={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingBottom: "16px"
                }}
            >
                <div style={{
                    cursor: "pointer"
                }} onMouseEnter={() => this.props.onHover(source)} onMouseLeave={() => this.props.onHover(source)} onClick={() => this.props.history.push('/bat-dong-san/'+source.slug)}>
                    <Card
                        hoverable
                        cover={
                            <aside style={{
                                backgroundImage: "url("+ImageMediumWithWatermark+source.cover_image+")"
                            }} className={styles.cartImage}/>
                        }
                        actions={[
                            /*<Descriptions column="3" style={{
                                textAlign: "left",
                                padding: "0 12px"
                            }} className="cartInfo" size={"small"}>
                                <Descriptions.Item >{source.bedroom_quantity} PN</Descriptions.Item>
                                <Descriptions.Item >{source.bathroom_quantity} WC</Descriptions.Item>
                                <Descriptions.Item >{source.floor} TẤM</Descriptions.Item>
                            </Descriptions>*/
                        ]}
                        size="small"
                    >
                        <Card.Meta title={
                            <Text className={styles.cartTitle}>
                                <MoneyFormat>{source.price}</MoneyFormat>
                                <span style={{float: "right"}}>{parseFloat(source.acreage, 2)}m<sup>2</sup></span>
                            </Text>
                        } description={
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <Text className={styles.cartDes}><p>{source.title}</p></Text>
                                <Text
                                    style={{margin: 0}}
                                >
                                    <b><Icon style={{
                                        marginRight: "5px"
                                    }} type="environment" /></b>{(source.address ? source.address+ ", " : "") + (source.ward ? source.ward+", " : "") +(source.district ? source.district+", " : "") + (source.city ? source.city+" " : "")}
                                </Text>
                                <Text style={{
                                    margin: 0
                                }}>
                                    <b><Icon style={{
                                        marginRight: "5px"
                                    }} type="clock-circle" /></b>{
                                    source.user_name ? <span className="user_name">
                                                    <Link to={ '/broker/' + source.user_name }>{ source.user_name } </Link>đăng{" "}
                                                </span> : null
                                }{moment(source.created_at).fromNow()}
                                </Text>
                            </div>
                        }/>
                    </Card>
                </div>
            </Col>
        ));
    }
}

export default withRouter(CardLists);
