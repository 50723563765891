import React, { useEffect, useState } from "react";
import { Table } from "antd";
import ContactApi from "../../api/contactPage";
import { Limit, Page } from "../../config/params";
import moment from "moment";

const ContactTable = () => {
  const [contacts, setContacts] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Ngày gửi",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).fromNow(),
    },
  ];

  useEffect(() => {
    const params = {};
    params[Limit] = limit;
    params[Page] = page;
    ContactApi.getAll(params).then((res) => {
      if (res.status) {
        setContacts(res.data);
        setTotal(res.total);
      }
    });
  }, [page, limit]);

  return (
    <Table
      pagination={{
        total: total,
        defaultPageSize: limit,
        pageSize: limit,
        current: page,
        onChange: (page, pageSize) => {
          setPage(page);
          setLimit(pageSize);
        },
      }}
      dataSource={contacts}
      columns={columns}
    />
  );
};

export default ContactTable;
