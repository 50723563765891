export const EVENT_CATEGORIES = [
  {
    code: "booking-du-an",
    color: "#21B21E",
    label: "Booking dự án",
  },
  {
    code: "mo-ban",
    color: "#BF0000;",
    label: "Mở bán",
  },
  {
    code: "cong-bo-du-an",
    color: "#E2A329",
    label: "Công bố dự án",
  },
];
