import Axios from "../axiosApi";

const loginSignUpApi = {
    register: (user) => {
        return Axios.post('/api/register', user).then(res => res.data).catch(err => err.response.data);
    },
    login: (user) => {
        return Axios.post('/api/login', user);
    },
    loginWith3rdParty: (user) => {
        return Axios.post('/api/auth/3rd-party', user);
    },
    getInfor: () => {
        return Axios.get('/api/user');
    },
    logout: () => {
        return Axios.post('/api/logout');
    },
    active: (params) => {
        return Axios.post('/api/active', params).then(res => res.data).catch(err => err.response.data);
    },
    resendActive: (params) => {
        return Axios.post('/api/resend-active', params).then(res => res.data).catch(err => err.response.data);
    },
    send3rdPartyActive: (params) => {
        return Axios.post('/api/send-3rd-party-active', params).then(res => res.data).catch(err => err.response.data);
    }
};

export default loginSignUpApi;
