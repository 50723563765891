import React from "react";
import RealEstateForm from '../../component/real_estate_form/realEstateForm'

class Detail extends React.Component{
    render() {
        return(
            <RealEstateForm />
        );
    }
}

export default Detail;