import { Button, Empty, Pagination } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { ResourceUrl } from "../../../../config/main";

const ArticleTable = ({
  articles,
  changePageIndex,
  pageIndex,
  totalItems,
  onDeleteArticle
}) => {
  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Hình ảnh</Th>
            <Th>Tiêu đề</Th>
            <Th width="20%">Ngày đăng</Th>
            <Th>&nbsp;&nbsp;&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {articles.length > 0 ? (
            articles.map((item, key) => (
              <Tr key={key}>
                <Td>
                  <div
                    style={{
                      backgroundImage: `url(${ResourceUrl}${item.cover_image})`,
                      backgroundRepeat: `no-repeat`,
                      backgroundPosition: ` 50%`,
                      backgroundSize: `cover`,
                      height: "50px",
                      width: "50px",
                    }}
                  ></div>
                </Td>
                <Td className="width-title">{item.title}</Td>
                <Td>{moment(item.created_at).format("DD/MM/YYYY h:m:s")}</Td>
                <Td className="td-action">
                  <Link to={`/user/articles/${item.slug}/edit`}>
                    <Button className="btn-edit" type="primary" icon="edit" />
                  </Link>
                  <Button
                    type="primary"
                    icon="delete"
                    className="btn-delete"
                    onClick={() => onDeleteArticle(item.id)}
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="7">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={20}
        pageSize={20}
        current={pageIndex}
        total={totalItems}
        onChange={(page, pageSize) => {
          changePageIndex(page);
        }}
      />
    </>
  );
};
ArticleTable.defaultProps = {
  articles: [],
  changePageIndex: () => {},
  onDeleteArticle: () => {},
  pageIndex: 0,
  totalItems: 0,
};

ArticleTable.propTypes = {
  articles: PropTypes.array,
  changePageIndex: PropTypes.func,
  onDeleteArticle: PropTypes.func,
  pageIndex: PropTypes.number,
  totalItems: PropTypes.number,
};

export default memo(ArticleTable);
