import { Breadcrumb, Button, Col, PageHeader, Row } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import BlogCatalogComponent from "../../component/blog/blogCatalogComponent";
import BlogList from "../../component/blog/BlogList";
import Image from "../../component/image";
import { ResourceUrl } from "../../config/main";
import { SIZE } from "../../constants/common";
import BlogModel from "../../models/Blog";
import "./blog.scss";

class BlogDetail extends React.Component<
  {},
  { detail: BlogModel.prototype.detailAttributes }
> {
  state = {
    detail: {},
    catalog: [],
    detailRelated: [],
  };
  componentDidMount(): void {
    this.run();
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.run();
    }
  }

  run() {
    const { slug } = this.props.match.params;
    BlogModel.blogDetail(slug).then((res) => {
      this.setState({
        detail: res.detail,
        catalog: res.catalog,
        detailRelated: res.detailRelated,
      });
    });
  }

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    const breakCum = [
      {
        breadcrumbName: "Home",
        path: "/",
      },
      {
        breadcrumbName: "Tin tức",
        path: "/blog",
      },
      {
        breadcrumbName: "catalog",
      },
      {
        breadcrumbName: this.state.detail.title,
      },
    ];
    return (
      <div className="site-blog">
        <div className="section-wrap">
          <div
            className="blog-content"
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Row gutter={{ xs: 0, lg: 16 }}>
              <Col
                xs={24}
                lg={16}
                style={{
                  background: "white",
                  padding: 0,
                }}
              >
                <div className="post-heading">
                  <PageHeader
                    breadcrumb={{
                      routes: breakCum,
                      itemRender: (route, params, routes, paths) => {
                        const last =
                          routes.indexOf(route) === routes.length - 1;
                        if (route.breadcrumbName === "catalog") {
                          if (this.state.detail.blog_category) {
                            const catalog = this.state.detail.blog_category.map(
                              (ctg, i) => {
                                return (
                                  <Link
                                    key={i}
                                    to={"/blog/catalog/" + ctg.code}
                                  >
                                    {ctg.name +
                                      (i ===
                                      this.state.detail.blog_category.length - 1
                                        ? ""
                                        : ",")}
                                  </Link>
                                );
                              }
                            );
                            return <Breadcrumb.Item>{catalog}</Breadcrumb.Item>;
                          } else {
                            return null;
                          }
                        } else {
                          return last ? (
                            <span>{route.breadcrumbName}</span>
                          ) : (
                            <Breadcrumb.Item>
                              <Link to={route.path}>
                                {route.breadcrumbName}
                              </Link>
                            </Breadcrumb.Item>
                          );
                        }
                      },
                      separator: "",
                    }}
                    title={this.state.detail.title}
                    subTitle={this.state.detail.keyword}
                    extra={
                      <Button type="link" disabled>
                        {moment(this.state.detail.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </Button>
                    }
                  >
                    <div className="hs-featured-image-wrapper">
                      <Image
                        className="img-ful img-post"
                        src={ResourceUrl + this.state.detail.cover_image}
                      />
                    </div>
                    <div className="post-body">
                      <span
                        style={{
                          marginBottom: 20,
                        }}
                      >
                        {this.state.detail.description}
                      </span>
                      <span
                        style={{
                          marginBottom: 20,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: this.state.detail.body,
                        }}
                      />
                    </div>
                  </PageHeader>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div className="right-aside">
                  <BlogCatalogComponent
                    selected={this.props.match.params.slug}
                    catalog={this.state.catalog}
                  />
                  <BlogList
                    size={SIZE.SMALL}
                    title={"Những sự kiện quan tâm nhất "}
                    data={this.state.detailRelated}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogDetail;
