import { Col, Row } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Services from "../../../../api/services";
import Image from "../../../../component/image";
import Map from "../../../../component/Map";
import MoneyFormat from "../../../../component/moneyFormat";
import Helpers from "../../../../functions/Helpers";
import averagePrice from "../../../../images/icon/average-price.svg";
import chartDown from "../../../../images/icon/chart-down.svg";
import chartUp from "../../../../images/icon/chart-up.svg";
import home from "../../../../images/icon/home.svg";
import styles from "./index.module.scss";

const PriceEstimate = ({ realEstate }) => {
  let {
    category_id,
    ward_id,
    district_id,
    province_code,
    street,
    status_number,
    lat,
    lng,
  } = realEstate;

  const [dataEstimate, setDataEstimate] = useState({});

  useEffect(() => {
    async function fetch() {
      const dataEstimate = await Services.priceEstimate({
        category_id,
        ward_id,
        district_id,
        province_code,
        street,
        status: status_number,
        type: "month",
        time: 1,
      });
      if (dataEstimate.data) {
        setDataEstimate(dataEstimate.data);
      }
    }
    fetch();
  }, [category_id, district_id, province_code, status_number, street, ward_id]);

  if (_.isEmpty(dataEstimate)) {
    return null;
  }

  const { sum, total, min, max, up, realStates } = dataEstimate;

  return (
    <div className={styles.priceEstimate}>
      <h3 className="sub-heading">Giá nhà đất 1 tháng gần đây</h3>
      <span>
        {realEstate.category}, {realEstate.street}, {realEstate.ward},{" "}
        {realEstate.district}, {realEstate.city}
      </span>
      <Map data={realStates} lat={lat} lng={lng} zoom={15} />
      <Row className={styles.estimateList}>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className={styles.estimateBox}>
          <Image src={averagePrice} />
          <div className={styles.colRight}>
            <p>Giá trung bình</p>
            <p className={styles.textTit}>
              {Helpers.moneyFormatVn(sum).slice(0, -3)}/m<sup>2</sup>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className={styles.estimateBox}>
          <Image src={home} />
          <div className={styles.colRight}>
            <p>Số lượng BĐS</p>
            <p className={styles.textTit}>{total} Bất động sản</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className={styles.estimateBox}>
          <Image src={averagePrice} />
          <div className={styles.colRight}>
            <p>Giá cho thuê</p>
            <p className={styles.textTit}>{total} tr/th</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className={styles.estimateBox}>
          <Image src={chartDown} />
          <div className={styles.colRight}>
            <p>Giá thấp nhất</p>
            <p className={styles.textTit}>
              {Helpers.moneyFormatVn(min).slice(0, -3)}/m<sup>2</sup>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8} className={styles.estimateBox}>
          <Image src={chartUp} />
          <div className={styles.colRight}>
            <p>Giá thấp nhất</p>
            <p className={styles.textTit}>
              {Helpers.moneyFormatVn(max).slice(0, -3)}/m<sup>2</sup>
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <h4 className={styles.estimateTitle}>Dự báo gia tăng giá trị</h4>
        </Col>
      </Row>
      <Row className={`${styles.estimateList} ${styles.estimateSecondList}`}>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={styles.estimateBox}>
          <Image src={averagePrice} />
          <div className={styles.colRight}>
            <p>Giá hiện tại</p>
            <p className={`${styles.textTit} ${styles.textTitRed}`}>
              {Helpers.moneyFormatVn(realEstate.price)}
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={styles.estimateBox}>
          <Image src={averagePrice} />
          <div className={styles.colRight}>
            <p>Sau 3 tháng</p>
            <p className={`${styles.textTit} ${styles.textTitRed}`}>
              <MoneyFormat>
                {parseFloat(realEstate.price) +
                  (realEstate.price * 3 * up) / 100 / 12}
              </MoneyFormat>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={styles.estimateBox}>
          <Image src={averagePrice} />
          <div className={styles.colRight}>
            <p>Sau 6 tháng</p>
            <p className={`${styles.textTit} ${styles.textTitRed}`}>
              <MoneyFormat>
                {parseFloat(realEstate.price) +
                  (realEstate.price * 6 * up) / 100 / 12}
              </MoneyFormat>
            </p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={styles.estimateBox}>
          <Image src={averagePrice} />
          <div className={styles.colRight}>
            <p>Sau 12 tháng</p>
            <p className={`${styles.textTit} ${styles.textTitRed}`}>
              <MoneyFormat>
                {parseFloat(realEstate.price) +
                  (realEstate.price * 12 * up) / 100 / 12}
              </MoneyFormat>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PriceEstimate;
