import Axios from "../axiosApi";
import {
  Acreage,
  GoodPrice,
  Hot,
  Latest,
  Limit,
  LowerAcreage,
  LowerPrice,
  Page,
  Price,
  Priority,
  LimitPriority
} from "../config/params";
import { Session } from "../functions/session";

const HomePageApi = {
  locations: function () {
    return Axios.get("/locations");
  },
  filterStatus: function () {
    return Axios.get("/filter/status");
  },
  latest: function ({ limitPage = 20, page = 1 }) {
    const params = {};
    params[Latest] = 1;
    params[Limit] = limitPage;
    params[Page] = page;
    params[Priority] = '1';
    params[LimitPriority] = '3';
    return this.getList({ params: params });
  },
  goodPrice: function (limitPage = 10, page = 1) {
    const params = {};
    params[GoodPrice] = 1;
    params[Limit] = limitPage;
    params[Page] = page;
    return this.getList({ params: params });
  },
  hot: function (limitPage = 4) {
    const params = {};
    params[Hot] = 1;
    params[Limit] = limitPage;
    params[Priority] = '1';
    params[LimitPriority] = '2';
    return this.getList({ params: params });
  },
  lowerPrice: function (limitPage = 8) {
    const params = {};
    params[LowerPrice] = 1;
    let recommendPrice = Session.getJson("recommendPriceAcreage", []);
    params[Price] =
      recommendPrice.reduce(
        (totalPrice, rcP) => totalPrice + parseFloat(rcP.price),
        0
      ) / recommendPrice.length || 0;
    params[Limit] = limitPage;
    return this.getList({ params: params });
  },
  lowerAcreage: function (limitPage = 8) {
    const params = {};
    params[LowerAcreage] = 1;
    let recommendAcreage = Session.getJson("recommendPriceAcreage", []);
    params[Acreage] =
      recommendAcreage.reduce(
        (totalAcreage, rcA) => totalAcreage + parseFloat(rcA.acreage),
        0
      ) / recommendAcreage.length || 0;
    params[Limit] = limitPage;
    return this.getList({ params: params });
  },
  getList: function (data) {
    return Axios.get("/list", { params: data.params });
  },
  projects() {
    return Axios.get("/projects");
  },
  allDataHome: (page) => {
    let request = [
      HomePageApi.latest({ page: page }),
      HomePageApi.goodPrice(),
      HomePageApi.hot(),
      HomePageApi.lowerPrice(),
      HomePageApi.lowerAcreage(),
      HomePageApi.getRealEstatePriority()
    ];
    return Promise.all(request);
  },

  getRealEstatePriority() {
    return Axios.get("/listRealEstatePriority");
  },
  getFooterContent: function () {
    return Axios.get("/footer-content");
  },
};

export default HomePageApi;
