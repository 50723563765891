import React from "react";

class ProjectApartment extends React.Component{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div></div>
        );
    }
}

export default ProjectApartment;