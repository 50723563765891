import Axios from "../axiosApi";
import axios from 'axios';
import SearchPageApi from '../api/searchPage';
import {LowerAcreage, LowerPrice, Query, Status, Type, Page} from "../config/params";

interface FilterOptions {
    lp?: number,
    la?: number,
    q?: string,
    s: number,
    t: Array,
    qSlug?: string,
    page?: number
}

class SearchModel implements FilterOptions{
    CancelToken = axios.CancelToken;
    cancel;
    fieldQueryString(query) {
        this.q = query[Query];
        this.s = query[Status] !== undefined ? query[Status] : 0;
        this.t = query[Type] !== undefined ? query[Type].split(',').map(val => {
            return parseInt(val);
        }) : [];
        this.lp = query[LowerPrice] !== undefined ? query[LowerPrice] : 0;
        this.la = query[LowerAcreage] !== undefined ? query[LowerAcreage] : 0;
        this.page = query[Page] !== undefined ? query[Page] : 1;
    }
    fetchAllQuery() {
        let queryString = {
            q: this.q,
            page: this.page
        };
        if (this.s)
            queryString.s = this.s;
        if (this.t.length > 0)
            queryString.t = this.t.join(',');
        if (this.lp)
            queryString.lp = this.lp;
        if (this.la)
            queryString.la = this.la;
        return queryString;
    }
    static setFilterOption() {
        return SearchPageApi.getFilterOption();
    }
    static filter(slug){
        return SearchPageApi.filter(slug);
    }
    storeKeyword(slug) {
        slug = slug || this.q;
        return Axios.post('/search/store-keyword/'+(slug === undefined ? '' : slug));
    }
    search() {
        let self = this;
        if (self.cancel !== undefined) {
            self.cancel('too many request');
        }
        return this.q ? Axios.get('/search/'+this.q, {
            cancelToken: new self.CancelToken(
                function executor(c) {
                    self.cancel = c;
            })
        }) : new Promise((resolve, reject) => {
            resolve(null);
        });
    }
}

export default SearchModel;