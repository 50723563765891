import React from "react";
import styles from "../BookingDetail.module.scss";
import { Typography } from "antd";
import { getBusinessType } from "../../../../../utils/BookingUtils";

const { Title } = Typography;
const OrganizationInfo = ({ booking, identifyLabel }) => {
  const {
    identifier,
    identify_type,
    identifier_issue_by,
    identifier_issue_at,
    tax_code,
    legal_name,
    legal_position,
    legal_email,
    legal_phone,
    legal_identifier,
    phone_number,
    name,
    email,
    date_of_birth,
    identifier_name,
  } = booking;
  console.log(email);
  const businessType = getBusinessType(identify_type);
  return (
    <>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Loại hình doanh nghiệp:</span>
        <span className={styles.orderInfoValue}>{businessType.label}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Mã doanh nghiệp:</span>
        <span className={styles.orderInfoValue}>{identifier}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Tên công ty:</span>
        <span className={styles.orderInfoValue}>{identifier_name}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Nơi cấp Mã số DN:</span>
        <span className={styles.orderInfoValue}>{identifier_issue_by}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Ngày cấp Mã số DN:</span>
        <span className={styles.orderInfoValue}>{identifier_issue_at}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Mã số thuế:</span>
        <span className={styles.orderInfoValue}>{tax_code}</span>
      </p>
      <Title level={4} className={styles.subHeading}>Thông tin Người đại diện Pháp luật</Title>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Người đại diện pháp luật:</span>
        <span className={styles.orderInfoValue}>{legal_name}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Chức vụ:</span>
        <span className={styles.orderInfoValue}>{legal_position}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Email:</span>
        <span className={styles.orderInfoValue}>{legal_email}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Số điện thoại:</span>
        <span className={styles.orderInfoValue}>{legal_phone}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>
          Giấy tờ chứng thực cá nhân:
        </span>
        <span className={styles.orderInfoValue}>{identifyLabel}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>
          Số giấy tờ chứng thực cá nhân:
        </span>
        <span className={styles.orderInfoValue}>{legal_identifier}</span>
      </p>
      <Title level={4} className={styles.subHeading} >Thông tin người liên hệ</Title>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Họ và tên:</span>
        <span className={styles.orderInfoValue}>{name}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Số điện thoại:</span>
        <span className={styles.orderInfoValue}>{phone_number}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Email:</span>
        <span className={styles.orderInfoValue}>{email}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Ngày sinh:</span>
        <span className={styles.orderInfoValue}>{date_of_birth}</span>
      </p>
    </>
  );
};

OrganizationInfo.propTypes = {};

export default OrganizationInfo;
