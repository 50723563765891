import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { googleMapApiKey } from "../../../../../config/main.js";
import HomeIcon from "../../../../../images/iconhome.png";
import Image from "../../../../../component/image";

const IconHome = () => <Image src={HomeIcon} className="image-map" />;
const IconUnity = ({ srcLogo }) => (
  <Image src={srcLogo} className="image-unity" />
);
const InfoWindow = ({ text, logo, showHideInfor, address }) => {
  return (
    <div className="InforWindow">
      <div className="leaflet-zoom-animated">
        <div className="leaflet-popup-content-wrapper">
          <div className="leaflet-popup-content">
            <div className="marker-popup">
              <div className="image">
                <Image src={logo} />
              </div>
              <b> {text} </b>
              <span> {address} </span>
            </div>
          </div>
        </div>
        <div className="leaflet-popup-tip-container">
          <div className="leaflet-popup-tip"></div>
        </div>
        <span className="leaflet-popup-close-button" onClick={showHideInfor}>
          x
        </span>
      </div>
    </div>
  );
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.zoom = props.zoom;
    this.state = {
      center: {
        lat: props.lat,
        lng: props.lng,
      },
      lat: props.lat,
      lng: props.lng,
    };
    this.listMarker = props.listMarker;
    this.inforUnity = props.inforUnity;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.lat !== nextProps.lat && this.props.lng !== nextProps.lng) {
      this.setState({
        center: {
          lat: nextProps.lat,
          lng: nextProps.lng,
        },
        lat: nextProps.lat,
        lng: nextProps.lng,
      });
    }
    if (
      this.props.listMarker !== nextProps.listMarker ||
      this.listMarker !== nextProps.listMarker
    ) {
      this.listMarker = nextProps.listMarker;
    }

    if (
      this.props.inforUnity !== nextProps.inforUnity ||
      this.inforUnity !== nextProps.inforUnity
    ) {
      this.inforUnity = nextProps.inforUnity;
      this.setState({
        center: {
          lat: nextProps.inforUnity.lat,
          lng: nextProps.inforUnity.lng,
        },
      });
    }

    if (this.props.zoom !== nextProps.zoom) this.zoom = nextProps.zoom;
  }

  getListUtility = () => {
    let locationUnity = [];
    if (Object.keys(this.listMarker).length > 0) {
      this.listMarker.forEach(function (point, index) {
        const locationElement = (
          <IconUnity
            key={index}
            srcLogo={point.logo}
            lat={point.lat}
            lng={point.lng}
          />
        );
        locationUnity.push(locationElement);
      });
    }
    return locationUnity.length > 0 ? locationUnity : null;
  };

  getInformationUtility = () => {
    let inforUnity = null;
    if (
      Object.keys(this.inforUnity).length > 0 &&
      this.inforUnity.show !== false
    ) {
      inforUnity = (
        <InfoWindow
          lat={this.inforUnity.lat}
          lng={this.inforUnity.lng}
          logo={this.inforUnity.logo}
          text={this.inforUnity.content}
          address={this.inforUnity.address}
          showHideInfor={this.props.showHideInfor}
        />
      );
    } else inforUnity = null;
    return inforUnity;
  };

  render() {
    return (
      <div style={{ height: "500px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapApiKey }}
          center={this.state.center}
          defaultZoom={this.zoom}
          zoom={this.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          <IconHome lat={this.state.lat} lng={this.state.lng} />
          {this.getListUtility()}
          {this.getInformationUtility()}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
