import { List, Select, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { http, MainDomain } from "../../../../config/main";
import "../../../../functions/moment_vi";
import styles from "../widget.module.scss";
import RealEstate from "./realEstate";

export type ListSize = "small" | "default" | "large";

const { Title, Text } = Typography;

class WidgetPage extends React.Component<
  {
    dataSource: Array,
    title?: string,
    page?: number,
    total?: number,
    listType?: string,
    filterChange?: func,
    size?: List,
    seeMore?: boolean,
    loading?: boolean,
    onHover?: void,
  },
  {
    t?: any,
    loading: boolean,
  }
> {
  filterChange = (page, type, limit = 10, t) => {
    this.props.filterChange(page, type, limit, t);
  };

  scrollRef = null;

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      t: null,
      loading: this.props.loading != null ? this.props.loading : false,
    };
  }

  scrollToMyRef = () =>
    this.scrollRef.current.scrollIntoView({ behavior: "smooth" });

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    // if (this.props.dataSource !== prevProps.dataSource) {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  getLink(item) {
    if (item.user_sub_domain) {
      return (
        <span className="user_name">
          <a
            href={http + item.user_sub_domain + "." + MainDomain + "/"}
            target="blank"
          >
            {item.user_name}{" "}
          </a>
          đăng{" "}
        </span>
      );
    }

    // if (item.user_name) {
    //   return (
    //     <span className="user_name">
    //       <Link to={"/broker/" + item.user_slug}>{item.user_name} </Link>
    //       đăng{" "}
    //     </span>
    //   );
    // }

    return null;
  }

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    let category = [];
    if (Object.entries(this.props.filter_options).length > 0) {
      category = this.props.filter_options.category.map((ob) => {
        return (
          <Select.Option key={ob.id} value={ob.id}>
            {ob.name}
          </Select.Option>
        );
      });
    }
    return (
      <div
        className={`${styles.frontPageSection} ${styles.newRealEstateList} custom-pagination`}
        ref={this.scrollRef}
        style={this.props.style}
      >
        {this.props.title && this.props.size !== "small" ? (
          <Title level={2} className={styles.widgetTitlePage}>
            {this.props.title}
            {_.isEmpty(this.props.subTitle) ? null : (
              <Text type="secondary" className={styles.widgetSubtitle}>
                {this.props.subTitle}
              </Text>
            )}
            {this.props.seeMore === false ? (
              ""
            ) : (
              <Text
                style={{
                  fontSize: 14,
                }}
                className={styles.widgetSelectFilter}
              >
                <Text>
                  <Select
                    disabled={this.props.loading}
                    defaultValue={0}
                    onChange={(e) => {
                      this.setState({
                        t: e,
                      });
                      this.filterChange(1, this.props.listType, null, e);
                      const page = e;
                      this.props.history.push({
                        search: "?pageLt=" + page,
                      });
                    }}
                    style={{
                      minWidth: 200,
                      fontWeight: "normal",
                    }}
                  >
                    <Select.Option key={0} value={0}>
                      Lọc theo
                    </Select.Option>
                    {category}
                  </Select>
                </Text>
              </Text>
            )}
          </Title>
        ) : null}
        <List
          loading={this.props.loading}
          header={
            this.props.title && this.props.size === "small"
              ? this.props.title
              : null
          }
          className={`${styles.listPage} ${
            this.props.title && this.props.size === "small"
              ? styles.smallWiget
              : ""
          }`}
          itemLayout="vertical"
          size={this.props.size | "default"}
          dataSource={this.props.dataSource}
          pagination={
            this.props.page
              ? {
                  onChange: (page, pageSize) => {
                    this.setState({
                      pageSize: pageSize,
                    });
                    this.filterChange(
                      page,
                      this.props.listType,
                      pageSize,
                      this.state.t
                    );
                    this.scrollToMyRef();
                  },
                  defaultPageSize: this.props.dataSource.length,
                  pageSize: this.props.dataSource.length,
                  total: this.props.total,
                  defaultCurrent: 1,
                  current: this.props.page,
                  hideOnSinglePage: true,
                }
              : null
          }
          renderItem={(item) => (
            <RealEstate
              item={item}
              onHover={this.onHover}
              size={this.props.size}
              styles={styles}
            />
          )}
        />
      </div>
    );
  }
}

WidgetPage.defaultProps = {
  seeMore: false,
  loading: false,
};

const mapStateToProps = (state) => {
  return {
    filter_options: state.filterSearch,
  };
};

export default connect(mapStateToProps, null)(withRouter(WidgetPage));
