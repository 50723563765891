import * as actionTypes from '../actions/actionTypes';
const initialState = {};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SAVE_DOMAIN: 
            return {
                ...state,
                domain: action.domain
            };
        default: return state;
    }
};

export default reducer;
