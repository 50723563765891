import { Button, Col, Form, Row, Typography } from "antd";
import React, { PureComponent } from "react";
import OtpInput from "react-otp-input";
import styles from "../../Auth.module.scss";
import PropTypes from "prop-types";

const { Title, Text } = Typography;
export class OtpVerifyForm extends PureComponent {
  render() {
    const { form, isLoading, onOtpVerify, onResendOtpCode } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={styles.signInFormContainer}>
        <Row>
          <Col>
            <Title level={3} style={{ color: "black", fontWeight: 700 }}>
              Xác nhận OTP
            </Title>
          </Col>
          <Col>
            <Text style={{ color: "black" }}>
              Vui lòng nhập mã OTP được gửi đến số điện thoại đã đăng ký
            </Text>
          </Col>
          <Form onSubmit={onOtpVerify}>
            <Form.Item className={styles.formItemOtp}>
              {getFieldDecorator("otp", {
                rules: [
                  {
                    required: true,
                    message: "Trường này không được để trống!",
                  },
                ],
              })(
                <OtpInput
                  numInputs={6}
                  isInputNum
                  containerStyle={{
                    justifyContent: "center",
                    marginTop: "8px",
                  }}
                  focusStyle={{
                    outline: "#BF8100 auto 1px",
                  }}
                  inputStyle={styles.otpInput}
                />
              )}
            </Form.Item>
            <Row className={styles.rowCreateAccount}>
              <Text>Không nhận được OTP?</Text>
              <Text onClick={onResendOtpCode}>Gửi lại</Text>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: "2rem" }}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                  onClick={onOtpVerify.bind(this)}
                >
                  Xác nhận
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
    );
  }
}
OtpVerifyForm.defaultProps = {
  form: null,
  onOtpVerify: () => {},
  onResendOtpCode: () => {},
};
OtpVerifyForm.propTypes = {
  form: PropTypes.object,
  onOtpVerify: PropTypes.func,
  onResendOtpCode: PropTypes.func,
};

export default OtpVerifyForm;
