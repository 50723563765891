import { Col, Form, message, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loginSignUpApi from "../../../api/loginSignUp";
import User from "../../../models/User";
import { saveProfile } from "../../../store/actions/profile";
import { redirectToPostRealEstate } from "../../../store/actions/redirectToPostRealEstate";
// Components
import styles from "../Auth.module.scss";
import { OtpVerifyForm, SignUpForm } from "./components";

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingOpt: false,
      formErrorMessage: "",
      formSuccessMessage: "",
      userInfo: null,
    };
  }

  componentWillUnmount() {
    this.setState({
      isLoading: false,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, user) => {
      if (!err) {
        this.setState({ isLoading: true, formErrorMessage: "" });
        loginSignUpApi.register(user).then((res) => {
          this.setState({
            isLoading: false,
          });
          if (res.status) {
            this.setState({
              userInfo: { ...res.infor },
            });
          } else {
            let errors = {};
            Object.keys(res.error).forEach((key) => {
              errors[key] = {
                value: this.props.form.getFieldValue(key),
                errors: res.error[key].map((mes) => new Error(mes)),
              };
            });
            this.props.form.setFields(errors);
          }
        });
      }
    });
  };
  handleRedirectToSignIn = () => {
    this.props.history.push("/sign-in");
  };
  handleOtpVerify = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, otp) => {
      if (!err) {
        this.setState({
          isLoadingOpt: true,
        });
        const params = {
          otp,
          user: this.state.userInfo,
        };
        loginSignUpApi.active(params).then((res) => {
          this.setState({
            isLoadingOpt: false,
          });
          if (res.status) {
            User.saveToken(res.token);
            this.props.saveProfile(res.user);
            message.success("Kích hoạt tài khoản thành công.");
            if (this.props.redirect) {
              this.props.redirectToPostRealEstate(false);
              this.props.history.push("/create-real-estate");
            } else {
              this.props.history.push("/user/my-profile");
            }
          } else {
            this.props.form.setFields({
              otp: {
                value: this.props.form.getFieldValue("otp"),
                errors: res.error.otp.map((mes) => new Error(mes)),
              },
            });
          }
        });
      }
    });
  };
  handleResendOtpCode = () => {
    loginSignUpApi
      .resendActive({
        phone: this.state.userInfo.phone,
      })
      .then((res) => {
        // this.setState({
        //   time: 120,
        // });
        if (res.status) {
          message.success("Gửi lại mã xác nhận thành công");
        } else {
          message.error(res.message);
        }
      });
  };
  render() {
    return (
      <div className={styles.wrapperSignIn}>
        <div className="section-wrap">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={14} xl={10}>
              {this.state?.userInfo ? (
                <OtpVerifyForm
                  form={this.props.form}
                  onOtpVerify={this.handleOtpVerify}
                  onResendOtpCode={this.handleResendOtpCode}
                />
              ) : (
                <SignUpForm
                  form={this.props.form}
                  onSubmit={this.handleSubmit}
                  isLoading={this.state.isLoading}
                  formErrorMessage={this.state.formErrorMessage}
                  onRedirectToSignIn={this.handleRedirectToSignIn}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (infor) => dispatch(saveProfile(infor)),
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
  };
};

const mapStateToProps = (state) => {
  return {
    redirect: state.redirectToPostRealEstate,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(SignUp)));
