import React, { Component } from 'react';
import { Col,  Row, Select, Input } from 'antd';


class selectRange extends Component {

  onChangeInput = (event) => {
      this.props.onChange(event.target.value);
  }

  onChange = (value) => {
    const data = this.props.data,
           dataSelect = data.filter(e => e.id === parseInt(value, 0));
    if(dataSelect.length > 0)
        this.props.onChange(dataSelect[0].loan_rate);
    else {
        this.props.onChange(this.props.optionDefault[0]);
    }
  }

  getOptionData(data) {
    let options = [];
    if(typeof data !== 'undefined') {
      data.forEach((item, i) => {
        options.push(
          <Select.Option key={ item.id }
                  value={ item.id }>
              { item.name }
          </Select.Option>
        )
      });
    }
    return options;
  }

  render() {
      return (
        <Row>
          <Col span={24} style={{
            marginBottom: '15px'
          }}>
            <label>
             Lãi suất %/năm
            </label>
          </Col>
          <Col span={17}>
              <Select defaultValue="0"
                      onChange={ this.onChange }
                      style={{
                width: '80%'
              }}>
                <Select.Option value="0">Mặc định</Select.Option>
                { this.getOptionData(this.props.data) }
              </Select>
          </Col>
          <Col span={7}>
            <Input suffix="%"
                   onChange={ this.onChangeInput }
                   min={ 1 }
                   value={ this.props.value }
                   type="number"/>
          </Col>
          </Row>
      );
   }
}

export default selectRange;
