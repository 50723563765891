import React from "react";
import area from "../images/icon/realEstateSpec/area.svg";
import bill from "../images/icon/realEstateSpec/bill.svg";
import deadline from "../images/icon/realEstateSpec/deadline.svg";
import height from "../images/icon/realEstateSpec/long.svg";
import width from "../images/icon/realEstateSpec/width.svg";
import road from "../images/icon/realEstateSpec/road.svg";
import house1 from "../images/icon/realEstateSpec/house1.svg";
import house2 from "../images/icon/realEstateSpec/house2.svg";
import house3 from "../images/icon/realEstateSpec/house3.svg";
import building from "../images/icon/realEstateSpec/building.svg";
import tax from "../images/icon/realEstateSpec/tax.svg";
import people from "../images/icon/realEstateSpec/people.svg";
import currentStatus from "../images/icon/realEstateSpec/current-status.svg";

export const PROJECT_CART_STATUS_RECEIVE_BOOKING_VALUE = 1;
export const PROJECT_CART_STATUS_SELLING_VALUE = 2;
export const PROJECT_CART_STATUS_SOLD_VALUE = 3;

export const PROJECT_CART_STATUSES = [
  {
    label: "Nhận booking",
    value: PROJECT_CART_STATUS_RECEIVE_BOOKING_VALUE,
    heading: "Dự án nhận booking",
    subHeading: "Những dự án vừa mở booking đặt chỗ",
  },
  {
    label: "Đang mở bán",
    value: PROJECT_CART_STATUS_SELLING_VALUE,
    heading: "Dự án đang mở bán",
  },
  {
    label: "Đã bán xong",
    value: PROJECT_CART_STATUS_SOLD_VALUE,
    heading: "Dự án đã bàn giao",
  },
];

export const PACKAGES = [
  {
    value: 1,
    label: "Vip 1",
  },
];
export const SPEC_AREA_CODE = "dien-tich";
export const SPEC_HEIGHT_CODE = "chieu-dai";
export const SPEC_WIDTH_CODE = "chieu-rong";
export const SPEC_HOUSE_1_CODE = "khoang-lui-truoc";
export const SPEC_HOUSE_2_CODE = "khoang-lui-sau";
export const SPEC_HOUSE_3_CODE = "khoang-lui-ben-hong";
export const SPEC_ROAD_1_CODE = "duong-truoc-nha-rong";
export const SPEC_ROAD_2_CODE = "duong-mo-rong";
export const SPEC_CURRENT_STATUS_CODE = "hien-trang";
export const SPEC_BILL_CODE = "hien-trang";
export const SPEC_DEAD_LINE_CODE = "nam-cap-so";
export const SPEC_BILDING_CODE = "so-tang-duoc-xay";
export const SPEC_TAX_CODE = "no-thue";
export const SPEC_PEOPLE_CODE = "muc-do-dan-cu";
export const SPEC_ZONING_CODE = "quy-hoach";
export const SPEC_ROAD_3_CODE = "duong-vao-rong";
export const SPEC_VIEW_CODE = "view";
export const SPEC_MAIN_DOOR_CODE = "huong-cua-chinh";
export const SPEC_FLOOR_NUMBER_CODE = "so-tang";
export const SPEC_BIZ_FIELD_CODE = "dang-kinh-doanh-linh-vuc";
export const SPEC_ROOM_NUMBER_CODE = "so-phong";
export const SPEC_BIZ_YEAR_NUMBER_CODE = "so-nam-da-kinh-doanh";
export const SPEC_REPUTATION_RATING_CODE = "xep-hang-uy-tin";
export const SPEC_ACTIVE_STATUS_CODE = "xep-hang-uy-tin";
export const SPEC_FLOOR_2_CODE = "tang-may";
export const SPEC_BED_ROOM_NUMBER_CODE = "so-luong-phong-ngu";
export const SPEC_WC_NUMBER_CODE = "so-luong-nha-ve-sinh";
export const SPEC_BALCONY_CODE = "ban-cong";
export const SPEC_LOW_BUILDING_CODE = "thuoc-toa-thap";
export const SPEC_VIEW_SIDE_CODE = "huong-view";
export const SPEC_TRANSFER_YEAR_CODE = "nam-ban-giao";
export const SPEC_YEAR_WITH_PINK_BOOK_CODE = "nam-co-so-hong";
export const SPEC_MAINTENANCE_FEE_CODE = "phi-bao-tri";
export const SPEC_BOOK_OUT_FEE_CODE = "phi-ra-so";
export const SPEC_MANAGEMENT_CODE = "don-vi-quan-ly";
export const SPEC_LOAN_BANK_CODE = "ngan-hang-cho-vay";
export const SPEC_ZONING_HEIGHT_CODE = "quy-hoach-chieu-cao";
export const SPEC_CONSTRUCTION_CODE = "don-vi-thi-cong";
export const SPEC_INVESTOR_CODE = "chu-dau-tu";
export const SPEC_MANAGER_FEE_CODE = "phi-quan-ly";
export const SPEC_OFFICE_AREA_CODE = "khu-van-phong";
export const SPEC_ELECTRIC_CODE = "dien-ba-pha";
export const SPEC_WORKSHOP_HEIGHT_CODE = "chieu-cao-xuong";
export const SPEC_FIRE_PROTECTION_CODE = "phong-chay-chua-chay";
export const SPEC_HEIGHT_WATER_CODE = "chieu-cao-thong-thuy";
export const SPEC_YEARS_OF_OWNERSHIP_CODE = "so-nam-so-huu";
export const SPEC_FACADE_NUMBER_CODE = "so-mat-tien";
export const SPEC_APARTMENT_NUMBER_CODE = "so-can-ho";
export const SPEC_NUMBER_OF_HIGH_FLOORS_CODE = "so-tang-cao";
export const SPEC_ROAD_WIDE_HEART_CODE = "so-tang-cao"; // the road is wide in the heart
export const SPEC_WIDE_CURB_CODE = "le-duong-rong";
export const SPEC_NUMBER_CAR_PARKING_SPACES_CODE = "so-cho-dau-xe-oto";
export const SPEC_ESTIMATED_POPULATION_CODE = "so-dan-du-kien";
export const SPEC_SECURITY_ROOM_CODE = "phong-bao-ve";
export const SPEC_FRONT_HOME = "duong-truoc-nha";

export const SPECS = [
  {
    code: "dien-tich",
    icon: area,
    unit: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  {
    code: "chieu-dai",
    icon: height,
    unit: <span>m</span>,
  },
  {
    code: "chieu-rong",
    icon: width,
    unit: <span>m</span>,
  },
  {
    code: "khoang-lui-truoc",
    icon: house1,
    unit: <span>m</span>,
  },
  {
    code: "khoang-lui-sau",
    icon: house2,
    unit: <span>m</span>,
  },
  {
    code: "khoang-lui-ben-hong",
    icon: house3,
    unit: <span>m</span>,
  },
  {
    code: "duong-truoc-nha-rong",
    icon: road,
    unit: <span>m</span>,
  },
  {
    code: "duong-mo-rong",
    icon: road,
    unit: <span>m</span>,
  },
  {
    code: "hien-trang",
    icon: currentStatus,
  },
  {
    code: "quy-hoach",
    icon: bill,
  },
  {
    code: "nam-cap-so",
    icon: deadline,
  },
  {
    code: "so-tang-duoc-xay",
    icon: building,
  },
  {
    code: "no-thue",
    icon: tax,
  },
  {
    code: "muc-do-dan-cu",
    icon: people,
  },
];
