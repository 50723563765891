import { Col, Icon, List, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../image";
import styles from "./ProjectWidget.module.scss";
import { ImageMediumWithWatermark } from "../../../config/main";
import { EnvironmentOutlined } from "@ant-design/icons";
import Helpers from "../../../functions/Helpers";
import moment from "moment";

const ProjectWidget = (props) => {
  const { header, isLoading } = props;
  return (
    <List
      loading={isLoading}
      className={styles.projectWidget}
      header={header}
      dataSource={props?.data}
      renderItem={(item) => (
        <List.Item>
          <Row type="flex" className={styles.projectItem}>
            <Col span={7} className={styles.itemLeft}>
              <Image
                className="img-full"
                src={ImageMediumWithWatermark + item.cover_image}
              />
            </Col>
            <Col span={17} className={styles.itemRight}>
              <Link className={styles.title} to={"/event/" + item.slug}>
                {item.title}
              </Link>
              <p className={styles.acreage}>
                {item.acreage} m <sup>2</sup>
              </p>
              <p>
                <EnvironmentOutlined className={styles.icon} />
                <span>
                  {Helpers.getString(item.address) +
                    Helpers.getString(item.district) +
                    Helpers.getString(item.city, true)}
                </span>
              </p>
              <p className="date">
                <Icon type="clock-circle" className={styles.icon} />
                <span className={styles.broker}>{Helpers.getLinkBroker(item)}</span><span>đăng{" "}</span>
                {moment(item.created_at).fromNow()}
              </p>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

ProjectWidget.propTypes = {};

export default ProjectWidget;
