import React from "react";
import {Affix, Button, Drawer} from "antd";
import OptionsMobile from "./options/optionsMobile";
import styles from "./filter.module.scss";
import MapCustom from "./map";

class FilterMobile extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            filterVisible: false,
            mapVisible: false
        };
    }

    onCloseFilter = () => {
        this.setState({
            filterVisible: false
        });
    };

    onCloseMap = () => {
        this.setState({
            mapVisible: false
        });
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Affix offsetTop={70} style={{
                textAlign: 'center',
                position: 'fixed',
                top: 70,
                zIndex: 99,
                width: "100%"
            }}>
                <Button.Group>
                    <Button onClick={() => this.setState({filterVisible: true})} shape="round" size="small" icon="filter">
                        Bộ lọc
                    </Button>
                    <Drawer
                        placement="top"
                        height="100%"
                        visible={this.state.filterVisible}
                        onClose={this.onCloseFilter}
                        title="Bộ lọc"
                    >
                        <OptionsMobile close={this.onCloseFilter} />
                    </Drawer>
                    <Button onClick={() => this.setState({mapVisible: true})} shape="round" size="small" icon="global">
                        Bản đồ
                    </Button>
                    <Drawer
                        placement="top"
                        height="100%"
                        visible={this.state.mapVisible}
                        onClose={this.onCloseMap}
                        className={styles.mapMobile}
                    >
                        <MapCustom/>
                    </Drawer>
                </Button.Group>
            </Affix>
        );
    }
}

export default FilterMobile;