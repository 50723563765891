import React from "react";
import {Button, Form, Input, Modal, notification} from "antd";
import Services from "../../api/services";

const MessageModal = ({visible, close, dataSubmit, slug, ...props}) => {

    const { getFieldDecorator } = props.form;

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, fieldsValue) => {
            if (err) {
                return;
            }
            let res = await Services.saveContact(slug, {...fieldsValue, ...dataSubmit});
            if (res.status) {
                notification.success({
                    message: 'Success message',
                    description: 'Gửi tin nhắn cho môi giới thành công.'
                });
                props.form.resetFields();
                close();
            }else {
                notification.error({
                    message: 'Error message',
                    description: res.message
                });
            }
        });
    }

    return (
        <Modal
            title="Liên hệ môi giới"
            visible={visible}
            onCancel={close}
            footer={false}
        >
            <Form id="sentMessage" onSubmit={handleSubmit}>
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true
                            }
                        ]
                    })(<Input placeholder="Họ & Tên"/>)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('phone', {
                        rules: [
                            {
                                required: true
                            },
                            {
                                max: 10,
                                message: 'Số điện thoại tối đa 10 số.'
                            },
                            {
                                pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/g,
                                message: 'Số điện thoại không hợp lệ'
                            }
                        ]
                    })(<Input placeholder="Số điện thoại"/>)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                required: true
                            },
                            {
                                type: 'email',
                                message: 'Email không hợp lệ.'
                            }
                        ]
                    })(<Input placeholder="Email"/>)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('body', {
                        rules: [
                            {
                                required: true,
                                message: 'Nội dung không được rỗng.'
                            }
                        ]
                    })(<Input.TextArea placeholder="Nội dung" rows={5}/>)}
                </Form.Item>
                <Form.Item>
                    <Button style={{marginRight: 10}} key="submit" htmlType="submit" form="sentMessage" type="primary">
                        Gửi
                    </Button>
                    <Button key="back" onClick={close}>
                        Bỏ qua
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}



export default Form.create({ name: 'sent-message' })(MessageModal);
