import Axios from "../axiosApi";
import { Limit, Page } from "../config/params";

const CreateUpdateArticleApi = {
  getAll: () => {
    return Axios.get("/article/all");
  },
  addNew: data => {
    return Axios.post("/article/addNew", data);
  },
  change: data => {
    return Axios.post("/article/change", data);
  },
  remove: id => {
    return Axios.delete(`/article/${id}/remove`);
  },
  getMyArticles: (limitPage = 20, page = 1) => {
    const params = {};
    params[Limit] = limitPage;
    params[Page] = page;
    return Axios.get("/article/my", { params: params });
  },
  getDetail: slug => {
    return Axios.get(`/article/detail/${slug}`);
  }
};

export default CreateUpdateArticleApi;
