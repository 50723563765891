import React from "react";
import { Tabs } from "antd";
import "./index.scss";
import CustomerList from "../customer-list";

const { TabPane } = Tabs;

class CustomerTable extends React.Component {
  render() {
    return (
      <div className="table-history">
        <Tabs
          defaultActiveKey="1"
        >
          <TabPane tab="Danh sách khách hàng" key="1">
            <CustomerList />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default CustomerTable;
