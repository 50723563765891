import {LocalStorage} from "node-localstorage";

let ls = typeof localStorage === "undefined" ? new LocalStorage('./scratch') : localStorage;

export const Session = {
    put: (key: string, val: any = null) => {
        ls.setItem(key, val);
    },
    putJson: (key: string, val: Array | Object = null) => {
        ls.setItem(key, JSON.stringify(val));
    },
    get: (key: string, defaultVal: any = null) => {
        return ls.getItem(key) || defaultVal;
    },
    getJson: (key: string, defaultVal: any = null) => {
        return JSON.parse(ls.getItem(key)) || defaultVal;
    },
    delete: (key: string) => {
        ls.removeItem(key);
    },
    deleteAll: () => {
        ls.clear();
    },
    has: (key: string) => {
        return ls.getItem(key) !== null;
    }
};