import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
import styles from "../Cart.module.scss";
import { IDENTIFY_TYPES, TITLES } from "../constants";

const { Option } = Select;

const PersonalForm = ({
  getFieldDecorator,
  onChangeCheckBoxPersonal,
  onValidatePhoneNumber,
  isDisabled,
  setFieldsValue,
}) => {
  return (
    <>
      <Checkbox onChange={onChangeCheckBoxPersonal}>
        Giống thông tin tài khoản của tôi
      </Checkbox>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Giấy tờ chứng thực cá nhân">
            {getFieldDecorator("personal.identify_type", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Select placeholder="Chọn..." disabled={isDisabled}>
                {IDENTIFY_TYPES.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.lable}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Số giấy tờ chứng thực cá nhân">
            {getFieldDecorator("personal.identifier", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Số giấy tờ chứng thực cá nhân"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Danh xưng">
            {getFieldDecorator("personal.title", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Select placeholder="Chọn..." disabled={isDisabled}>
                {TITLES.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.lable}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Họ và tên">
            {getFieldDecorator("personal.name", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Họ và tên"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Số điện thoại">
            {getFieldDecorator("personal.phone_number", {
              rules: [
                {
                  required: true,
                  message: "Thông tin này không thể bỏ trống",
                },
                {
                  validator: onValidatePhoneNumber,
                },
              ],
            })(
              <Input
                placeholder="Số điện thoại"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Email">
            {getFieldDecorator(
              "personal.email",
              {}
            )(
              <Input
                placeholder="Email"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Nơi cấp">
            {getFieldDecorator("personal.identifier_issue_by", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Nơi cấp"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Ngày cấp">
            {getFieldDecorator("personal.identifier_issue_at", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <DatePicker
                className={styles.datePicker}
                disabled={isDisabled}
                placeholder="Chọn ngày"
                format="DD/MM/YYYY"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Ngày sinh">
            {getFieldDecorator("personal.date_of_birth", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <DatePicker
                className={styles.datePicker}
                disabled={isDisabled}
                placeholder="Chọn ngày"
                format="DD/MM/YYYY"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PersonalForm;
