import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import styles from "../profileForm/profileForm.module.scss";

import PostRealEstatePageApi from "../../api/postRealEstatePage";
import ProfileApi from "../../api/profileApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    profile: state.profile.infor
  };
};

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      cities: [],
      isSubmit: false
    };
  }

  componentDidMount = () => {
    PostRealEstatePageApi.getAllCities().then(res => {
      if (res.data.status === 1) {
        this.setState({
          cities: res.data.cities
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("new_password")) {
      callback("Nhập lại mật khẩu mới không trùng khớp");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["c_new_password"], { force: true });
    }
    callback();
  };

  handleChangeProfile = e => {
    e.preventDefault();
    this.setState({
      isSubmit: true
    })
    this.props.form.validateFields((err, user) => {
      if (!err) {
        user = {
          ...user,
          phone: this.props.profile.phone
        }
        ProfileApi.changePass(user).then(res => {
          if (res.data.status) {
            this.props.form.resetFields([
              "password",
              "new_password",
              "c_new_password"
            ]);
            Swal.fire({
              icon: "success",
              title: "Cập nhật mật khẩu thành công",
              showConfirmButton: true
            });
            this.setState({
              isSubmit: false
            })
          }
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Cập nhật mật khẩu thất bại",
          text: "Vui lòng nhập các thông tin cần thiết",
          showConfirmButton: true,
        });
        this.setState({
          isSubmit: false,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Form onSubmit={this.handleChangeProfile.bind(this)}>
          <Row>
            <Form.Item
              label="Mật khẩu hiện tại"
              hasFeedback
              className={styles.rowInput}
            >
              {getFieldDecorator("password", {
                rules: [
                  {
                    validator: this.validateToNextPassword
                  },
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu hiện tại!"
                  }
                ]
              })(<Input.Password />)}
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col xs={24} span={12}>
              <Form.Item
                label="Mật khẩu mới"
                hasFeedback
                className={styles.rowInput}
              >
                {getFieldDecorator("new_password", {
                  rules: [
                    {
                      validator: this.validateToNextPassword
                    },
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu mới!"
                    }
                  ]
                })(<Input.Password />)}
              </Form.Item>
            </Col>
            <Col xs={24} span={12}>
              <Form.Item
                label="Xác nhận mật khẩu mới"
                hasFeedback
                className={styles.rowInput}
              >
                {getFieldDecorator("c_new_password", {
                  rules: [
                    {
                      validator: this.compareToFirstPassword
                    },
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu mới xác nhận!"
                    }
                  ]
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Form.Item>
              <Col span={12} offset={6} style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.isSubmit ? true : false}
                  onClick={this.handleChangeProfile.bind(this)}
                >
                  Cập nhật mật khẩu
                </Button>
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(Form.create()(ProfileForm));
