import { Avatar, Button, Col, Icon, Row, Typography, Upload } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { uploadUrl } from "../../../../config/main";
import DefaultAvatar from "../../../../images/avatar-default.png";
import { getBase64 } from "../../../../utils";
import { useAvatar } from "../../hooks";
import styles from "../MyProfile.module.scss";

const { Title } = Typography;

const UserInfoPanel = ({ fullName, phone, email, onUpdateAvatar, avatar }) => {
  const { avatarUrl, handleImageLoadError, handleChangeAvatar } = useAvatar();

  const handleChange = (info) => {
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrlWallPicture) =>
        handleChangeAvatar(imageUrlWallPicture)
      );
      onUpdateAvatar(info.file);
    }
  };
  return (
    <>
      <Title style={{ color: "black" }} level={3}>
        Thông tin của tôi
      </Title>
      <Row className={styles.myProfileInfo}>
        <Col xs={24} sm={8} md={12} lg={12} xl={8} style={{ height: "100%" }}>
          <Row type="flex" align="middle" style={{ height: "100%" }}>
            <Col span={12}>
              <div className={styles.avatar}>
                <Avatar
                  src={avatarUrl || DefaultAvatar}
                  onError={handleImageLoadError}
                />
              </div>
            </Col>
            <Col span={12}>
              <div>
                <h4>Ảnh đại diện</h4>
                <Upload name="logo" action={uploadUrl} onChange={handleChange}>
                  <Button>
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={16}
          className={styles.profileContent}
        >
          <Title style={{ color: "black" }} level={4}>
            {fullName}
          </Title>
          <p>
            <Icon
              style={{
                color: "#BF8100",
                marginRight: 8,
                fontSize: 16,
              }}
              type="phone"
            />
            <span style={{ color: "black", fontSize: 16 }}>{phone}</span>
          </p>
          <p>
            <Icon
              style={{
                color: "#BF8100",
                marginRight: 8,
                fontSize: 16,
              }}
              type="mail"
            />
            <span style={{ color: "black", fontSize: 16 }}>{email}</span>
          </p>
        </Col>
      </Row>
    </>
  );
};

UserInfoPanel.defaultProps = {
  fullName: "",
  phone: "",
  email: "",
  onUpdateAvatar: () => {},
};

UserInfoPanel.prototype = {
  fullName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  onUpdateAvatar: PropTypes.func,
};

export default UserInfoPanel;
