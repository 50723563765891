import React from "react";
import { Col, Form, InputNumber, Row, Radio, Select } from "antd";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import PostRealEstatePageApi from "../../../api/postRealEstatePage";
import { getRealEstateDetail } from "../../../utils/realEstateUtils";

const { Option } = Select;
class Cafe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: [],
    };
  }

  componentDidMount = () => {
    if (this.props.real_estate) {
      this.props.form.setFieldsValue(getRealEstateDetail(this.props.real_estate.details));
    }

    PostRealEstatePageApi.getAllDirections().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          directions: res.data.directions,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Diện tích (m2)">
              {getFieldDecorator("acreage", {
                rules: [
                  { required: true, message: "Vui lòng nhập diện tích!" },
                ],
              })(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Chiều rộng (m)">
              {getFieldDecorator("width")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chiều dài (m)">
              {getFieldDecorator("height")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Đường vào rộng (m)">
              {getFieldDecorator("duong_vao_rong")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Cửa chính hướng">
              {getFieldDecorator("cua_chinh_huong", {
                initialValue:
                  !!this.state.directions.length &&
                  this.state.directions[0].code,
              })(
                <Select>
                  {!!this.state.directions.length &&
                    this.state.directions.map((item, index) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Số tầng">
              {getFieldDecorator("so_tang")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Số phòng">
              {getFieldDecorator("so_phong")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Số năm đã kinh doanh">
              {getFieldDecorator("so_nam_da_kinh_doanh")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Tình trạng">
              {getFieldDecorator("tinh_trang_hoat_dong", {
                initialValue: 1,
              })(
                <Radio.Group>
                  <Radio value={0}>Hoạt động luôn</Radio>
                  <Radio value={1}>Cần tu sửa</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Xếp hạng uy tín">
              {getFieldDecorator("xep_hang_uy_tin", {
                initialValue: 5,
              })(
                <Radio.Group>
                  <Radio value={5}>5 sao</Radio>
                  <Radio value={4}>4 sao</Radio>
                  <Radio value={3}>3 sao</Radio>
                  <Radio value={2}>2 sao</Radio>
                  <Radio value={1}>1 sao</Radio>
                  <Radio value={0}>Không sao</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Cafe;
