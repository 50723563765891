import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ProfileApi from "../../../../api/profileApi";
import styles from "../MyProfile.module.scss";

const ChangePasswordForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { form, profile } = props;
  const { getFieldDecorator, getFieldValue, validateFields } = form;
  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue("new_password")) {
      callback("Mật khẩu xác nhận không khớp với mật khẩu mới");
    } else {
      callback();
    }
  };
  const handleSubmitChangePassword = (event) => {
    event.preventDefault();
    validateFields((err, user) => {
      if (!err) {
        setIsLoading(true);
        user = {
          ...user,
          phone: profile.phone,
        };
        ProfileApi.changePass(user)
          .then((res) => {
            if (res.data.status) {
              this.props.form.resetFields([
                "password",
                "new_password",
                "c_new_password",
              ]);
              Swal.fire({
                icon: "success",
                title: "Cập nhật mật khẩu thành công",
                showConfirmButton: true,
              });
              setIsLoading(false);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Cập nhật mật khẩu thất bại",
              text: "Xin lỗi! Hệ thống đang bận vui lòng quay lại sau",
              showConfirmButton: true,
            });
            setIsLoading(false);
          });
      }
    });
  };
  return (
    <Form
      onSubmit={handleSubmitChangePassword.bind(this)}
      className={styles.formContainer}
    >
      <Form.Item label="Mật khẩu hiện tại">
        {getFieldDecorator("password", {
          rules: [
            {
              required: true,
              message: "Vui lòng nhập mật hiện tại",
            },
          ],
        })(
          <Input className={styles.inputItem} placeholder="Mật khẩu hiện tại" />
        )}
      </Form.Item>
      <Form.Item label="Mật khẩu mới">
        {getFieldDecorator("new_password", {
          rules: [
            {
              required: true,
              message: "Vui lòng nhập mật khẩu mới!",
            },
          ],
        })(
          <Input
            className={styles.inputItem}
            placeholder="Xác nhận mật khẩu mới"
          />
        )}
      </Form.Item>
      <Form.Item label="Xác nhận mật khẩu mới">
        {getFieldDecorator("c_new_password", {
          rules: [
            {
              validator: compareToFirstPassword,
            },
            {
              required: true,
              message: "Vui lòng nhập mật khẩu mới xác nhận!",
            },
          ],
        })(
          <Input
            className={styles.inputItem}
            placeholder="Xác nhận mật khẩu mới"
          />
        )}
      </Form.Item>
      <br />
      <Row className={styles.formBottom}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            Cập nhật
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
    redirect: state.redirectToPostRealEstate,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(ChangePasswordForm)));
