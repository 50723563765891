import React from 'react';
import { Row, Col, Descriptions } from 'antd';
import Image from '../../image';
import Helpers from '../../../functions/Helpers';
import {ResourceUrl} from "../../../config/main";

const ItemArticle = (props) => {
  return (
    <Row className="data-item"
         key={ props.index }>
             <Col xs={ 24 } sm={ 10 } md={ 10 } lg={ 10 } xl={ 10 } className="image" >
                 <a href={ props.link }>
                     <Image src={ ResourceUrl  + props.cover_image  }   />
                 </a>
             </Col>
             <Col xs={ 24 } sm={ 14 } md={ 14 } lg={ 14 } xl={ 14 } className="description" >
                 <Descriptions.Item>
                     <span className="title-item">
                         <a href={ props.link }>
                             { props.title }
                         </a>
                     </span><br/>
                     <span className="date">
                     Ngày { Helpers.formatDate(props.created_at) } bởi { props.user_name }
                     </span>
                 </Descriptions.Item>
             </Col>
   </Row>
 );
}

export default ItemArticle;
