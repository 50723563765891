import { Button, Carousel, Col, Icon, Row } from "antd";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  AMOUNT_ITEM_BREACK_POINT_LG,
  AMOUNT_ITEM_BREACK_POINT_MD,
  AMOUNT_ITEM_BREACK_POINT_XL,
  AMOUNT_ITEM_BREACK_POINT_XS,
  SIZES
} from "../constants";
import styles from "../OnlinePrices.module.scss";

const BuildingList = ({
  buildingList,
  selectedBuildId,
  onSelectBuildingId,
}) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: SIZES.xl + 1,
        settings: {
          slidesToShow: AMOUNT_ITEM_BREACK_POINT_XL,
          slidesToScroll: AMOUNT_ITEM_BREACK_POINT_XL,
        },
      },
      {
        breakpoint: SIZES.lg + 1,
        settings: {
          slidesToShow: AMOUNT_ITEM_BREACK_POINT_LG,
          slidesToScroll: AMOUNT_ITEM_BREACK_POINT_LG,
        },
      },
      {
        breakpoint: SIZES.md + 1,
        settings: {
          slidesToShow: AMOUNT_ITEM_BREACK_POINT_MD,
          slidesToScroll: AMOUNT_ITEM_BREACK_POINT_MD,
        },
      },
      {
        breakpoint: SIZES.xs + 1,
        settings: {
          slidesToShow: AMOUNT_ITEM_BREACK_POINT_XS,
          slidesToScroll: AMOUNT_ITEM_BREACK_POINT_XS,
        },
      },
    ],
  };
  const slider = useRef(null);
  const handleClickNext = () => {
    slider.current.next();
  };
  const handleClickPrev = () => {
    slider.current.prev();
  };

  const [width, setWidth] = useState(window.innerWidth);

  const detectSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [width]);
  const isShowButton = () => {
    if (width >= SIZES.xl)
      return buildingList?.length > AMOUNT_ITEM_BREACK_POINT_XL;
    if (width >= SIZES.lg)
      return buildingList?.length > AMOUNT_ITEM_BREACK_POINT_LG;
    if (width >= SIZES.md)
      return buildingList?.length > AMOUNT_ITEM_BREACK_POINT_MD;
    if (width <= SIZES.xs)
      return buildingList?.length > AMOUNT_ITEM_BREACK_POINT_XS;
  };

  return (
    <Row type="flex" style={{ height: "100%" }}>
      <Col xs={5} sm={5} md={3} lg={2} xl={2}>
        <span>Phân khu</span>
      </Col>
      {isShowButton() && (
        <Col span={2}>
          <Button onClick={handleClickPrev} className={styles.buttonSlider}>
            <Icon type="left" />
          </Button>
        </Col>
      )}
      <Col
        xs={15}
        sm={15}
        md={17}
        lg={isShowButton() ? 18 : 22}
        xl={isShowButton() ? 18 : 22}
      >
        <Carousel {...settings} ref={slider} className={styles.slider}>
          {buildingList?.map((building) => (
            <div
              key={building.SAPID}
              className={`${styles.building} ${
                selectedBuildId === building.SAPID ? styles.buildingActive : ""
              }`}
              onClick={() => onSelectBuildingId(building)}
            >
              <span>{building.Name}</span>
            </div>
          ))}
        </Carousel>
      </Col>
      {isShowButton() && (
        <Col className={styles.buttonSliderWrapper} span={2}>
          <Button onClick={handleClickNext} className={styles.buttonSlider}>
            <Icon type="right" />
          </Button>
        </Col>
      )}
    </Row>
  );
};
BuildingList.defaultProps = {
  buildingList: null,
  selectedBuildId: null,
  onSelectBuildingId: () => {},
};
BuildingList.prototype = {
  buildingList: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string,
      SAPID: PropTypes.string,
    })
  ),
  selectedBuildId: PropTypes.string,
  onSelectBuildingId: PropTypes.func,
};

export default memo(BuildingList);
