import React from "react";
import { List, Typography } from "antd";
import { Link } from "react-router-dom";
import HeadingBox from "../HeadingBox";

class BlogCatalogComponent extends React.Component {
  render() {
    return (
      <List
        className="list-category"
        header={<HeadingBox title="Danh mục" backgroundColor="#BF0000" />}
        dataSource={this.props.catalog}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text className="catalog-style">
              {item.code !== this.props.selected ? (
                <Link to={"/blog/catalog/" + item.code}>
                  {item.name} ({item.blog_count})
                </Link>
              ) : (
                <Link to="#" disabled>
                  {item.name} ({item.blog_count})
                </Link>
              )}
            </Typography.Text>
          </List.Item>
        )}
      />
    );
  }
}

export default BlogCatalogComponent;
