import * as actionTypes from "../actions/actionTypes";
const initialState = {
  selectedProjectId: null,
  selectedApartment: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PROJECT_ID:
      return {
        ...state,
        selectedProjectId: action.selectedProjectId,
      };
    case actionTypes.SET_SELECTED_APARTMENT:
      return {
        ...state,
        selectedApartment: action.selectedApartment,
      };
    default:
      return state;
  }
};
export default reducer;
