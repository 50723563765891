import React from 'react';

import { Result } from 'antd';

const PaymentSuccess = () => (
    <Result
      status="success"
      title="Yêu cầu đẩy tin của bạn đã gửi thành công."
      subTitle="Vui lòng chờ trong thời gian vài giờ để chúng tôi xác nhận và kích hoạt cho bạn. Xin cảm ơn!"
    />
);

export default PaymentSuccess;
