import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import React from "react";
import styles from "../Cart.module.scss";
import { BUSINESS_TYPES, IDENTIFY_TYPES } from "../constants";
const { Option } = Select;

const OrganizationForm = ({
  getFieldDecorator,
  onValidatePhoneNumber,
  onChangeCheckBoxOrganization,
  isDisabled,
}) => {
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Loại hình doanh nghiệp">
            {getFieldDecorator("organization.identify_type", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Select placeholder="Chọn..." disabled={isDisabled}>
                {BUSINESS_TYPES.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Mã doanh nghiệp">
            {getFieldDecorator("organization.identifier", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Mã doanh nghiệp"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Tên công ty">
            {getFieldDecorator("organization.identifier_name", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Tên công ty"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Nơi cấp Mã số DN">
            {getFieldDecorator("organization.identifier_issue_by", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Nơi cấp Mã số DN"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Ngày cấp Mã số DN">
            {getFieldDecorator("organization.identifier_issue_at", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <DatePicker
                format="DD/MM/YYYY"
                className={styles.datePicker}
                disabled={isDisabled}
                placeholder="Chọn ngày"
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Mã số thuế">
            {getFieldDecorator("organization.tax_code", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Mã số thuế"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title className={styles.title} level={4}>
        Thông tin Người đại diện Pháp luật
      </Typography.Title>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Người đại diện pháp luật">
            {getFieldDecorator("organization.legal_name", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Người đại diện pháp luật"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Chức vụ">
            {getFieldDecorator("organization.legal_position", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Chức vụ"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Email">
            {getFieldDecorator(
              "organization.legal_email",
            )(
              <Input
                placeholder="Email"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Số điện thoại">
            {getFieldDecorator("organization.legal_phone", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
                {
                  validator: onValidatePhoneNumber,
                },
              ],
            })(
              <Input
                placeholder="Số điện thoại"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Giấy tờ chứng thực cá nhân">
            {getFieldDecorator("organization.legal_identify_type", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Select placeholder="Chọn..." disabled={isDisabled}>
                {IDENTIFY_TYPES.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.lable}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Số giấy tờ chứng thực cá nhân">
            {getFieldDecorator("organization.legal_identifier", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Số giấy tờ chứng thực cá nhân"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title className={styles.title} level={4}>
        Thông tin người liên hệ
      </Typography.Title>
      <Checkbox onChange={onChangeCheckBoxOrganization} disabled={isDisabled}>
        Giống thông tin tài khoản của tôi
      </Checkbox>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Họ và tên">
            {getFieldDecorator("organization.name", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Họ và tên"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Số điện thoại">
            {getFieldDecorator("organization.phone_number", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <Input
                placeholder="Số điện thoại"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Email">
            {getFieldDecorator("organization.email")(
              <Input
                placeholder="Email"
                className={styles.inputItem}
                disabled={isDisabled}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Ngày sinh">
            {getFieldDecorator("organization.date_of_birth", {
              rules: [
                { required: true, message: "Thông tin này không thể bỏ trống" },
              ],
            })(
              <DatePicker
                format="DD/MM/YYYY"
                className={styles.datePicker}
                disabled={isDisabled}
                placeholder="Chọn ngày"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default OrganizationForm;
