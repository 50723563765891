import { Col, Icon, List, Row } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { SIZE } from "../../constants/common";
import { formattedDateShortTime } from "../../utils/commonUtils";
import HeadingBox from "../HeadingBox";
import Image from "../image";
import styles from "./BlogList.module.scss";

const BlogList = (props) => {
  const history = useHistory();
  const isSizeSMall = props.size === SIZE.SMALL;
  return (
    <List
      className={`custom-pagination ${isSizeSMall && styles.eventSmallList}`}
      header={
        isSizeSMall && (
          <HeadingBox
            title="Những sự kiện quan tâm nhất"
            backgroundColor="#E2A329"
          />
        )
      }
      loading={props.isLoading}
      dataSource={props.data ?? []}
      pagination={
        props.pageSize && {
          size: "small",
          onChange: (page) => {
            history.push({
              search: "?page=" + page,
            });
          },
          pageSize: props.pageSize,
          total: props.total,
          defaultCurrent: 1,
          current: props.current_page,
          hideOnSinglePage: true,
        }
      }
      renderItem={(item) => (
        <List.Item>
          <Row
            type="flex"
            className={`${styles.eventItem} ${
              isSizeSMall && styles.widgetSmall
            }`}
          >
            <Col
              xs={!isSizeSMall ? 24 : 5}
              sm={!isSizeSMall ? 24 : 5}
              md={5}
              lg={5}
              xl={5}
              className={styles.itemLeft}
            >
              <Image className="img-full h-full" src={item.cover_image} />
            </Col>
            <Col
              xs={!isSizeSMall ? 24 : 19}
              sm={!isSizeSMall ? 24 : 19}
              md={19}
              lg={19}
              xl={19}
              className={styles.itemRight}
            >
              <Link className={styles.title} to={`/blog/${item.slug}`}>
                {item.title}
              </Link>
              <div className={styles.eventCreatedAtWrapper}>
                {!isSizeSMall ? (
                  <span className={styles.keyword}>{item.keyword}</span>
                ) : null}
                <Icon type="clock-circle" />
                <span className={styles.eventCreatedAt}>
                  {formattedDateShortTime(item.created_at)}
                </span>
              </div>
              {!isSizeSMall && <p>{item.description}</p>}
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default BlogList;
