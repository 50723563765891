import Axios from "../axiosApi";

const BrokerPageApi = {
  getDetail(name) {
      return Axios.get('/broker/'+name).then(data=> data.data);
  },

  getArticleDetail(slug) {
      return Axios.get('/article/detail/'+slug).then(data=> data.data);
  }
}

export default BrokerPageApi;
