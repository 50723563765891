import { SPECS } from "../constants/realEstate";
import { generateUId } from "./commonUtils";
import * as realEstateConst from "../constants/realEstate";

export const transformSpecToMap = (directions) => {
  const specMap = new Map();
  SPECS.forEach((spec) => {
    specMap.set(spec.code, spec);
  });
  if(directions?.length > 0) {
    directions.forEach(item => {
      specMap.set(item.code, item);
    });
  }
  return specMap;
};
export const getSpecs = (specs) => {
  const specMap = transformSpecToMap();
  return specs.map((spec) => {
    const specMeta = specMap.get(spec?.code);
    return {
      id: generateUId(),
      ...spec,
      icon: specMeta.icon,
      unit: specMeta?.unit || null,
    };
  });
};
const transformRealEstateDetailToMap = (details) => {
  const realEstateMap = new Map();
  details.forEach((item) => {
    realEstateMap.set(item.code, item.value);
  });
  return realEstateMap;
};
// this function is handle for 15 types real estate
export const getRealEstateDetail = (details) => {
  const realEstateDetailMap = transformRealEstateDetailToMap(details);
  return {
    // COMMON
    acreage: realEstateDetailMap.get(realEstateConst.SPEC_AREA_CODE),
    height: realEstateDetailMap.get(realEstateConst.SPEC_HEIGHT_CODE),
    width: realEstateDetailMap.get(realEstateConst.SPEC_WIDTH_CODE),
    so_tang_hien_huu: realEstateDetailMap.get(
      realEstateConst.SPEC_DEAD_LINE_CODE
    ),
    khoang_lui_truoc: realEstateDetailMap.get(
      realEstateConst.SPEC_HOUSE_1_CODE
    ),
    khoang_lui_sau: realEstateDetailMap.get(realEstateConst.SPEC_HOUSE_2_CODE),
    view: realEstateDetailMap.get(realEstateConst.SPEC_VIEW_CODE),
    duong_truoc_nha_rong: realEstateDetailMap.get(
      realEstateConst.SPEC_ROAD_1_CODE
    ),
    duong_mo_rong: realEstateDetailMap.get(realEstateConst.SPEC_ROAD_2_CODE),
    hien_trang: realEstateDetailMap.get(
      realEstateConst.SPEC_CURRENT_STATUS_CODE
    ),
    quy_hoach: realEstateDetailMap.get(realEstateConst.SPEC_ZONING_CODE),
    no_thue:
      realEstateDetailMap.get(realEstateConst.SPEC_TAX_CODE) === "Có" ? 1 : 0,
    nam_cap_so: realEstateDetailMap.get(realEstateConst.SPEC_DEAD_LINE_CODE),
    so_tang_duoc_xay: realEstateDetailMap.get(
      realEstateConst.SPEC_BILDING_CODE
    ),
    muc_do_dan_cu:
      realEstateDetailMap.get(realEstateConst.SPEC_PEOPLE_CODE) === "Cực đông"
        ? 1
        : realEstateDetailMap.get(realEstateConst.SPEC_PEOPLE_CODE) === "Đông"
        ? 2
        : 3,
    // BUILDING AND CAFE
    khoang_lui_ben_hong: realEstateDetailMap.get(
      realEstateConst.SPEC_HOUSE_3_CODE
    ),
    duong_vao_rong: realEstateDetailMap.get(realEstateConst.SPEC_ROAD_3_CODE),
    cua_chinh_huong: realEstateDetailMap.get(
      realEstateConst.SPEC_MAIN_DOOR_CODE
    ),

    so_tang: realEstateDetailMap.get(realEstateConst.SPEC_FLOOR_NUMBER_CODE),
    dang_kinh_doanh_linh_vuc: realEstateDetailMap.get(
      realEstateConst.SPEC_BIZ_FIELD_CODE
    ),
    so_phong: realEstateDetailMap.get(realEstateConst.SPEC_ROOM_NUMBER_CODE),
    so_nam_da_kinh_doanh: realEstateDetailMap.get(
      realEstateConst.SPEC_BIZ_YEAR_NUMBER_CODE
    ),
    xep_hang_uy_tin: realEstateDetailMap.get(
      realEstateConst.SPEC_REPUTATION_RATING_CODE
    ),
    tinh_trang_hoat_dong: realEstateDetailMap.get(
      realEstateConst.SPEC_ACTIVE_STATUS_CODE
    ),
    // CAN HO
    so_luong_lau: realEstateDetailMap.get(realEstateConst.SPEC_FLOOR_2_CODE),
    so_luong_phong_ngu: realEstateDetailMap.get(
      realEstateConst.SPEC_BED_ROOM_NUMBER_CODE
    ),
    so_luong_nha_ve_sinh: realEstateDetailMap.get(
      realEstateConst.SPEC_WC_NUMBER_CODE
    ),
    ban_cong: realEstateDetailMap.get(realEstateConst.SPEC_BALCONY_CODE)
      ? 1
      : 0,
    thuoc_toa_thap: realEstateDetailMap.get(
      realEstateConst.SPEC_LOW_BUILDING_CODE
    ),
    view_huong: realEstateDetailMap.get(realEstateConst.SPEC_VIEW_SIDE_CODE),
    nam_ban_giao: realEstateDetailMap.get(
      realEstateConst.SPEC_TRANSFER_YEAR_CODE
    ),
    nam_co_so_hong: realEstateDetailMap.get(
      realEstateConst.SPEC_YEAR_WITH_PINK_BOOK_CODE
    ),
    phi_quan_ly: realEstateDetailMap.get(realEstateConst.SPEC_MANAGER_FEE_CODE),
    phi_bao_tri: realEstateDetailMap.get(
      realEstateConst.SPEC_MAINTENANCE_FEE_CODE
    ),
    phi_ra_so: realEstateDetailMap.get(realEstateConst.SPEC_BOOK_OUT_FEE_CODE),
    don_vi_quan_ly: realEstateDetailMap.get(
      realEstateConst.SPEC_MANAGEMENT_CODE
    ),
    chu_dau_tu: realEstateDetailMap.get(realEstateConst.SPEC_INVESTOR_CODE),
    don_vi_thi_cong: realEstateDetailMap.get(
      realEstateConst.SPEC_CONSTRUCTION_CODE
    ),
    ngan_hang_cho_vay: realEstateDetailMap.get(
      realEstateConst.SPEC_LOAN_BANK_CODE
    ),
    chieu_cao_xuong: realEstateDetailMap.get(
      realEstateConst.SPEC_WORKSHOP_HEIGHT_CODE
    ),
    khu_van_phong: realEstateDetailMap.get(
      realEstateConst.SPEC_OFFICE_AREA_CODE
    )
      ? 1
      : 0,
    dien_ba_pha: realEstateDetailMap.get(realEstateConst.SPEC_ELECTRIC_CODE)
      ? 1
      : 0,
    phong_bao_ve: realEstateDetailMap.get(
      realEstateConst.SPEC_SECURITY_ROOM_CODE
    )
      ? 1
      : 0,
    phong_chay_chua_chay: realEstateDetailMap.get(
      realEstateConst.SPEC_FIRE_PROTECTION_CODE
    )
      ? 1
      : 0,
    // SHOPHOUSE CAN HO
    so_nam_so_huu: realEstateDetailMap.get(
      realEstateConst.SPEC_YEARS_OF_OWNERSHIP_CODE
    ),
    so_mat_tien: realEstateDetailMap.get(
      realEstateConst.SPEC_FACADE_NUMBER_CODE
    ),
    chieu_cao_thong_thuy: realEstateDetailMap.get(
      realEstateConst.SPEC_HEIGHT_WATER_CODE
    ),
    so_can_ho: realEstateDetailMap.get(
      realEstateConst.SPEC_APARTMENT_NUMBER_CODE
    ),
    so_dan_du_kien: realEstateDetailMap.get(
      realEstateConst.SPEC_ESTIMATED_POPULATION_CODE
    ),
    // SHOPHOUSE NHA PHO
    so_tang_cao: realEstateDetailMap.get(realEstateConst.SPEC_NUMBER_OF_HIGH_FLOORS_CODE),
    duong_rong_lot_long: realEstateDetailMap.get(realEstateConst.SPEC_ROAD_WIDE_HEART_CODE),
    le_duong_rong: realEstateDetailMap.get(realEstateConst.SPEC_WIDE_CURB_CODE),
    quy_hoach_chieu_cao: realEstateDetailMap.get(realEstateConst.SPEC_ZONING_HEIGHT_CODE), 
    duong_truoc_nha: realEstateDetailMap.get(realEstateConst.SPEC_FRONT_HOME)
  };
};
