import React, { Component } from "react";
import Helpers from "../../../functions/Helpers";
import Image from "../../image";
import zaloImage from "../../..//images/zalo.png";
import messengerImage from "../../../images/messenger.png";
import {
  Row,
  Col,
  Typography,
  Button,
  List,
  Skeleton,
  Icon,
  Tooltip,
} from "antd";
import { ResourceUrl } from "../../../config/main";
const { Title } = Typography;

const LoadingAvatar = () => {
  return (
    <Row>
      <Skeleton avatar paragraph={{ rows: 4 }} />
    </Row>
  );
};

class Header extends Component {
  state = {
    number_cut: false,
  };

  getLocationComponent() {
    const broker = this.props.broker;
    const location = Helpers.isJSON(broker.location)
      ? JSON.parse(broker.location)
      : [];
    if (location.length > 0) {
      const locationHtml = (
        <Col xs={24} sm={18} md={8} lg={8} xl={8} className="area-sales">
          <Title className="red-color" level={4}>
            Khu vực hoạt động
          </Title>
          <List
            dataSource={location}
            renderItem={(item) => <List.Item>Khu vực {item.name}</List.Item>}
          />
        </Col>
      );
      return locationHtml;
    }
    return null;
  }

  render() {
    const props = this.props;
    const broker = props.broker;

    return (
      <div className="header">
        <section>
          <div className="image-wall">
            <Image
              broker_wall_picture={true}
              src={ResourceUrl + broker.wall_picture}
            />
          </div>
        </section>
        <div className="information">
          <Row>
            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
              {Object.keys(props.broker).length > 0 ? (
                <div className="broker-information">
                  <Row>
                    <Col
                      className="avatar"
                      xs={24}
                      sm={24}
                      md={10}
                      lg={6}
                      xl={6}
                    >
                      <div className="image-cropper">
                        <Image
                          src={ResourceUrl + broker.avatar}
                          avatar={true}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={18} xl={18}>
                      <div className="name-rate">
                        <Title level={3}>
                          {broker.last_name} {broker.first_name}{" "}
                          {broker.verify ? (
                            <Tooltip title="Đã xác minh">
                              <Icon
                                className="icon-verify"
                                type="check-circle"
                                theme="filled"
                              />
                            </Tooltip>
                          ) : null}
                        </Title>
                        <div className="name-role">
                          {broker.broker_category_name}
                        </div>
                        {/* <Rate disabled defaultValue={broker.ranking} /> */}
                      </div>

                      <div className="contact">
                        <Button
                          type="primary"
                          onClick={() => {
                            this.setState({ number_cut: true });
                          }}
                        >
                          {!this.state.number_cut ? (
                            String(broker.phone).slice(0, -3) + "***"
                          ) : (
                            <a href={"tel:" + broker.phone}>{broker.phone}</a>
                          )}{" "}
                          <br />
                          <small>
                            <i>Gọi ngay </i>
                          </small>
                        </Button>
                        <Button type="danger">
                          Nhắn tin <br />
                          <small>
                            <i>Cho người bán</i>
                          </small>
                        </Button>
                        {broker.zalo ? (
                          <a
                            href={"http://zalo.me/" + broker.zalo}
                            target="blank"
                          >
                            <img
                              src={zaloImage}
                              className="zalo-img"
                              alt="zalo_sms"
                              width="40"
                            />
                          </a>
                        ) : null}
                        {broker.facebook ? (
                          <a
                            href={"http://m.me/" + broker.facebook}
                            target="blank"
                          >
                            <img
                              src={messengerImage}
                              className="messenger-img"
                              alt="facebook_messgener"
                              width="40"
                            />
                          </a>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  {!Helpers.isBlank(broker.about_us) ? (
                    <Row className="text-introduce">
                      <Title className="red-color" level={4}>
                        Giới thiệu
                      </Title>
                      <p> {broker.about_us}</p>
                    </Row>
                  ) : null}
                </div>
              ) : (
                <LoadingAvatar />
              )}
            </Col>
            {this.getLocationComponent()}
          </Row>
        </div>
      </div>
    );
  }
}

export default Header;
