import { Button, Col, Icon, Row, Upload } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ResourceUrl, uploadUrl } from "../../../../config/main";
import DefaultBanner from "../../../../images/no-photo.png";
import { getBase64 } from "../../../../utils";
import styles from "../MyProfile.module.scss";

const UploadBannerImage = ({ currentBanner, onUpdateBanner, isLoadingUpdateBanner }) => {
  const [imageUrlWallPicture, setImageUrlWallPicture] = useState();
  const [loadingWallPicture, setLoadingWallPicture] = useState();
  const [currentBannerUrl, setCurrentBannerUrl] = useState();
  const [newBannerFile, setNewBannerFile] = useState();
  const handleChangeWallPicture = (info) => {
    if (info.file.status === "uploading") {
      setLoadingWallPicture({ isLoading: true });
      return;
    }
    if (info.file.status === "done") {
      setNewBannerFile(info.file);
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrlWallPicture) => setImageUrlWallPicture(imageUrlWallPicture),
        setLoadingWallPicture(false)
      );
    }
  };
  useEffect(() => {
    if (currentBanner) {
      setCurrentBannerUrl(`${ResourceUrl}${currentBanner}`);
    }
  }, [currentBanner]);
  const handleImageLoadError = () => {
    setCurrentBannerUrl(DefaultBanner);
  };
  return (
    <div className={styles.formContainer}>
      <div className={styles.bannerImageWrapper}>
        <img
          src={currentBannerUrl || DefaultBanner}
          alt=""
          onError={handleImageLoadError}
        />
      </div>
      <Row>
        <Upload
          name="logo"
          className={styles.uploadBanner}
          listType="picture-card"
          showUploadList={false}
          action={uploadUrl}
          onChange={handleChangeWallPicture}
        >
          {imageUrlWallPicture ? (
            <img
              src={imageUrlWallPicture}
              onError={(curerntError) => {
                curerntError.currentTarget.src = DefaultBanner;
              }}
              alt="imageUrlWallPicture"
              style={{ width: "100%" }}
            />
          ) : (
            <>
              <Icon type={loadingWallPicture ? "loading" : "plus"} />
              <div className="ant-upload-text">Upload</div>
            </>
          )}
        </Upload>
      </Row>

      <Row style={{ marginTop: "1rem" }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Button
            type="primary"
            size="large"
            disabled={!newBannerFile}
            loading={isLoadingUpdateBanner}
            onClick={() => onUpdateBanner(newBannerFile)}
          >
            Cập nhật
          </Button>
        </Col>
      </Row>
    </div>
  );
};

UploadBannerImage.defaultProps = {
  currentBanner: "",
  onUpdateBanner: () => {},
  isLoadingUpdateBanner: false,
};
UploadBannerImage.prototype = {
  currentBanner: PropTypes.string,
  onUpdateBanner: PropTypes.func,
  isLoadingUpdateBanner: PropTypes.bool,
};

export default UploadBannerImage;
