import { Col, Empty, Row } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import Banner from "../../component/banner/banner";
import HeadingContent from "../../component/banner/HeadingContent";
import BlogCatalogComponent from "../../component/blog/blogCatalogComponent";
import BlogList from "../../component/blog/BlogList";
import { SIZE } from "../../constants/common";
import BlogModel from "../../models/Blog";
import "./blog.scss";

class Blog extends Component {
  state = {
    data: [],
    total: null,
    current_page: null,
    pageSize: null,
    catalog: [],
    isLoading: false,
  };

  loadBlogList(page, slug) {
    this.setState({ isLoading: true });
    BlogModel.blogLists(page, slug).then((res) => {
      const data: BlogModel = res;
      this.setState({
        data: data.attributes.data,
        total: data.attributes.total,
        current_page: data.attributes.current_page,
        pageSize: data.attributes.per_page,
        catalog: data.catalogAttributes,
        isLoading: false,
      });
    }).catch(() => this.setState({ isLoading: false }))
  }

  run(): void {
    let params = queryString.parse(this.props.location.search);
    const { slug } = this.props.match.params;
    this.loadBlogList(params.page || null, slug || null);
  }

  componentDidMount(): void {
    this.run();
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    if (this.props.location !== prevProps.location) {
      this.run();
    }
  }

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <div className="site-blog">
        <Helmet>
          <title>Tin tức</title>
          <meta property="og:title" content="Tin tức" />
          <meta
            property="og:description"
            content="Các tin tức nóng hổi về thị trường bất động sản"
          />
          <meta
            name="description"
            content="Các tin tức nóng hổi về thị trường bất động sản"
          />
        </Helmet>
        <Banner
          headingContent={
            <HeadingContent
              heading="TIN TỨC"
              subheading="Các tin tức nóng hổi về thị trường bất động sản"
            />
          }
        />
        <div className="section-wrap">
          <div className="blog-content">
            <Row gutter={{ xs: 0, lg: 16 }}>
              <Col xs={24} lg={16}>
                <div className="content">
                  {this.state.data.length > 0 ? (
                    <BlogList {...this.state} />
                  ) : (
                    <Empty />
                  )}
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div className="right-aside">
                  <BlogCatalogComponent
                    selected={this.props.match.params.slug}
                    catalog={this.state.catalog}
                  />
                  <BlogList
                    size={SIZE.SMALL}
                    title={"Những sự kiện quan tâm nhất "}
                    {...this.state}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
