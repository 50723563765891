import { Icon } from "antd";
import React, { useState } from "react";
import styles from "../realEstateContent.module.scss";

const RealEstateDesc = ({ realEstate }) => {
  const [isViewMoreDesc, setIsViewMoreDesc] = useState(false);
  return (
    <>
      <h3 className="sub-heading">Thông tin mô tả</h3>
      <div className={styles.realEstateDescContent}>
        <p
          className={`description-real-estate ${
            isViewMoreDesc ? `full-desc` : ""
          }`}
          dangerouslySetInnerHTML={{ __html: realEstate.body.trim() }}
        ></p>
        {realEstate.body.trim().length > 500 && (
          <button
            onClick={() => setIsViewMoreDesc((prev) => !prev)}
            className={styles.viewMoreBtn}
          >
            {isViewMoreDesc ? (
              <>
                <span>Thu gọn</span> <Icon type="up" />
              </>
            ) : (
              <>
                <span>Xem thêm</span> <Icon type="right" />
              </>
            )}
          </button>
        )}
      </div>
    </>
  );
};

export default RealEstateDesc;
