import React  from 'react';
import { MainDomain, ImageMediumWithWatermark, DomainLink, http  } from "../../../config/main";
import Helpers from "../../../functions/Helpers";
import moment from 'moment';
import {
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Col,  Card, Typography, Icon } from 'antd';
const {  Text } = Typography;

const cardItem = (props) => {
    const data = props.data;
    return (
        <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ props.fourItem ? 6 : 8 } xl={ props.fourItem ? 6 : 8 }
            className="card-column-re">
            <a href={ DomainLink + '/bat-dong-san/'+data.slug}
               target={ props.isSubDomain ? "_blank" : "_parent" }>
                <Card hoverable
                      className="card-item"
                      cover={
                          <aside style={{
                              backgroundImage: "url('" + ImageMediumWithWatermark + data.cover_image +"')"
                          }} className="cartImage"/>
                      }
                      size="small">
                    <Card.Meta title={
                        <Text className="cartTitle">
                            { Helpers.moneyFormatVn(data.price) }
                            <span style={{float: "right"}}>{parseFloat(data.acreage, 2)}m<sup>2</sup></span>
                        </Text>
                    } description={
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Text className=""><p>{ data.title }</p></Text>
                              <Text>
                                <EnvironmentOutlined className="icon"  />
                                { Helpers.getString(data.address) +
                                  Helpers.getString(data.district_name)  +
                                  Helpers.getString(data.province_name, true) }
                              </Text>
                            <Text>
                            <Icon type="clock-circle" className="icon" />
                            {
                              data.user_sub_domain  &&
                              data.user_sub_domain !== null && data.user_sub_domain !== '' ?
                               <span className="user_name">
                                 <a href={ http + data.user_sub_domain + MainDomain + '/broker/' + data.slug }>{ data.user_name } </a>
                                 đăng
                               </span> :
                              null
                            } {moment(data.created_at).fromNow()}
                            </Text>
                        </div>
                    }/>
                </Card>
            </a>
        </Col>
    );
};

export default cardItem;
