import React from "react";
import Breadcumb from "../../component/breadcrumb/breadcrumb";
import InvestorPageApi from "../../api/investorPage";
import "./investor.scss";
import { Card, Col, Descriptions, PageHeader, Row, Typography } from "antd";
import Image from "../../component/image";
import { ResourceUrl } from "../../config/main";
import WidgetProject from "../home/widgets_area/widget_project/widgetProject";
import DetectEmpty from "../../component/empty";
import LinkDuo from "../../component/linkDuo";

const { Title, Paragraph, Text } = Typography;

class Investor extends React.Component {
  state = {
    investor: {
      full_name: null,
      founded_in: new Date().getFullYear(),
      website: null,
      project_ready_to_sell: [],
      project_on_sell: [],
    },
  };

  componentDidMount() {
    const slug = this.props.match.params.slug;
    InvestorPageApi.getDetail(slug).then((data) => {
      if (data.investor !== null) {
        this.setState({
          investor: data.investor,
        });
        if (typeof data.investor.name !== "undefined")
          document.title = "Chủ đầu tư - " + data.investor.name;
      }
    });
  }

  render() {
    return (
      <div className="investor-container">
        <Breadcumb title={this.state.investor.name} subitem="Chủ đầu tư" />
        <div className="section-wrap">
          <Row type="flex" gutter={[{ xs: 20, lg: 12 }, { xs: 0, lg: 0 }]} justify="center">
            <Col xs={24} lg={7}>
              <Card
                style={{
                  height: "100%",
                }}
                cover={
                  <div className="logo-investor">
                    <Image src={ResourceUrl + this.state.investor.logo} />
                  </div>
                }
              >
                <Card.Meta
                  description={
                    <Descriptions column={1} layout="horizontal" size="small">
                      <Descriptions.Item>
                        {this.state.investor.full_name}
                      </Descriptions.Item>
                      {this.state.investor.founded_in ? (
                        <Descriptions.Item label="Thành lập">
                          {new Date(
                            this.state.investor.founded_in
                          ).getFullYear()}
                        </Descriptions.Item>
                      ) : null}
                      {this.state.investor.website ? (
                        <Descriptions.Item label="Website">
                          <LinkDuo
                            to={"//" + this.state.investor.website}
                            target="_blank"
                          >
                            {this.state.investor.website}
                          </LinkDuo>
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                  }
                />
              </Card>
            </Col>
            <Col xs={24} lg={17}>
              <Card bodyStyle={{ padding: 0 }}>
                <PageHeader
                  title={
                    <Title
                      style={{
                        margin: 0,
                      }}
                      level={3}
                    >
                      {this.state.investor.name}
                      <br />
                      <Text
                        style={{
                          fontSize: "55%",
                          fontWeight: "normal",
                        }}
                        type="secondary"
                      >
                        {this.state.investor.address}
                      </Text>
                    </Title>
                  }
                >
                  <Descriptions title="Giới thiệu">
                    <Descriptions.Item>
                      <Paragraph
                        style={{
                          columnCount: 2,
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.investor.description,
                          }}
                        />
                      </Paragraph>
                    </Descriptions.Item>
                  </Descriptions>
                </PageHeader>
              </Card>
            </Col>
          </Row>
          <br />
          <DetectEmpty
            val={this.state.investor.project_ready_to_sell}
            returnVal={
              <WidgetProject
                slideItem={4}
                seeMore={false}
                title={
                  <Title level={3}>
                    <Typography.Text>
                      Dự án {this.state.investor.name} sắp mở bán
                    </Typography.Text>
                  </Title>
                }
                dataSource={this.state.investor.project_ready_to_sell}
              />
            }
          />
          <br />
          <DetectEmpty
            val={this.state.investor.project_on_sell}
            returnVal={
              <WidgetProject
                slideItem={4}
                seeMore={false}
                title={
                  <Title level={3}>
                    <Typography.Text>
                      Dự án {this.state.investor.name} đang mở bán
                    </Typography.Text>
                  </Title>
                }
                dataSource={this.state.investor.project_on_sell}
              />
            }
          />
        </div>
      </div>
    );
  }
}

export default Investor;
