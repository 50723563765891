export function formatCurrency(number, shouldDisplayCurrencyUnit = true, locale="vi-VI", currency="VND") {
    if (!number) return 0;
    if (!shouldDisplayCurrencyUnit) {
      return new Intl.NumberFormat(locale, {}).format(number);
    }
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(number);
  }
  