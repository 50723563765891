import Axios from "../axiosApi";
import {
  SamePrice,
  SameAcreage,
  SameStreet,
  Slug,
  DistrictId,
  Lat,
  Lng,
} from "../config/params";

const RealEstatePage = {
  realEstateSamePrice: function (project) {
    const params = {};
    params[SamePrice] = project.price;
    params[Slug] = project.slug;
    params[Lat] = project.lat;
    params[Lng] = project.lng;
    return this.getList({ params: params });
  },
  realEstateSameAcreage: function (project) {
    const params = {};
    params[SameAcreage] = project.acreage;
    params[Slug] = project.slug;
    params[Lat] = project.lat;
    params[Lng] = project.lng;
    return this.getList({ params: params });
  },
  realEstateSameStreet: function (project) {
    const params = {};
    params[SameStreet] = project.street;
    params[DistrictId] = project.district_id;
    params[Slug] = project.slug;
    params[Lat] = project.lat;
    params[Lng] = project.lng;
    return this.getList({ params: params });
  },
  getList: function (data) {
    return Axios.get("/list", { params: data.params });
  },
  getProjectDetail(slug, func) {
    return Axios.get("/project/detail/" + slug).then(function (response) {
      func(response.data);
    });
  },
  getRealEstatePush(slug) {
    return Axios.get("/realEstate/push/" + slug);
  },
};
export default RealEstatePage;
