import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { getWards, GET_WARDS_URL } from "../../api/homeTown";
import { Select } from "antd";

const { Option } = Select;
const SelectWardField = ({ districtCode, onSelect, valueOfSelected, isDisabled }) => {
  const { data, isFetching } = useQuery(
    [GET_WARDS_URL(districtCode)],
    () => getWards(districtCode),
    {
      enabled: !!districtCode,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  const wards = data?.data?.wards;
  return (
    <Select
      onSelect={onSelect}
      placeholder="Chọn..."
      disabled={!districtCode || isDisabled}
      loading={isFetching}
      value={valueOfSelected}
    >
      {wards?.length > 0 &&
        wards.map(({ id, _name }) => (
          <Option key={id} value={id}>
            {_name}
          </Option>
        ))}
    </Select>
  );
};

SelectWardField.defaultProps = {
  districtCode: null,
  onSelect: () => {},
  valueOfSelected: '',
  isDisabled: false,
};

SelectWardField.propTypes = {
  districtCode: PropTypes.string,
  onSelect: PropTypes.func,
  valueOfSelected: PropTypes.number,
  isDisabled: PropTypes.bool,
};

export default SelectWardField;
