import {Icon} from "antd";
import React from "react";

export default [
    {
        to: "/me/dashboard",
        name: <React.Fragment><Icon type="dashboard" style={{
            marginRight: 10
        }} /> Dashboard</React.Fragment>
    },
    {
        to: "/me/history",
        name: <React.Fragment><Icon type="bank" style={{
            marginRight: 10
        }} /> Nhà đất của tôi</React.Fragment>
    },
    {
        to: "/me/article",
        name: <React.Fragment><Icon type="container" style={{
            marginRight: 10
        }} /> Bài đánh giá của tôi</React.Fragment>
    },
    {
        to: "/me/buyers",
        name: <React.Fragment><Icon type="team" style={{
            marginRight: 10
        }} /> Khách hàng của tôi</React.Fragment>
    },
    {
        to: "/user/my-profile",
        name: <React.Fragment><Icon type="edit" style={{
            marginRight: 10
        }} /> Thông tin tài khoản</React.Fragment>
    }
];