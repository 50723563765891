const Latest = 'lt';
const GoodPrice = 'gp';
const Hot = 'h';
const LowerPrice = 'lp';
const LowerAcreage = 'la';
const Query = 'q';
const Type = 't';
const Status = 's';
const Price = 'p';
const Acreage = 'a';
const SamePrice = 'same_price';
const SameAcreage = 'same_acreage';
const SameStreet = 'same_street';
const DistrictId = 'district_id';
const Slug = 'slug';
const Limit = 'limit';
const Page = 'page';
const Lat = 'lat';
const Lng = 'lng';
const Priority ='priority';
const LimitPriority ='limit_priority';

export { Status,Query,Type,Latest,GoodPrice,Hot,LowerPrice,
         LowerAcreage, SamePrice, SameAcreage, SameStreet,
         Slug, Limit, Page, DistrictId, Acreage, Price, Lat, Lng, Priority, LimitPriority };
