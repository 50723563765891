import { Card, Col, Statistic } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "../Dashboard.module.scss";

const SummaryCard = ({ icon, link, title, count, className }) => {
  return (
    <Col xs={24} sm={24} md={12} xl={6} style={{ paddingRight: 16 }}>
      <Link to={link}>
        <Card className={className}>
          <div className={styles.groupDetailSummary}>
            <img src={icon} alt="logo" />
            <Statistic value={count} />
          </div>
        </Card>
      </Link>
    </Col>
  );
};

SummaryCard.defaultProps = {
  icon: "",
  link: "",
  title: "",
  value: 0,
  className: "",
};
SummaryCard.prototype = {
  icon: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  className: PropTypes.string,
};

export default SummaryCard;
