import { Modal } from "antd";
import React from "react";
import PropTypes from "prop-types";

const ConfirmModal = ({
  title,
  okText,
  cancelText,
  modalBodyContent,
  isVisible,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      title={title}
      visible={isVisible}
      onOk={onConfirm}
      onCancel={onClose}
      okText={okText}
      cancelText={cancelText}
    >
      {modalBodyContent}
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onConfirm: () => {},
  title: "Xác nhận",
  okText: "Đồng ý",
  cancelText: "Huỷ",
  modalBodyContent: <></>,
};
ConfirmModal.prototypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.element,
};

export default ConfirmModal;
