import { useMutation, useQuery } from "@tanstack/react-query";
import { Col, Icon, Modal, notification, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import {
  createTransaction,
  getExpiredTimeByBookId,
  getPaymentAccount,
  GET_EXPIRED_TIME_BY_BOOK_ID_URL,
  GET_PAYMENT_ACCOUNT_URL,
} from "../../../api/onlinePrice";
import { ApartmentInfo } from "../../../component";
import { useFetchApartmentDetail } from "../../../hooks/useFetchApartmentDetail";
import styles from "../Cart.module.scss";
import CountDownTimer from "./components/CountDownTimer";
import TransactionForm from "./components/TransactionForm";

const { Title } = Typography;
const Transaction = () => {
  const { id: bookingId } = useParams();
  const history = useHistory();
  const [isTimesUp, setIsTimeUp] = useState(false);
  const hanldeChangeTimesUp = () => {
    setIsTimeUp(true);
  };
  const { data, isLoading, isError } = useQuery(
    [GET_EXPIRED_TIME_BY_BOOK_ID_URL(bookingId), {}],
    () => getExpiredTimeByBookId(bookingId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: () => {
        setIsTimeUp(true);
      },
    }
  );
  const { data: responsePayment, isLoading: isLoadingPayment } = useQuery(
    [GET_PAYMENT_ACCOUNT_URL, {}],
    () => getPaymentAccount(bookingId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  const paymentAccount = responsePayment?.data?.listPaymentAccount;
  const expiredTime = data?.data?.Book;
  const apartment = data?.data?.Apartment;
  const createTransationMutation = useMutation(createTransaction, {
    onSuccess: (res) => {
      notification.open({
        message: "Thành công",
        description: "Thanh toán thành công",
      });
      history.push("/user/booking-list");
    },
    onError: () => {
      Modal.error({
        title: "Máy chủ đang bị lỗi",
        content: "Bạn vui lòng quay lại sau, Cám ơn",
      });
    },
  });
  const handleSubmitTransaction = (formData) => {
    createTransationMutation.mutate({
      "bank-account-name": formData.bankAccountName,
      "bank-number": formData.bankNumber,
      note: formData.note,
      amount: formData.amount,
      "booking-id": bookingId,
      image_path: formData.imagePath,
      name: "",
      bank: "",
    });
  };
  const fetchedApartment = useFetchApartmentDetail(apartment?.ApartmentID);

  if (isError) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.wrapper}>
      {isLoading || isLoadingPayment ? (
        <Spin
          style={{
            width: `100%`,
            margin: `auto`,
          }}
          indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
        />
      ) : (
        <div className="section-wrap">
          <Title level={3}>Thanh toán cọc</Title>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className={styles.paymentInfo}>
                <p>
                  Để hoàn tất đơn hàng, quý khách vui lòng thanh toán đặt cọc là
                  50.000.000 vnđ, thông tin tài khoản thanh toán
                </p>
                <p className={styles.headingPayment}>
                  Tên: {paymentAccount?.name}
                </p>
                <p className={styles.headingPayment}>
                  TK: {paymentAccount?.number_account}
                </p>
                <p className={styles.headingPayment}>
                  Ngân hàng: {paymentAccount?.bank_name}
                </p>
                <p className={styles.headingPayment}>
                  Nội dung: thanh toán cọc - mã căn
                </p>
                <p>
                  Sau thời gian {expiredTime?.expired_at} nếu Homesface chưa
                  nhận được thông tin thanh toán và uỷ nhiệm chi từ quý khách
                  thì đơn hàng của quý khách tự động bị huỷ
                </p>
              </div>
              {!isTimesUp ? (
                <h4>
                  Thanh toán 50.000.000 vnđ cần được thực hiện trong&nbsp;
                  <CountDownTimer
                    timeRemaining={expiredTime?.time_remaining}
                    onChangeTimesUp={hanldeChangeTimesUp}
                  />
                </h4>
              ) : (
                <h4>
                  Hết thời gian thanh toán.&nbsp;
                  <Link to="/" className={styles.backToHome}>
                    Về trang chủ
                  </Link>
                </h4>
              )}
              {!isTimesUp && (
                <TransactionForm
                  onSubmitTransaction={handleSubmitTransaction}
                  isLoading={createTransationMutation.isLoading}
                />
              )}
            </Col>
            <Col span={24} xs={24} sm={24} md={24} lg={8} xl={8}>
              {fetchedApartment && (
                <ApartmentInfo
                  houseName={fetchedApartment.HouseName}
                  floorNumber={fetchedApartment.FloorNumber}
                  apartmentAttributes={fetchedApartment?.ApartmentAttributes}
                  totalPriceNonVAT={fetchedApartment?.TotalPriceNonVAT}
                  buildingName={fetchedApartment?.buildingName}
                  totalPriceVAT={fetchedApartment?.TotalPriceVAT}
                  percent={fetchedApartment.percent}
                  percentAmount={fetchedApartment.percentAmount}
                  shouldDisplayPriceDetail
                />
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

Transaction.propTypes = {};

export default Transaction;
