import Axios from "../axiosApi";

export const GET_CITIES_URL = "/cities";
export const getCities = async () => {
  const result = await Axios.get(GET_CITIES_URL, {});
  return result;
};

export const GET_DISTRICTS_URL = (cityCode) => `/city/${cityCode}/districts`;
export const getDistricts = async (cityCode) => {
  const result = await Axios.get(GET_DISTRICTS_URL(cityCode), {});
  return result;
};
export const GET_WARDS_URL = (districtCode) => `/district/${districtCode}/wards`;
export const getWards = async (districtCode) => {
  const result = await Axios.get(GET_WARDS_URL(districtCode), {});
  return result;
};