import React, { Component } from 'react';
import { Row, Col, Slider, Input } from 'antd';

class inputRange extends Component {

    onChange = (event) => {
        this.props.onChange(event.target.value);
    };

    render() {
        let props = this.props;

        return (
            <Row>
                <Col span={24}>
                    <label>
                        { props.label }
                    </label>
                </Col>
                <Col span={17} >
                    <Slider min={ 1 }
                            style={{
                                width: '80%'
                            }}
                            tooltipVisible={ false }
                            value={ props.value || 0 }
                            onChange={ props.onChange }
                            max={ props.max} />
                </Col>
                <Col span={7}>
                    <Input suffix={<span>{props.addonAfter}</span>}
                           min={ 1 }
                           type="number"
                           value={ props.value || 0 }
                           onChange={ this.onChange }
                    />
                </Col>
            </Row>
        );
    }
}

export default inputRange;
