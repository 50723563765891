import { Typography } from "antd";
import React from "react";
import styles from "../BookingDetail.module.scss";

const { Title } = Typography;
const AddressInfo = ({ address, cityLabel, districtLabel }) => {
  return (
    <>
      <Title level={4} className={styles.subHeading}>Địa chỉ</Title>
      <div className={styles.orderInfoWrapper}>
        <p className={styles.orderInfoRow}>
          <span className={styles.orderInfoLabel}>Tỉnh/thành phố:</span>
          <span className={styles.orderInfoValue}>{cityLabel}</span>
        </p>
        <p className={styles.orderInfoRow}>
          <span className={styles.orderInfoLabel}>Quận/huyện:</span>
          <span className={styles.orderInfoValue}>{districtLabel}</span>
        </p>
        <p className={styles.orderInfoRow}>
          <span className={styles.orderInfoLabel}>Địa chỉ:</span>
          <span className={styles.orderInfoValue}>{address}</span>
        </p>
      </div>
    </>
  );
};

AddressInfo.propTypes = {};

export default AddressInfo;
