import React, { useEffect, useState } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import MainLayoutRoute from "./layout/main/main";
import routes from "./routes";
import Axios from "./axiosApi";
import { Icon, Layout, Spin } from "antd";
import { saveDomain } from "./store/actions/domain";
import { connect } from "react-redux";
import User from "./models/User";
import loginSignUpApi from "./api/loginSignUp";
import { saveProfile } from "./store/actions/profile";
import Broker from "./page/broker/broker";
import styles from "./App.module.scss";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { ImageThump } from "./config/main";
import { Session } from "./functions/session";
import Helpers from "./functions/Helpers";

const RouteLayout = (props) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!props.profile) {
      if (User.getToken()) {
        loginSignUpApi
          .getInfor()
          .then((res) => {
            if (res.data.status) {
              props.saveProfile({
                ...res.data.infor,
                chung_chi: res.data.chung_chi,
                cmnd: res.data.cmnd,
              });
              setRedirect(false);
            } else {
              setRedirect(true);
            }
          })
          .catch((error) => {
            setRedirect(true);
          });
      } else {
        setRedirect(true);
      }
    } else setRedirect(false);
  }, [props]);

  const showContentMenu = () => {
    let result = [];
    if (props && props.domain.isSubDomain) {
      result = [
        <MainLayoutRoute
          exact={true}
          path={""}
          component={Broker}
          domainData={props}
        />,
      ];
    } else if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <MainLayoutRoute
            key={index}
            exact={route.exact}
            path={route.path}
            component={route.main}
            domainData={props}
            redirect={redirect}
            privateRouter={route.private}
            renderWithoutMainlayout={route.renderWithoutMainlayout}
          />
        );
      });
    }
    return result;
  };
  return showContentMenu(routes);
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});
class App extends React.Component {
  constructor(props) {
    super(props);
    let recommend = Session.getJson("recommendPriceAcreage", []);
    recommend = _.filter(recommend, (o) => {
      if (!_.isEmpty(o.dateSeen))
        return (
          Helpers.getTimestamp(o.dateSeen) >=
          Helpers.getTimestamp(Helpers.getDateNow())
        );
      return false;
    });
    Session.putJson("recommendPriceAcreage", recommend);
  }

  componentDidMount() {
    Axios.get("/domain")
      .then((response) => {
        this.props.saveDomain(response.data);
        this.setState({
          ...response.data,
        });
      })
      .catch((error) => {});
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Homesface | Sàn giao dịch bất động sản công nghệ số</title>
          <meta
            property="og:title"
            content="Homesface | Sàn giao dịch bất động sản công nghệ số"
          />
          <meta
            property="og:image"
            content={ImageThump + "summer-house-with-a-pole.jpg"}
          />
          <meta
            property="og:description"
            content="Homesface.com là sàn giao bất động sản công nghệ số. Chúng tôi kết nối nhà cung cấp, nhà đầu tư, môi giới và tất cả người mua bán, cho thuê, trao đổi... nhanh chóng, chính xác và  là hệ thống định giá chính xác theo từng tuyến đường. Nơi cung cấp những đánh giá, nhận định của chuyên gia về Nhà, đất, căn hộ, chung cư, biệt thự, liền kề, văn phòng, khách sạn, phòng trọ, cửa hàng..."
          />
          <meta
            name="description"
            content="Homesface.com là sàn giao bất động sản công nghệ số. Chúng tôi kết nối nhà cung cấp, nhà đầu tư, môi giới và tất cả người mua bán, cho thuê, trao đổi... nhanh chóng, chính xác và  là hệ thống định giá chính xác theo từng tuyến đường. Nơi cung cấp những đánh giá, nhận định của chuyên gia về Nhà, đất, căn hộ, chung cư, biệt thự, liền kề, văn phòng, khách sạn, phòng trọ, cửa hàng..."
          />
        </Helmet>
        <Layout className={styles.App}>
          {_.isEmpty(this.state) ? (
            <Spin
              style={{
                margin: "0 auto",
                width: "100%",
                position: "absolute",
                bottom: "50%",
                transform: "translateY(-50%)",
              }}
              indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
            />
          ) : (
            <QueryClientProvider client={queryClient}>
              <RouteLayoutR {...this.state} />
            </QueryClientProvider>
          )}
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domain: state.domainInfo.domain,
    profile: state.profile.infor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveDomain: (domain) => dispatch(saveDomain(domain)),
    saveProfile: (info) => dispatch(saveProfile(info)),
  };
};

const RouteLayoutR = connect(mapStateToProps, mapDispatchToProps)(RouteLayout);
export default connect(mapStateToProps, mapDispatchToProps)(App);
