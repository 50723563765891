import React from "react";
import { Tabs, Button } from "antd";
import "./historyArticle.scss";
import TableHistoryArticle from "../tableHistoryArticle/tableHistoryArticle";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

class HistoryArticle extends React.Component {
  render() {
    return (
      <div className="table-history">
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={
            <Link to="/me/article/create">
              <Button icon="plus" type="primary">
                Tạo mới
              </Button>
            </Link>
          }
        >
          <TabPane tab="Bài đánh giá của tôi" key="1">
            <TableHistoryArticle isMyPost={true} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default HistoryArticle;
