import { useQuery } from '@tanstack/react-query';
import { Icon, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getProjectCarts, GET_PROJECT_CARTS_URL } from '../../api/onlinePrice';
import { Image } from '../../component';
import Banner from '../../component/banner/banner';
import HeadingContent from '../../component/banner/HeadingContent';
import { ResourceUrl } from '../../config/main';
import {
  PROJECT_CART_STATUS_RECEIVE_BOOKING_VALUE,
  PROJECT_CART_STATUS_SELLING_VALUE,
  PROJECT_CART_STATUS_SOLD_VALUE,
} from '../../constants/realEstate';
import { generateUId } from '../../utils';
import { getProjectCartByStatus } from '../../utils/projectCartUtils';
import styles from './ProjectCarts.module.scss';

const ProjectCarts = () => {
  const history = useHistory();

  const query = useQuery([GET_PROJECT_CARTS_URL], () => getProjectCarts(), {
    retry: 1,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (data) => {},
  });
  const { data, isLoading } = query;

  const bookingList =
    Array.isArray(data) && data ? data[0].data.listsProject : [];
  const sellingList =
    Array.isArray(data) && data ? data[1].data.listsProject : [];
  const soldList = Array.isArray(data) && data ? data[2].data.listsProject : [];
  const { heading: bookingHeading, subHeading: bookingSubHeading } =
    getProjectCartByStatus(PROJECT_CART_STATUS_RECEIVE_BOOKING_VALUE);
  const projectList = [
    {
      id: generateUId(),
      itemList: bookingList,
      heading: bookingHeading,
      subHeading: bookingSubHeading,
    },
    {
      id: generateUId(),
      itemList: sellingList,
      heading: getProjectCartByStatus(PROJECT_CART_STATUS_SELLING_VALUE)
        .heading,
    },
    {
      id: generateUId(),
      itemList: soldList,
      heading: getProjectCartByStatus(PROJECT_CART_STATUS_SOLD_VALUE).heading,
    },
  ];
  const countProject =
    bookingList.length + sellingList.length + soldList.length;

  const handleRedirectTo = (projectId, status) => {
    history.push(`/gio-hang-booking/du-an/${projectId}?status=${status}`);
  };
  return (
    <>
      {isLoading ? (
        <Spin
          tip="Loading..."
          style={{
            width: `100%`,
            margin: `auto`,
          }}
          indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
        />
      ) : (
        <>
          <Banner
            headingContent={
              <HeadingContent
                heading="Giỏ hàng dự án"
                subheading={`Hiện đang có ${countProject} dự án trong giỏ hàng`}
              />
            }
            // isHiddenSearch
          />
          <div className={`section-wrap ${styles.projectCartsWrapper}`}>
            {projectList?.length > 0 && (
              <>
                {projectList
                  .filter((item) => item.itemList?.length > 0)
                  .map((project) => {
                    return (
                      <div key={project.id}>
                        <h1 className={styles.projectHeading}>
                          {project.heading}
                        </h1>
                        {project?.subHeading && (
                          <p
                            className={`${styles.receiveStatusSubHeading} mb-24`}
                          >
                            {project?.subHeading}
                          </p>
                        )}
                        <div className={styles.projectCartList}>
                          {project.itemList.map((item) => (
                            <div
                              className={styles.projectCartItem}
                              onClick={() =>
                                handleRedirectTo(
                                  item.project_id,
                                  item.status_cart
                                )
                              }
                            >
                              <Image
                                className="img-full"
                                src={`${ResourceUrl}/${item.cover_image}`}
                                alt=""
                              />
                              <div className={styles.projectCartContent}>
                                <p>{item.location}</p>
                                <p className={styles.projectTitle}>
                                  {item.title}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ProjectCarts;
