import React from "react";
import { Form, Input, Select } from "antd";

const { Option } = Select;

function PackageSetting(props) {
  return (
    <div className="package_setting">
      <Form.Item label="Thời gian đẩy tin">
        <Input
          type="number"
          addonAfter="Ngày"
          value={props.days}
          min={3}
          onChange={(event) => {
            if (props.paymentSelected === "0") {
              props.setDays(3);
            } else {
              props.setDays(event.target.value);
            }
          }}
        />
      </Form.Item>
      {props.packageSelected !== props.Package.Vip1 && (
        <Form.Item label="Chọn nhãn tin">
          <Select
            placeholder="Nhãn tin"
            onChange={(value) => props.setLabel(value)}
          >
            {props.labelOptions &&
              props.labelOptions.map((el, index) => (
                <Option key={index} value={el.value}>
                  {el.value}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
    </div>
  );
}

export default PackageSetting;
