import Axios from "../axiosApi";
import { PROJECT_ORDER_TYPE_NEW_PRODUCT } from "../page/Project/Projects/contants";

export const GET_PROJECTS_URL = "/projects";
export const getProjects = async ({ orderBy, page }) => {
  const params = {
    order_by: orderBy || PROJECT_ORDER_TYPE_NEW_PRODUCT,
    page
  };
  const result = await Axios.get(GET_PROJECTS_URL, { params: params });
  return result;
};

export const GET_PROJECT_DETAIL_URL = (slug) => `/project/detail/${slug}`;
export const getProjectDetail = async (slug) => {
  const result = await Axios.get(GET_PROJECT_DETAIL_URL(slug));
  return result;
};
export const GET_HOT_REAL_ESTATES_URL = "/list";
export const getHotRealEstates = async () => {
  const params = {
    h: 1,
    Limit: 10,
  };
  const result = await Axios.get(GET_HOT_REAL_ESTATES_URL, { params: params });
  return result;
};
