import React from 'react';
import styles from './banner.module.scss';
import { AutoComplete, Form, Input, Radio, Icon, Select } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import TopIcon from './topIcon';
import queryString from 'query-string';
import { connect } from 'react-redux';
import SearchModel from '../../models/Search';
import HomePageApi from '../../api/homePage';
import _ from 'lodash';
import banner from '../../images/home/banner.jpeg';

const { Option } = Select;

const TopCategoriesIcon = () => {
  return TopIcon.map((icon, index) => {
    return (
      <Link key={index} to={icon.link}>
        <span className={styles.catIcon}>{icon.Icon ? <icon.Icon /> : ''}</span>
        <span className={styles.catText}>{icon.text}</span>
      </Link>
    );
  });
};

class Banner extends React.Component<{ search: SearchModel }> {
  state = {
    dataSource: [],
    filterStatus: [],
  };
  componentDidMount(): void {
    let status = 1;
    HomePageApi.filterStatus()
      .then((res) => {
        let filterStatus = res.data.filter_status;
        if (filterStatus.length) status = filterStatus[0].id;
        this.props.form.setFieldsValue({
          s: status,
        });
        this.setState({
          filterStatus,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSubmit = (e = null) => {
    if (e) e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.search.storeKeyword().then((r) => {
          if (r.data) {
            this.props.search.q = r.data.value;
            this.props.search.qSlug = r.data.slug;
            this.props.history.push({
              pathname: `/${this.props.search.qSlug}.html`,
              search: queryString.stringify(values),
            });
          }
        });
      }
    });
  };
  handleSearch = (value) => {
    if (!_.isEmpty(value)) {
      this.props.search.q = value;
      this.props.search.search().then((response) => {
        this.setState((prevState) => {
          let q = _.some(response.data.results, (o) => {
            return o === this.props.search.q;
          });
          let dataSource = [];
          if (!q) {
            dataSource = [this.props.search.q, ...response.data.results];
          } else {
            dataSource = response.data.results;
          }
          return {
            dataSource,
          };
        });
      });
    } else {
      this.setState({
        dataSource: [],
      });
    }
  };

  handleSelect = (value) => {
    this.props.search.q = value;
    this.handleSubmit();
  };

  render() {
    const { headingContent, isHiddenSearch } = this.props;
    const { getFieldDecorator } = this.props.form;
    let radio_button = [];
    if (this.state.filterStatus.length) {
      radio_button = this.state.filterStatus.map((object) => (
        <Radio.Button
          style={{
            textTransform: 'uppercase',
            fontSize: '18px',
          }}
          key={object.id}
          value={object.id}
        >
          {object.name}
        </Radio.Button>
      ));
    }
    const selectBefore = (
      <Select
        className={styles.filterStatus}
        defaultValue={1}
        style={{ width: 90 }}
      >
        {this.state.filterStatus.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    );

    return (
      <header className={styles.entryHeader}>
        <div
          className={styles.entryFeatured}
          style={{
            backgroundImage: 'url(' + banner + ')',
          }}
        />
        <div className={styles.headerContent}>
          {headingContent}
          {!isHiddenSearch && (
            <Form className={styles.searchForm}>
              <Form.Item>
                {getFieldDecorator('q', {
                  rules: [
                    {
                      required: true,
                      message: 'Xin vui lòng nhập từ khoá tìm kiếm',
                    },
                  ],
                })(
                  <AutoComplete
                    size="large"
                    placeholder="Tìm theo khu vực, dự án, địa điểm..."
                    onSearch={this.handleSearch}
                    dataSource={this.state.dataSource}
                    onSelect={this.handleSelect}
                    className={styles.autoComplete}
                  >
                    <Input
                      className={styles.inputSearch}
                      addonBefore={selectBefore}
                      suffix={
                        <Icon onClick={this.handleSubmit} type="search" className="certain-category-icon" />
                      }
                    />
                  </AutoComplete>
                )}
              </Form.Item>
            </Form>
          )}
        </div>
        <div
          className={styles.topCategories}
          style={{
            display: 'none',
          }}
        >
          <TopCategoriesIcon />
          <div
            style={{
              position: 'relative',
            }}
            className={styles.whatBuy}
          >
            <span className={styles.ctaText}>Nhà bạn tìm thuộc loại nào</span>
          </div>
        </div>
        {this.props.children}
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};

export default connect(
  mapStateToProps,
  null
)(Form.create({ name: 'search' })(withRouter(Banner)));
