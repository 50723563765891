import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './banner.module.scss';

const { Text, Title } = Typography;
export const HeadingContentCommon = () => {
  const domain = useSelector((state) => state?.domainInfo?.domain);
  const provinceName = domain?.province?.name;
  return (
    <>
      <Title className={styles.pageTitle}>
        <span>Tìm nhà đúng giá nhất</span>
        <Text className={styles.tabNational}>
          {provinceName}
        </Text>
      </Title>
      <div className={styles.entrySubtitle}>
        <p>
          Nhà đất được thẩm định, giá tính chuẩn nhất đến từng khu vực, tuyến
          đường, hẻm, tình trạng nhà
        </p>
      </div>
    </>
  );
};
