import { Icon, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "sweetalert2/src/sweetalert2.scss";
import Api from "../../../../api/dashboardPage";
import { VIPRealEstateTable } from "../components";
//Comp

const VIPRealEstate = () => {
  const [realEstateList, setRealEstateList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);
    Api.myVip(10, pageIndex).then((res) => {
      if (res.data.status) {
        setRealEstateList(res.data.real_estates);
        setTotalItems(res.data.total);
        setLoading(false);
      }
    });
  }, [pageIndex]);
  const changePageIndex = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);
  return (
    <>
      {isLoading ? (
        <Spin
          style={{
            width: `100%`,
            margin: `auto`,
          }}
          indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
        />
      ) : (
        <VIPRealEstateTable
          realEstateList={realEstateList}
          changePageIndex={changePageIndex}
          pageIndex={pageIndex}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default VIPRealEstate;
