import React from "react";
import styles from "./filter.module.scss";
import Options from "./options/options";
import { Icon, Radio, Switch, Tooltip } from "antd";

class Filter extends React.Component<
  {
    view: string,
    typeView: string,
    onClick: void,
    onChangeTypeView: void,
    emptyData?: boolean,
  },
  {}
> {
  changeView = (checked) => {
    if (checked) this.props.onClick("map");
    else this.props.onClick("list");
  };

  changeTypeView = (type) => {
    this.props.onChangeTypeView(type.target.value);
  };

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <div className={styles.filterBar} id="filBar">
        <Options />
        <Radio.Group
          defaultValue={this.props.typeView}
          onChange={this.changeTypeView}
        >
          <Tooltip placement="top" title="List view">
            <Radio.Button value="listView">
              <Icon type="menu-unfold" />
            </Radio.Button>
          </Tooltip>
          <Tooltip placement="top" title="Grid view">
            <Radio.Button value="gridView">
              <Icon type="appstore" />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
        {this.props.emptyData ? null : (
          <Switch
            checkedChildren="Bản đồ"
            checked={this.props.view === "map"}
            unCheckedChildren="Danh sách"
            onChange={this.changeView}
            style={{
              marginLeft: "auto",
            }}
          />
        )}
      </div>
    );
  }
}

export default Filter;
