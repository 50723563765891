import { Button, Col, Input, Row, Select } from "antd";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import styles from "../OnlinePrices.module.scss";

const { Option } = Select;
const BuildingFilter = ({
  properties,
  priceBoardData,
  onChangeProperty,
  onChangeSearch,
  onClearFilter,
}) => {
  const [search, setSearch] = useState();
  const handleChangeInputSearch = (value) => {
    setSearch(value);
    onChangeSearch(value);
  };
  return (
    <Row
      type="flex"
      justify="end"
      gutter={[16, 16]}
      className={styles.buildingFilterContainer}
    >
      <Col span={3}>
        <Button
          block
          onClick={() => {
            onClearFilter();
            setSearch();
          }}
        >
          Xoá bộ lọc
        </Button>
      </Col>
      <Col span={6}>
        <Input
          placeholder="Tìm kiếm theo tên căn hộ"
          onChange={(event) => handleChangeInputSearch(event.target.value)}
          value={search}
        />
      </Col>
      <Col span={6}>
        <Select
          defaultValue="Căn hộ"
          style={{ width: "100%" }}
          onChange={onChangeProperty}
        >
          {properties?.map((item) => (
            <Option key={item?.value} value={item?.label}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

BuildingFilter.defaultProps = {
  properties: null,
};
BuildingFilter.prototype = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
};

export default memo(BuildingFilter);
