import React from "react";
import {
  AutoComplete,
  Col,
  Form,
  InputNumber,
  Row,
  Input,
  Radio,
  Select,
} from "antd";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import PostRealEstatePageApi from "../../../api/postRealEstatePage";
import { getRealEstateDetail } from "../../../utils/realEstateUtils";

const { Option } = Select;
const OptionAutoComplete = AutoComplete.Option;

class HomeStay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: [],
      builders: [],
      investors: [],
      comManagements: [],
    };
  }

  componentDidMount = () => {
    if (this.props.real_estate) {
      this.props.form.setFieldsValue(getRealEstateDetail(this.props.real_estate.details));
    }

    PostRealEstatePageApi.getAllDirections().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          directions: res.data.directions,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  handSelectComManagement = (value, option) => {
    this.props.form.setFieldsValue({
      don_vi_quan_ly: option.props.children,
    });
  };

  handleChangeAutoCompleteComManagement = (name) => {
    if (name) {
      PostRealEstatePageApi.autoCompleteComManagement(name).then((res) => {
        if (res.data.status === 1)
          this.setState({ comManagements: res.data.comManagements });
      });
    }
  };

  handSelectBuilder = (value, option) => {
    this.props.form.setFieldsValue({
      don_vi_thi_cong: option.props.children,
    });
  };

  handleChangeAutoCompleteBuilder = (name) => {
    if (name) {
      PostRealEstatePageApi.autoCompleteBuilder(name).then((res) => {
        if (res.data.status === 1)
          this.setState({ builders: res.data.builders });
      });
    }
  };

  handSelectInvestor = (value, option) => {
    this.props.form.setFieldsValue({
      chu_dau_tu: option.props.children,
    });
  };

  handleChangeAutoCompleteInvestor = (name) => {
    if (name) {
      PostRealEstatePageApi.autoCompleteInvestor(name).then((res) => {
        if (res.data.status === 1)
          this.setState({ investors: res.data.investors });
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Diện tích (m2)">
              {getFieldDecorator("acreage", {
                rules: [
                  { required: true, message: "Vui lòng nhập diện tích!" },
                ],
              })(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Chiều rộng (m)">
              {getFieldDecorator("width")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chiều dài (m)">
              {getFieldDecorator("height")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Số lượng phòng ngủ">
              {getFieldDecorator("so_luong_phong_ngu")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Số lượng nhà vệ sinh">
              {getFieldDecorator("so_luong_nha_ve_sinh")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Thuộc khu">
              {getFieldDecorator("thuoc_khu")(<Input />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Ban công">
              {getFieldDecorator("ban_cong", {
                initialValue: 1,
              })(
                <Radio.Group>
                  <Radio value={0}>Không</Radio>
                  <Radio value={1}>Có</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="View hướng">
              {getFieldDecorator("view_huong", {
                initialValue:
                  !!this.state.directions.length &&
                  this.state.directions[0].code,
              })(
                <Select>
                  {!!this.state.directions.length &&
                    this.state.directions.map((item, index) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Cửa chính hướng">
              {getFieldDecorator("cua_chinh_huong", {
                initialValue:
                  !!this.state.directions.length &&
                  this.state.directions[0].code,
              })(
                <Select>
                  {!!this.state.directions.length &&
                    this.state.directions.map((item, index) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Năm bàn giao">
              {getFieldDecorator("nam_ban_giao")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phí quản lý (đ/m2)">
              {getFieldDecorator("phi_quan_ly")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Đơn vị quản lý">
              {getFieldDecorator(
                "don_vi_quan_ly",
                {}
              )(
                <AutoComplete
                  backfill={true}
                  allowClear={true}
                  onSelect={this.handSelectComManagement}
                  onSearch={this.handleChangeAutoCompleteComManagement}
                >
                  {!!this.state.comManagements.length &&
                    this.state.comManagements.map((item, key) => (
                      <OptionAutoComplete key={key} value={item.id.toString()}>
                        {item.name}
                      </OptionAutoComplete>
                    ))}
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Chủ đầu tư">
              {getFieldDecorator(
                "chu_dau_tu",
                {}
              )(
                <AutoComplete
                  backfill={true}
                  allowClear={true}
                  onSelect={this.handSelectInvestor}
                  onSearch={this.handleChangeAutoCompleteInvestor}
                >
                  {!!this.state.investors.length &&
                    this.state.investors.map((item, key) => (
                      <OptionAutoComplete key={key} value={item.id.toString()}>
                        {item.name}
                      </OptionAutoComplete>
                    ))}
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Đơn vị thi công">
              {getFieldDecorator(
                "don_vi_thi_cong",
                {}
              )(
                <AutoComplete
                  backfill={true}
                  allowClear={true}
                  onSelect={this.handSelectBuilder}
                  onSearch={this.handleChangeAutoCompleteBuilder}
                >
                  {!!this.state.builders.length &&
                    this.state.builders.map((item, key) => (
                      <OptionAutoComplete key={key} value={item.id.toString()}>
                        {item.name}
                      </OptionAutoComplete>
                    ))}
                </AutoComplete>
              )}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default HomeStay;
