import { Breadcrumb, Button, Col, PageHeader, Row } from "antd";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Link } from "react-router-dom";
import EventCategory from "../../component/event/EventCategory";
import EventList from "../../component/event/EventList";
import Image from "../../component/image";
import { ResourceUrl } from "../../config/main";
import { SIZE } from "../../constants/common";
import Helpers from "../../functions/Helpers";
import { Session } from "../../functions/session";
import EventModel from "../../models/Event";
import "./eventDetail.scss";
class EventDetail extends React.Component {
  state = {
    detail: {},
    category: [],
    eventTopView: {
      data: [],
      total: null,
      current_page: null,
      pageSize: null,
      isLoading: false,
    },
    isLoadingTopView: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.slug !== prevProps.match.params.slug ||
      this.props.location !== prevProps.location
    ) {
      this.loadData();
    }
  }

  loadData() {
    let params = queryString.parse(this.props.location.search);
    let { slug } = this.props.match.params;
    let countView = this.getCountViewEvent(slug);

    EventModel.EventDetail(slug, countView).then((res) => {
      this.setState(
        {
          detail: res.detail,
          category: res.eventCategory,
        },
        () => this.updateSessionEvent()
      );
    });
    this.setState({
      isLoadingTopView: true,
    });
    EventModel.EventTopView(slug, params.page)
      .then((res) => {
        const data: EventModel = res;
        this.setState({
          eventTopView: {
            data: data.attributes.data,
            total: data.attributes.total,
            current_page: data.attributes.current_page,
            pageSize: data.attributes.per_page,
          },
          isLoadingTopView: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingTopView: false,
        });
      });
  }

  updateSessionEvent() {
    let eventSeen = Session.getJson("eventSeen");
    if (eventSeen === null) this.saveNewSessionEvent();
    if (eventSeen !== null) {
      if (
        Helpers.getTimestamp(eventSeen.dateSeen) <
        Helpers.getTimestamp(Helpers.getDateNow())
      ) {
        Session.delete("eventSeen");
        this.saveNewSessionEvent();
      } else {
        let uniqueEvent = [];
        uniqueEvent.push(this.state.detail);
        eventSeen.event.map((x) =>
          uniqueEvent.filter((a) => a.slug === x.slug).length > 0
            ? null
            : uniqueEvent.push(x)
        );
        eventSeen.event = uniqueEvent;
        Session.putJson("eventSeen", eventSeen);
      }
    }
  }

  saveNewSessionEvent() {
    let eventSeen = {};
    eventSeen.dateSeen = "";
    eventSeen.event = [];
    eventSeen.dateSeen = Helpers.getDateNow();
    eventSeen.event.push(this.state.detail);
    Session.putJson("eventSeen", eventSeen);
  }

  getCountViewEvent(slug) {
    let countView = true;
    let eventSession = Session.getJson("eventSeen");
    if (eventSession) {
      let seen = eventSession.event.find((value, index) => {
        if (value.slug === slug) return value;
        return null;
      });
      if (seen) countView = false;
    }
    return countView;
  }

  breadcrumbPage() {
    const breadcrumb = [
      {
        breadcrumbName: "Home",
        path: "/",
      },
      {
        breadcrumbName: "Sự kiện",
        path: "/event",
      },
      {
        breadcrumbName: "category",
      },
      {
        breadcrumbName: this.state.detail.title,
      },
    ];
    return breadcrumb;
  }

  customBreadcrumb(route, routesList) {
    const lastElement = routesList.indexOf(route) === routesList.length - 1;
    if (route.breadcrumbName === "category") {
      if (!this.state.detail.event_category) return null;

      if (this.state.detail.event_category) {
        const catalog = this.state.detail.event_category.map((ctg, i) => {
          return (
            <Link key={i} to={"/event/category/" + ctg.code}>
              {ctg.name +
                (i === this.state.detail.event_category.length - 1 ? "" : ",")}
            </Link>
          );
        });
        return <Breadcrumb.Item>{catalog}</Breadcrumb.Item>;
      }
    } else {
      return lastElement ? (
        <span>{route.breadcrumbName}</span>
      ) : (
        <Breadcrumb.Item>
          <Link to={route.path}>{route.breadcrumbName}</Link>
        </Breadcrumb.Item>
      );
    }
  }

  render() {
    return (
      <div className="site-event detail">
        <div className="section-wrap">
          <div className="event-content">
            <Row gutter={{ xs: 0, lg: 16 }}>
              <Col xs={24} lg={16} className="event-html">
                <div className="post-heading">
                  <PageHeader
                    breadcrumb={{
                      routes: this.breadcrumbPage(),
                      itemRender: (route, params, routes, paths) =>
                        this.customBreadcrumb(route, routes),
                      separator: "",
                    }}
                    title={this.state.detail.title}
                    subTitle={this.state.detail.keyword}
                    extra={
                      <Button type="link" disabled>
                        {moment(this.state.detail.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </Button>
                    }
                  >
                    <div className="hs-featured-image-wrapper">
                      <Image
                        className="img-ful img-post"
                        src={ResourceUrl + this.state.detail.cover_image}
                      />
                    </div>
                    <div className="post-body">
                      <span className="description">
                        {this.state.detail.description}
                      </span>
                      <span
                        className="body"
                        dangerouslySetInnerHTML={{
                          __html: this.state.detail.body,
                        }}
                      />
                    </div>
                  </PageHeader>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <EventCategory category={this.state.category} />
                <EventList
                  size={SIZE.SMALL}
                  title={"Những sự kiện quan tâm nhất "}
                  isLoading={this.state.isLoadingTopView}
                  {...this.state.eventTopView}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
export default EventDetail;
