import { Button, Col, Icon, Modal, Row, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import CreateUpdateArticleApi from "../../../api/createUpdateArticlePage";
// Comp
import { PageContent, PageSidebar, Wrapper } from "../components";
import ArticleTable from "./components/ArticleTable";

import { MENU_ARTICLES_ID } from "../constants";
import styles from "../User.module.scss";

const { Title } = Typography;
const { confirm } = Modal;

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const history = useHistory();

  const fetchData = (pageIndexParam) => {
    setLoading(true);
    CreateUpdateArticleApi.getMyArticles(10, pageIndexParam)
      .then((res) => {
        if (res.data.status) {
          setArticles(res.data.articles);
          setTotalItems(res.data.total);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(pageIndex);
  }, [pageIndex]);
  const changePageIndex = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);
  const handleRedirectToCreateArticle = () =>
    history.push("/user/article/create");

  const handleDeleteArticle = (id) => {
    confirm({
      title: "Bạn có chắc muốn xóa tin tức này?",
      okText: "Có",
      okType: "danger",
      cancelText: "Không",
      onOk: () => {
        CreateUpdateArticleApi.remove(id).then((res) => {
          if (res.data.status) {
            fetchData(pageIndex);
            Swal.fire({
              icon: "success",
              title: `<p class='${styles.swalTitle}'>Xoá thành công</p>`,
              width: "23rem",
              showConfirmButton: true,
            });
          }
        });
      },
    });
    
  };
  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_ARTICLES_ID} />
        <PageContent>
          <Row type="flex" justify="space-between">
            <Col>
              <Title style={{ color: "black" }} level={3}>
                Bài đánh giá của tôi
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{
                  fontSize: "16px",
                }}
                onClick={handleRedirectToCreateArticle}
              >
                <Icon type="form" style={{ fontSize: 18 }} />
                Tạo mới
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <Spin
              style={{
                width: `100%`,
                margin: `auto`,
              }}
              indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
            />
          ) : (
            <div className="table-history">
              <ArticleTable
                articles={articles}
                changePageIndex={changePageIndex}
                pageIndex={pageIndex}
                isLoading={isLoading}
                totalItems={totalItems}
                onDeleteArticle={handleDeleteArticle}
              />
            </div>
          )}
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default Articles;
