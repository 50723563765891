import React, { PureComponent } from 'react';
import CardItem from './cardItem/cardItem';
import './cardColumn.scss'
import { Pagination, Row, Select, Col, Typography } from 'antd';
const { Title } = Typography;

class CardColumnData extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        minPage: 0,
        maxPage: 0,
        typeCategory: 0
      };
    }

    componentDidMount() {
      this.setState({
        maxPage: this.props.numEachPage
      });
    }

    handleChangePage = value => {
      this.setState({
        minPage: (value - 1) * this.props.numEachPage,
        maxPage: value * this.props.numEachPage
      });
    };

    handleChangeCategory = value => {
      this.setState({
          typeCategory: value
      });
    }

    getCategoryOption = () => {
        let category = [];
        if (Object.entries(this.props.category).length > 0) {
          this.props.category.forEach((ob, i) => {
              category.push(<Select.Option key={ob.id} value={ob.id}>{ob.name}</Select.Option>)
          });
        }
        return category;
    }

    getDataByOption = (data) => {
      let cartItem = [];
      data.slice(this.state.minPage, this.state.maxPage).forEach((element, index) => {
       if(element.category_id === this.state.typeCategory)
          cartItem.push(<CardItem key={index}
                                  fourItem={ this.props.fourItem }
                                  isSubDomain={ this.props.isSubDomain }
                                  data={ element }/>)
      });

      if(cartItem.length === 0) {
        let category = this.props.category.filter(obj => obj.id === this.state.typeCategory)[0];
        cartItem.push(<span className="no-result">
          Không có BĐS { category.name } mà bạn muốn xem
        </span>);
      }

      return cartItem;
    }

    render() {
      let data = this.props.data;

      if(data.length > 0)
          return (
            <Row type="flex" className="card-column" style={ this.props.style }>
              <Col xs={ 24 } className="search-container">
                  <Title className='red-color' level={4}>{ this.props.title }</Title>
                  {
                    Object.entries(this.props.category).length > 0 ?
                        <Select defaultValue={0} className="select" onChange={ this.handleChangeCategory }>
                            <Select.Option key={0} value={0}>Lọc theo</Select.Option>
                            { this.getCategoryOption() }
                        </Select>
                      : null
                  }
              </Col>
              { this.state.typeCategory !== 0 ?
                this.getDataByOption(data) :
                data.slice(this.state.minPage, this.state.maxPage).map((element, index) => {
                    return <CardItem key={index}
                                     fourItem={ this.props.fourItem }
                                     isSubDomain={ this.props.isSubDomain }
                                     data={ element }/>
                })
              }
             {
                data.length > this.props.numEachPage ?
                  <div className="pagination-container">
                    <Pagination
                        defaultCurrent={1}
                        defaultPageSize={ this.props.numEachPage } //default size of page
                        onChange={this.handleChangePage}
                        total={ data.length }
                      />
                  </div> : null
              }
            </Row>
          )
      else return null;
    }
}


export default CardColumnData;
