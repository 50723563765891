import { debounce } from "lodash";
import moment from "moment";

export const generateUId = () => {
  const head = Date.now().toString(36);
  const tail = Math.random().toString(36).substr(2);
  return head + tail;
};

const WAIT_MS = 550;

export const withDebounce = (func) => debounce(func, WAIT_MS);

export const formattedDateShortTime = (value) => {
  if(!value) return null;
  return moment(value).format("DD/MM/YYYY");
};
