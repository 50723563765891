import React from "react";
import { Col, Form, InputNumber, Row, Select } from "antd";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import PostRealEstatePageApi from "../../../api/postRealEstatePage";
import { getRealEstateDetail } from "../../../utils/realEstateUtils";

const { Option } = Select;
class ShopHouseCanHo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: [],
    };
  }

  componentDidMount = () => {
    if (this.props.real_estate) {
      this.props.form.setFieldsValue(getRealEstateDetail(this.props.real_estate.details));
    }

    PostRealEstatePageApi.getAllDirections().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          directions: res.data.directions,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Diện tích (m2)">
              {getFieldDecorator("acreage", {
                rules: [
                  { required: true, message: "Vui lòng nhập diện tích!" },
                ],
              })(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Chiều rộng (m)">
              {getFieldDecorator("width")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chiều dài (m)">
              {getFieldDecorator("height")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Đường vào rộng (m)">
              {getFieldDecorator("duong_vao_rong")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Số năm sở hữu">
              {getFieldDecorator("so_nam_so_huu")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Số mặt tiền">
              {getFieldDecorator("so_mat_tien")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Cửa chính hướng">
              {getFieldDecorator("cua_chinh_huong", {
                initialValue:
                  !!this.state.directions.length &&
                  this.state.directions[0].code,
              })(
                <Select>
                  {!!this.state.directions.length &&
                    this.state.directions.map((item, index) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="View hướng">
              {getFieldDecorator("view_huong", {
                initialValue:
                  !!this.state.directions.length &&
                  this.state.directions[0].code,
              })(
                <Select>
                  {!!this.state.directions.length &&
                    this.state.directions.map((item, index) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Chiều cao thông thủy (m)">
              {getFieldDecorator("chieu_cao_thong_thuy")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Phí quản lý (đ/m2)">
              {getFieldDecorator("phi_quan_ly")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Tổng số căn hộ">
              {getFieldDecorator("so_can_ho")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Tổng số dân dự kiến">
              {getFieldDecorator("so_dan_du_kien")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ShopHouseCanHo;
