import React from 'react'
import { Doughnut } from 'react-chartjs-2';

const ChartRate  = (props) => {
    const sumRate = props.sumRate,
          loanMoney = props.loanMoney,
          price = props.price,
          totalPaid = props.totalPaid,
          series = [
            Math.round(loanMoney/totalPaid * 100),
            Math.round(price/totalPaid * 100),
            Math.round(sumRate/totalPaid * 100),
          ],
          labelDisplay = [
            props.convertNumber(loanMoney),
            props.convertNumber(price),
            props.convertNumber(sumRate),
          ];

    const data = {
            labels: [
              'Cần trả trước',
              'Gốc cần trả',
              'Lãi cần trả'
            ],
            datasets: [{
          		data: series,
              backgroundColor: [
            		'#1890ff',
            		'black',
            		'red'
          		],
            }]
    }

    const options =  {
      legend: {
        display: false
      },
      tooltips: {
          titleFontColor: 'grey',
          backgroundColor: 'white',
          displayColors: false,
          caretSize: 0,
          titleFontSize: 16,
          bodyFontSize: 16,
          bodySpacing: 0,
          titleSpacing: 0,
          xPadding: 2,
          yPadding: 2,
          cornerRadius: 2,
          titleMarginBottom: 2,
          callbacks: {
             title: function(item, data) {
                 var title = data.labels[item[0].index];
                 return title;
             },
             labelTextColor: function(tooltipItem, chart) {
                    return data.datasets[0].backgroundColor[tooltipItem.index];
             },
             label: function(tooltipItem, data) {
                var label =   data.datasets[0].data[tooltipItem.index] + "%: " + labelDisplay[tooltipItem.index];
                return label;
            }
         },
       },
       cutoutPercentage: 80,
    };



    if(!isNaN(totalPaid)) {
      return (
          <div className="chart-estimate">
              <Doughnut
                  className="chart-custom"
                  data={ data }
                  options={ options }
                  width={100}
                  height={80 }
                  type="donut"
              />
              <div id="chartjs-tooltip"></div>
              <div className="price-estimate">
                  <div className="text" >
                    {
                        props.formatMillionTo2Decimal(props.totalPaid) +
                       (props.priceProps > 1000000000 ? ' tỷ' : ' triệu')
                    }
                  </div>
              </div>
          </div>
       )
    }

    return '....';
}



export default ChartRate
