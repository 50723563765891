import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Input } from "antd";

import "./search.scss";

const Search = (props) => {
  const {
    title,
    search,
    listItem,
    onSearch,
    searchPricing,
    onSearchPricing,
    searchName = "",
  } = props;

  return (
    <div className="search-container">
      {title && <h2>{title}</h2>}
      {typeof search !== "undefined" && (
        <Input
          onChange={(event) => onSearch(event.target.value)}
          defaultValue={search}
          placeholder="Tìm thành phố tỉnh thành"
        />
      )}
      <div className="type-list">
        {listItem.map((item) => (
          <Checkbox
            key={item.id + "_" + item.name}
            className="type-list__item"
            value={item.id}
            onChange={(event) =>
              onSearchPricing(searchName, event.target.value)
            }
            checked={searchPricing.some((search) => item.id === search)}
          >
            {item.name}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

Search.propTypes = {
  title: PropTypes.string,
  search: PropTypes.string,
  listItem: PropTypes.array,
  onSearch: PropTypes.func,
  searchPricing: PropTypes.array,
  onChangeSearchPricing: PropTypes.func,
  searchName: PropTypes.string,
};

Search.defaultProps = {
  title: "",
  listItem: [],
  onSearch: () => null,
  searchPricing: [],
  onChangeSearchPricing: () => null,
  searchName: "",
};

export default Search;
