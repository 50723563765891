import { Icon, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import dashboardApi from "../../../api/dashboardPage";
// COMP
import { PageContent, PageSidebar, Wrapper } from "../components";
import {
  MENU_DASHBOARD_ID,
  SUMMARY_LIST,
  SUMMARY_RENT_ID,
  SUMMARY_REVIEW_ID,
  SUMMARY_SALE_ID,
  SUMMARY_SOLD_ID,
} from "../constants";
import { SummaryCard } from "./components";

const { Title } = Typography;
const Dashboard = () => {
  const [summaryList, setSummaryList] = useState(SUMMARY_LIST);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let localSummaryList = [...summaryList]; 
    const fetchMySell = async () => {
      const data = await dashboardApi.mySellRealEstates();
      const newList = localSummaryList?.map((item) => {
        if (item?.id === SUMMARY_SALE_ID) {
          return { ...item, count: data?.data?.count };
        } else {
          return {
            ...item,
          };
        }
      });
      localSummaryList = [...newList];
      setSummaryList(localSummaryList);
    };

    const fetchMyRent = async () => {
      const data = await dashboardApi.myRentRealEstates();
      const newList = localSummaryList?.map((item) => {
        if (item?.id === SUMMARY_RENT_ID) {
          return { ...item, count: data?.data?.count };
        } else {
          return {
            ...item,
          };
        }
      });
      localSummaryList = [...newList];
      setSummaryList(localSummaryList);
    };

    const fetchMyReview = async () => {
      const data = await dashboardApi.myCountArticles();
      const newList = localSummaryList?.map((item) => {
        if (item?.id === SUMMARY_REVIEW_ID) {
          return { ...item, count: data?.data?.count };
        } else {
          return {
            ...item,
          };
        }
      });
      localSummaryList = [...newList];
      setSummaryList(localSummaryList);
    };

    const fetchMySold = async () => {
      const data = await dashboardApi.mySoldRealEstates();
      const newList = localSummaryList?.map((item) => {
        if (item?.id === SUMMARY_SOLD_ID) {
          return { ...item, count: data?.data?.count };
        } else {
          return {
            ...item,
          };
        }
      });
      localSummaryList = [...newList];
      setSummaryList(localSummaryList);
      setIsLoading(false);
    };
    fetchMySell();
    fetchMyRent();
    fetchMyReview();
    fetchMySold();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_DASHBOARD_ID} />
        <PageContent withoutBoxShadow>
          <Title style={{ color: "black" }} level={3}>
            Dashboard
          </Title>

          {isLoading ? (
            <Spin
              style={{
                width: `100%`,
                margin: `auto`,
              }}
              indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
            />
          ) : (
            <div>
              {summaryList?.map((item) => (
                <SummaryCard
                  key={item.id}
                  icon={item.icon}
                  link={item.to}
                  title={item.title}
                  count={item.count}
                  className={item.className}
                />
              ))}
            </div>
          )}
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default Dashboard;
