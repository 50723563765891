import PropTypes from "prop-types";
import _ from "lodash";

export default function DetectEmpty(props) {
    return (
        _.isEmpty(props.val) ? null : props.returnVal
    );
}

DetectEmpty.propTypes = {
    val: PropTypes.any.isRequired,
    returnVal: PropTypes.element.isRequired
};

DetectEmpty.defaultProps = {
    val: null,
    returnVal: null
};