import Axios from "../axiosApi";

const RealEstatePricingApi = {
  getAll: function (provinceCode, category) {
    return Axios.get(
      `/real-estate-pricing/getAll?provinceCode=${provinceCode}${
        category && `&category=${category}`
      }`
    );
  },
};

export default RealEstatePricingApi;
