import Axios from "../axiosApi";

const Services = {
    getJustSold() {
        return Axios.get('/services/just-sold').then(res => res.data);
    },
    hotKeyword() {
        return Axios.get('/hot-keyword').then(res => res.data);
    },
    priceEstimate(params) {
        return Axios.get('/services/price-estimate', {
            params
        }).then(res => res.data);
    },
    saveContact(slug, params) {
        return Axios.post('/contacts/save-contact/'+slug, params).then(res => res.data);
    }
};

export default Services
