import { Col, Layout } from "antd";
import React from "react";
import styles from "../User.module.scss";

const PageContent = ({ children, withoutBoxShadow }) => {
  return (
    <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
      <Layout
        className={`${styles.container} ${
          withoutBoxShadow ? "" : styles.boxShadow
        }`}
      >
        {children}
      </Layout>
    </Col>
  );
};

export default PageContent;
