import { Badge, Button, Empty, Pagination } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import {
  getBookingStatus,
  isPurchaseTimeOut,
  isWaitingForDeposite,
  shouldDisplayPurchaseConfirm,
} from "../../../../../utils/BookingUtils";
import { formatCurrency } from "../../../../../utils/FormatCurrency";
import styles from "../BookingList.module.scss";

const BookingTable = ({
  bookedList,
  onChangeTable,
  pageIndex,
  totalItems,
  pageSize,
  onPerchaseConfirmation,
  isLoadinngPerchaseConfirmation,
  requestRefundMutation,
}) => {
  const history = useHistory();
  const renderBadgeStatus = (currentStatus) => {
    const status = getBookingStatus(currentStatus);
    if (status) {
      return <Badge color={status.color} text={status.label} />;
    }
    return null;
  };
  const handleRedirectToBookingDetail = (id) => {
    history.push(`/user/booking-list/${id}`);
  };
  const handleRedirectToTransaction = (id) => {
    history.push(`/cart/transaction/order/${id}`);
  };
  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Tên dự án</Th>
            <Th>Tên phân khu</Th>
            <Th>Tầng số</Th>
            <Th>Tên căn hộ</Th>
            <Th>Giá tiền</Th>
            <Th>Trạng thái</Th>
            <Th>Ngày booking</Th>
            <Th>Thao tác</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {bookedList?.length > 0 ? (
            bookedList.map((item, key) => (
              <Tr key={key}>
                <Td>{item.project_name}</Td>
                <Td>{item.subdivision_name}</Td>
                <Td>{item.floor}</Td>
                <Td>{item.apartment_name}</Td>
                <Td>{formatCurrency(item.amount, false)} VND</Td>
                <Td>{renderBadgeStatus(item.status)}</Td>
                <Td>{moment(item.created_at).format("DD/MM/YYYY hh:mm:ss")}</Td>
                <Td>
                  <div className={styles.actionWrapper}>
                    <Button
                      type="ghost"
                      className={styles.commonBtn}
                      style={{ backgroundColor: "#1890FF" }}
                      onClick={() => handleRedirectToBookingDetail(item.id)}
                    >
                      Xem chi tiết
                    </Button>
                    {isWaitingForDeposite(item?.status) && (
                      <Button
                        type="ghost"
                        className={styles.commonBtn}
                        style={{ backgroundColor: "#bf0000" }}
                        onClick={() => handleRedirectToTransaction(item.id)}
                      >
                        Thanh toán
                      </Button>
                    )}
                    {shouldDisplayPurchaseConfirm(item) && (
                      <Button
                        type="ghost"
                        className={styles.commonBtn}
                        loading={isLoadinngPerchaseConfirmation}
                        style={{ backgroundColor: "#21B21E" }}
                        onClick={() => onPerchaseConfirmation(item.id)}
                      >
                        Xác nhận mua
                      </Button>
                    )}
                    {isPurchaseTimeOut(item?.status) && (
                      <Button
                        type="primary"
                        className={styles.commonBtn}
                        loading={requestRefundMutation.isLoading}
                        onClick={() => requestRefundMutation.mutate({bookingId: item?.id})}
                      >
                        Hoàn tiền
                      </Button>
                    )}
                  </div>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        showSizeChanger
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={10}
        pageSize={Number(pageSize)}
        current={pageIndex}
        total={totalItems}
        onShowSizeChange={onChangeTable}
        onChange={onChangeTable}
      />
    </>
  );
};
BookingTable.defaultProps = {
  bookedList: [],
  changePageIndex: () => {},
  pageIndex: 0,
  totalItems: 0,
};

BookingTable.propTypes = {
  bookedList: PropTypes.array,
  changePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  totalItems: PropTypes.number,
};

export default BookingTable;
