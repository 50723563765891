import { Button, List, Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from '../../../../component';
import StreetViewPopup from '../../../../component/project/header/streetView/streetView';
import { useFormModal } from '../../../../hooks';
import shoppingCart from '../../../../images/shopping-cart.svg';
import ImageStreetView from '../../../../images/street-view.png';

const ProjectDetailInfo = ({ project, products }) => {
  const { title, address } = project;
  const history = useHistory();
  const { isVisible, show, close } = useFormModal();

  const renderProduct = () => {
    if (!products || products?.length === 0) return null;
    return (
      <ul>
        {products.map((item, index) => (
          <li key={index}>
            <p>
              {item?.label} {item.quanlity} căn - Giá từ: {item.average_price} m
              <sup>2</sup>
            </p>
          </li>
        ))}
      </ul>
    );
  };

  const infoDescList = [
    {
      lable: 'Chủ đầu tư:',
      value: project?.investor_name,
      valueclassName: 'investor-name',
    },
    {
      lable: 'Quy mô:',
      value: project?.acreage,
    },
    {
      lable: 'Tình Trạng:',
      value: project?.status,
    },
    {
      lable: 'Ngày khởi công:',
      value: project?.start_date,
    },
    {
      lable: 'Ngày kết thúc:',
      value: project?.end_date,
    },
    {
      lable: 'Sản phẩm bảo gồm:',
      value: renderProduct(),
      className: 'product',
      valueclassName: 'product',
    },
  ];
  const hanldeRedirectTo = () => {
    history.push(
      `/gio-hang-booking/du-an/${project?.id}?status-text=${project?.status}`
    );
  };
  return (
    <div className="project-detail-info">
      <h2 className="project-detail-info-heading">{title}</h2>
      <p className="project-detail-info-location">{address}</p>
      {infoDescList.map((item, index) => (
        <div
          key={index}
          className={`project-detail-info-desc ${item?.className || ''}`}
        >
          <p className="project-detail-info-desc-label">{item.lable}</p>
          <p
            className={`project-detail-info-desc-value ${
              item?.valueclassName || ''
            }`}
          >
            {item.value}
          </p>
        </div>
      ))}
      <ul className="google-street-view" onClick={show}>
        <List.Item>
          <List.Item.Meta
            avatar={<Image className="img-full" src={ImageStreetView} />}
            title={<span>Xem 3d tình trạng thực tế</span>}
            description="Google street view"
          />
        </List.Item>
      </ul>
      {/* <Button className='project-detail-download-btn'>
        <DownloadOutlined />
        <span>Tải bảng giá</span>
      </Button> */}
      {project.has_cart_booking && (
        <Button
          block
          className="project-detail-info-shopping-cart"
          onClick={hanldeRedirectTo}
        >
          <Image src={shoppingCart} />
          <span>Giỏ Hàng Booking</span>
        </Button>
      )}
      <Modal
        title="Tình trạng thực tế  của dự án"
        visible={isVisible}
        onCancel={close}
        width={800}
        centered
        className="google-street-popup"
      >
        <StreetViewPopup lat={project.lat} lng={project.lng} zoom={10} />
      </Modal>
    </div>
  );
};

export default ProjectDetailInfo;
