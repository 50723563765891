import React from "react";

import CountDown from "./";
import "./styles.scss";

class CountdownContainer extends React.Component {
  state = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  componentDidMount = () => {
    const { timeTillDate } = this.props;
    const then = new Date(timeTillDate).getTime();
    if (then > new Date().getTime()) {
      setInterval(() => {
        const now = new Date().getTime();
        const countdown = then - now;
        const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (countdown % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((countdown % (1000 * 60)) / 1000);
        this.setState({ days, hours, minutes, seconds });
      }, 1000);
    }
  };

  render() {
    return <CountDown {...this.state} />;
  }
}

export default CountdownContainer;
