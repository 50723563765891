import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Button, Empty, Modal, Spin, Icon, Pagination } from "antd";
import "./index.scss";
import PostRealEstatePageApi from "../../api/postRealEstatePage";
import { withRouter } from "react-router-dom";
import { ResourceUrl } from "../../config/main.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Helpers from "../../functions/Helpers";
import { Link } from "react-router-dom";
import moment from "moment";

const { confirm } = Modal;

class MySoldList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listMySoldRealEstates: [],
      totalMySoldRealEstates: 0,
      currentMySoldRealEstatesPage: 1,
    };
  }

  componentDidMount = () => {
    PostRealEstatePageApi.mySoldRealEstatesList(20, 1).then((res) => {
      if (res.data.status) {
        this.setState({
          listMySoldRealEstates: res.data.real_estates,
          totalMySoldRealEstates: res.data.total,
          isLoading: false,
        });
      }
    });
  };

  handleEdit = (slug) => {
    this.props.history.push(`/me/real-estate/edit/${slug}`);
  };

  handleView = (slug) => {
    this.props.history.push(`/bat-dong-san/${slug}`);
  };

  handleDelete = (id) => {
    confirm({
      title: "Bạn có chắc muốn xóa tin bất động sản này?",
      okText: "Có",
      okType: "danger",
      cancelText: "Không",
      onOk: () => {
        PostRealEstatePageApi.remove(id).then((res) => {
          if (res.data.status) {
            const index = this.state.listMySoldRealEstates.findIndex((item) => {
              return item.id === id;
            });
            let temp = [...this.state.listMySoldRealEstates];
            if (index > -1) {
              temp.splice(index, 1);
            }
            this.setState({
              listMySoldRealEstates: temp,
            });
            Swal.fire({
              icon: "success",
              title: "Xóa thành công",
              showConfirmButton: true,
            });
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { isLoading } = this.state;
    return isLoading ? (
      <Spin
        style={{
          width: `100%`,
          margin: `auto`,
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
      />
    ) : (
      <>
        <Table>
          <Thead className="ant-table-thead">
            <Tr>
              <Th>Ảnh bìa</Th>
              <Th>Tiêu đề</Th>
              <Th>Diện tích</Th>
              <Th>Giá</Th>
              <Th>Ngày đăng</Th>
              <Th>&nbsp;&nbsp;&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody className="ant-table-tbody">
            {this.state.listMySoldRealEstates &&
            !!this.state.listMySoldRealEstates.length ? (
              this.state.listMySoldRealEstates.map((item, key) => (
                <Tr key={key}>
                  <Td>
                    <div
                      className="img"
                      style={{
                        backgroundImage: `${
                          item.real_estate_galleries
                            ? `url(${ResourceUrl}${item.real_estate_galleries})`
                            : `url(${ResourceUrl}${item.cover_image})`
                        }`,
                      }}
                    ></div>
                  </Td>
                  <Td className="width-title">
                    <Link to={`/bat-dong-san/${item.slug}`}>
                      <div className="hide-on-mobile">{item.title}</div>
                      <div
                        className="hide-on-desktop"
                        style={{ marginTop: "10px" }}
                      >
                        {item.title}
                      </div>
                      <div style={{ color: `#BF0000`, fontSize: `12px` }}>
                        {`${item.address_number} ${item.street}`},{" "}
                        {`${item.district_name}, ${item.province_name}`}
                      </div>
                      <div
                        className="hide-on-mobile"
                        style={{ color: `#62D273`, fontSize: `12px` }}
                      >
                        {item.view_number === 0 ? "0" : item.view_number} lượt
                        xem
                      </div>
                      <div
                        className="hide-on-desktop"
                        style={{ fontSize: `12px` }}
                      >
                        {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                        {"  "}
                        <span style={{ color: `#62D273` }}>
                          {item.view_number === 0 ? "0" : item.view_number} lượt
                          xem
                        </span>
                      </div>
                      <div
                        className="hide-on-desktop"
                        style={{
                          marginTop: "10px",
                          color: `#ff4d4f`,
                          fontSize: `20px`,
                        }}
                      >
                        {item.price > 1000000000
                          ? `${Helpers.convertPrice(item.price, "Tỷ")} tỷ`
                          : `${Helpers.convertPrice(
                              item.price,
                              "Triệu"
                            )} triệu`}{" "}
                        / {`${parseInt(item.acreage)}`} m<sup>2</sup>
                      </div>
                    </Link>
                  </Td>
                  <Td className="hide-on-mobile">
                    {`${parseInt(item.acreage)}`} m<sup>2</sup>
                  </Td>
                  <Td className="hide-on-mobile">
                    {item.price > 1000000000
                      ? `${Helpers.convertPrice(item.price, "Tỷ")} tỷ`
                      : `${Helpers.convertPrice(item.price, "Triệu")} triệu`}
                  </Td>
                  <Td className="hide-on-mobile">
                    {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                  </Td>
                  <Td className="td-action">
                    <Link to={`/bat-dong-san/${item.slug}`}>
                      <Button className="btn-user" type="primary" icon="eye" />
                    </Link>
                    <Link to={`/me/real-estate/edit/${item.slug}`}>
                      <Button className="btn-edit" type="primary" icon="edit" />
                    </Link>
                    <Button
                      type="primary"
                      icon="delete"
                      className="btn-delete"
                      onClick={this.handleDelete.bind(this, item.id)}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6">
                  <Empty />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Pagination
          style={{ float: "right", marginTop: "1rem" }}
          defaultPageSize={20}
          pageSize={20}
          current={this.state.currentMySoldRealEstatesPage}
          total={this.state.totalMySoldRealEstates}
          onChange={(page, pageSize) => {
            this.setState({
              isLoading: true,
              currentMySoldRealEstatesPage: page,
            });
            PostRealEstatePageApi.mySoldRealEstatesList(20, page).then(
              (res) => {
                if (res.data.status) {
                  this.setState({
                    listMySoldRealEstates: res.data.real_estates,
                    totalMySoldRealEstates: res.data.total,
                    isLoading: false,
                  });
                }
              }
            );
          }}
        />
      </>
    );
  }
}

export default withRouter(MySoldList);
