import Axios from "../axiosApi";
import {LowerAcreage, LowerPrice, Page, Query, Status, Type} from "../config/params";
import _ from 'lodash';

const SearchPageApi = {
    getFilterOption(){
        return Axios.get('/filter/option');
    },
    filter(slug){
        const params = this.getParams();
        return Axios.get(!_.isEmpty(slug) ? '/filter/'+slug : '/filter' ,{params:params});
    },
    getParams(){
        let params = {};
        if (typeof window !== "undefined") {
            const url = new URL(window.location.href);
            if(url.searchParams.get(Status) !== null)
                params[Status] = url.searchParams.get(Status);
            if(url.searchParams.get(Query) !== null)
                params[Query] = url.searchParams.get(Query);
            if(url.searchParams.get(Type) !== null)
                params[Type] = url.searchParams.get(Type);
            if(url.searchParams.get(LowerAcreage) !== null)
                params[LowerAcreage] = url.searchParams.get(LowerAcreage);
            if(url.searchParams.get(LowerPrice) !== null)
                params[LowerPrice] = url.searchParams.get(LowerPrice);
            params[Page] = url.searchParams.get(Page) || 1;
        }
        return params;
    }
};

export default SearchPageApi;
