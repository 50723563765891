import numeral from 'numeral';
import React from 'react';
import { http, MainDomain } from '../config/main';
import 'numeral/locales/vi';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const Helpers = {
  isJSON: (str) => {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  },
  isBlank: (str) => {
    return !str || /^\s*$/.test(str);
  },
  formatDate: (date) => {
    return moment(date).format('L');
  },
  getTimestamp: (time) => {
    const date = new Date(time);
    return (date.getTime() - date.getTimezoneOffset() * 60 * 1000) / 1000;
  },
  moneyFormatVn: (money) => {
    return numeral(parseFloat(money, 2)).format('0[.]00 a');
  },
  getDateNow: () => {
    let today = new Date(),
      dd = today.getDate(),
      mm = today.getMonth() + 1,
      yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
  },
  getTextByRating: (rating) => {
    if (rating === 0) return 'Chưa có đánh giá';
    else if (rating < 5) return 'Yếu';
    else if (rating >= 5 && rating < 6) return 'Bình thường';
    else if (rating >= 6 && rating < 7) return 'Trung bình';
    else if (rating >= 7 && rating < 8) return 'Khá';
    else if (rating >= 8 && rating < 8.5) return 'Tốt';
    else if (rating >= 8.5 && rating < 8.9) return 'Rất tốt';
    else if (rating >= 9 && rating <= 10) return 'Xuất xắc';
  },
  convertNumber: (number) => {
    let strNumber = '';
    strNumber = String(number);
    return strNumber.replace(/\d(?=(?:\d{3})+(?!\d))/g, '$&,');
  },
  convertPrice: (price, currency) => {
    if (currency === 'Tỷ') return price / 1000000000;
    else if (currency === 'Triệu') return price / 1000000;
  },
  getString: (content, substring = false) => {
    let string_return = '';
    if (content !== null) string_return = content + ', ';

    if (substring)
      string_return = string_return.substring(0, string_return.length - 2);

    return string_return;
  },
  slug: (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeeiiiiooooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  getLinkBroker(item) {
    if (item.user_slug) {
      return (
        <>
          <span className="user_name">
            <a
              href={http + item.user_slug + '.' + MainDomain + '/'}
              target="blank"
            >
              {item.user_name}
            </a>
          </span>
        </>
      );
    }

    if (item.user_name) {
      return (
        <>
          <span className="user_name">
            <Link to={"/broker/" + item.user_slug}>{item.user_name} </Link>
          </span>{" "}
        </>
      );
    }

    return null;
  },
  stripHtml(html, truncate = 200) {
    if (html) return html.replace(/(<([^>]+)>)/gi, '').slice(0, truncate);
    return null;
  },
  urlParam: (name) => {
    let regexStr = '[?&]' + name + '=([^&#]*)';
    const results = new RegExp(regexStr, 'i').exec(window.location.href);
    if (results === null) return null;
    else return decodeURI(results[1]) || 0;
  },

  formatNumber: (int) => {
    return new Intl.NumberFormat().format(int);
  },

  randomArray(items) {
    return items.sort(() => Math.random() - 0.5);
  },

  insertArray(arr, index, newItem) {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)];
  },
};

export default Helpers;
