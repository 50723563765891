import { Empty, Pagination } from "antd";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "sweetalert2/src/sweetalert2.scss";
import { ResourceUrl } from "../../../../config/main";
import Helpers from "../../../../functions/Helpers";

function VIPRealEstateTable({
  realEstateList,
  changePageIndex,
  pageIndex,
  totalItems,
}) {
  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Ảnh bìa</Th>
            <Th width="40%">Tiêu đề</Th>
            <Th>Loai tin</Th>
            <Th>Số ngày</Th>
            <Th>Hình thức</Th>
            <Th>Thanh toán</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {realEstateList.length > 0 ? (
            realEstateList.map((item, key) => (
              <Tr key={item.id}>
                <Td>
                  <div
                    className="img"
                    style={{
                      backgroundImage: `${
                        item.real_estate_galleries
                          ? `url(${ResourceUrl}${item.real_estate_galleries})`
                          : `url(${ResourceUrl}${item.cover_image})`
                      }`,
                    }}
                  ></div>
                </Td>
                <Td>
                  <Link to={`/bat-dong-san/${item.slug}`}>
                    <div className="hide-on-mobile">{item.title}</div>
                    <div
                      className="hide-on-desktop"
                      style={{ marginTop: "10px" }}
                    >
                      {item.title}
                    </div>
                    <div style={{ color: `#BF0000`, fontSize: `12px` }}>
                      {`${item.address_number} ${item.street}`},{" "}
                      {`${item.district_name}, ${item.province_name}`}
                    </div>
                    <div
                      className="hide-on-mobile"
                      style={{ color: `#62D273`, fontSize: `12px` }}
                    >
                      {item.view_number === 0 ? "0" : item.view_number} lượt xem
                    </div>
                    <div
                      className="hide-on-desktop"
                      style={{ fontSize: `12px` }}
                    >
                      {moment(item.created_at).format("DD/MM/YYYY h:m:s")}
                      {"  "}
                      <span style={{ color: `#62D273` }}>
                        {item.view_number === 0 ? "0" : item.view_number} lượt
                        xem
                      </span>
                    </div>
                    <div
                      className="hide-on-desktop"
                      style={{
                        marginTop: "10px",
                        color: `#ff4d4f`,
                        fontSize: `20px`,
                      }}
                    >
                      {Helpers.moneyFormatVn(item.price)}/{" "}
                      {parseInt(item.acreage)} m<sup>2</sup>
                    </div>
                  </Link>
                </Td>
                <Td className="hide-on-mobile">{item.packages_name}</Td>
                <Td className="hide-on-mobile">{item.days}</Td>
                <Td className="hide-on-mobile">{item.payments_name}</Td>
                <Td className="hide-on-mobile">
                  {Helpers.formatNumber(item.price) + "đ"}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="6">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={10}
        pageSize={10}
        current={pageIndex}
        total={totalItems}
        onChange={(page, pageSize) => {
          changePageIndex(page);
        }}
      />
    </>
  );
}

VIPRealEstateTable.defaultProps = {
  realEstateList: [],
  changePageIndex: () => {},
  pageIndex: 0,
  totalItems: 0,
};

VIPRealEstateTable.propTypes = {
  realEstateList: PropTypes.array,
  changePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  totalItems: PropTypes.number,
};

export default VIPRealEstateTable;
