import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import realEstateReducer from "./store/reducers/realEstate";
import domainReducer from "./store/reducers/domain";
import filterSearchReducer from "./store/reducers/filterSearch";
import searchReducer from "./store/reducers/search";
import searchDataReducer from "./store/reducers/searchData";
import profileReducer from "./store/reducers/profile";
import redirectToPostRealEstateReducer from "./store/reducers/redirectToPostRealEstate";
import cartProjectReducer from "./store/reducers/cartProject";
import thunk from "redux-thunk";
let composeEnhancers = compose;
if (typeof window !== "undefined") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const rootReducer = combineReducers({
  realEstate: realEstateReducer,
  domainInfo: domainReducer,
  filterSearch: filterSearchReducer,
  search: searchReducer,
  searchData: searchDataReducer,
  profile: profileReducer,
  redirectToPostRealEstate: redirectToPostRealEstateReducer,
  cartProject: cartProjectReducer,
});

export default function configureStore(preloadedState = {}) {
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)) || preloadedState
  );
}
