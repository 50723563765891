import { useMutation, useQuery } from "@tanstack/react-query";
import { Icon, Modal, Row, Spin, Typography } from "antd";
import React, { useState } from "react";
import {
  confirmPerchase,
  getBookingList,
  GET_BOOKING_LIST_URL,
  requestRefund,
} from "../../../../api/onlinePrice";
import { PageContent, PageSidebar, Wrapper } from "../../components";
import { MENU_BOOKING_LIST_ID } from "../../constants";
import BookedTable from "./components/BookingTable";

const { Title } = Typography;

const BookingList = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading, refetch } = useQuery(
    [
      GET_BOOKING_LIST_URL,
      {
        pageIndex,
        pageSize,
      },
    ],
    () => getBookingList({ pageIndex, pageSize }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  const responseBooks = data?.data?.Books;

  const handleOnChangeTable = (page, pageSize) => {
    setPageIndex(page);
    setPageSize(pageSize);
  };

  const confirmPerchaseMutation = useMutation(confirmPerchase, {
    onSuccess: () => {
      Modal.info({
        title: "Xác nhận mua",
        content: "Xác nhận mua thành công",
      });
      refetch();
    },
    onError: () => {
      Modal.error({
        title: "Máy chủ đang bị lỗi",
        content: "Bạn vui lòng quay lại sau, Cám ơn",
      });
    },
  });

  const requestRefundMutation = useMutation(requestRefund, {
    onSuccess: () => {
      Modal.info({
        title: "Yêu cầu hoàn tiền",
        content: "Yêu cầu hoàn tiền thành công",
      });
      refetch();
    },
    onError: () => {
      Modal.error({
        title: "Máy chủ đang bị lỗi",
        content: "Bạn vui lòng quay lại sau, Cám ơn",
      });
    },
  });

  const handlePerchaseConfirmation = (bookingId) => {
    confirmPerchaseMutation.mutate({ bookingId });
  };
  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_BOOKING_LIST_ID} />
        <PageContent>
          <Title className="text-black" level={3}>
            Danh sách booking
          </Title>
          {isLoading ? (
            <Spin
              style={{
                width: `100%`,
                margin: `auto`,
              }}
              indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
            />
          ) : (
            <div className="table-history">
              <BookedTable
                bookedList={responseBooks?.data}
                onChangeTable={handleOnChangeTable}
                pageIndex={pageIndex}
                isLoading={isLoading}
                pageSize={responseBooks?.per_page}
                totalItems={responseBooks?.total}
                onPerchaseConfirmation={handlePerchaseConfirmation}
                isLoadinngPerchaseConfirmation={
                  confirmPerchaseMutation.isLoading
                }
                requestRefundMutation={requestRefundMutation}
              />
            </div>
          )}
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default BookingList;
