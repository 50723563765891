import React from "react";
import SearchModel from "../../../models/Search";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {Button, Checkbox, List, Slider} from "antd";
import _ from "lodash";
import styles from "./options.module.scss"
import MoneyFormat from "../../moneyFormat";
import queryString from "query-string";
import { withRouter } from 'react-router-dom';

class OptionsMobile extends React.Component<{search: SearchModel, filter_options: {}, close: void}>{

    updateValueSearch = (val, key) => {
        this.props.search[key] = val;
    };

    triggerFilter = () => {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: queryString.stringify(this.props.search.fetchAllQuery())
        });
        this.props.run(this.props.match.params.slug);
        this.props.close();
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {filter_options} = this.props;
        let category = _.map(filter_options.category, (o) => {
            return {
                label: o.name,
                value: o.id
            };
        });
        filter_options.max_price = parseFloat(filter_options.max_price);
        filter_options.max_acreage = parseFloat(filter_options.max_acreage);
        let mask = {0: '0'};
        let sizeMask = {0: '0'};
        mask[filter_options.max_price] = {
            label: <strong><MoneyFormat>{filter_options.max_price}</MoneyFormat></strong>
        };
        sizeMask[filter_options.max_acreage] = {
            label: <strong>{filter_options.max_acreage}m</strong>
        };
        return (
            <React.Fragment>
                <List
                    className={styles.listOptionsMobile}
                >
                    <List.Item>
                        <List.Item.Meta title={<strong>Danh mục</strong>} />
                        <Checkbox.Group defaultValue={this.props.search.t} onChange={(val) => this.updateValueSearch(val, 't')} options={category} />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta title={<strong>Chọn khoảng gia</strong>} />
                        <Slider onAfterChange={(val) => this.updateValueSearch(val === filter_options.max_price ? 0 : val, 'lp')} defaultValue={parseFloat(this.props.search.lp) || filter_options.max_price} step={filter_options.max_price / 10} marks={mask} max={filter_options.max_price} />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta title={<strong>Chọn diện tích</strong>} />
                        <Slider onAfterChange={(val) => this.updateValueSearch(val === filter_options.max_acreage ? 0 : val, 'la')} defaultValue={parseFloat(this.props.search.la) || filter_options.max_acreage} step={filter_options.max_acreage / 10} marks={sizeMask} max={filter_options.max_acreage} />
                    </List.Item>
                </List>
                <Button style={{
                    position: "absolute",
                    bottom: 0
                }} onClick={() => this.triggerFilter()} block type="primary">Áp dụng</Button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        filter_options: state.filterSearch,
        search: state.search
    }
};

const mapDispatchToProps = dispatch => {
    return {
        run: (slug) => dispatch(actions.searchDataQuery(slug))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OptionsMobile));