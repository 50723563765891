import Axios from "../axiosApi";
import { Limit, Page } from "../config/params";

const CustomerApi = {
  myList: (limitPage = 20, page = 1) => {
    const params = {};
    params[Limit] = limitPage;
    params[Page] = page;
    return Axios.get("/customer/my", { params: params });
  },
};

export default CustomerApi;
