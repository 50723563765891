import moment from "moment";
import {
  BUYER_TYPE_ORGANIZATION_VALUE,
  BUYER_TYPE_PERSONAL_VALUE,
} from "./constants";

const getBuyerAddress = ({ province_code, district_id, address }) => {
  return { province_code, district_id, address };
};
const getBuyerTypePersonal = ({
  date_of_birth,
  email,
  identifier,
  identify_type,
  identifier_issue_at,
  identifier_issue_by,
  name,
  phone_number,
  title,
  province_code,
  district_id,
  address,
}) => {
  const buyerAddress = getBuyerAddress({ province_code, district_id, address });
  return {
    ...buyerAddress,
    personal: {
      date_of_birth: moment(date_of_birth),
      email,
      identifier,
      identify_type,
      identifier_issue_at: moment(identifier_issue_at),
      identifier_issue_by,
      name,
      phone_number,
      title,
    },
  };
};
const getBuyerTypeOrganization = ({
  email,
  phone_number,
  name,
  date_of_birth,
  identifier,
  identifier_issue_at,
  identifier_issue_by,
  identifier_name,
  identify_type,
  legal_email,
  legal_identifier,
  legal_identify_type,
  legal_name,
  legal_phone,
  legal_position,
  tax_code,
  province_code,
  district_id,
  address,
}) => {
  const buyerAddress = getBuyerAddress({ province_code, district_id, address });
  return {
    ...buyerAddress,
    organization: {
      email,
      phone_number,
      name,
      date_of_birth: moment(date_of_birth),
      identifier,
      identifier_issue_at: moment(identifier_issue_at),
      identifier_issue_by,
      identifier_name,
      identify_type,
      legal_email,
      legal_identifier,
      legal_identify_type,
      legal_name,
      legal_phone,
      legal_position,
      tax_code,
    },
  };
};
export const getBuyerType = {
  personal: getBuyerTypePersonal,
  organization: getBuyerTypeOrganization,
};
export const getBuyerValue = (type, params) => {
  return getBuyerType[type]({ ...params });
};
export const converBuyerTypeNumberToTypeString = (type) => {
  switch (type) {
    case BUYER_TYPE_PERSONAL_VALUE:
      return "personal";
    case BUYER_TYPE_ORGANIZATION_VALUE:
      return "organization";
    default:
      return "";
  }
};
