import * as React from "react";
import SearchModel from "../../../models/Search";
import { Dropdown, Slider, Icon, Button, Checkbox } from "antd";
import styles from "./options.module.scss";
import "./../../global.scss";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import MoneyFormat from "../../moneyFormat";

class Options extends React.Component<{
  search: SearchModel,
  filter_options: {},
}> {
  state = {
    visible: {},
  };

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    if (
      Object.entries(this.props.filter_options).length > 0 &&
      Object.entries(this.state.visible).length === 0
    ) {
      let visible = this.state.visible;
      for (let key in this.props.filter_options) {
        visible[key] = false;
      }
      this.setState({ visible });
    }
  }

  handleChangeType = (value) => {
    this.props.search.t = value;
    this.reloadUrl();
  };

  handlePrice = (value) => {
    this.props.search.lp = value;
    this.reloadUrl();
  };

  handleAcreage = (value) => {
    this.props.search.la = value;
    this.reloadUrl();
  };

  reloadUrl = () => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(this.props.search.fetchAllQuery()),
    });
    this.props.run(this.props.match.params.slug);
  };

  handleVisibleChange = (flag, key) => {
    let visible = this.state.visible;
    visible[key] = flag;
    this.setState({
      visible: visible,
    });
  };

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    let category = [];
    const mask = { 0: "Giá tối thiểu" };
    const sizeMask = { 0: "Diện thích tối thiểu" };

    if (Object.keys(this.props.filter_options).length) {
      if (this.props.filter_options.category !== undefined) {
        category = this.props.filter_options.category.map((object) => ({
          label: object.name,
          value: object.id,
        }));
      }
      if (this.props.filter_options.max_price !== undefined) {
        this.props.filter_options.max_price = Number(
          this.props.filter_options.max_price
        );
        mask[this.props.filter_options.max_price] = {
          label: "Giá tối đa",
        };
      }
      if (this.props.filter_options.max_acreage !== undefined) {
        this.props.filter_options.max_acreage = Number(
          this.props.filter_options.max_acreage
        );
        sizeMask[this.props.filter_options.max_acreage] = {
          label: "Diện tích tối đa",
        };
      }
    }
    return (
      <ul className={styles.listOptions}>
        <li>
          <Dropdown
            visible={this.state.visible.category}
            onVisibleChange={(flag) =>
              this.handleVisibleChange(flag, "category")
            }
            trigger={["click"]}
            overlay={
              <div className="dropdownMenu">
                <p>Chọn loại BĐS</p>
                <Checkbox.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onChange={this.handleChangeType}
                  defaultValue={this.props.search.t}
                  options={category}
                />
              </div>
            }
          >
            <Button size="large">
              Loại BĐS <Icon type={"down"} />
            </Button>
          </Dropdown>
        </li>
        <li>
          <Dropdown
            visible={this.state.visible.max_price}
            onVisibleChange={(flag) =>
              this.handleVisibleChange(flag, "max_price")
            }
            trigger={["click"]}
            overlay={
              <div className="dropdownMenu">
                <p>
                  <strong>0</strong>đ -{" "}
                  <strong>
                    <MoneyFormat format={"00a+"}>
                      {this.props.filter_options.max_price}
                    </MoneyFormat>
                  </strong>
                </p>
                <Slider
                  className={styles.customSlider}
                  defaultValue={parseFloat(this.props.search.lp)}
                  step={this.props.filter_options.max_price / 10}
                  onAfterChange={this.handlePrice}
                  marks={mask}
                  max={this.props.filter_options.max_price}
                />
              </div>
            }
          >
            <Button size="large">
              Khoảng giá <Icon type={"down"} />
            </Button>
          </Dropdown>
        </li>
        <li>
          <Dropdown
            trigger={["click"]}
            visible={this.state.visible.max_acreage}
            onVisibleChange={(flag) =>
              this.handleVisibleChange(flag, "max_acreage")
            }
            overlay={
              <div className="dropdownMenu">
                <p>
                  <strong>0</strong> m -{" "}
                  <strong>
                    <MoneyFormat format={"0,0"}>
                      {this.props.filter_options.max_acreage}
                    </MoneyFormat>
                  </strong>{" "}
                  m
                </p>
                <Slider
                  className={styles.customSlider}
                  defaultValue={parseFloat(this.props.search.la)}
                  step={this.props.filter_options.max_acreage / 10}
                  onAfterChange={this.handleAcreage}
                  min={0}
                  marks={sizeMask}
                  max={this.props.filter_options.max_acreage}
                />
              </div>
            }
          >
            <Button size="large">
              Diện tích <Icon type={"down"} />
            </Button>
          </Dropdown>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filter_options: state.filterSearch,
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    run: (slug) => dispatch(actions.searchDataQuery(slug)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Options));
