import React from "react";
import { Col, Form, InputNumber, Row, Input, Radio, Select } from "antd";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import PostRealEstatePageApi from "../../../api/postRealEstatePage";
import { getRealEstateDetail } from "../../../utils/realEstateUtils";

const { Option } = Select;
class DuAn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: [],
    };
  }

  componentDidMount = () => {
    if (this.props.real_estate) {
      this.props.form.setFieldsValue(getRealEstateDetail(this.props.real_estate.details));
    }

    PostRealEstatePageApi.getAllDirections().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          directions: res.data.directions,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Diện tích (m2)">
              {getFieldDecorator("acreage", {
                rules: [
                  { required: true, message: "Vui lòng nhập diện tích!" },
                ],
              })(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Chiều rộng (m)">
              {getFieldDecorator("width")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chiều dài (m)">
              {getFieldDecorator("height")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Khoảng lùi trước (m)">
              {getFieldDecorator("khoang_lui_truoc")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Khoảng lùi sau (m)">
              {getFieldDecorator("khoang_lui_sau")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Khoảng lùi bên hông (m)">
              {getFieldDecorator("khoang_lui_ben_hong")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Đường trước nhà rộng (m)">
              {getFieldDecorator("duong_truoc_nha_rong")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Đường mở rộng dự kiến (m)">
              {getFieldDecorator("duong_mo_rong")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Hướng">
              {getFieldDecorator("huong", {
                initialValue:
                  !!this.state.directions.length &&
                  this.state.directions[0].code,
              })(
                <Select>
                  {!!this.state.directions.length &&
                    this.state.directions.map((item, index) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Quy hoạch">
              {getFieldDecorator("quy_hoach")(<Input />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Hiện trạng">
              {getFieldDecorator("hien_trang")(<Input />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Nợ thuế">
              {getFieldDecorator("no_thue", {
                initialValue: 0,
              })(
                <Radio.Group>
                  <Radio value={0}>Không</Radio>
                  <Radio value={1}>Có</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Năm cấp sổ">
              {getFieldDecorator("nam_cap_so")(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item label="Số tầng được xây dựng">
              {getFieldDecorator("so_tang_duoc_xay_dung")(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Mức độ dân cư">
              {getFieldDecorator("muc_do_dan_cu", {
                initialValue: 1,
              })(
                <Radio.Group>
                  <Radio value={1}>Cực đông</Radio>
                  <Radio value={2}>Đông</Radio>
                  <Radio value={3}>Còn thưa</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label="Chứng minh tài chính">
            {getFieldDecorator("chung_minh_tai_chinh", {
              initialValue: 1,
            })(
              <Radio.Group>
                <Radio value={0}>Không</Radio>
                <Radio value={1}>Có</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Row>
      </React.Fragment>
    );
  }
}

export default DuAn;
