import { Card, Skeleton, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import HomePageApi from '../../../../api/homePage';
import CardSlider from '../../../../component/card_slider/cardSlider';
import Image from '../../../../component/image';
import { DomainLink, ImageMedium } from '../../../../config/main';
import Helpers from '../../../../functions/Helpers';
import styles from '../widget.module.scss';
import './widgetProject.scss';

const { Title } = Typography;

class WidgetProject extends React.Component {
  content = [];
  state = {
    projects: [],
    loading: true,
  };

  componentDidMount() {
    if (!this.props.dataSource) {
      HomePageApi.projects()
        .then((res) => {
          this.setState({
            projects: res.data.projects,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else this.setState({ projects: this.props.dataSource, loading: false });
  }

  _slider = (dt, index) => {
    const { loading } = this.state;
    return (
      <Skeleton loading={loading} key={index} active>
        {_.isEmpty(dt) ? null : (
          <a
            className="card-link"
            href={DomainLink + '/du-an-bat-dong-san/' + dt.slug}
            target={this.props.isSubDomain ? '_blank' : '_parent'}
          >
            <Card hoverable className={'cardBlock ' + styles.categoriesProject}>
              <div className="card-head">
                <span className="head-subTitle">{dt.location}</span>
                <Title level={4} className="head-title">
                  {dt.title}
                </Title>
              </div>
              <div className="flex-content" />
            </Card>
            <div className="cartImage">
              <Image src={ImageMedium + dt.cover_image} />
            </div>
          </a>
        )}
      </Skeleton>
    );
  };

  _renderNormal = (dt, index) => {
    return (
      <div className="slick-normal" key={index}>
        {this._slider(dt, index)}
      </div>
    );
  };

  _renderTwoColumn = (index, content) => {
    return (
      <div className="slick-double" key={index}>
        {content}
      </div>
    );
  };

  loadProject() {
    let content = [];
    this.content = [];
    this.state.projects.forEach((item, index) => {
      if (index % 5 === 0) {
        this.content.push(this._renderNormal(item, index));
      } else {
        content.push(this._slider(item, index));
        if (content.length === 2) {
          this.content.push(this._renderTwoColumn(index, content));
          content = [];
        }
      }
    });
    return (
      <CardSlider slideItem={this.props.slideItem} className="widget-project">
        {this.content}
      </CardSlider>
    );
  }
  getAveragePrice(project_products) {
    if (Helpers.isJSON(project_products)) {
      let products = JSON.parse(project_products),
        average_price = 0;
      products.forEach((product) => {
        average_price += product.average_price;
      });
      return (average_price / products.length).toFixed(0);
    } else return 0;
  }

  getProductName(project_products) {
    if (Helpers.isJSON(project_products)) {
      let products = JSON.parse(project_products),
        name = '';
      products.forEach((product) => {
        name += product.label + ', ';
      });
      return name.substring(0, name.length - 2);
    } else return '';
  }
  render() {
    return (
      <div className={styles.frontPageSection}>
        <div className="section-wrap">
          <div className="heading-container">
            <h1>Dự án mới nhất</h1>
            <p>Những dự án mới nhất đang được quan tâm</p>
          </div>
          <div className={styles.categoriesWrap}>{this.loadProject()}</div>
        </div>
      </div>
    );
  }
}

export default WidgetProject;
