import { useQuery } from '@tanstack/react-query';
import { Col, Icon, Row, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getOnlinePrice, GET_ONLINE_PRICE_URL } from '../../api/onlinePrice';
import { setSelectedAparment, setSelectedProjectId } from '../../store/actions';
import { saveApartment } from '../../utils';
import { AppartmentTable, BuildingList } from './components';
import styles from './OnlinePrices.module.scss';
import Helpers from '../../functions/Helpers';
import {
  addClassNameToApartmentPivots,
  addClassNammeToHeaders,
  getFloors,
  getHeaders,
  getRoomNumberList,
  removeClassNameToApartmentPivots,
  removeClassNammeToHeaders,
} from './utils';
import { getProjectCartByStatus } from '../../utils/projectCartUtils';
import Banner from '../../component/banner/banner';
import HeadingContent from '../../component/banner/HeadingContent';

const OnlinePrices = () => {
  const [apartmentPivots, setApartmentPivots] = useState([]);
  const [apartmentHeaders, setAparmentHeaders] = useState([]);
  const [buildingList, setBuildingList] = useState();
  const [selectedBuilding, setSelectedBuilding] = useState();
  const selectedApartment = useSelector(
    (state) => state.cartProject.selectedApartment
  );
  const { id: projectId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.infor);
  const currentProjectCartStatus = Helpers.urlParam('status');
  const { data, isFetching, isLoading } = useQuery(
    [GET_ONLINE_PRICE_URL, { projectId, selectedBuilding }],
    () =>
      getOnlinePrice({
        projectSapId: projectId,
        building: selectedBuilding,
      }),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        const buildingInfos = data?.data?.Data?.BuildingInfos;
        if (buildingInfos?.length > 0) {
          setBuildingList(buildingInfos);
          if (!selectedBuilding) {
            setSelectedBuilding(buildingInfos?.length > 0 && buildingInfos[0]);
          }
        }
      },
    }
  );

  const onlinePrice = data?.data?.Data;
  const priceBoardData = onlinePrice?.PriceBoardData[0];

  useEffect(() => {
    if (!priceBoardData) {
      setAparmentHeaders([]);
      setApartmentPivots([]);
      return;
    }
    setAparmentHeaders(getHeaders(priceBoardData.ApartmentPivots));
    const floors = getFloors(
      priceBoardData.ApartmentPivots,
      priceBoardData.FloorInfos
    );
    setApartmentPivots(floors);
  }, [priceBoardData]);

  const handleOnMouseApparment = (item) => {
    setApartmentPivots(
      addClassNameToApartmentPivots(
        apartmentPivots,
        styles.apartmentHover,
        item
      )
    );
    setAparmentHeaders(
      addClassNammeToHeaders(
        apartmentHeaders,
        item.roomNumber,
        styles.apartmentHover
      )
    );
  };
  const handleOnMouseLeaveApparment = () => {
    setApartmentPivots(removeClassNameToApartmentPivots(apartmentPivots));
    setAparmentHeaders(removeClassNammeToHeaders(apartmentHeaders));
  };

  const handleSelectBuildingId = (building) => {
    setSelectedBuilding(building);
  };

  const roomNumberList = useMemo(() => {
    return priceBoardData
      ? getRoomNumberList(priceBoardData.ApartmentPivots)
      : [];
  }, [priceBoardData]);
  const handleRemoveSelectedAparment = () => {
    dispatch(setSelectedAparment(null));
  };
  const handleConfirm = (apartment = null) => {
    const currentAparment = selectedApartment || apartment;
    saveApartment({
      ...currentAparment,
      buildingName: selectedBuilding?.Name,
    });
    history.push('/cart/buyer-info/create');
  };

  const handleClickCart = (apartment) => {
    dispatch(setSelectedAparment(apartment));
  };

  const handleBookAparment = () => {
    if (!profile) {
      saveApartment(selectedApartment);
      history.push('/sign-in');
      dispatch(setSelectedProjectId(parseInt(projectId, 10)));
      return;
    }
    handleConfirm(selectedApartment);
  };
  const projectCart = getProjectCartByStatus(Number(currentProjectCartStatus));
  const statusText = Helpers.urlParam('status-text');
  return (
    <div className={styles.wrapper}>
      <Banner
        headingContent={
          <HeadingContent
            heading={
              <>
                Giỏ hàng {`${projectCart?.heading || statusText || 'Booking'}`}
              </>
            }
            subheading={
              onlinePrice?.ProjectName && <>Dự án: {onlinePrice?.ProjectName}</>
            }
          />
        }
        isHiddenSearch
      />
      <div className={styles.blockPriceContent}>
        <div className={styles.container}>
          <>
            {isLoading || isFetching ? (
              <Spin
                style={{
                  width: `100%`,
                  margin: `auto`,
                }}
                indicator={
                  <Icon type="loading" style={{ fontSize: 56 }} spin />
                }
              />
            ) : (
              <>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className={styles.boxBuilding}
                >
                  <Col span={24}>
                    <BuildingList
                      buildingList={buildingList}
                      selectedBuildId={selectedBuilding?.SAPID}
                      onSelectBuildingId={handleSelectBuildingId}
                    />
                  </Col>
                </Row>
                <div
                  className={`${styles.boxAppartmentContainer} ${
                    apartmentPivots.length > 0 ? styles.heightFull : ''
                  }`}
                >
                  <AppartmentTable
                    roomNumbersLength={roomNumberList.length}
                    apartmentPivots={apartmentPivots}
                    apartmentHeaders={apartmentHeaders}
                    onMouseApparment={handleOnMouseApparment}
                    onMouseLeaveApparment={handleOnMouseLeaveApparment}
                    onClickCart={handleClickCart}
                    onBookAparment={handleBookAparment}
                    onRemoveSelectedAparment={handleRemoveSelectedAparment}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default OnlinePrices;
