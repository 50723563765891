import Axios from "../axiosApi";
import { Limit, Page } from "../config/params";

const PostRealEstatePageApi = {
  autoCompleteBank: (name) => {
    return Axios.get(`/banks/${name}/autocomplete`);
  },
  autoCompleteInvestor: (name) => {
    return Axios.get(`/investors/${name}/autocomplete`);
  },
  autoCompleteBuilder: (name) => {
    return Axios.get(`/builders/${name}/autocomplete`);
  },
  autoCompleteComManagement: (name) => {
    return Axios.get(`/comManagements/${name}/autocomplete`);
  },
  getAllCities: () => {
    return Axios.get(`/cities`);
  },
  getDistrictByCity: (codeCity) => {
    return Axios.get(`/city/${codeCity}/districts`);
  },
  getStreetsByDistrict: (districtId) => {
    return Axios.get(`/district/${districtId}/streets`);
  },
  getWardsByDistrict: (districtId) => {
    return Axios.get(`/district/${districtId}/wards`);
  },
  getAllDirections: () => {
    return Axios.get(`/directions`);
  },
  addNew: (data) => {
    return Axios.post("/realEstate", data);
  },
  change: (data) => {
    return Axios.post("/realEstate/change", data);
  },
  changeStatus: (data) => {
    return Axios.post("/realEstate/changeStatus", data);
  },
  remove: (id) => {
    return Axios.delete(`/realEstate/${id}/remove`);
  },
  mySellRealEstatesList: (limitPage = 20, page = 1) => {
    const params = {};
    params[Limit] = limitPage;
    params[Page] = page;
    return Axios.get("/realEstate/mySellList", { params: params });
  },
  mySoldRealEstatesList: (limitPage = 20, page = 1) => {
    const params = {};
    params[Limit] = limitPage;
    params[Page] = page;
    return Axios.get("/realEstate/mySoldList", { params: params });
  },
  realEstateSameArea: (limitPage = 20, page = 1) => {
    const params = {};
    params[Limit] = limitPage;
    params[Page] = page;
    return Axios.get("/realEstate/sameArea", { params: params });
  },
  getAllProject: () => {
    return Axios.get("/project/all");
  },
  autoCompleteProject: (title) => {
    return Axios.get(`/project/${title}/autocomplete`);
  },
  autoCompleteStreet: (name) => {
    return Axios.get(`/street/${name}/autocomplete`);
  },
  getAllInvestors: () => {
    return Axios.get("/investor");
  },
  getAllBanks: () => {
    return Axios.get("/banks");
  },
  getDetailRealEstate: (slug) => {
    return Axios.get(`/realState/${slug}`);
  },
  getAllInternalOptions: () => {
    return Axios.get(`/internal-options`);
  },
  getAllFurniture: () => {
    return Axios.get(`/furnitures`);
  },
  getAllBrokerCategory: () => {
    return Axios.get(`/broker-category`);
  }
};

export default PostRealEstatePageApi;
