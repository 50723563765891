export const LOAD_REAL_ESTATE = 'LOAD_REAL_ESTATE';
export const GET_REAL_ESTATE_SAME_PRICE = 'GET_REAL_ESTATE_SAME_PRICE';
export const GET_REAL_ESTATE_SAME_STREET = 'GET_REAL_ESTATE_SAME_STREET';
export const GET_REAL_ESTATE_SAME_ACREAGE = 'GET_REAL_ESTATE_SAME_ACREAGE';
export const SAVE_DOMAIN = 'SAVE_DOMAIN';
export const SEARCH = 'SEARCH';
export const SEARCH_FILTER_OPTIONS = 'SEARCH_FILTER_OPTIONS';
export const SEARCH_DATA = 'SEARCH_DATA';
export const SEARCH_DATA_UPDATE = 'SEARCH_DATA_UPDATE';
export const CLOSE_ALL_MARKER = 'CLOSE_ALL_MARKER';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const LOGOUT = 'LOGOUT';
export const REDIRECT_TO_POST_REAL_ESTATE = 'REDIRECT_TO_POST_REAL_ESTATE';
export const REDIRECT_TO_CREATE_BUYER_INFO = 'REDIRECT_TO_CREATE_BUYER_INFO';
export const GET_REAL_ESTATE_VIP = 'GET_REAL_ESTATE_VIP';
export const SET_SELECTED_PROJECT_ID = 'SET_SELECTED_PROJECT_ID';
export const SET_SELECTED_APARTMENT = 'SET_SELECTED_APARTMENT';