import Axios from "../axiosApi";

const ProfileApi = {
  changeLocation: location => {
    return Axios.post("/profile/location", location);
  },
  changePass: user => {
    return Axios.post("/api/change-pass", user);
  },
  editProfile: user => {
    return Axios.post("/api/edit-profile", user);
  },
  getDetailLocation: () => {
    return Axios.get("/profile/get-detail-location");
  },
  submitPayment: (data) => {
    return Axios.post("/payment/summit", data);
  },
};

export default ProfileApi;
