import React from 'react';
import { Breadcrumb } from 'antd';
import { DomainLink } from '../../config/main';
import './breadcrumb.scss';

const BreadcrumbCustom = (props) => {
    return (
        <div className="bread-custom-container" >
            <div className="section-wrap">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className=" breadcrumbcustom active">
                        <a href={DomainLink} >Trang chủ</a>
                    </Breadcrumb.Item>
                    {
                        props.subitem !== null ?
                         <Breadcrumb.Item className="breadcrumbcustom active">
                             { props.subitem }
                         </Breadcrumb.Item>
                        : null
                    }
                    <Breadcrumb.Item>
                        { props.title }
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
        </div>
    );
}
export default BreadcrumbCustom;
