import React from "react";
import numeral from "numeral";

numeral.register('locale', 'vi-custom', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'nghìn',
        million: 'triệu',
        billion: 'tỷ',
        trillion: 'nghìn tỷ'
    },
    ordinal: function () {
        return '.';
    },
    currency: {
        symbol: '₫'
    }
});

numeral.locale('vi-custom');

class MoneyFormat extends React.Component<{format?: string, style?: React.CSSProperties}>{
    state = {
        price: 0
    };

    constructor(props) {
        super(props);
        this.state.price = parseFloat(this.props.children);
    }

    formatCurrency() {
        return <span style={this.props.style}>{numeral(this.state.price).format(this.props.format ? this.props.format : '0[.]00 a')}</span>;
    }

    render() {
        return (
            <React.Fragment>
                {this.formatCurrency()}
            </React.Fragment>
        );
    }
}

export default MoneyFormat;
