import { Col, Form, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loginSignUpApi from "../../../api/loginSignUp";
import User from "../../../models/User";
import { saveProfile } from "../../../store/actions/profile";
import { redirectToPostRealEstate } from "../../../store/actions/redirectToPostRealEstate";
// Components
import SignInForm from "./components/SignInForm";
import styles from "../Auth.module.scss";

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorMessage: "",
      formSuccessMessage: "",
    };
  }

  componentWillUnmount() {
    this.setState({
      isLoading: false,
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, user) => {
      if (!err) {
        this.setState({ isLoading: true, formErrorMessage: "" });
        loginSignUpApi
          .login(user)
          .then((res) => {
            if (res.data.status) {
              if (res.data.token) {
                User.saveToken(res.data.token);
                this.props.saveProfile(res.data.infor);
              }
              this.setState({
                isLoading: false,
              });
              if (this.props.selectedProjectId) {
                this.props.history.push(`cart/buyer-info/create`);
                return;
              }
              if (this.props.redirect) {
                this.props.redirectToPostRealEstate(false);
                this.props.history.push("/user/real-estate/create");
                return;
              }
              this.props.history.push("/user/my-profile");
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
              formErrorMessage:
                "Đăng nhập thất bại: sai tên tài khoản hoặc mật khẩu",
            });
          });
      }
    });
  };
  handleRedirectToSignUp = () => {
    this.props.history.push("/sign-up");
  };
  render() {
    return (
      <div className={styles.wrapperSignIn}>
        <div className="section-wrap">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={14} xl={10}>
              <SignInForm
                form={this.props.form}
                onSubmit={this.handleSubmit}
                isLoading={this.state.isLoading}
                formErrorMessage={this.state.formErrorMessage}
                onRedirectToSignUp={this.handleRedirectToSignUp}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (infor) => dispatch(saveProfile(infor)),
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
  };
};

const mapStateToProps = (state) => {
  return {
    redirect: state.redirectToPostRealEstate,
    selectedProjectId: state.cartProject.selectedProjectId,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(SignIn)));
