import React from "react";
import styles from "../BookingDetail.module.scss";

const PersonalInfo = ({ booking, identifyLabel }) => {
  const {
    name,
    email,
    phone_number,
    identifier,
    identifier_issue_by,
    identifier_issue_at,
    date_of_birth,
  } = booking;
  return (
    <>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Họ và tên:</span>
        <span className={styles.orderInfoValue}>{name}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Email:</span>
        <span className={styles.orderInfoValue}>{email}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Số điện thoại:</span>
        <span className={styles.orderInfoValue}>{phone_number}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Số CMND:</span>
        <span className={styles.orderInfoValue}>{identifier}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>
          Giấy tờ chứng thực cá nhân:
        </span>
        <span className={styles.orderInfoValue}>{identifyLabel}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Nơi cấp:</span>
        <span className={styles.orderInfoValue}>{identifier_issue_by}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Ngày cấp:</span>
        <span className={styles.orderInfoValue}>{identifier_issue_at}</span>
      </p>
      <p className={styles.orderInfoRow}>
        <span className={styles.orderInfoLabel}>Ngày sinh:</span>
        <span className={styles.orderInfoValue}>{date_of_birth}</span>
      </p>
    </>
  );
};

PersonalInfo.propTypes = {};

export default PersonalInfo;
