import { Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import styles from "../MyProfile.module.scss";

const FormRow = ({ getFieldDecorator, itemList }) => {
  return (
    <Row gutter={[16, 16]}>
      {itemList.map(({ fieldName, rules, placeholder, lablel, disabled }) => (
        <Col key={fieldName} xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={lablel}>
            {getFieldDecorator(fieldName, {
              rules,
            })(
              <Input disabled={disabled} placeholder={placeholder} className={styles.inputItem} />
            )}
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

FormRow.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      lablel: PropTypes.string,
      fieldName: PropTypes.string,
      placeholder: PropTypes.string,
      disabled: PropTypes.bool,
      rules: PropTypes.arrayOf(
        PropTypes.shape({
          required: PropTypes.bool,
          message: PropTypes.string,
        })
      ),
    })
  ),
};

export default FormRow;
