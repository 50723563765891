import { Icon, Upload } from "antd";
import React, { useEffect, useState } from "react";
import DefaultBanner from "../..//images/no-photo.png";
import { ResourceUrl, uploadUrl } from "../../config/main";
import { getBase64 } from "../../utils";
import styles from "./Booking.module.scss";

const IdentityImage = ({ setFieldsValue, currentImageUrl, isDisabled }) => {
  const [imageUrlIdentity, setImageUrlIdentity] = useState();
  const [isLoading, setIsLoading] = useState();
  const handleChangeIdentityImage = (info) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFieldsValue({ imagePath: info.file });
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrlIdentity(imageUrl),
        setIsLoading(false)
      );
    }
  };
  useEffect(() => {
    if (currentImageUrl) {
      setImageUrlIdentity(`${ResourceUrl}${currentImageUrl}`);
    }
  }, [currentImageUrl]);
  return (
    <Upload
      name="logo"
      className={styles.uploadIdentityImage}
      listType="picture-card"
      showUploadList={false}
      action={uploadUrl}
      onChange={handleChangeIdentityImage}
      disabled={isDisabled}
    >
      {imageUrlIdentity ? (
        <img
          className={styles.identityImg}
          src={imageUrlIdentity}
          onError={(curerntError) => {
            curerntError.currentTarget.src = DefaultBanner;
          }}
          alt="IdentityImage"
        />
      ) : (
        <>
          <Icon type={isLoading ? "loading" : "plus"} />
          <div className="ant-upload-text">Upload</div>
        </>
      )}
    </Upload>
  );
};

IdentityImage.propTypes = {};

export default IdentityImage;
