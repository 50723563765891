import { useQuery } from "@tanstack/react-query";
import { Select } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { getCities, GET_CITIES_URL } from "../../api/homeTown";

const { Option } = Select;
const SelectCityField = ({ onSelect, valueOfSelected, isDisabled }) => {
  const { data, isFetching } = useQuery(
    [GET_CITIES_URL, {}],
    () => getCities(),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  const cities = data?.data?.cities;
  return (
    <Select
      onSelect={onSelect}
      value={valueOfSelected}
      placeholder="Chọn..."
      loading={isFetching}
      disabled={isDisabled}
    >
      {cities?.length > 0 &&
        cities.map((city, index) => (
          <Option key={city.code} value={city.code}>
            {city.name}
          </Option>
        ))}
    </Select>
  );
};

SelectCityField.defaultProps = {
  onSelect: () => {},
  valueOfSelected: '',
  isDisabled: false,
};

SelectCityField.propTypes = {
  onSelect: PropTypes.func,
  valueOfSelected: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default SelectCityField;
