import React from 'react';
import Img from 'react-image';
import Notfound from '../images/not-found.png';
import AvatarDefault from '../images/avatar-default.png';
import WallPicture from '../images/real-state.jpg';
import { Spin } from 'antd';

const LoadingCustom = () =>  {
  return <div className="loading-custom" style={{ textAlign: 'center',
                       width: '100%',
                       margin: '30% 0px' }}>
          <Spin />
  </div>
};

const getDefaultImage = (props) => {
    if(props.avatar) {
        return AvatarDefault;
    } else if(props.broker_wall_picture) {
        return WallPicture
    } else {
        return Notfound
    }
}

const Image = ({src, className, onNotFound, style, ...props}) => {
  let imageNotFound = (
      <img style={style} className={ className } src={  getDefaultImage(props) } alt="Not found" />
  );
  return (
      <Img style={style} loader={ <LoadingCustom/> }
             unloader={ imageNotFound }
             src={ src }
             className={ className }
             decode={false}  />
  )
};

export default Image;
