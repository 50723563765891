import { Button, Icon, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import loginSignUpApi from "../../../api/loginSignUp";
import shoppingCart from "../../../images/icon/shopping-cart-white.svg";
import User from "../../../models/User";
import { logout, saveProfile } from "../../../store/actions/profile";
import { redirectToPostRealEstate } from "../../../store/actions/redirectToPostRealEstate";
import { getBasePath } from "../../../utils";
import Login from "../../login/login";
import styles from "../header.module.scss";
import UserPopover from "../UserPopover";
import "./index.scss";

const { SubMenu } = Menu;

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (infor) => dispatch(saveProfile(infor)),
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
    logout: () => dispatch(logout()),
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
    domain: state.domainInfo.domain,
  };
};

class MenuTop extends Component {
  menus = [
    {
      name: "Giá nhà đất",
      to: "/price-by-month",
    },
    {
      name: "Dự án",
      to: "/du-an-bat-dong-san",
    },
    {
      name: "Sự kiện",
      to: "/event",
    },
    {
      name: "Tin tức",
      to: "/blog",
    },
  ];

  constructor(props) {
    super(props);
    const { location } = props;
    let selectedIndex = this.menus.findIndex((e) => e.to === location.pathname);
    this.state = {
      mode: this.props.mode ? this.props.mode : "horizontal",
      selected: location.pathname + "-" + selectedIndex,
      user: null,
    };
    this.login = React.createRef();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let selectedIndex = this.menus.findIndex(
        (e) => e.to === this.props.location.pathname
      );
      this.setState({
        selected: this.props.location.pathname + "-" + selectedIndex,
      });
    }
  }
  handleRedirectToSignIn = () => {
    this.props.history.push("/sign-in");
  };
  handleRedirectTo;

  handleClick = (e) => {
    this.setState({
      selected: e.key,
    });
  };

  handleLogout = () => {
    loginSignUpApi.logout().then((res) => {
      if (res.data.status) {
        this.props.logout();
        this.props.redirectToPostRealEstate(false);
        User.logout();
      }
    });
  };
  handleCreateRealEstate = () => {
    if (!this.props.profile) {
      this.props.redirectToPostRealEstate(true);
      this.handleRedirectToSignIn();
    } else {
      this.props.history.push("/user/real-estate/create");
    }
  };

  render() {
    let loadMenus = this.menus.slice();
    const path = getBasePath(this.props.location.pathname);

    return (
      <>
        <Menu
          onClick={this.handleClick}
          className={styles.menuStyle}
          mode={this.state.mode}
        >
          <Login wrappedComponentRef={this.login} />
          {this.showMenus(loadMenus, path)}
        </Menu>
        <div className={styles.btnActionGroup}>
          <Button
            className={styles.postRealEstateBtn}
            onClick={this.handleCreateRealEstate}
          >
            <Icon type="plus" style={{ fontSize: 18 }} />
            Đăng tin
          </Button>
          <Button
            className={styles.shoppingCartBtn}
            type="primary"
            onClick={() => this.props.history.push("/project-carts")}
          >
            <img src={shoppingCart} alt="shopping cart" />
            Giỏ hàng booking
          </Button>
          <UserPopover />
        </div>
      </>
    );
  }
  showMenus = (menus, path) => {
    let result = null;
    if (menus.length > 0) {
      result = menus.map((menu, index) => {
        if (menu.child && menu.child.length > 0) {
          return (
            <SubMenu
              onTitleClick={() => {
                if (menu.to && !menu.to.isEmpty) {
                  this.props.history.push(menu.to);
                }
              }}
              key=""
              title={menu.name}
            >
              {this.showMenus(menu.child)}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item
              key={index}
              onClick={menu.onClick}
              className={`${menu.to === path ? "ant-menu-item-selected" : ""}`}
            >
              <Link
                to={menu.to !== "#" ? menu.to : "#"}
                target={
                  menu.to !== "#" && this.props.domain.isSubDomain
                    ? "blank"
                    : "_self"
                }
              >
                {menu.name}
              </Link>
            </Menu.Item>
          );
        }
      });
    }
    return result;
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuTop));
