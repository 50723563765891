export const APARTMENT_STATUS_SALEABLE_VALUE = 1;
export const APARTMENT_STATUS_APPROVED_LOCK_VALUE = 2;
export const APARTMENT_STATUS_UN_SALEABLE_VALUE = 3;
export const APARTMENT_STATUS_WAITING_FOR_PAY_VALUE = 4;

export const APARTMENT_STATUS_SALEABLE = {
  value: APARTMENT_STATUS_SALEABLE_VALUE,
  icon: "shopping-cart",
  color: "#34C600",
  label: "Đang bán",
};
export const APARTMENT_STATUS_APPROVED_LOCK = {
  value: APARTMENT_STATUS_APPROVED_LOCK_VALUE,
  icon: "stop",
  color: "#F95A5A",
  label: "Đã bán",
};
export const APARTMENT_STATUS_UN_SALEABLE = {
  value: APARTMENT_STATUS_UN_SALEABLE_VALUE,
  icon: "lock",
  color: "#979797",
  label: "Chưa mở bán/Đã bán",
};
export const APARTMENT_STATUS_WAITING_FOR_PAY = {
  value: APARTMENT_STATUS_WAITING_FOR_PAY_VALUE,
  icon: "shopping-cart",
  color: "#DDA941",
  label: "Chờ thanh toán",
};
export const APARTMENT_STATUSES = [
  APARTMENT_STATUS_SALEABLE,
  APARTMENT_STATUS_APPROVED_LOCK,
  APARTMENT_STATUS_UN_SALEABLE,
  APARTMENT_STATUS_WAITING_FOR_PAY,
];
export const SIZES = {
  xs: 480,
  md: 768,
  lg: 1024,
  xl: 1440,
};
export const AMOUNT_ITEM_BREACK_POINT_XL = 10;
export const AMOUNT_ITEM_BREACK_POINT_LG = 5;
export const AMOUNT_ITEM_BREACK_POINT_MD = 4;
export const AMOUNT_ITEM_BREACK_POINT_XS = 2;
