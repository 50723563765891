import React, { PureComponent } from "react";
import "./broker.scss";
import Header from "../../component/broker/header/header";
import Breadcrumb from "../../component/breadcrumb/breadcrumb";
import BrokerPageApi from "../../api/brokerPage";
import CardColumnData from "../../component/cardColumnData/cardColumnData";
import ArticleList from "../../component/dataList/dataList";
import WidgetProject from "../home/widgets_area/widget_project/widgetProject";
import ArticleDetail from "../articleDetail/articleDetail";
import { Row, Col, Typography, Layout, Skeleton } from "antd";
const { Title } = Typography;
const { Content } = Layout;

class Broker extends PureComponent {
  state = {
    broker: {},
    real_estates: [],
    articles: [],
    projects: [],
    article_detail_show: false,
    article_detail_slug: "",
  };

  componentDidMount() {
    let slug = "";
    if (this.props.isSubDomain) {
      slug = this.props.domainUserId;
    } else {
      slug = this.props.match.params.slug;
    }
    BrokerPageApi.getDetail(slug).then((data) => {
      this.setStateFromResponse(data);
    });
  }

  setStateFromResponse = (data) => {
    if (data.status === 1) {
      this.setState({
        broker: data.broker,
        real_estates: data.real_estates,
        articles: data.articles,
        projects: data.projects,
        category: data.category,
      });
    }
  };

  componentDidUpdate() {
    this.updateArticle();
  }


  updateArticle = () => {
    const slug = this.props.match.params.slug;
    const location = this.props.location.pathname.split("/");
    let article_slug = location[location.length - 1];

    if(article_slug.substr(article_slug.length - 5) === ".html")
      article_slug = article_slug.slice(0, -5)

    console.log(article_slug)

    if (slug !== article_slug && article_slug !== "") {
      this.setState({
        article_detail_show: true,
        article_detail_slug: article_slug,
      });
    } else {
      this.setState({
        article_detail_show: false,
      });
    }
  };

  getRealEstateCardColumn = () => {
    if (this.state.real_estates.length > 0) {
      return (
        <CardColumnData
          data={this.state.real_estates}
          category={this.state.category}
          fourItem={this.state.articles.length > 0 ? false : true}
          numEachPage={this.state.articles.length > 0 ? 9 : 12}
          style={{
            paddingRight: this.state.articles.length > 0 ? "0px" : "15px",
          }}
          isSubDomain={this.props.isSubDomain ? this.props.isSubDomain : false}
          title={"Bất động sản đang bán"}
        />
      );
    }
    return null;
  };

  getProjectComponent = () => {
    if (this.state.projects.length > 0) {
      return (
        <div className="project-column">
          <Title className="red-color" level={4}>
            Dự án đang bán
          </Title>
          <WidgetProject
            slideItem={3}
            dataSource={this.state.projects}
            isSubDomain={
              this.props.isSubDomain ? this.props.isSubDomain : false
            }
            showTitle={false}
          />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="broker-container">
        <Content className=" section-wrap">
          <Breadcrumb title={this.state.broker.name} subitem="Môi giới" />
          <Header broker={this.state.broker} />
          <Row className="body">
            <div className="body-content">
              {Object.keys(this.state.broker).length > 0 ? (
                <>
                  <Col xs={24}  sm={24} md={24}
                       lg={this.state.articles.length > 0 ? 16 : 24}
                       xl={this.state.articles.length > 0 ? 16 : 24}>
                    {this.state.article_detail_show ? (
                      <ArticleDetail slug={this.state.article_detail_slug} />
                    ) : null}
                    {this.getRealEstateCardColumn()}
                    {this.getProjectComponent()}
                  </Col>
                  <ArticleList title={"Phân tích đánh giá "} articles={this.state.articles}
                    xs={24} sm={24} md={24}  lg={8} xl={8}
                    style={{
                      paddingLeft: "25px",
                    }}
                    isSubDomain={
                      this.props.isSubDomain ? this.props.isSubDomain : false
                    }
                    classTitle="red-color"
                  />
                </>
              ) : (
                <Skeleton paragraph={{ rows: 50 }} />
              )}
            </div>
          </Row>
        </Content>
      </div>
    );
  }
}

export default Broker;
