import React from 'react';
import {Link} from "react-router-dom";
import { Row, Col, Descriptions, Icon, Tag } from 'antd';
import Image from '../../image';
import Helpers from '../../../functions/Helpers';
import {ImageMediumWithWatermark} from "../../../config/main";
import {
  EnvironmentOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import "../../../functions/moment_vi";

const ItemRealEstate = (props) => {
  const item = props.item;
  return (
    <Row className="data-item real-estates"
         key={ props.index }>
             <Col xs={ 6 } sm={ 10 } md={ 6 } lg={ 6 } xl={ 6 } className="image real-estates" >
                <Link to={ '/bat-dong-san/' + item.slug }>
                   <Image src={ ImageMediumWithWatermark  + item.cover_image  }   />
                   {item.status ? <Tag color="#BF8100" className="card-tag-left">{ item.status }</Tag> : ""}
                </Link>
             </Col>
             <Col xs={ 18 } sm={ 14 } md={ 18 } lg={ 18 } xl={ 18 } className="description" >
                 <Descriptions.Item>
                     <span className="title-item">
                       <Link to={ '/bat-dong-san/' + item.slug }>
                           { item.title }
                       </Link>
                     </span><br/>
                     <div className="price-acreage">
                       <span className="price">{ Helpers.moneyFormatVn(item.price) } /</span>
                       <span className="acreage"> { item.acreage } m<sup>2</sup></span>
                     </div>
                     <span className="location">
                       <EnvironmentOutlined className="icon"  />
                       { Helpers.getString(item.address) + Helpers.getString(item.district)  + Helpers.getString(item.city, true) }
                      </span>

                     <span className="date">
                       <Icon type="clock-circle" className="icon" />
                       {
                         Helpers.getLinkBroker(item)
                       }<span>đăng{" "}</span>{moment(item.created_at).fromNow()}
                     </span>
                 </Descriptions.Item>
             </Col>
   </Row>
 );
}

export default ItemRealEstate;
