import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// COMPs
import { Col, Icon, Row, Spin } from 'antd';
import { ProjectDetailInfo } from './components';

import { getProjectDetail, GET_PROJECT_DETAIL_URL } from '../../../api/project';
import { ResourceUrl } from '../../../config/main';

const ProjectDetail = () => {
  const { slug } = useParams();
  const { data, isLoading } = useQuery([GET_PROJECT_DETAIL_URL, { slug }], () =>
    getProjectDetail(slug)
  );
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
  }, []);
  if (isLoading)
    return (
      <Spin
        style={{
          width: `100%`,
          margin: `auto`,
        }}
        indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
        tip="Loading..."
      />
    );
  const { project, descriptions, products } = data?.data;
  if(!project) return null;
  const { project_images } = project;

  const projectImages = JSON.parse(project_images);
  const projectImage = projectImages?.length > 0 ? projectImages[0] : null;

  return (
    <div className="project-detail">
      {isLoading ? (
        <Spin
          style={{
            width: `100%`,
            margin: `auto`,
          }}
          indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
          tip="Loading..."
        />
      ) : (
        <>
          <div
            className="project-detail-banner"
            style={{ background: `url(${ResourceUrl}/${projectImage.url})` }}
          >
            <ProjectDetailInfo project={project} products={products} />
          </div>
          <div className="section-wrap">
            <Row>
              <Col span={24}>
                {descriptions?.map(({ name, body }, index) => (
                  <div key={index} className="project-detail-desc-item">
                    <h3 id={`/${index}`} className="desc-name">
                      {name}
                    </h3>
                    <div
                      className="desc-body"
                      dangerouslySetInnerHTML={{ __html: body }}
                    />
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectDetail;
