import { Empty, Pagination } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const CustomerTable = ({
  customerList,
  changePageIndex,
  pageIndex,
  totalItems,
}) => {
  return (
    <>
      <Table>
        <Thead className="ant-table-thead">
          <Tr>
            <Th>Tên</Th>
            <Th>Số điện thoại</Th>
            <Th>Bất động sản đã mua</Th>
            <Th>Ngày mua</Th>
          </Tr>
        </Thead>
        <Tbody className="ant-table-tbody">
          {customerList?.length > 0 ? (
            customerList.map((item, key) => (
              <Tr key={key}>
                <Td>{item.buyers_name}</Td>
                <Td>{item.buyers_phone}</Td>
                <Td>
                  <Link to={`/bat-dong-san/${item.real_estate_slug}`}>
                    <div>{item.real_estate_title}</div>
                    <div style={{ color: `#BF0000`, fontSize: `12px` }}>
                      {`${item.real_estate_address_number} ${item.real_estate_street}`}
                      , {`${item.district_name}, ${item.province_name}`}
                    </div>
                    <div style={{ color: `#62D273`, fontSize: `12px` }}>
                      {item.real_estate_view_number === 0
                        ? "0"
                        : item.real_estate_view_number}{" "}
                      lượt xem
                    </div>
                  </Link>
                </Td>
                <Td>
                  {moment(item.buyers_created_at).format("DD/MM/YYYY h:m:s")}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="6">
                <Empty />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Pagination
        style={{ float: "right", marginTop: "1rem" }}
        defaultPageSize={1}
        pageSize={10}
        current={pageIndex}
        total={totalItems}
        onChange={(page, pageSize) => {
          changePageIndex(page);
        }}
      />
    </>
  );
};
CustomerTable.defaultProps = {
  customerList: [],
  changePageIndex: () => {},
  pageIndex: 0,
  totalItems: 0,
};

CustomerTable.propTypes = {
  customerList: PropTypes.array,
  changePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  totalItems: PropTypes.number,
};

export default memo(CustomerTable);
