import { useQuery } from "@tanstack/react-query";
import { Col, Icon, Row, Spin, Typography } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import {
  getBookedById, GET_BOOKED_BY_ID_URL
} from "../../../../api/onlinePrice";
import { ApartmentInfo, IdentityImage } from "../../../../component";
import { useFetchApartmentDetail } from "../../../../hooks/useFetchApartmentDetail";
import {
  getBookingStatus,
  getBuyerType,
  getIdentifyType
} from "../../../../utils/BookingUtils";
import {
  BUYER_TYPE_ORGANIZATION_VALUE,
  BUYER_TYPE_PERSONAL_VALUE
} from "../../../Cart/constants";
import { PageContent, PageSidebar, Wrapper } from "../../components";
import styles from "./BookingDetail.module.scss";
import AddressInfo from "./components/AddressInfo";
import OrderInfo from "./components/OrderInfo";
import OrganizationInfo from "./components/OrganizationInfo";
import PersonalInfo from "./components/PersonalInfo";

const { Title } = Typography;

const BookingDettail = () => {
  const { id: bookingId } = useParams();
  const { data, isLoading } = useQuery(
    [GET_BOOKED_BY_ID_URL(bookingId), {}],
    () => getBookedById(bookingId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  const booking = data?.data.Book;
  const buyerType = getBuyerType(booking?.type);
  let identifyType = null;
  if(booking?.type === BUYER_TYPE_PERSONAL_VALUE) {
    identifyType = getIdentifyType(booking?.identify_type);
  } else {
    identifyType = getIdentifyType(booking?.legal_identify_type);
  }
  const bookingStatus = getBookingStatus(booking?.status);

  const fetchedApartment = useFetchApartmentDetail(booking?.apartment_id);

  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar />
        <PageContent>
          <Title className={styles.heading} level={3}>
            Chi tiết booking
          </Title>
          {isLoading ? (
            <Spin
              style={{
                width: `100%`,
                margin: `auto`,
              }}
              indicator={<Icon type="loading" style={{ fontSize: 56 }} spin />}
            />
          ) : (
            <>
              {booking ? (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Row>
                      <Col span={24}>
                        <OrderInfo
                          bookingStatusLable={bookingStatus?.label}
                          bookedAt={booking?.created_at}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Title level={4} className={styles.subHeading}>
                          Thông tin khách hàng
                        </Title>
                        <div className={styles.orderInfoWrapper}>
                          <p className={styles.orderInfoRow}>
                            <span className={styles.orderInfoLabel}>
                              Đối tượng khách hàng:
                            </span>
                            <span className={styles.orderInfoValue}>
                              {buyerType?.label}
                            </span>
                          </p>
                          {buyerType?.value === BUYER_TYPE_PERSONAL_VALUE && (
                            <PersonalInfo
                              booking={booking}
                              identifyLabel={identifyType?.lable}
                            />
                          )}
                          {buyerType?.value ===
                            BUYER_TYPE_ORGANIZATION_VALUE && (
                            <OrganizationInfo
                              booking={booking}
                              identifyLabel={identifyType?.lable}
                            />
                          )}
                        </div>
                        <IdentityImage
                          currentImageUrl={booking?.image_path}
                          isDisabled
                        />
                        <AddressInfo
                          address={booking?.address}
                          cityLabel={booking?.city_label}
                          districtLabel={booking?.district_label}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={24} lg={8} xl={8}>
                    <div className={styles.apartmentInfoWrapper}>
                      {fetchedApartment && (
                        <ApartmentInfo
                          houseName={fetchedApartment.HouseName}
                          floorNumber={fetchedApartment.FloorNumber}
                          apartmentAttributes={
                            fetchedApartment?.ApartmentAttributes
                          }
                          totalPriceNonVAT={fetchedApartment?.TotalPriceNonVAT}
                          buildingName={fetchedApartment?.buildingName}
                          totalPriceVAT={fetchedApartment?.TotalPriceVAT}
                          percent={fetchedApartment.percent}
                          percentAmount={fetchedApartment.percentAmount}
                          shouldDisplayPriceDetail
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <p className={styles.subHeading}>Không có dữ liệu</p>
              )}
            </>
          )}
        </PageContent>
      </Row>
    </Wrapper>
  );
};

export default BookingDettail;
