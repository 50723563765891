import React from "react";
import { Button, Col, Form, Row, Select } from "antd";
import styles from "../brokerArea/brokerArea.module.scss";

import PostRealEstatePageApi from "../../api/postRealEstatePage";
import ProfileApi from "../../api/profileApi";
import { connect } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
  };
};

class BrokerArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      cities: [],
      districts: [],
      isSubmit: false,
    };
  }

  componentDidMount = () => {
    if (this.props.profile) {
      this.props.form.setFieldsValue({
        province_code: this.props.profile.province_code,
      });
      PostRealEstatePageApi.getDistrictByCity(
        this.props.profile.province_code
      ).then((res) => {
        if (res.data.status === 1) {
          this.setState(
            {
              districts: res.data.districts,
            },
            () => {
              ProfileApi.getDetailLocation().then((res) => {
                if (res.data.status) {
                  let result = [];
                  let temp = [...res.data.districts];
                  temp.forEach((item) => {
                    result.push(item.district_id);
                  });
                  this.props.form.setFieldsValue({
                    district_id: result,
                  });
                }
              });
            }
          );
        } else {
          alert(res.data.message);
        }
      });
    }
    PostRealEstatePageApi.getAllCities().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          cities: res.data.cities,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  selectCity = (e) => {
    this.setState(
      {
        province: e,
      },
      () => {
        PostRealEstatePageApi.getDistrictByCity(e).then((res) => {
          if (res.data.status === 1) {
            this.setState(
              {
                districts: res.data.districts,
              },
              () => {
                this.props.form.setFieldsValue({
                  district_id: res.data.districts[0].id,
                });
              }
            );
          } else {
            alert(res.data.message);
          }
        });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isSubmit: true,
    });
    this.props.form.validateFields((err, location) => {
      if (!err) {
        ProfileApi.changeLocation(location).then((res) => {
          if (res.data.status) {
            Swal.fire({
              icon: "success",
              title: "Cập nhật khu vực môi giới thành công",
              showConfirmButton: true,
            });
            this.setState({
              isSubmit: false,
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Cập nhật khu vực môi giới thất bại",
          text: "Vui lòng nhập các thông tin cần thiết",
          showConfirmButton: true,
        });
        this.setState({
          isSubmit: false,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Tỉnh thành" className={styles.rowInput}>
                {getFieldDecorator("province_code", {
                  rules: [
                    {
                      required: true,
                      message: "Vui lòng chọn tỉnh thành!",
                    },
                  ],
                })(
                  <Select onSelect={this.selectCity.bind(this)}>
                    {!!this.state.cities.length &&
                      this.state.cities.map((city, index) => (
                        <Option key={index} value={city.code}>
                          {city.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Quận/ huyện" className={styles.rowInput}>
                {getFieldDecorator(
                  "district_id",
                  {}
                )(
                  <Select mode="multiple">
                    {!!this.state.districts.length &&
                      this.state.districts.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Form.Item>
              <Col span={12} offset={6} style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.isSubmit ? true : false}
                  onClick={this.handleSubmit.bind(this)}
                >
                  Cập nhật khu vực môi giới
                </Button>
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(Form.create()(BrokerArea));
