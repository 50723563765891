import { Col, Icon, Typography } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ResourceUrl } from "../../../config/main";
import { MENU_LIST } from "../constants";
import { useAvatar } from "../hooks";
import styles from "./PageSidebar.module.scss";

const { Title } = Typography;
const PageSidebar = ({ selectedMenuId }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const profile = useSelector((state) => state.profile.infor);
  const { avatarUrl, handleImageLoadError } = useAvatar();

  return (
    <Col span={6}>
      <span
        className={styles.menuOpenIcon}
        onClick={() => {
          setOpenMenu(true);
        }}
      >
        <Icon type="menu" />
      </span>
      <Col
        span={6}
        className={`${styles.sidebarWrapper} ${
          openMenu ? styles.openMenu : ""
        }`}
      >
        <span
          className={styles.menuCloseIcon}
          onClick={() => {
            setOpenMenu(false);
          }}
        >
          <Icon type="close" />
        </span>
        <Link to="/" className={styles.logoWrapper}>
          <img
            className={styles.logo}
            src={ResourceUrl + "locations/logos/vn.png"}
            alt=""
          />
        </Link>
        <div className={styles.sidebarBottom}>
          <div className={styles.avatarWapper}>
            <img src={avatarUrl} alt="avatar" onError={handleImageLoadError} />
            <Title level={4}>{profile?.full_name}</Title>
          </div>
          <ul className={styles.menuList}>
            {MENU_LIST.map((menu) => (
              <li className={styles.menuItem} key={menu.id}>
                <Link
                  to={menu.link}
                  className={selectedMenuId === menu.id ? styles.active : ""}
                >
                  <Icon
                    style={{
                      color: "#BF0000",
                      marginRight: 8,
                      fontSize: 16,
                    }}
                    type={menu.icon}
                  />
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Col>
    </Col>
  );
};
PageSidebar.defaultProps = {
  selectedMenuId: 0,
};
PageSidebar.prototype = {
  selectedMenuId: PropTypes.number,
};

export default PageSidebar;
