import React from "react";
import { Radio } from "antd";
import Helpers from "../../../functions/Helpers";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const PackageItem = ({ value, text, description, colorText }) => {
  return (
    <Radio style={radioStyle} className="radio-custom" value={value}>
      <span className="title" style={{ color: colorText }}>
        {text}
      </span>
      <div className="package-description">{description}</div>
    </Radio>
  );
};

function Packages(props) {
  function changePackage(event) {
    props.setPackageSelected(event.target.value);
    props.setLabelOptions(
      props.packages.filter((p) => p.id === event.target.value)[0].label
    );
    props.setPackageObject(
      props.packages.filter((p) => p.id === event.target.value)[0]
    );
  }

  return (
    <div className="package-content">
      <span className="text-service">Chọn gói tin ưu tiên</span>
      <div className="content">
        <Radio.Group value={props.packageSelected} onChange={changePackage}>
          {props.packages &&
            props.packages.map((packageItem) => (
              <PackageItem
                key={packageItem.id}
                value={packageItem.id}
                text={packageItem.name + " - " + Helpers.formatNumber(packageItem.price) + " đ /ngày" }
                description={packageItem.description}
                colorText={packageItem.color}
              />
            ))}
        </Radio.Group>
      </div>
    </div>
  );
}

export default Packages;
