import React from "react";
import GoogleMapReact   from 'google-map-react';
import { googleMapApiKey }from '../../../../config/main.js';

const StreetView = (props) => {
    const center = {
          lat: props.lat,
          lng: props.lng
    }

    const  apiIsLoaded = () => {
        const google = window.google;
        const position = { lat: props.lat, lng: props.lng };
        const streetViewId = document.getElementById('street-view');
        const map = new google.maps.Map(streetViewId , {
           center: position,
           zoom: 14
         });
        const panorama = new google.maps.StreetViewPanorama(
            streetViewId, {
            position: position,
            pov: {
              heading: 34,
              pitch: 10,
            },
          });

        map.setStreetView(panorama);
    };

    return (
      <div className="street-view-custom" id="street-view">
        <GoogleMapReact bootstrapURLKeys={{ key: googleMapApiKey }}
                        center={ center }
                        defaultZoom={ props.zoom }
                        zoom={ props.zoom }
                        options={{streetViewControl:true}}
                        onGoogleApiLoaded={() => apiIsLoaded()}  >
        </GoogleMapReact>
      </div>
     );
}
export default StreetView;
