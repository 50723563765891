import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import "./map.scss";

import GoogleMapReact from "google-map-react";
import { googleMapApiKey } from "../../config/main";
import Marker from "./Marker";
import MarkerDetail from "./MakerDetail";

const Map = (props) => {
  const { data, lat, lng, shouldShowDetail, zoom, showSup } = props;
  const [makerDetail, setMakerDetail] = useState({});
  const [center, setCenter] = useState({ lat, lng });

  const handleShowMakerDetail = (realState) => {
    if (!shouldShowDetail) {
      return false;
    }

    if (
      makerDetail.lat !== realState.lat &&
      makerDetail.lng !== realState.lng
    ) {
      setMakerDetail({ ...realState });
      setCenter({ lat: realState.lat, lng: realState.lng });
    }
  };

  const handleHideMakerDetail = () => {
    setMakerDetail({});
  };

  return (
    <div className="map-estimate">
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapApiKey }}
        yesIWantToUseGoogleMapApiInternals={true}
        zoom={zoom}
        center={center}
      >
        {!_.isEmpty(makerDetail, true) && (
          <MarkerDetail
            makerDetail={makerDetail}
            lat={makerDetail.lat}
            lng={makerDetail.lng}
            handleHideMakerDetail={handleHideMakerDetail}
          />
        )}
        {data.map((realState, index) => (
          <Marker
            key={index}
            realState={realState}
            lat={realState.lat}
            lng={realState.lng}
            handleShowMakerDetail={handleShowMakerDetail}
            showSup={showSup}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  realStates: PropTypes.array,
  shouldShowDetail: PropTypes.bool,
  zoom: PropTypes.number,
  showSup: PropTypes.bool,
};

Map.defaultProps = {
  realStates: [],
  shouldShowDetail: true,
  zoom: 5,
  showSup: false,
};

export default Map;
