import React, { useEffect, useState } from "react";
import { transformSpecToMap } from "../../../../utils/realEstateUtils";
import area from "../../../../images/icon/realEstateSpec/area.svg";
import Img from "react-image";
import styles from "./Specs.module.scss";
import PostRealEstatePageApi from "../../../../api/postRealEstatePage";
import { SPEC_VIEW_CODE } from "../../../../constants/realEstate";

const Specs = ({ specs: specList }) => {
  const [directions, setDirections] = useState([]);
  useEffect(() => {
    PostRealEstatePageApi.getAllDirections().then((res) => {
      if (res.data.status === 1) {
        setDirections(res.data.directions)
      }
    });
  }, []);
  const directionsMap = new Map();
  if(directions?.length > 0) {
    directions.forEach(item => {
      directionsMap.set(item?.code, item);
    });
  }
  const specMap = transformSpecToMap();
  const getViewValue = (value) => {
    const foundDirection = directionsMap.get(value);
    return foundDirection?.name ? `Hướng ${foundDirection?.name}` : value;
  };
  return (
    <ul className={styles.specList}>
      {specList.filter(item => item?.value).map((item) => {
        const specMeta = specMap.get(item?.code);
        return (
          <li key={item.id} className={styles.specItem}>
            <div className={styles.specItemLabelWrapper}>
              <span className={styles.specItemIcon}>
                <Img className="img-full" src={specMeta?.icon || area} />
              </span>
              <span className={styles.specItemLabel}>{item?.label}</span>
            </div>
            <span className={styles.specItemValue}>
              {item?.code === SPEC_VIEW_CODE ? <>{getViewValue(item?.value)}</> : <>{item?.value || "Chưa có dữ liệu"} {item?.value ? specMeta?.unit : ''}</>}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default Specs;
