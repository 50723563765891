import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../update';

const initialState = {
  realEstate: {},
  reSamePrice: [],
  reSameStreet: [],
  reSameAcreage: [],
  reVip: []
}
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOAD_REAL_ESTATE: return updateObject(state, { realEstate: action.realEstate });
        case actionTypes.GET_REAL_ESTATE_SAME_PRICE: return updateObject(state, { reSamePrice: action.realStates });
        case actionTypes.GET_REAL_ESTATE_SAME_STREET: return updateObject(state, { reSameStreet: action.realStates });
        case actionTypes.GET_REAL_ESTATE_SAME_ACREAGE: return updateObject(state, { reSameAcreage: action.realStates });
        case actionTypes.GET_REAL_ESTATE_VIP: return updateObject(state, {reVip: action.realStates})
        default: return state;
    }
}

export default reducer;
