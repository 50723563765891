import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
import { Image } from "../../../component";
import bookingSuccess from "../../../images/booking-success.png";
import styles from "../Cart.module.scss";
import { useHistory } from "react-router-dom";

const BookingSuccessModal = ({ isVisible, onClose }) => {
  const history = useHistory();
  const handleRedirectToBookingList = () => {
    history.push("/user/booking-list");
  };
  return (
    <Modal
      title="Thanh toán thành công"
      visible={isVisible}
      onCancel={onClose}
      footer={
        <Button onClick={handleRedirectToBookingList} type="primary">
          Đi đến danh sách đã book
        </Button>
      }
    >
      <div className={styles.bookingSuccessImgWrapper}>
        <Image src={bookingSuccess} />
      </div>
    </Modal>
  );
};
BookingSuccessModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
};
BookingSuccessModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BookingSuccessModal;
