import React from "react";
import styles from "./HeadingBox.module.scss";

const HeadingBox = ({ title, backgroundColor }) => {
  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className={styles.headingWrapper}
    >
      <h3>{title}</h3>
    </div>
  );
};

export default HeadingBox;
