import React from "react";
import styles from "./footer.module.scss";
import { Icon, Layout, List } from "antd";
import HomePageApi from "../../api/homePage";
import { connect } from "react-redux";
import { ResourceUrl, DomainLink, http, MainDomain } from "../../config/main";
import HotKeyword from "../hotKeyword/HotKeyword";
import Image from "../image";

const { Footer } = Layout;

class SiteFooter extends React.Component {
  state = {
    articles: [],
    blogs: [],
  };

  componentDidMount() {
    HomePageApi.getFooterContent().then((response) => {
      if (response.data.status === 1) {
        this.setState({
          articles: response.data.articles,
          blogs: response.data.blogs,
        });
      }
    });
  }

  getArticle() {
    let articleHtml = [];
    if (this.state.articles.length > 0) {
      for (let y in this.state.articles) {
        const article = this.state.articles[y];
        const articleElement = (
          <List.Item key={y}>
            <List.Item.Meta
              avatar={
                <Image
                  style={{
                    width: 50,
                  }}
                  src={ResourceUrl + article.cover_image}
                />
              }
            />
            <a
              href={
                (article.user_sub_domain
                  ? http +
                    article.user_sub_domain +
                    "." +
                    MainDomain +
                    "/" +
                    article.slug
                  : "/broker/" + article.user_slug + "/" + article.slug) +
                ".html"
              }
              target={
                this.props.domain !== undefined && this.props.domain.isSubDomain
                  ? "blank"
                  : "_self"
              }
            >
              {article.title}
            </a>
          </List.Item>
        );
        articleHtml.push(articleElement);
      }
    }
    return articleHtml;
  }

  getBlogs() {
    let blogHtml = [];
    if (this.state.blogs.length > 0) {
      for (let i in this.state.blogs) {
        const blog = this.state.blogs[i];
        const blogElement = (
          <List.Item key={i}>
            <List.Item.Meta
              avatar={
                <Image
                  style={{
                    width: 50,
                  }}
                  src={ResourceUrl + blog.cover_image}
                />
              }
            />
            <a
              href={DomainLink + "/blog/" + blog.slug}
              target={
                this.props.domain !== undefined && this.props.domain.isSubDomain
                  ? "blank"
                  : "_self"
              }
            >
              {blog.title}
            </a>
          </List.Item>
        );
        blogHtml.push(blogElement);
      }
    }
    return blogHtml;
  }

  render() {
    return (
      <Footer className={styles.siteFooter}>
        <HotKeyword />
        <div className={styles.footerWidgetArea}>
          <div className={styles.widgetFooter}>
            <div className={styles.widgetFooterContent}>
              <List
                itemLayout="vertical"
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 3,
                  xxl: 3,
                }}
              >
                <List.Item className="img-logo">
                  <img
                    className="img-ful"
                    src={ResourceUrl + "locations/logos/vn.png"}
                    alt=""
                  />
                  <span className="address">
                    <Icon type="mail" theme="filled" />{" "}
                    <a href="mailto:info@homesface.com">info@homesface.com</a>
                  </span>
                  <img
                    className="img-ful logo-bct"
                    src={ResourceUrl + "/BCT.png"}
                    alt=""
                  />

                  <span>
                    Chúng tôi có trách nhiệm chuyển tải tin đăng. Chúng tôi
                    không chịu bất kỳ trách nhiệm nào từ các tin này.
                  </span>
                  <div className={styles.social}>
                    <a
                      href="https://www.facebook.com/Homes-Face-101696581189707"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon type="facebook" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCVITXSW18hOY9Ubhrl8smTg/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon type="youtube" />
                    </a>
                  </div>
                </List.Item>

                <List.Item>
                  <List
                    header={
                      <b
                        style={{
                          textTransform: "uppercase",
                          color: "rgb(191, 129, 0)",
                        }}
                      >
                        Chuyên gia đánh giá
                      </b>
                    }
                  >
                    <ul>{this.getArticle()}</ul>
                  </List>
                </List.Item>
                <List.Item>
                  <List
                    header={
                      <b
                        style={{
                          textTransform: "uppercase",
                          color: "rgb(191, 129, 0)",
                        }}
                      >
                        Tin tức
                      </b>
                    }
                  >
                    <ul>{this.getBlogs()}</ul>
                  </List>
                </List.Item>
              </List>
            </div>
            <div className={styles.footerTextArea}>
              <div className={styles.siteInfo}>
                <div className="site-copyright-area">
                  Copyright © 2020 - HomesFace. All Rights Reserved.
                </div>
                <ul id="menu-footer-menu" className={styles.footerMenu}>
                  <li
                    id="menu-item-994"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-994"
                  >
                    <a href="__">Chính sách bảo mật</a>
                  </li>
                  <li
                    id="menu-item-994"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-994"
                  >
                    <a href="__">Điều khoản sử dụng</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    domain: state.domainInfo.domain,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteFooter);
