import axios from "axios";
import {ApiUrl} from "../config/main";
const https = require('https');

const axiosApi = axios.create({
    baseURL: ApiUrl, 
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
});

const SEO = {
    realStateSeo(data) {
        return axiosApi.get('/seo/real-state', {
            params: data
        })
    },
    projectSeo(data) {
        return axiosApi.get('/seo/project', {
            params: data
        })
    },
    blogSeo(data) {
        return axiosApi.get('/seo/blog', {
            params: data
        })
    }
};

export default SEO;