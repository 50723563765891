import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResourceUrl } from "../../../config/main";
import DefaultAvatar from "../../../images/avatar-default.png";

const useAvatar = () => {
  const [avatarUrl, setAvatarUrl] = useState("");
  const profile = useSelector((state) => state.profile.infor);
  useEffect(() => {
    if (profile?.avatar) {
      setAvatarUrl(`${ResourceUrl}${profile?.avatar}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.avatar]);
  const handleImageLoadError = (value) => {
    setAvatarUrl(DefaultAvatar);
  };
  const handleChangeAvatar = (value) => {
    setAvatarUrl(value);
  };
  return {
    avatarUrl: avatarUrl || DefaultAvatar,
    handleImageLoadError,
    handleChangeAvatar,
  };
};

export default useAvatar;
