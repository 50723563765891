import React from "react";
import Image from "../image";
import Helpers from '../../functions/Helpers';
import {ResourceUrl} from "../../config/main";
import {Col,  Row } from "antd";

const ArticleDetail = (props) => {
  const article = props.article;
  return (
    <div className="aritcle">
        <div className="aritcle-title">
            <div className="blog-banner">
                <h3>{article.title}</h3>
                <span className="updater">
                  Đăng ngày { Helpers.formatDate(article.created_at) } bởi { article.user_name }
                </span>
            </div>
        </div>
        <div className="section-wrap article-content">
            <div className="article-body" >
              <Row gutter={24}>
                  <Col span={24}>
                      <div className="post-heading">

                      </div>
                      <div className="hs-featured-image-wrapper">
                          <Image className="img-ful img-post" src={ResourceUrl + article.cover_image}/>
                      </div>
                      <div className="post-body">
                          <p style={{ textAlign: 'justify' }}
                             dangerouslySetInnerHTML={{ __html: article.description}}></p>
                          <p style={{ textAlign: 'justify' }}
                             dangerouslySetInnerHTML={{ __html: article.body}}></p>
                      </div>
                  </Col>
              </Row>
            </div>
        </div>
    </div>
  )
}

export default ArticleDetail;
