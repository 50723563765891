import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { getDistricts, GET_DISTRICTS_URL } from "../../api/homeTown";
import { Select } from "antd";

const { Option } = Select;
const SelectDistrictField = ({ cityCode, onSelect, valueOfSelected, isDisabled }) => {
  const { data, isFetching } = useQuery(
    [GET_DISTRICTS_URL(cityCode)],
    () => getDistricts(cityCode),
    {
      enabled: !!cityCode,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  const districts = data?.data?.districts;
  return (
    <Select
      onSelect={onSelect}
      placeholder="Chọn..."
      disabled={!cityCode || isDisabled}
      loading={isFetching}
      value={valueOfSelected}
    >
      {districts?.length > 0 &&
        districts.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
    </Select>
  );
};

SelectDistrictField.defaultProps = {
  cityCode: null,
  onSelect: () => {},
  valueOfSelected: '',
  isDisabled: false,
};

SelectDistrictField.propTypes = {
  cityCode: PropTypes.string,
  onSelect: PropTypes.func,
  valueOfSelected: PropTypes.number,
  isDisabled: PropTypes.bool,
};

export default SelectDistrictField;
