import React from "react";
import { Redirect, Route } from "react-router-dom";
import SiteHeader from "../../component/header/Header";
import SiteFooter from "../../component/footer/footer";
import JusSold from "../../component/justSold/jusSold";
import MenuBot from "../../component/menuBot/menuBot" 

const MainLayoutRoute = ({
  component: Component,
  domainData,
  privateRouter,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <React.Fragment>
          {rest.renderWithoutMainlayout ? (
            <Component {...props} {...domainData} />
          ) : (
            <>
              <SiteHeader {...domainData} />
              <Component {...props} {...domainData} />
              <SiteFooter />
              <JusSold />
              <MenuBot />
            </>
          )}
          {privateRouter && rest.redirect ? <Redirect to="/" /> : null}
        </React.Fragment>
      )}
    />
  );
};

export default MainLayoutRoute;
