import React from "react";
import FormArticle from '../../component/formArticle/formArticle'
import { Row, Col, Icon, Breadcrumb } from 'antd';
import ListVertical from '../../component/listVertical/listVertical'
import {withRouter} from "react-router-dom";

class CreateEditArticle extends React.Component {

    render() {
        return (
          <div className="frontPageSection">
            <div className="section-wrap-private">
              <Row gutter={32}>
                <Col span={24} style={{ marginBottom: "1rem" }}>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Icon type="home" />
                      <span>Trang chủ</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span>
                        {this.props.match.params.slug
                          ? `Sửa bài đánh giá`
                          : `Đăng bài đánh giá mới`}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
                <Col span={4}>
                  <ListVertical activeLink={`Tin đã đăng`} />
                </Col>
                <Col span={20}>
                  <FormArticle />
                </Col>
              </Row>
            </div>
          </div>
        );
    }
}

export default withRouter(CreateEditArticle);