import React from "react";
import { Button, Checkbox, Col, Divider, Form, Input, Row, Alert } from "antd";
import styles from "../banner/banner.module.scss";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { FacebookId, GoogleId } from "../../config/main";
import User from "../../models/User";
import loginSignUpApi from "../../api/loginSignUp";
import { connect } from "react-redux";
import { saveProfile } from "../../store/actions/profile";
import { redirectToPostRealEstate } from "../../store/actions/redirectToPostRealEstate";
import { withRouter } from "react-router-dom";

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (infor) => dispatch(saveProfile(infor)),
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
  };
};

const mapStateToProps = (state) => {
  return {
    redirect: state.redirectToPostRealEstate,
  };
};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmDirty: false,
      alertLoginFailed: false,

      isSubmit: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      visible: false,
      isSubmit: false,
    });
  }

  show = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  responseGoogle = (response) => {
    if (response) {
      let user = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
        provider: "google",
        provider_id: response.googleId,
      };
      loginSignUpApi.loginWith3rdParty(user).then((res) => {
        if (res.data.status === 1) {
          User.saveToken(res.data.token);
          this.props.saveProfile(res.data.infor);
          this.setState({
            visible: false,
          });
          if (this.props.redirect) {
            this.props.redirectToPostRealEstate(false);
            this.props.history.push("/create-real-estate");
          }
        }
      });
    }
  };

  responseFacebook = (response) => {
    if (response) {
      let user = {
        email: response.email,
        first_name: response.first_name,
        last_name: response.last_name,
        provider: "facebook",
        provider_id: response.id,
      };
      loginSignUpApi.loginWith3rdParty(user).then((res) => {
        if (res.data.status === 1) {
          User.saveToken(res.data.token);
          this.props.saveProfile(res.data.infor);
          this.setState({
            visible: false,
          });
          if (this.props.redirect) {
            this.props.redirectToPostRealEstate(false);
            this.props.history.push("/create-real-estate");
          }
        }
      });
    }
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({
      isSubmit: true,
    });
    this.props.form.validateFields((err, user) => {
      if (!err) {
        loginSignUpApi
          .login(user)
          .then((res) => {
            if (res.data.status) {
              if (res.data.token) {
                User.saveToken(res.data.token);
                this.props.saveProfile(res.data.infor);
              } 
              this.setState({
                visible: false,
                isSubmit: false,
              });
              if (this.props.redirect) {
                this.props.redirectToPostRealEstate(false);
                this.props.history.push("/create-real-estate");
              }
            }
          })
          .catch((err) => {
            this.setState({
              alertLoginFailed: true,
              isSubmit: false,
            });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Row>
          <FacebookLogin
            appId={FacebookId}
            fields="first_name, last_name, email,picture"
            render={(renderProps) => (
              <FacebookLoginButton
                align={"center"}
                onClick={renderProps.onClick}
              >
                <span>Đăng nhập với Facebook</span>
              </FacebookLoginButton>
            )}
            callback={this.responseFacebook.bind(this)}
          />
        </Row>
        <br />
        <Row>
          <GoogleLogin
            clientId={GoogleId}
            buttonText="Login"
            onSuccess={this.responseGoogle.bind(this)}
            onFailure={this.responseGoogle.bind(this)}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <GoogleLoginButton
                align={"center"}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <span>Đăng nhập với Google</span>
              </GoogleLoginButton>
            )}
          />
        </Row>
        <Divider>Hoặc</Divider>
        <Form onSubmit={this.handleLogin}>
          <Form.Item label="Số điện thoại" className={styles.rowInput}>
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "Vui lòng nhập số điện thoại!" },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Mật khẩu" className={styles.rowInput}>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Vui lòng nhập mật khẩu!" }],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item className={styles.rowInput}>
            <Row>
              <Col span={12}>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Checkbox>Nhớ mật khẩu</Checkbox>)}
              </Col>
              <Col span={12} style={{ textAlign: "end" }}>
                <a href="/">Quên mật khẩu</a>
              </Col>
            </Row>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={!!this.state.isSubmit}
              onClick={this.handleLogin.bind(this)}
            >
              Đăng nhập
            </Button>
          </Form.Item>
          {this.state.alertLoginFailed && (
            <Alert
              message="Đăng nhập thất bại: sai tên tài khoản hoặc mật khẩu"
              type="error"
              showIcon
            />
          )}
        </Form>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(LoginForm)));
