import React, { useEffect, useState } from "react";
import styles from "../../Cart.module.scss";

const CountDownTimer = ({ onChangeTimesUp, timeRemaining }) => {
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  useEffect(() => {
    setMinutes(timeRemaining?.minutes);
    setSeconds(timeRemaining?.seconds);
  }, [timeRemaining]);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          onChangeTimesUp();
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <>
      {displayMinutes && displaySeconds && (
        <span className={styles.countDown}>
          00:{displayMinutes}:{displaySeconds}
        </span>
      )}
    </>
  );
};

CountDownTimer.propTypes = {};

export default CountDownTimer;
