import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Row,
  Typography
} from "antd";
import PropTypes, { string } from "prop-types";
import React, { PureComponent } from "react";
//
import styles from "../../Auth.module.scss";
import { FormItemInput, FormItemInputPassword } from "../../components";

const { Text, Title } = Typography;
export class SignInForm extends PureComponent {
  render() {
    const { form, isLoading, onSubmit, formErrorMessage, onRedirectToSignUp } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={styles.signInFormContainer}>
        <Row>
          <Col>
            <Title level={3} style={{ color: "black", fontWeight: 700, textAlign: 'center' }}>
              Đăng nhập Hệ thống
            </Title>
          </Col>
        </Row>
        <br />
        {formErrorMessage && (
          <Alert message={formErrorMessage} type="error" showIcon />
        )}
        <Form onSubmit={() => {}}>
          <FormItemInput
            addonBefore={<Icon type="user" />}
            getFieldDecorator={getFieldDecorator}
            name="phone"
            label={<span style={{ fontWeight: 700 }}>Số điện thoại</span>}
            rules={[
              { required: true, message: "Vui lòng nhập số điện thoại!" },
            ]}
            placeholder="Nhập số điện thoại của bạn"
          />
          <FormItemInputPassword
            getFieldDecorator={getFieldDecorator}
            name="password"
            label={<span style={{ fontWeight: 700 }}>Mật khẩu</span>}
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
            placeholder="Nhập mật khẩu"
          />
          <Form.Item>
            <Row>
              <Col span={12}>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Checkbox>Nhớ mật khẩu</Checkbox>)}
              </Col>
              <Col span={12} style={{ textAlign: "end" }}>
                <a href="/">Quên mật khẩu</a>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                  onClick={onSubmit.bind(this)}
                >
                  Đăng nhập
                </Button>
              </Col>
            </Row>

            <Row className={styles.rowCreateAccount}>
              <Text>Bạn chưa có tài khoản?</Text>
              <Text onClick={onRedirectToSignUp}>Đăng ký</Text>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

SignInForm.defaultProps = {
  form: null,
  onSubmit: () => {},
  isLoading: false,
  formErrorMessage: string,
  onRedirectToSignUp: () => {}
};
SignInForm.propTypes = {
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  formErrorMessage: string,
  onRedirectToSignUp: PropTypes.func,
};

export default SignInForm;
