import { Divider } from "antd";
import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./Booking.module.scss";
import { formatCurrency } from "../../utils/FormatCurrency";
import {
  isAttributeArea,
  isAttributeNumberBedRoom,
  isAttributeNumberToilet,
} from "../../utils/apartmentUtils";

const ApartmentInfo = ({
  houseName,
  floorNumber,
  apartmentAttributes,
  totalPriceNonVAT,
  buildingName,
  shouldDisplayPriceDetail,
  percentAmount,
  percent,
}) => {
  return (
    <div className={styles.cartPayCard}>
      <p className={styles.title}>
        {houseName} - Tầng {floorNumber} - Phân khu {buildingName}
      </p>
      {apartmentAttributes?.length > 0
        ? apartmentAttributes.map((attribute, index) => (
            <p key={index} className={styles.content}>
              <span>{attribute.label}:</span>
              <span>
                {attribute.value}
                {isAttributeNumberBedRoom(attribute) && (
                  <span className={styles.attributeSuffix}>PN</span>
                )}
                {isAttributeNumberToilet(attribute) && (
                  <span className={styles.attributeSuffix}>WC</span>
                )}
                {isAttributeArea(attribute) && (
                  <span className={styles.attributeSuffix}>
                    m<sup>2</sup>
                  </span>
                )}
              </span>
            </p>
          ))
        : null}
      <Divider className={styles.devider} />
      {shouldDisplayPriceDetail ? (
        <>
          <div className={styles.totalPriceRow}>
            <p className={styles.totalPriceLabel}>Tạm tính</p>
            <p className={styles.totalPrice}>
              <span>{formatCurrency(totalPriceNonVAT, false)}</span>
              <span className={styles.unit}>vnđ</span>
            </p>
          </div>
          {(Boolean(percentAmount) || Boolean(percent)) && (
            <div
              className={`${styles.totalPriceRow} ${styles.discountPriceRow}`}
            >
              <p className={styles.totalPriceLabel}>
                {Boolean(percentAmount) ? "Chiết khấu" : "Hoa hồng"}
              </p>
              <p className={styles.totalPrice}>
                {Boolean(percentAmount) ? (
                  <>
                    <span>{formatCurrency(percentAmount, false)}</span>
                    <span className={styles.unit}>vnđ</span>
                  </>
                ) : (
                  <span>{percent} %</span>
                )}
              </p>
            </div>
          )}
          <Divider className={styles.devider} />
          <div className={styles.totalPriceRow}>
            <p className={styles.totalPriceLabel}>Tổng cộng</p>
            <p className={styles.totalPrice}>
              <span className={styles.totalPriceValue}>
                {formatCurrency(totalPriceNonVAT, false)}
              </span>
              <span className={styles.unit}>vnđ</span>
              <p className={styles.textVAT}>Đã bao gồm VAT và KPBT</p>
            </p>
          </div>
        </>
      ) : (
        <>
          <p className={styles.totalPrice}>
            <span>{formatCurrency(totalPriceNonVAT, false)}</span>
            <span className={styles.unit}>vnđ</span>
          </p>
        </>
      )}
    </div>
  );
};

ApartmentInfo.defaultProps = {
  houseName: "Unknown",
  floorNumber: 0,
  apartmentAttributes: [],
  totalPriceNonVAT: 0,
  buildingName: "Unknown",
  shouldDisplayPriceDetail: false,
  percentAmount: 0,
};
ApartmentInfo.propTypes = {
  houseName: PropTypes.string,
  floorNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  apartmentAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  totalPriceNonVAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buildingName: PropTypes.string,
  shouldDisplayPriceDetail: PropTypes.bool,
};

export default memo(ApartmentInfo);
