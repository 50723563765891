import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Typography, Tag, Skeleton } from "antd";
import { ResourceUrl } from "../../../config/main";
import Axios from "../../../axiosApi";
import "./index.scss";
import Image from "../../../component/image";
import CardSlider from "../../../component/card_slider/cardSlider";
import { Link } from "react-router-dom";
import moment from "moment";
import CountDownContainer from "../../../component/CountDown/CountDownContainer";
import { EVENT_CATEGORIES } from "../../../constants/event";

const { Title } = Typography;

function Event(props) {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      Axios.get("home/listEventHome")
        .then((res) => {
          setEvents(res.data.events);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);
  const renderEventSkeleton = () => {
    return Array(5)
      .fill()
      .map((_, index) => {
        return (
          <div key={index}>
            <div className="event-card">
              <Card hoverable>
                <Skeleton className="event-card-skeleton" paragraph={{ rows: 1, width: '100%' }} title={false} active />
              </Card>
            </div>
          </div>
        );
      });
  };

  function getEvents() {
    return events.map((event) => {
      return <ItemEvent key={event.id} {...event} />;
    });
  }
  return (
    <div className="event-container section-wrap">
      {props.title}
      <CardSlider slideItem={3}>{isLoading ? renderEventSkeleton() : getEvents()}</CardSlider>
    </div>
  );
}

const ItemEvent = (event) => {
  let startDate = "";
  if (event.start_date !== null) startDate = event.start_date.split(" ");

  const tags = event.event_category.map((category, i) => {
    const eventCate = EVENT_CATEGORIES.find(
      (item) => item.code === category?.code
    );
    return (
      <Tag
        style={{ backgroundColor: eventCate ? eventCate.color : "" }}
        key={i}
      >
        {category.name}
      </Tag>
    );
  });

  return (
    <div className="event-card">
      <Link to={"/event/" + event.slug}>
        <Card hoverable>
          <div className="body body--has-count-down">
            {tags}
            <CountDownContainer timeTillDate={event.start_date} />
            <div className="event__content">
              <Title level={3} className="title-event">
                {event.title}
              </Title>
              {event.start_date !== null && event.start_date !== "" && (
                <div className="time-event__count-down">
                  <Title level={3}>
                    Lúc {startDate[1].substring(0, startDate[1].length - 3)},
                    ngày {moment(startDate[0]).format("DD-MM-Y")}
                  </Title>
                </div>
              )}
            </div>
          </div>
        </Card>
        <div className="cartImage">
          <Image src={ResourceUrl + event.cover_image} />
        </div>
      </Link>
    </div>
  );
};

const TitleEvent = (
  <div className="title-event">
    {/* <HeadingBox
      headingColor="#BF0000"
      heading="Sự kiện nổi bật"
      subHeadingColor="#878D97"
      subHeading="Những sự kiện nổi bật đang diễn ra"
    /> */}
  </div>
);

Event.propTypes = {
  title: PropTypes.element.isRequired,
};

Event.defaultProps = {
  title: TitleEvent,
};

export default Event;
