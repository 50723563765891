import _ from "lodash";
import { NUMBER_BED_ROOM_CODE, WALL_AREA_CODE } from "../../constants/apartment";
import {
  APARTMENT_STATUSES,
  APARTMENT_STATUS_UN_SALEABLE_VALUE,
} from "./constants";

export const getHeaders = (apartmentPivots) => {
  if (apartmentPivots?.length === 0) {
    return [];
  }
  return [
    {
      id: "RoomName",
      tooltipTitle: "Số thứ tự của căn",
      iconName: "home",
      collections: apartmentPivots.map((item) => ({
        roomNumber: item.RoomNumber,
        property: item.RoomNumber,
      })),
    },
    // {
    //   id: "HouseStyle",
    //   tooltipTitle: "Loại căn hộ",
    //   iconName: "shop",
    //   collections: apartmentPivots.map((item) => ({
    //     roomNumber: item.RoomNumber,
    //     property: item.HouseStyle,
    //   })),
    // },
    // {
    //   id: "BalconyOrientation",
    //   tooltipTitle: "Hướng ban công",
    //   iconName: "compass",
    //   collections: apartmentPivots.map((item) => ({
    //     roomNumber: item.RoomNumber,
    //     property: item.BalconyOrientation,
    //   })),
    // },
    // {
    //   id: "ExpectedInsideAreaMax",
    //   tooltipTitle: "Diện tích tim tường m2",
    //   iconName: "area-chart",
    //   collections: apartmentPivots.map((item) => ({
    //     roomNumber: item.RoomNumber,
    //     property: `${item.ExpectedInsideAreaMax} m2`,
    //   })),
    // },
  ];
};

export const addClassNammeToHeaders = (
  headers,
  currentRoomNumber,
  className
) => {
  if (headers?.length === 0) return [];
  return headers.map((header) => {
    const newCollections = header.collections.map((item) => {
      const newItem = { ...item };
      if (item.roomNumber === currentRoomNumber) {
        newItem.className = className;
      } else {
        newItem.className = "";
      }
      return newItem;
    });
    const newHeader = { ...header, collections: newCollections };
    return newHeader;
  });
};
export const removeClassNammeToHeaders = (headers) => {
  return headers.map((header) => {
    const newCollections = header.collections.map((item) => {
      const newItem = { ...item };
      newItem.className = "";
      return newItem;
    });
    const newHeader = { ...header, collections: newCollections };
    return newHeader;
  });
};
// get all aparment of per floor
export const getApartments = (apartmentPivots) => {
  const apartments = [];

  if (apartmentPivots?.length > 0) {
    apartmentPivots.forEach(({ Apartments, RoomNumber }) => {
      Apartments.forEach((element) => {
        apartments.push({
          roomNumber: RoomNumber,
          ...element,
        });
      });
    });
  }
  return apartments;
};
export const groupAparmentsByFloorNumber = (appartments) => {
  return _.chain(appartments)
    .groupBy("FloorNumber")
    .map((value, key) => {
      return { floorNumber: key, apartments: value };
    })
    .value();
};

export const updateApartments = (apartments, roomNumbers, floorNumber) => {
  const newApartments = roomNumbers?.map((item) => {
    let newItem = null;
    apartments.forEach((apartment) => {
      if (item === apartment.roomNumber) {
        newItem = { ...apartment };
      }
    });
    if (!newItem) {
      newItem = {
        roomNumber: item,
        Status: APARTMENT_STATUS_UN_SALEABLE_VALUE,
        FloorNumber: Number(floorNumber),
      };
    }
    return newItem;
  });
  return newApartments;
};
export const getRoomNumberList = (apartmentPivots) => {
  return apartmentPivots.map((item) => {
    return item.RoomNumber;
  });
};

export const getFloors = (apartmentPivots, floorInfos) => {
  if (!apartmentPivots) return [];
  const roomNumbers = getRoomNumberList(apartmentPivots);
  const apartments = getApartments(apartmentPivots);
  const floors = groupAparmentsByFloorNumber(apartments);
  const newFloors = floors?.map((item) => {
    const newItem = { ...item };
    newItem.apartments = updateApartments(
      item.apartments,
      roomNumbers,
      newItem.floorNumber
    );
    // attach sale able counter to floor
    if (floorInfos?.length > 0) {
      floorInfos.forEach((floor) => {
        if (Number(newItem.floorNumber) === Number(floor.FloorNumber)) {
          newItem.saleableCounter = floor.SaleableCounter;
        }
      });
    }

    return newItem;
  });
  return newFloors;
};

export const getRooms = (apartmentPivots) => {
  return apartmentPivots?.map((item) => {
    return {
      roomName: item.RoomName,
      roomNumber: item.RoomNumber,
      status: APARTMENT_STATUS_UN_SALEABLE_VALUE,
    };
  });
};
export const getApartmentStatus = (status) => {
  return APARTMENT_STATUSES.find((item) => item.value === status);
};

export const addClassNameToApartmentPivots = (
  apartmentPivots,
  className,
  currentApartment
) => {
  if (apartmentPivots?.length === 0) return [];
  const newApartmentPivots = apartmentPivots.map((apartmentPivot) => {
    const newApartments = apartmentPivot.apartments.map((apartment) => {
      const newApartment = { ...apartment };
      if (
        (newApartment.roomNumber <= currentApartment.roomNumber &&
          Number(newApartment.FloorNumber) ===
            Number(currentApartment.FloorNumber)) ||
        (currentApartment.roomNumber === newApartment.roomNumber &&
          Number(newApartment.FloorNumber) <=
            Number(currentApartment.FloorNumber))
      ) {
        newApartment.className = className;
      } else {
        newApartment.className = "";
      }

      return newApartment;
    });
    const newApartmentPivot = { ...apartmentPivot, apartments: newApartments };
    return newApartmentPivot;
  });
  return newApartmentPivots;
};

export const removeClassNameToApartmentPivots = (apartmentPivots) => {
  const newApartmentPivots = apartmentPivots.map((apartmentPivot) => {
    const newApartments = apartmentPivot.apartments.map((apartment) => {
      const newApartment = { ...apartment };
      newApartment.className = "";
      return newApartment;
    });
    const newApartmentPivot = { ...apartmentPivot, apartments: newApartments };
    return newApartmentPivot;
  });
  return newApartmentPivots;
};
export const getNumberBedRoom = (apartmentAttributes) => {
  if (apartmentAttributes?.length === 0) return null;
  const bedRoom = apartmentAttributes.find(
    (item) => item.code === NUMBER_BED_ROOM_CODE
  );
  if (!bedRoom) return null;
  return bedRoom.value;
};
export const getWallArea = (apartmentAttributes) => {
  if (apartmentAttributes?.length === 0) return null;
  const wallArea = apartmentAttributes.find(
    (item) => item.code === WALL_AREA_CODE
  );
  if (!wallArea) return null;
  return wallArea.value;
};