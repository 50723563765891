import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ProfileApi from "../../../api/profileApi";
import { ResourceUrl } from "../../../config/main";
import { saveProfile } from "../../../store/actions/profile";
import { redirectToPostRealEstate } from "../../../store/actions/redirectToPostRealEstate";
// Components
import { PageContent, PageSidebar, Wrapper } from "../components";
import { MENU_MY_PROFILE_ID } from "../constants";
import ChangePasswordForm from "./components/ChangePasswordForm";
import EditUserInfoForm from "./components/EditUserInfoForm";
import UploadBannerImage from "./components/UploadBannerImage";
import UserInfoPanel from "./components/UserInfoPanel";

const MyProfile = (props) => {
  const { form, profile, saveProfile } = props;
  const { getFieldDecorator, setFieldsValue, validateFields } = form;

  const [certificationFiles, setCertificationFiles] = useState([]);
  const [certificationDeleteFiles, setCertificationDeleteFiles] = useState([]);
  const [certificationInsertFiles, setCertificationInsertFiles] = useState([]);

  const [citizeIdenFiles, setCitizeIdenFiles] = useState([]);
  const [citizeIdenDeleteFiles, setCitizeIdenDeleteFiles] = useState([]);
  const [citizeIdenInsertFiles, setCitizeIdenInsertFiles] = useState([]);
  const [isLoadingEditUser, setIsLoadingEditUser] = useState(false);
  const [isLoadingUpdateBanner, setIsLoadingUpdateBanner] = useState(false);
  useEffect(() => {
    if (profile) {
      setFieldsValue({
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        name: profile?.name,
        email: profile?.email,
        phone: profile?.phone,
        about_us: profile.about_us,
        slug: profile.slug,
        zalo: profile.zalo,
        facebook: profile?.facebook,
        broker_category_id: profile.broker_category_id,
        cmnd: profile.cmnd,
        chung_chi: profile.chung_chi,
      });
      setCitizeIdenFiles(() => {
        return profile?.cmnd?.map((item) => ({
          uid: item.id,
          url: `${ResourceUrl}${item.url}`,
        }));
      });
      setCertificationFiles(() => {
        return profile?.chung_chi?.map((item) => ({
          uid: item.id,
          url: `${ResourceUrl}${item.url}`,
        }));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleSubmitEditUserInfo = (event) => {
    event.preventDefault();
    validateFields((err, user) => {
      if (!err) {
        user = {
          ...user,
          id: profile.id,
          images_cmnd_delete: citizeIdenDeleteFiles,
          images_cmnd_insert: citizeIdenInsertFiles,
          images_chung_chi_delete: certificationDeleteFiles,
          images_chung_chi_insert: certificationInsertFiles,
        };
        setIsLoadingEditUser(true);
        ProfileApi.editProfile(user)
          .then((res) => {
            if (res.data.status) {
              Swal.fire({
                icon: "success",
                title: "Cập nhật thông tin thành công",
                showConfirmButton: true,
              });
              saveProfile({
                ...profile,
                wall_picture: res.data.wall_picture,
                first_name: user.first_name,
                last_name: user.last_name,
                name: user.name,
                email: user.email,
                phone: user.phone,
                about_us: user.about_us,
                slug: user.slug,
                zalo: user.zalo,
                facebook: user.facebook,
                broker_category_id: user.broker_category_id,
              });
              setIsLoadingEditUser(false);
            }
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Cập nhật thông tin thất bại",
              text: "Xin lỗi!, hệ thống đang bận vui lòng quay lại sau",
              showConfirmButton: true,
            });
            setIsLoadingEditUser(false);
          });
      }
    });
  };

  const handleChangeCitizenIdenFile = ({ file, fileList }) => {
    if (file.status === "done") {
      setCitizeIdenInsertFiles((previous) => {
        return [...previous, file];
      });
    }
    setCitizeIdenFiles(fileList);
  };

  const handleChangeCertificationFile = ({ file, fileList }) => {
    if (file.status === "done") {
      setCertificationInsertFiles((previous) => {
        return [...previous, file];
      });
    }
    setCertificationFiles(fileList);
  };
  const handleRemoveCitizenIdenFile = (file) => {
    setCitizeIdenDeleteFiles((previous) => {
      return [...previous, file];
    });
  };
  const handleRemoveCertificationFile = (file) => {
    setCertificationDeleteFiles((previous) => {
      return [...previous, file];
    });
  };
  const handleUpdateAvatar = (file) => {
    const param = {
      id: profile.id,
      avatar: [file],
    };
    ProfileApi.editProfile(param).then((res) => {
      if (res.data.status) {
        saveProfile({
          ...profile,
          avatar: res.data.avatar,
        });
      }
    });
  };
  const handleUpdateBanner = (file) => {
    const param = {
      id: profile.id,
      wall_picture: [file],
    };
    setIsLoadingUpdateBanner(true);
    ProfileApi.editProfile(param)
      .then((res) => {
        if (res.data.status) {
          saveProfile({
            ...profile,
            wall_picture: res.data.wall_picture,
          });
          setIsLoadingUpdateBanner(false);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Cập nhật banner thất bại",
          text: "Xin lỗi!, hệ thống đang bận vui lòng quay lại sau",
          showConfirmButton: true,
        });
        setIsLoadingUpdateBanner(false);
      });
  };
  return (
    <Wrapper>
      <Row type="flex">
        <PageSidebar selectedMenuId={MENU_MY_PROFILE_ID} />
        <PageContent>
          <UserInfoPanel
            fullName={`${profile?.last_name} ${profile?.first_name}`}
            phone={profile?.phone}
            email={profile?.email}
            onUpdateAvatar={handleUpdateAvatar}
            avatar={profile?.avatar}
          />

          <EditUserInfoForm
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            profile={profile}
            citizeIdenFiles={citizeIdenFiles}
            certificationFiles={certificationFiles}
            onSubmit={handleSubmitEditUserInfo}
            onChangeCitizenIdenFile={handleChangeCitizenIdenFile}
            onChangeCertificationFile={handleChangeCertificationFile}
            onRemoveCitizenIdenFile={handleRemoveCitizenIdenFile}
            onRemoveCertificationFile={handleRemoveCertificationFile}
            isLoading={isLoadingEditUser}
          />

          <Row gutter={[16, 16]} type="flex" style={{ marginTop: 25 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <ChangePasswordForm />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <UploadBannerImage
                currentBanner={profile?.wall_picture}
                onUpdateBanner={handleUpdateBanner}
                isLoadingUpdateBanner={isLoadingUpdateBanner}
              />
            </Col>
          </Row>
        </PageContent>
      </Row>
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => dispatch(saveProfile(info)),
    redirectToPostRealEstate: (flag) =>
      dispatch(redirectToPostRealEstate(flag)),
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
    redirect: state.redirectToPostRealEstate,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(MyProfile)));
