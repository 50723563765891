import Axios from "../axiosApi";

const ContactApi = {
    getAll: (params) => {
        return Axios.get('/contact', {
            params
        }).then(res => res.data);
    }
}

export default ContactApi;
