import React from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import styles from "../banner/banner.module.scss";
import loginSignUpApi from "../../api/loginSignUp";
import { connect } from "react-redux";
import { saveProfile } from "../../store/actions/profile";
import User from "../../models/User";

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (infor) => dispatch(saveProfile(infor)),
  };
};

class SignUpForm extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmDirty: false,
      alertLoginFailed: false,

      googleLoginUrl: null,
      isSubmit: false,
    };
  }

  handleRegister = (e) => {
    e.preventDefault();
    this.setState({
        isSubmit: true,
    });
    this.props.form.validateFields((err, user) => {
      if (!err) {
        loginSignUpApi.register(user).then((res) => {
          this.setState({
            isSubmit: false
          });
          if (res.status) {
            User.saveToken(res.token);
            this.props.saveProfile(res.infor);
            this.setState({
              visible: false,
            });
            this.props.afterSubmit(res.infor);
          }else{
            let errors = {};
            Object.keys(res.error).forEach( (key) => {
              errors[key] = {
                value: this.props.form.getFieldValue(key),
                errors: res.error[key].map(mes => new Error(mes))
              };
            });
            this.props.form.setFields(errors);
          }
        });
      }else {
        this.setState({
          isSubmit: false,
        });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Mật khẩu nhập lại không giống mật khẩu!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["c_password"], { force: true });
    }
    callback();
  };

  validatePhoneNumber = (rule, value, callback) => {
    var phoneno = /^\d{10}$/;
    if (value && !value.match(phoneno)) {
      callback("Số điện thoại là số có 10 chữ số!");
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleRegister}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item label="Họ" className={styles.rowInput}>
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập họ!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tên" className={styles.rowInput}>
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: "Vui lòng nhập tên!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row> 
        <Form.Item label="Số điện thoại" className={styles.rowInput}>
          {getFieldDecorator("phone", {
            rules: [
              {
                required: true,
                message: "Vui lòng nhập số điện thoại!",
              },
              {
                validator: this.validatePhoneNumber,
              },
            ],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Mật khẩu" hasFeedback className={styles.rowInput}>
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Vui lòng nhập mật khẩu!",
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item
          label="Nhập lại mật khẩu"
          hasFeedback
          className={styles.rowInput}
        >
          {getFieldDecorator("c_password", {
            rules: [
              {
                required: true,
                message: "Vui lòng nhập mật khẩu xác nhận!",
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item className={styles.rowInput}>
          <Row>
            <Col span={24}>
              {getFieldDecorator("remember", {
                valuePropName: "checked",
                initialValue: true,
              })(
                <Checkbox>Tôi đồng ý với các điều khoản của Homesface</Checkbox>
              )}
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={this.state.isSubmit}
            onClick={this.handleRegister.bind(this)}
          >
            Đăng ký
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default connect(null, mapDispatchToProps)(Form.create()(SignUpForm));
