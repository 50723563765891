import React from 'react';

import { Result } from 'antd';

const PaymentFail = () => (
    <Result
      status="error"
      title="Yêu cầu đẩy tin của bạn đã gửi không thành công."
      subTitle="Vui lòng chờ trong thời gian vài giờ để chúng tôi kiểm tra,  Xin cảm ơn!"
    />
);

export default PaymentFail;
