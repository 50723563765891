import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Upload,
  Icon,
  Modal,
  Select,
} from "antd";
import "../aboutUsForm/aboutUsForm.scss";
import { uploadUrl } from "../../config/main";
import { ResourceUrl } from "../../config/main";
import PostRealEstatePageApi from "../../api/postRealEstatePage";
import ProfileApi from "../../api/profileApi";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { connect } from "react-redux";
import DefaultAvatar from "../../images/generic-avatar.png";
import DefaultBanner from "../../images/no-photo.png";
import { saveProfile } from "../../store/actions/profile";

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile: (info) => dispatch(saveProfile(info)),
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
  };
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function getBase64FromList(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AboutUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      imageUrl: null,
      imageUrlWallPicture: null,
      isSubmit: false,

      fileListCMND: [],
      file_list_cmnd_insert: [],
      file_list_cmnd_delete: [],

      fileListChungChi: [],
      file_list_chung_chi_insert: [],
      file_list_chung_chi_delete: [],
      broker_categories: [],
    };
  }

  componentDidMount = () => {
    if (this.props.profile) {
      this.props.form.setFieldsValue({
        first_name: this.props.profile.first_name,
        last_name: this.props.profile.last_name,
        name: this.props.profile.name,
        email: this.props.profile.email,
        phone: this.props.profile.phone,
        about_us: this.props.profile.about_us,
        slug: this.props.profile.slug,
        zalo: this.props.profile.zalo,
        facebook: this.props.profile.facebook,
        broker_category_id: this.props.profile.broker_category_id,
      });

      let tempCMND = this.props.profile.cmnd;
      if (tempCMND) {
        tempCMND.forEach((item) => {
          item.url = `${ResourceUrl}${item.url}`;
          item.uid = item.id;
        });
      } else {
        tempCMND = [];
      }

      let tempChungChi = this.props.profile.chung_chi;
      if (tempChungChi) {
        tempChungChi.forEach((item) => {
          item.url = `${ResourceUrl}${item.url}`;
          item.uid = item.id;
        });
      } else {
        tempChungChi = [];
      }
      this.setState({
        imageUrl: `${ResourceUrl}${this.props.profile.avatar}`,
        imageUrlWallPicture: `${ResourceUrl}${this.props.profile.wall_picture}`,
        fileListChungChi: tempChungChi,
        fileListCMND: tempCMND,
      });
    }
    PostRealEstatePageApi.getAllCities().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          cities: res.data.cities,
        });
      } else {
        alert(res.data.message);
      }
    });

    PostRealEstatePageApi.getAllBrokerCategory().then((res) => {
      if (res.data.status === 1) {
        this.setState({
          broker_categories: res.data.broker_categories,
        });
      } else {
        alert(res.data.message);
      }
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!this.props.profile && nextProps.profile) {
      this.props.form.setFieldsValue({
        first_name: nextProps.profile.first_name,
        last_name: nextProps.profile.last_name,
        name: nextProps.profile.name,
        email: nextProps.profile.email,
        phone: nextProps.profile.phone,
        about_us: nextProps.profile.about_us,
        slug: nextProps.profile.slug,
        zalo: nextProps.profile.zalo,
        facebook: nextProps.profile.facebook,
        broker_category_id: nextProps.profile.broker_category_id,
      });

      let tempCMND = nextProps.profile.cmnd;
      if (tempCMND) {
        tempCMND.forEach((item) => {
          item.url = `${ResourceUrl}${item.url}`;
          item.uid = item.id;
        });
      } else {
        tempCMND = [];
      }

      let tempChungChi = nextProps.profile.chung_chi;
      if (tempChungChi) {
        tempChungChi.forEach((item) => {
          item.url = `${ResourceUrl}${item.url}`;
          item.uid = item.id;
        });
      } else {
        tempChungChi = [];
      }

      this.setState({
        imageUrl: `${ResourceUrl}${nextProps.profile.avatar}`,
        imageUrlWallPicture: `${ResourceUrl}${nextProps.profile.wall_picture}`,
        fileListChungChi: tempChungChi,
        fileListCMND: tempCMND,
      });
    }
  };

  handleChangeProfile = (e) => {
    e.preventDefault();

    this.setState({
      isSubmit: true,
    });
    this.props.form.validateFields((err, user) => {
      if (!err) {
        user = {
          ...user,
          id: this.props.profile.id,
          images_cmnd_delete: this.state.file_list_cmnd_delete,
          images_cmnd_insert: this.state.file_list_cmnd_insert,
          images_chung_chi_delete: this.state.file_list_chung_chi_delete,
          images_chung_chi_insert: this.state.file_list_chung_chi_insert,
        };
        ProfileApi.editProfile(user).then((res) => {
          if (res.data.status) {
            Swal.fire({
              icon: "success",
              title: "Cập nhật thông tin thành công",
              showConfirmButton: true,
            });
            this.props.saveProfile({
              ...this.props.profile,
              avatar: res.data.avatar,
              wall_picture: res.data.wall_picture,
              first_name: user.first_name,
              last_name: user.last_name,
              name: user.name,
              email: user.email,
              phone: user.phone,
              about_us: user.about_us,
              slug: user.slug,
              zalo: user.zalo,
              facebook: user.facebook,
              broker_category_id: user.broker_category_id,
            });
            this.setState({
              isSubmit: false,
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Cập nhật thông tin thất bại",
          text: "Vui lòng nhập các thông tin cần thiết",
          showConfirmButton: true,
        });
        this.setState({
          isSubmit: false,
        });
      }
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  handleChangeWallPicture = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loadingWallPicture: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrlWallPicture) =>
        this.setState({
          imageUrlWallPicture,
          loadingWallPicture: false,
        })
      );
    }
  };

  validatePhoneNumber = (rule, value, callback) => {
    var phoneno = /^\d{10}$/;
    if (value && !value.match(phoneno)) {
      callback("Số điện thoại là số có 10 chữ số!");
    } else {
      callback();
    }
  };

  handleChangeCMND = ({ file, fileList }) => {
    if (file.status === "done" && file.response) {
      let temp = [...this.state.file_list_cmnd_insert];
      temp.push(file);
      this.setState({
        file_list_cmnd_insert: temp,
      });
    }
    this.setState({ fileListCMND: fileList });
  };

  handleChangeChungChi = ({ file, fileList }) => {
    if (file.status === "done" && file.response) {
      let temp = [...this.state.file_list_chung_chi_insert];
      temp.push(file);
      this.setState({
        file_list_chung_chi_insert: temp,
      });
    }
    this.setState({ fileListChungChi: fileList });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64FromList(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleRemoveCMND = (file) => {
    let temp = [...this.state.file_list_cmnd_delete];
    temp.push(file);
    this.setState({
      file_list_cmnd_delete: temp,
    });
  };

  handleRemoveChungChi = (file) => {
    let temp = [...this.state.file_list_chung_chi_delete];
    temp.push(file);
    this.setState({
      file_list_chung_chi_delete: temp,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const uploadWallPictureButton = (
      <div>
        <Icon type={this.state.loadingWallPicture ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const uploadMultiButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const {
      previewVisible,
      previewImage,
      fileListCMND,
      fileListChungChi,
      imageUrl,
      imageUrlWallPicture,
      broker_categories,
    } = this.state;

    return (
      <React.Fragment>
        <Form onSubmit={this.handleChangeProfile.bind(this)}>
          <Row>
            <Col>
              <Row>
                <Col xs={24}>
                  <Form.Item label="Banner">
                    {getFieldDecorator("wall_picture")(
                      <Upload
                        name="logo"
                        className="banner"
                        listType="picture-card"
                        showUploadList={false}
                        action={uploadUrl}
                        onChange={this.handleChangeWallPicture}
                      >
                        {imageUrlWallPicture ? (
                          <img
                            src={imageUrlWallPicture}
                            onError={(curerntError) => {
                              curerntError.currentTarget.src = DefaultBanner;
                            }}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadWallPictureButton
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item label="Ảnh đại diện">
                    {getFieldDecorator("avatar")(
                      <Upload
                        name="logo"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={uploadUrl}
                        onChange={this.handleChange}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            onError={(curerntError) => {
                              curerntError.currentTarget.src = DefaultAvatar;
                            }}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item label="CMND">
                    {getFieldDecorator("cmnd")(
                      <React.Fragment>
                        <Upload
                          name="logo"
                          action={uploadUrl}
                          listType="picture-card"
                          fileList={fileListCMND}
                          multiple
                          onPreview={this.handlePreview}
                          onChange={this.handleChangeCMND}
                          onRemove={this.handleRemoveCMND}
                        >
                          {fileListCMND.length >= 8 ? null : uploadMultiButton}
                        </Upload>
                        <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={this.handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </React.Fragment>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item label="Chứng chỉ môi giới">
                    {getFieldDecorator("chung_chi")(
                      <React.Fragment>
                        <Upload
                          name="logo"
                          action={uploadUrl}
                          listType="picture-card"
                          fileList={fileListChungChi}
                          multiple
                          onPreview={this.handlePreview}
                          onChange={this.handleChangeChungChi}
                          onRemove={this.handleRemoveChungChi}
                        >
                          {fileListChungChi.length >= 8
                            ? null
                            : uploadMultiButton}
                        </Upload>
                        <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={this.handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </React.Fragment>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Tôi là">
                {getFieldDecorator(
                  "broker_category_id",
                  {}
                )(
                  <Select placeholder="Chọn một loại">
                    {broker_categories.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="My domain">
                {getFieldDecorator("slug", {
                  rules: [
                    {
                      required: true,
                      message: "Vui lòng nhập tên domain!",
                    },
                  ],
                })(
                  <Input
                    style={{ textAlign: "right" }}
                    addonAfter=".homesface.com"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Họ">
                {getFieldDecorator("last_name", {
                  rules: [
                    {
                      required: true,
                      message: "Vui lòng nhập họ!",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tên">
                {getFieldDecorator("first_name", {
                  rules: [
                    {
                      required: true,
                      message: "Vui lòng nhập tên!",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Số điện thoai">
                {getFieldDecorator("phone", {
                  rules: [
                    {
                      validator: this.validatePhoneNumber,
                    },
                  ],
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Địa chỉ email">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: "Địa chỉ email không hợp lệ!",
                    },
                  ],
                })(<Input disabled />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Zalo">
                {getFieldDecorator("zalo", {
                  rules: [
                    {
                      validator: this.validatePhoneNumber,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Trang Facebook">
                {getFieldDecorator("facebook", {
                  rules: [
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i
                      ),
                      message: "Địa chỉ trang Facebook không hợp lệ!",
                    },
                  ],
                })(<Input addonBefore="https://www.facebook.com/" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Form.Item label="Thông tin về tôi">
                    {getFieldDecorator("about_us")(
                      <Input.TextArea allowClear autoSize={{ minRows: 8 }} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Form.Item>
              <Col span={24} style={{ textAlign: "end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.isSubmit ? true : false}
                  onClick={this.handleChangeProfile.bind(this)}
                >
                  Cập nhật thông tin
                </Button>
              </Col>
            </Form.Item>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AboutUsForm));
