import React from "react";
import { Icon, List } from "antd";
import "./listVertical.scss";
import { Link } from "react-router-dom";
import loginSignUpApi from "../../api/loginSignUp";
import { connect } from "react-redux";
import { logout } from "../../store/actions/profile";
import User from "../../models/User";

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

const data = [
  {
    name: "Dashboard",
    icon: <Icon type="dashboard" />,
    link: "/me/dashboard"
  },
  {
    name: "Nhà đất của tôi",
    icon: <Icon type="bank" />,
    link: "/me/history"
  },
  {
    name: "Bài đánh giá của tôi",
    icon: <Icon type="container" />,
    link: "/me/article"
  },
  {
    name: "Khách hàng của tôi",
    icon: <Icon type="team" />,
    link: "/me/buyers"
  },
  {
    name: "Thông tin tài khoản",
    icon: <Icon type="edit" />,
    link: "/user/my-profile"
  },
  {
    name: "Liên hệ",
    icon: <Icon type="contacts" />,
    link: "/me/contact"
  },
  {
    name: "Đăng xuất",
    icon: <Icon type="logout" />,
    link: "/"
  }
];

class ListVertical extends React.Component {
  handleLogOut = () => {
    loginSignUpApi.logout().then(res => {
      if (res.data.status) {
        this.props.logout();
        User.logout();
      }
    });
  };

  render() {
    return (
      <div className="list-vertical-menu">
        <List
          bordered
          dataSource={data}
          renderItem={(item) => (
            <Link to={item.link}>
              <List.Item
                className={this.props.activeLink === item.name && "active"}
                onClick={
                  item.name === "Đăng xuất"
                    ? this.handleLogOut.bind(this)
                    : null
                }
              >
                {item.icon}&nbsp;&nbsp;{item.name}
              </List.Item>
            </Link>
          )}
        />
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ListVertical);
