import { Button, Form, Radio, Typography } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { reCaptchaKey } from "../../../config/main";
import { getAparment } from "../../../utils";
import styles from "../Cart.module.scss";
import { BUYER_TYPES, BUYER_TYPE_PERSONAL_VALUE } from "../constants";
import { converBuyerTypeNumberToTypeString, getBuyerValue } from "../utils";
import AddressForm from "./AddressForm";
import OrganizationForm from "./OrganizationForm";
import PersonalForm from "./PersonalForm";

class BuyerForm extends Component {
  state = {
    buyerType: BUYER_TYPE_PERSONAL_VALUE,
    isVerifiedReCaptcha: false,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const aparment = getAparment();
        let params = null;
        if(values.type === BUYER_TYPE_PERSONAL_VALUE) {
          params = {
            ...values,
            personal: {
              ...values.personal,
              date_of_birth: moment(values.personal.date_of_birth).format('YYYY-MM-DD'),
              identifier_issue_at: moment(values.personal.identifier_issue_at).format('YYYY-MM-DD'),
            }
          }
        } else {
          params = {
            ...values,
            organization: {
              ...values.organization,
              identifier_issue_at: moment(values.organization.identifier_issue_at).format('YYYY-MM-DD'),
              date_of_birth: moment(values.organization.date_of_birth).format('YYYY-MM-DD'),
            }
          }
        }
        params = {
          ...params,
          project_real_estate_id: aparment.ApartmentID,
          project_cart_id: aparment.SAPID,
        }
        this.props.createBookingMutation.mutate(params);
      }
    });
  };
  componentWillMount() {
    if (this.props.bookedItem) {
      this.setState({
        buyerType: this.props.bookedItem.type,
      });
    }
  }
  componentDidMount() {
    if (this.props.bookedItem) {
      const buyerTypeString = converBuyerTypeNumberToTypeString(
        this.props.bookedItem.type
      );
      const buyerValues = getBuyerValue(buyerTypeString, this.props.bookedItem);
      this.props.form.setFieldsValue({
        ...buyerValues,
      });
    } else {
      this.props.form.setFieldsValue({
        type: BUYER_TYPE_PERSONAL_VALUE,
      });
    }
  }

  handleChangeRadioBuyerType = (e) => {
    this.setState({
      buyerType: e.target.value,
    });
    this.props.form.setFieldsValue({
      type: e.target.value,
    });
  };
  handleValidatePhoneNumber = (rule, value, callback) => {
    const phoneNumber = /^\d{10}$/;
    if (value && !value.match(phoneNumber)) {
      callback("Số điện thoại là số có 10 chữ số!");
    } else {
      callback();
    }
  };
  handleChangeCheckBoxPersonal = (event) => {
    const profile = this.props.profile;
    if (event.target.checked) {
      this.props.form.setFieldsValue({
        personal: {
          email: profile?.email,
          phone_number: profile?.phone,
          name: profile?.full_name,
        },
      });
    } else {
      this.props.form.setFieldsValue({
        personal: {
          email: "",
          phone_number: "",
          name: "",
        },
      });
    }
  };
  handleChangeReCaptcha = () => {
    this.setState({
      isVerifiedReCaptcha: true,
    });
  };
  handleChangeCheckBoxOrganization = (event) => {
    const profile = this.props.profile;
    if (event.target.checked) {
      this.props.form.setFieldsValue({
        organization: {
          email: profile.email,
          phone_number: profile.phone,
          name: profile.full_name,
        },
      });
    } else {
      this.props.form.setFieldsValue({
        organization: {
          email: "",
          phone_number: "",
          name: "",
        },
      });
    }
  };
  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } =
      this.props.form;
    return (
      <>
        <Form className={styles.createBuyerForm} onSubmit={this.handleSubmit}>
          <Typography.Title
            className={`${styles.title} ${styles.customerHeading}`}
            level={4}
          >
            Đối tượng khách hàng
          </Typography.Title>
          <Form.Item label="">
            {getFieldDecorator("type")(<></>)}
            <Radio.Group
              className={styles.buyerTypeGroup}
              onChange={this.handleChangeRadioBuyerType}
              value={this.state.buyerType}
              disabled={this.props.view}
            >
              {BUYER_TYPES.map((item) => (
                <Radio
                  key={item.value}
                  className={styles.buyerTypeItem}
                  value={item.value}
                >
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <div className={styles.buyerInfoTop}>
            {this.state.buyerType === BUYER_TYPE_PERSONAL_VALUE ? (
              <PersonalForm
                getFieldDecorator={getFieldDecorator}
                setFieldsValue={setFieldsValue}
                onValidatePhoneNumber={this.handleValidatePhoneNumber}
                onChangeCheckBoxPersonal={this.handleChangeCheckBoxPersonal}
                isDisabled={this.props.view}
                getFieldValue={getFieldValue}
              />
            ) : (
              <OrganizationForm
                getFieldDecorator={getFieldDecorator}
                setFieldsValue={setFieldsValue}
                onValidatePhoneNumber={this.handleValidatePhoneNumber}
                onChangeCheckBoxOrganization={
                  this.handleChangeCheckBoxOrganization
                }
                isDisabled={this.props.view}
                getFieldValue={getFieldValue}
              />
            )}
          </div>
          <Typography.Title className={styles.title} level={4}>
            Địa chỉ
          </Typography.Title>
          <AddressForm
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            isDisabled={this.props.view}
          />
          <>
            {!this.props.view && (
              <>
                <br />
                <ReCAPTCHA
                  sitekey={reCaptchaKey}
                  onChange={this.handleChangeReCaptcha}
                />
                <Form.Item className={styles.submitButtonWrapper}>
                  <Button
                    className={styles.submitButton}
                    type="primary"
                    htmlType="submit"
                    onClick={this.handleSubmit.bind(this)}
                    loading={this.props.createBookingMutation.isLoading}
                    disabled={!this.state.isVerifiedReCaptcha}
                  >
                    Xác nhận thông tin
                  </Button>
                </Form.Item>
              </>
            )}
          </>
        </Form>
      </>
    );
  }
}
BuyerForm.defaultProps = {
  createBookingMutation: null,
  view: false,
};
BuyerForm.propTypes = {
  createBookingMutation: PropTypes.object,
  view: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.infor,
  };
};

export default connect(mapStateToProps, null)(Form.create()(BuyerForm));
