import { Form, Icon, Input } from "antd";
import React, { Component } from "react";
import styles from "../Auth.module.scss";
import PropTypes, { arrayOf } from "prop-types";

export class FormItemInputPassword extends Component {
  render() {
    const { getFieldDecorator, name, label, rules, placeholder } = this.props;
    return (
      <Form.Item label={label}>
        {getFieldDecorator(name, {
          rules,
        })(
          <Input.Password
            className={styles.inputItem}
            addonBefore={<Icon type="key" />}
            placeholder={placeholder}
          />
        )}
      </Form.Item>
    );
  }
}

FormItemInputPassword.defaultProps = {
  getFieldDecorator: null,
  name: null,
  rule: null,
  placeholder: "",
};
FormItemInputPassword.propTypes = {
  getFieldDecorator: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.element,
  rule: arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      message: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
};

export default FormItemInputPassword;
