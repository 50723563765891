import Home from "./page/home/home";
import Detail from "./page/detail/detail";
import RealEstate from "./page/RealEstate";
import Search from "./page/search/search";
import Profile from "./page/profile/profile";
import PostRealEstate from "./page/postRealEstate/postRealEstate";
import HistoryPost from "./page/historyPost/historyPost";
import ProjectApartment from "./page/projectApartment/projectApartment";
import Investor from "./page/investor/investor";
import MyArticle from "./page/myArticle/myArticle";
import CreateEditArticle from "./page/createEditArticle/createEditArticle";
import Broker from "./page/broker/broker";
import Blog from "./page/blog/blog";
import BlogDetail from "./page/blog/blogDetail";
import MyCustomer from "./page/myCustomer";
import Event from "./page/event/event";
import EventDetail from "./page/event/eventDetail";
import SEO from "./api/seo";
import RealEstatePush from "./page/realEstatePush";
import Contact from "./page/myContact";
import PaymentFail from "./page/payment/PaymentFail";
import PaymentSuccess from "./page/payment/PaymentSuccess";
import PriceByMonth from "./page/PriceByMonth";
import MyProfile from "./page/User/MyProfile";
import Dashboard from "./page/User/Dashboard";
import RealEstates from "./page/User/RealEstate/RealEstates";
import EditRealEstate from "./page/User/RealEstate/EditRealEstate";
import CreateRealEstate from "./page/User/RealEstate/CreateRealEstate";
import PushRealEstate from "./page/User/RealEstate/PushRealEstate";
import Customers from "./page/User/Customers";
import Aticles from "./page/User/Article/Articles";
import CreateArticle from "./page/User/Article/CreateArticle";
import EditArticle from "./page/User/Article/EditArticle";
import Contacts from "./page/User/Contacts";
import BookingList from "./page/User/Booking/BookingList";
import SignIn from "./page/Auth/SignIn";
import SignUp from "./page/Auth/SignUp";
import OnlinePrices from "./page/OnlinePrices";
import CreateBuyer from "./page/Cart/CreateBuyer";
import BookingDettail from "./page/User/Booking/BookingDettail";
import ProjectCarts from "./page/ProjectCarts";
import Transaction from "./page/Cart/Transaction";
import Projects from './page/Project/Projects';
import ProjectDetail from './page/Project/ProjectDetail';

const routes = [
  {
    path: "/",
    main: Home,
    exact: true,
  },
  {
    path: "/detail",
    main: Detail,
  },
  {
    path: "/bat-dong-san/:name",
    main: RealEstate,
    seo: (params) => {
      return SEO.realStateSeo(params)
        .then((result) => result.data)
        .catch((error) => console.error(error));
    },
  },
  {
    path: "/me/profile",
    main: Profile,
    private: true,
  },
  {
    path: "/me/real-estate/edit/:slug",
    main: PostRealEstate,
    private: true,
  },
  {
    path: "/create-real-estate",
    main: PostRealEstate,
    private: true,
  },
  {
    path: "/me/history",
    main: HistoryPost,
    private: true,
  },
  {
    path: "/me/contact",
    main: Contact,
    private: true,
  },
  {
    path: "/project-apartment/:slug",
    main: ProjectApartment,
  },
  {
    path: "/me/dashboard",
    main: Dashboard,
    private: true,
  },
  {
    path: "/price-by-month",
    main: PriceByMonth,
  },
  {
    path: "/me/article",
    main: MyArticle,
    private: true,
    exact: true,
  },
  {
    path: "/chu-dau-tu/:slug",
    main: Investor,
  },
  {
    path: "/me/article/edit/:slug",
    main: CreateEditArticle,
    private: true,
    exact: true,
  },
  {
    path: "/me/article/create",
    main: CreateEditArticle,
    private: true,
    exact: true,
  },
  {
    path: ["/broker/:slug", "/broker/:slug/:slug_article"],
    main: Broker,
    exact: true,
  },
  {
    path: ["/blog", "/blog/catalog/:slug"],
    main: Blog,
    exact: true,
    seo: (params = null) => {
      return new Promise((resolve, reject) => {
        resolve({
          seo: {
            title: "Tin tức",
            description: "Các tin tức nóng hổi về thị trường bất động sản",
          },
        });
      });
    },
  },
  {
    path: "/blog/:slug",
    main: BlogDetail,
    exact: true,
    seo: (params) => {
      return SEO.blogSeo(params)
        .then((result) => result.data)
        .catch((error) => console.error(error));
    },
  },
  {
    path: "/me/buyers",
    main: MyCustomer,
    exact: true,
    private: true,
  },
  {
    path: ["/event", "/event/category/:slug"],
    main: Event,
    exact: true,
  },
  {
    path: "/event/:slug",
    main: EventDetail,
    exact: true,
  },
  {
    path: "/:slug.html",
    main: Search,
    exact: true,
  },
  {
    path: "/me/real-estate-push/:slug",
    main: RealEstatePush,
    exact: true,
    private: true,
  },
  {
    path: "/payment-success",
    main: PaymentSuccess,
    exact: true,
  },
  {
    path: "/payment-fail",
    main: PaymentFail,
    exact: true,
  },
  {
    path: "/user/my-profile",
    main: MyProfile,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/dashboard",
    main: Dashboard,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/real-estates",
    main: RealEstates,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/real-estates/:slug/edit",
    main: EditRealEstate,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/real-estate/create",
    main: CreateRealEstate,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/real-estates/:slug/push",
    main: PushRealEstate,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/customers",
    main: Customers,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/articles",
    main: Aticles,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/article/create",
    main: CreateArticle,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/articles/:slug/edit",
    main: EditArticle,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/contacts",
    main: Contacts,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/booking-list",
    main: BookingList,
    exact: true,
    private: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/user/booking-list/:id",
    main: BookingDettail,
    private: true,
    exact: true,
    renderWithoutMainlayout: true,
  },
  {
    path: "/sign-in",
    main: SignIn,
    exact: true,
  },
  {
    path: "/sign-up",
    main: SignUp,
    exact: true,
  },
  {
    path: "/gio-hang-booking/du-an/:id",
    main: OnlinePrices,
    exact: true,
  },
  {
    path: "/cart/buyer-info/create",
    main: CreateBuyer,
    private: true,
    exact: true,
  },
  {
    path: "/cart/transaction/order/:id",
    main: Transaction,
    private: true,
    exact: true,
  },
  {
    path: "/project-carts",
    main: ProjectCarts,
    exact: true,
  },
  {
    path: "/du-an-bat-dong-san",
    main: Projects,
    exact: true,
  },
  {
    path: "/du-an-bat-dong-san/:slug",
    main: ProjectDetail,
    exact: true,
    seo: (params) => {
      return SEO.projectSeo(params)
        .then((result) => result.data)
        .catch((error) => console.error(error));
    },
  }
];

export default routes;
