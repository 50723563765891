import React from "react";
import {Affix, Avatar, Button, Icon, List, PageHeader} from "antd";
import styles from "./jusSold.module.scss";
import Services from "../../api/services";
import CustomCard from "../card/customCard";
import Animate from "rc-animate";
import AvatarDefault from '../../images/avatar-default.png';
import {Session} from "../../functions/session";
import CardSlider from "../card_slider/cardSlider";
import {Link} from "react-router-dom";
import {isMobile} from "react-device-detect";
import _ from "lodash";

class JusSold extends React.Component{
    state = {
        data: [],
        show: Session.get('soldShow', "true") === "true"
    };


    componentDidMount(): void {
        Services.getJustSold().then(res => {
            this.setState({
                data: res.sold
            })
        })
    }

    onClick(){
        this.setState(state => {
            const show = !state.show;
            Session.put('soldShow', show);
            return {
                show
            }
        });
    }

    render() {
        return (
            this.state.data.length > 0 ? <Affix offsetBottom={0} className={styles.sold} style={{
                    right: 0,
                    zIndex: 1001
                }}>
                    {this.state.show ? (
                        <PageHeader
                            style={{
                                float: "right"
                            }}
                            title={"Chúc mừng giao dịch thành công"}
                            extra={[
                                <Button key="close" style={{
                                    color: "black"
                                }} onClick={() => this.onClick()} type="link" ghost icon={this.state.show ? 'close' : 'up'}/>
                            ]}
                        >
                            <Animate
                                component=""
                                transitionName="fade"
                            >
                                <CardSlider className={styles.cardListSold} slideItem={1}>
                                    {this.state.data.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className={styles.userSold}>
                                                <List
                                                    size="large"
                                                    style={{
                                                        padding: "0 10px"
                                                    }}
                                                >
                                                    <List.Item>
                                                        {!_.isEmpty(item.broker) ? (
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <Avatar size={isMobile ? 32 : 64} srcSet={AvatarDefault} src={item.broker.avatar ? item.broker.avatar : AvatarDefault} />
                                                                }
                                                                title={<Link to={ '/broker/' + item.broker.slug }>{ item.broker.full_name } </Link>}
                                                                description={
                                                                    <React.Fragment>
                                                                        <Button size={isMobile ? "small" : "default"} shape="round" type="danger">
                                                                            <Icon type="message" />
                                                                            Chat ngay
                                                                        </Button>
                                                                        <Button href={"tel:"+item.broker.phone} size={isMobile ? "small" : "default"} shape="round" type="ghost">
                                                                            <Icon type="phone" theme="filled" />
                                                                            {item.broker.phone}
                                                                        </Button>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        ) : null}
                                                    </List.Item>
                                                </List>
                                            </div>
                                            <CustomCard className="customStyleCard" sourceContent={item}/>
                                        </React.Fragment>
                                    ))}
                                </CardSlider>
                            </Animate>
                        </PageHeader>
                    ) : <div/>}
                </Affix> : null
        );
    }
}

export default JusSold;