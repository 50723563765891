import React from "react";
import { Row, Col, Tabs, Breadcrumb, Icon } from "antd";
import "./profile.scss";
import ProfileForm from "../../component/profileForm/profileForm";
import BrokerArea from "../../component/brokerArea/brokerArea";
import ListVertical from "../../component/listVertical/listVertical";
import AboutUsForm from "../../component/aboutUsForm/aboutUsForm";

const { TabPane } = Tabs;

class Profile extends React.Component {
  render() {
    return (
      <div className="frontPageSection">
        <div className="section-wrap-private">
          <Row gutter={32}>
            <Col span={24} style={{ marginBottom: "1rem" }}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Icon type="home" />
                  <span>Trang chủ</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>Thông tin tài khoản</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col
              className="hide-on-mobile"
              xs={24}
              sm={24}
              md={7}
              lg={6}
              xl={4}
            >
              <ListVertical activeLink={`Thông tin tài khoản`} />
            </Col>
            <Col xs={24} sm={24} md={17} lg={18} xl={20} className="section-table">
              <div className="table-history">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Thông tin tài khoản" key="1">
                    <AboutUsForm />
                  </TabPane>
                  <TabPane tab="Đổi mật khẩu" key="2">
                    <ProfileForm />
                  </TabPane>
                  <TabPane tab="Khu vực môi giới" key="3">
                    <BrokerArea />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Profile;
